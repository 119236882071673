import { styled } from '@mui/material/styles';
import { SovosSelect } from 'mosaic-react';

const FilingSelect = styled(SovosSelect)(({ theme: { palette, spacing } }) => ({
  color: palette.text.hint,
  '&.MuiTextField-root': {
    width: spacing(18),
  },
}));

export default FilingSelect;
