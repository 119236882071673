export const initialState = {
  isLoading: false,
  list: [],
  totalItems: 0,
};

export function usersReducer(state, action) {
  switch (action.type) {
    case 'LoadUsersBegin':
      return { ...state, isLoading: true, list: [], totalItems: 0 };
    case 'LoadUsersSuccess':
      return {
        ...state,
        isLoading: false,
        list: action.users || [],
        totalItems: parseInt(action.totalItems, 10),
      };
    case 'LoadUsersFailure':
      return { ...state, isLoading: false, list: [], totalItems: 0 };
    default:
      return state;
  }
}
