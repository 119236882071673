import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  contentSidePadding,
  contentToolbarActions,
  contentTablePadding,
} from '../internals/page-layout/contentPadding';
import SovosDataGrid from '../sovos-data-grid';
import { spacingToNumber } from '../internals/utils';

const StyledDataGrid = styled(SovosDataGrid)(
  ({ theme: { breakpoints, spacing } }) => {
    const padding = contentSidePadding(breakpoints, spacing);
    const toolbarPadding = contentToolbarActions(breakpoints, spacing);
    const tablePadding = contentTablePadding(breakpoints, spacing);
    return {
      '& .sovosTableGroup__noTable': {
        ...padding,
      },
      '& .sovosTableGroup__tableWrapper': {
        ...tablePadding,
      },
      '& .sovosPaginationFooter': {
        ...padding,
        marginTop: 0,
      },
      '& .sovosDataGrid__toolbar.MuiToolbar-root': {
        borderTop: 'none',
        ...toolbarPadding,
        height: spacingToNumber(spacing(6)) - 1,
        minHeight: spacingToNumber(spacing(6)) - 1,
      },
    };
  }
);

/**
 * `SovosPageContentDataGrid` should only be used as a child of
 * `SovosPageLayout` or `SovosPageContentTwoColumnMain`, and is responsible
 * for responsively setting the amount of padding and handling other
 * aspects of full-page layout. It is otherwise identical to
 * `SovosDataGrid` and takes all the same props.
 */
const SovosPageContentDataGrid = ({ className, ...rest }) => (
  <StyledDataGrid
    autoHeight
    className={clsx('sovosPageContentDataGrid', className)}
    {...rest}
  />
);

SovosPageContentDataGrid.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosPageContentDataGrid.defaultProps = {
  className: undefined,
};

SovosPageContentDataGrid.baseComponent = {
  name: 'SovosDataGrid',
};

export default SovosPageContentDataGrid;
