import React from 'react';
import { node, object, string } from 'prop-types';
import clsx from 'clsx';
import { alpha, styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';

const StyledTypography = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: alpha(palette.common.black, 0.5),
    paddingBottom: spacing(2),
  })
);

/**
 * `SovosSettingsSubtitle` contains text describing the settings section.
 *
 * **Note**: Must be a child of `SovosSettingsSection`
 */
const SovosSettingsSubtitle = ({ className, classes, children, ...rest }) => (
  <StyledTypography
    variant="body1"
    className={clsx('sovosSettingsSubtitle', className, classes?.root)}
    {...rest}
  >
    {children}
  </StyledTypography>
);

SovosSettingsSubtitle.propTypes = {
  /**
   * The content of the subtitle
   */
  children: node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosSettingsSubtitle.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default SovosSettingsSubtitle;
