import React from 'react';
import { node } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosTypography from '../../sovos-typography';
import SovosDivider from '../../sovos-divider';
import SovosFixedWidthText from '../../sovos-fixed-width-text';

const Root = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  minHeight: spacing(5),
  margin: 0,
  padding: `0 ${spacing(2)}`,
}));

const StyledDivider = styled(SovosDivider)(({ theme: { spacing } }) => ({
  position: 'absolute',
  top: '50%',
  left: spacing(2),
  right: spacing(2),
}));

const StyledTypography = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    background: palette.common.white,
    display: 'inline-block',
    paddingRight: spacing(),
    maxWidth: '100%',
    position: 'relative',
  })
);

const SectionDivider = ({ content }) => (
  <Root>
    <StyledDivider />
    {content && (
      <StyledTypography
        className="sovosTreeListItem__header"
        color="text.secondary"
        component="h6"
        variant="body1"
      >
        {typeof content === 'string' ? (
          <SovosFixedWidthText text={content} className="sovosTreeListText" />
        ) : (
          <>{content}</>
        )}
      </StyledTypography>
    )}
  </Root>
);

SectionDivider.propTypes = {
  content: node,
};

SectionDivider.defaultProps = {
  content: undefined,
};

export default SectionDivider;
