import React, { forwardRef } from 'react';
import { any, array, bool } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosLink from '../../sovos-link/SovosLink';

const BreadcrumbList = styled('ol')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
  whiteSpace: 'nowrap',
});

const Breadcrumb = styled('li')(({ theme: { palette, typography } }) => ({
  ...typography.body1,
  color: palette.text.secondary,
  margin: 0,
  padding: 0,
  display: 'inline',
}));

const BreadcrumbText = styled(SovosLink)(
  ({ theme: { palette, transitions } }) => ({
    color: palette.text.secondary,
    transition: `color ${transitions.duration.complex}ms`,
    outline: 'none',

    '&.sovosPageTitleBar__breadCrumbText--clickable': {
      '&:hover': {
        cursor: 'pointer',
        color: palette.text.primary,
        textDecoration: 'underline',
      },
    },
  })
);

const BreadcrumbDivider = styled('span')(({ theme: { spacing } }) => ({
  padding: `0 ${spacing(0.75)}`,
}));

export const abbreviatedText = '…';

export const BreadcrumbsWithoutRef = ({
  ancestorsCollapsed,
  forwardedRef,
  pageCrumbs,
  parentCollapsed,
}) => {
  const renderBreadcrumb = (item, isCollapsed) => (
    <BreadcrumbText
      onClick={item.onClick}
      className={clsx(
        'sovosPageTitleBar__breadCrumbText',
        !!item.onClick && 'sovosPageTitleBar__breadCrumbText--clickable'
      )}
      tabIndex={0}
      role="button"
      title={isCollapsed && item.text}
    >
      {isCollapsed ? abbreviatedText : item.text}
    </BreadcrumbText>
  );

  return (
    <nav
      ref={forwardedRef}
      className="sovosPageTitleBar__breadcrumbsContainer"
      aria-label="breadcrumb"
    >
      <BreadcrumbList>
        {pageCrumbs?.map((element, index) => {
          const isLastItem = index === pageCrumbs.length - 1;
          const isCollapsed =
            parentCollapsed || (!isLastItem && ancestorsCollapsed);

          return (
            <Breadcrumb
              className="sovosPageTitleBar__breadCrumb"
              key={`BreadCrumb-${element.text}`}
            >
              {renderBreadcrumb(element, isCollapsed)}

              {!isLastItem && (
                <BreadcrumbDivider aria-hidden="true">/</BreadcrumbDivider>
              )}
            </Breadcrumb>
          );
        })}
      </BreadcrumbList>
    </nav>
  );
};

BreadcrumbsWithoutRef.propTypes = {
  ancestorsCollapsed: bool.isRequired,
  forwardedRef: any.isRequired,
  pageCrumbs: array.isRequired,
  parentCollapsed: bool.isRequired,
};

export default forwardRef((props, ref) => (
  <BreadcrumbsWithoutRef {...props} forwardedRef={ref} />
));
