import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  SovosButton,
  SovosPageLayout,
  SovosPageContentTwoColumn,
  SovosPageContentTwoColumnMain,
  SovosPageContentTwoColumnSidebar,
} from 'mosaic-react';
import Dialog from '../commonComponents/Dialog';
import ImportsSidebar from './ImportsSidebar';
import ImportsTable from './ImportsTable';

function ImportsPage() {
  const { palette } = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const openDialog = (text) => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  const addButtonClick = () => {
    openDialog('All transactions have been added');
  };

  function titleBarActions() {
    return [
      <SovosButton
        onClick={addButtonClick}
        key="add"
        className="ImportsPage__AddButton"
      >
        Add transaction
      </SovosButton>,
    ];
  }

  return (
    <SovosPageLayout
      pageTitle="Batch Imports"
      titleBarActions={titleBarActions()}
      className="ImportsPage__PageLayout"
    >
      <SovosPageContentTwoColumn>
        <SovosPageContentTwoColumnSidebar
          style={{
            borderRight: `1px solid ${palette.divider}`,
          }}
        >
          <ImportsSidebar />
        </SovosPageContentTwoColumnSidebar>
        <SovosPageContentTwoColumnMain>
          <ImportsTable />
        </SovosPageContentTwoColumnMain>
      </SovosPageContentTwoColumn>
      <Dialog
        text={dialogText}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </SovosPageLayout>
  );
}

export default ImportsPage;
