import React from 'react';
import { node, func, string, bool } from 'prop-types';
import { styled } from '@mui/material/styles';
import useMosaicTranslation from '../i18n/useMosaicTranslation';
import SovosTypography from '../../sovos-typography';
import sovosButtonsGroup from '../../sovos-buttons-group';

const Root = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

const ErrorMessage = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.error.main,
    marginTop: spacing(),
  })
);

const Buttons = styled(sovosButtonsGroup)(({ theme: { spacing } }) => ({
  marginTop: spacing(),
}));

const LoginFlowForm = ({
  className,
  formInputs,
  formButtons,
  submitFunction,
  hasError,
  errorMessage,
}) => {
  const { t } = useMosaicTranslation();

  return (
    <Root className={className} onSubmit={submitFunction}>
      {formInputs}
      {hasError && (
        <ErrorMessage>
          {errorMessage || t('loginFlowForm.errorMessage')}
        </ErrorMessage>
      )}
      <Buttons align="right">{formButtons}</Buttons>
    </Root>
  );
};

LoginFlowForm.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of inputs
   */
  formInputs: node.isRequired,
  /**
   * Array of inputs
   */
  formButtons: node.isRequired,
  /**
   * Function fired while submit
   */
  submitFunction: func.isRequired,
  /**
   * Error message to show when check email fails
   */
  errorMessage: string,
  /**
   * Did check email fail
   */
  hasError: bool,
};

LoginFlowForm.defaultProps = {
  className: undefined,
  errorMessage: undefined,
  hasError: false,
};

export default LoginFlowForm;
