/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  SovosAttachmentsListTab,
  SovosButton,
  SovosCard,
  SovosCardHeader,
  SovosCircularProgress,
  SovosCommentsListTab,
  SovosDialog,
  SovosGrid,
  SovosHeroBanner,
  SovosHeroBannerStatus,
  SovosHeroBannerSubtitle,
  SovosHeroBannerTitle,
  SovosHistoryListTab,
  SovosIconMenu,
  SovosMenuItem,
  SovosPageContentGeneric,
  SovosPageContentTwoColumn,
  SovosPageContentTwoColumnMain,
  SovosPageContentTwoColumnSidebar,
  SovosPageLayout,
  SovosTabGroup,
  SovosTypography,
} from 'mosaic-react';
import {
  AttachMoney,
  CalendarToday,
  ErrorOutline,
  MoreVert,
  ShoppingCart,
} from 'mosaic-react-icons';
import { useErrorSnackbar } from 'mosaic-react/hooks';
import assetResolver, { assetKeys } from 'mosaic-assets-resolver';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { invoiceData } from './services/invoice';

const SubtitleElement = (props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 2,
    }}
    {...props}
  />
);

const commentsData = [
  {
    id: 1,
    name: 'Adam',
    surname: 'Barker',
    comment: 'Thanks. Customer confirmed receipt of the invoice.',
    formattedDate: '3 hours ago',
  },
  {
    id: 2,
    name: 'Jill',
    surname: 'Smith',
    comment: 'Done. I sent the invoice to billing@acme.co.',
    formattedDate: 'Yesterday',
  },
  {
    id: 3,
    name: 'Adam',
    surname: 'Barker',
    comment:
      'Customer requests a copy of the invoice be forwarded to billing@acme.co.',
    formattedDate: '2 days',
  },
];

const historyData = [
  {
    username: 'Adam Barker',
    action: 'moved the return to step 3',
    timestamp: 2,
    timeLabel: 'an hour ago',
  },
  {
    username: 'Adam Barker',
    action: 'moved the return to complete',
    timestamp: 3,
    timeLabel: '3 minutes ago',
  },
  {
    username: 'First Last',
    action: 'moved the return to step 2',
    timestamp: 1,
    timeLabel: '2 hours ago',
  },
];

const attachmentsData = [
  {
    id: 1,
    document: 'Filename-here.doc',
    documentLink: 'http://google.com',
    timestamp: 2,
    timeLabel: '04/11/19 03:25 am',
    name: 'Abby',
    surname: 'Birch',
  },
  {
    id: 2,
    document: 'Another.doc',
    documentLink: 'http://google.com',
    timestamp: 3,
    timeLabel: '04/12/19 05:13 pm',
  },
  {
    id: 3,
    document: 'Lastfile.txt',
    documentLink: 'http://google.com',
    timestamp: 1,
    timeLabel: '04/11/19 01:32 am',
    name: 'Tim',
    surname: 'Cook',
  },
];

const InvoicePage = () => {
  const { id } = useParams();
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const [comments, setComments] = useState(commentsData);
  const { addError } = useErrorSnackbar();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const invoice = useSelector((state) => state.invoice);
  const { current } = invoice;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(invoiceData(id)).catch((err) => addError(err));
  }, [addError, dispatch, id]);

  const openDialog = (text) => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  const xmlButtonClick = () => {
    openDialog('Thanks for clicking. We admire your enthusiasm!');
  };

  const pdfButtonClick = () => {
    openDialog('Everyone loves PDFs.');
  };

  const deleteClick = (attachment) => {
    const { attachmentId } = attachment;
    openDialog(`Delete clicked for id: ${attachmentId}`);
  };

  const downloadClick = (attachment) => {
    const { attachmentId } = attachment;
    openDialog(`Download clicked for id: ${attachmentId}`);
  };

  const getHeroBannerColor = (status) => {
    switch (status) {
      case 'Authorized':
        return 'success';
      case 'Failed Authorization':
        return 'error';
      default:
        return 'default';
    }
  };

  const getPageTitle = () =>
    current && current.id
      ? `${current.purchasedBy.companyName} - #${current.id}`
      : 'loading';

  const titleBarActions = () => [
    <SovosIconMenu key="b" iconElement={<MoreVert />}>
      <SovosMenuItem>Refresh</SovosMenuItem>
      <SovosMenuItem>Help</SovosMenuItem>
      <SovosMenuItem>Sign out</SovosMenuItem>
    </SovosIconMenu>,
    <SovosButton
      onClick={xmlButtonClick}
      key="export"
      className="InvoicePage__ExportButton"
    >
      XML
    </SovosButton>,
    <SovosButton
      onClick={pdfButtonClick}
      key="addInvoice"
      className="InvoicePage__AddButton"
    >
      PDF
    </SovosButton>,
  ];

  const getStatusNode = () => {
    if (current && current.id) {
      const line1 = current.errors ? 'Transmitting' : 'Govt. Approved';
      const line2 = current.errors
        ? `${current.errors} attempts`
        : format(parseISO(current.date), 'MM/dd/yyyy HH:mm a');
      return (
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            height: '100%',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div>{line1}</div>
          <div>{line2}</div>
        </Box>
      );
    }
    return null;
  };

  const renderHeroBanner = () => {
    if (current && current.id) {
      const bannerColor = getHeroBannerColor(current.status);
      const stateBackground =
        current.state === '' || bannerColor === 'error'
          ? undefined
          : assetResolver.heroBanners(
              assetKeys.heroBanners.states[current.state]
            );

      return (
        <SovosHeroBanner color={bannerColor} backgroundImage={stateBackground}>
          <SovosHeroBannerTitle>
            {current.type} #{current.id}
          </SovosHeroBannerTitle>
          <SovosHeroBannerSubtitle>
            <SubtitleElement>
              <SubtitleElement>
                <AttachMoney />
                {Number(current.invoiceTotal).toFixed(2)}
              </SubtitleElement>
              <SubtitleElement>
                <ShoppingCart />
                {current.numItems} items
              </SubtitleElement>
              <SubtitleElement>
                <CalendarToday />
                {formatDistanceToNow(parseISO(current.date))}
              </SubtitleElement>
            </SubtitleElement>
          </SovosHeroBannerSubtitle>
          <SovosHeroBannerStatus>{getStatusNode()}</SovosHeroBannerStatus>
        </SovosHeroBanner>
      );
    }
    return null;
  };

  const onAddComment = (comment) => {
    const commentsArray = [...comments];
    commentsArray.push({
      id: commentsData.length + 1,
      comment,
      name: 'New',
      surname: 'User',
      formattedDate: 'Just now',
    });
    setComments(commentsArray);
  };

  const onAttachmentClicked = () => {
    console.warn('adding attachment not implemented yet');
  };

  const renderHeaderAddress = (title, company) => (
    <SovosGrid
      item
      xs={4}
      component="address"
      sx={{
        fontStyle: 'normal',
      }}
    >
      <SovosTypography
        color="text.secondary"
        component="h3"
        sx={{
          paddingBottom: 0.5,
        }}
        variant="body1"
      >
        {title}
      </SovosTypography>
      <SovosTypography variant="subtitle2">
        {company.companyName}
      </SovosTypography>
      <SovosTypography variant="body1">{company.address}</SovosTypography>
      <SovosTypography variant="body1">{company.state}</SovosTypography>
    </SovosGrid>
  );

  const renderInvoiceDetailsRow = (label, value) => (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'grey.200',
        color: 'text.secondary',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        typography: 'body1',
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <span>{label}</span>
      <span>{value}</span>
    </Box>
  );

  return (
    <SovosPageLayout
      pageTitle={getPageTitle()}
      pageCrumbs={[
        {
          text: 'eInvoices - Sent',
          onClick: () => navigate('/invoices/all'),
        },
      ]}
      titleBarActions={titleBarActions()}
      className="InvoicePage__PageLayout"
    >
      {renderHeroBanner()}
      {!invoice.isLoading ? (
        <div>
          {current && current.id ? (
            <SovosPageContentTwoColumn mainAlignment="left">
              <SovosPageContentTwoColumnMain>
                <SovosPageContentGeneric>
                  <SovosCard>
                    <SovosCardHeader>
                      <SovosGrid container spacing={3}>
                        {renderHeaderAddress('Sold By', current.soldBy)}
                        {renderHeaderAddress(
                          'Invoiced By',
                          current.purchasedBy
                        )}
                        {renderHeaderAddress('Ship To', current.shipTo)}
                      </SovosGrid>
                    </SovosCardHeader>
                    <SovosGrid container>
                      <SovosGrid item xs={8}>
                        {renderInvoiceDetailsRow(
                          'Government Reference ID',
                          '546231354769879854'
                        )}
                        {renderInvoiceDetailsRow(
                          'Total',
                          current.invoiceTotal.toFixed(2)
                        )}
                        {renderInvoiceDetailsRow(
                          'Total Value of Products and Services',
                          current.invoiceTotal.toFixed(2)
                        )}
                        {renderInvoiceDetailsRow(
                          'FCP Value per ST',
                          current.invoiceTotal.toFixed(2)
                        )}
                        {renderInvoiceDetailsRow(
                          `Total value of ${current.type}`,
                          current.invoiceTotal.toFixed(2)
                        )}
                      </SovosGrid>
                    </SovosGrid>
                  </SovosCard>
                  <SovosTabGroup sx={{ marginTop: 2 }}>
                    <SovosCommentsListTab
                      data={comments}
                      onAddComment={onAddComment}
                      onAddCommentAttachment={onAttachmentClicked}
                    />
                    <SovosHistoryListTab data={historyData} />
                    <SovosAttachmentsListTab
                      data={attachmentsData}
                      onDelete={deleteClick}
                      onDownload={downloadClick}
                    />
                  </SovosTabGroup>
                </SovosPageContentGeneric>
              </SovosPageContentTwoColumnMain>
              <SovosPageContentTwoColumnSidebar>
                {current && current.errors !== 0 && (
                  <SovosCard
                    sx={{
                      backgroundColor: 'error.main',
                      marginBottom: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <ErrorOutline
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 1,
                          margin: 'auto',
                          color: 'white',
                          fontSize: spacing(5),
                        }}
                      />
                      <SovosTypography
                        color="primary.contrastText"
                        paragraph
                        variant="body1"
                      >
                        We are unable to transmit, which usually means that the
                        government server is offline. We will continue to resend
                        this document every 2 minutes until successful
                      </SovosTypography>
                    </Box>
                  </SovosCard>
                )}
                <SovosCard>
                  <Box
                    sx={{
                      height: spacing(5),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Status
                  </Box>
                </SovosCard>
              </SovosPageContentTwoColumnSidebar>
            </SovosPageContentTwoColumn>
          ) : (
            <div>loading error...</div>
          )}
        </div>
      ) : (
        <SovosPageContentGeneric>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 7,
            }}
          >
            <SovosCircularProgress size={100} aria-label="Loading Data" />
          </Box>
        </SovosPageContentGeneric>
      )}
      <SovosDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        {dialogText}
      </SovosDialog>
    </SovosPageLayout>
  );
};

export default InvoicePage;
