import React, { useEffect, useMemo } from 'react';
import { bool, func, node, number, object, string } from 'prop-types';
import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import {
  findChildrenByType,
  findChildByType,
  filterOutChildrenByType,
} from '../internals/utils/reactHelpers';
import { useWindowSize } from '../hooks';
import SovosTabContent from '../sovos-tab-content/SovosTabContent';
import OverlayLayer from './components/overlayLayer/OverlayLayer';
import ContainerLayer from './components/containerLayer/ContainerLayer';
import SovosSlidingPanelHeader from '../sovos-sliding-panel-header';
import {
  getNumberOfSlides,
  createNewSlide,
  removeSlide,
} from './store/SovosSlidingPanelStore';
import { getPanelWidth } from './components/helper/width';

const StyledContent = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing(3),
}));

/**
 * This component is for unusual custom applications; most sliding panels
 * should be `SovosEditableSlidingPanel` or `SovosReadOnlySlidingPanel`.
 *
 * The panel's contents can include `SovosSlidingPanelHeader`,
 * `SovosSlidingPanelHeaderActionButtons`,
 * `SovosSlidingPanelHeaderAdditionalButtons`, and `SovosTabContent`,
 */
const SovosSlidingPanel = ({
  baseZIndex,
  children,
  className,
  classes,
  onClose,
  open,
  style,
  ...rest
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [order, setOrder] = React.useState(0);
  const { width: windowWidth } = useWindowSize();
  const theme = useTheme();
  const id = useMemo(() => Date.now(), []);

  useEffect(() => {
    if (open) {
      setOrder(getNumberOfSlides());
      createNewSlide(id);
    } else {
      removeSlide(id);
    }
    setSelectedTab(0);
    return () => removeSlide(id);
  }, [open, id]);

  const handleTabClick = (event, value) => {
    setSelectedTab(value);
  };

  const renderPanelHeader = () => {
    const tabs = findChildrenByType(children, SovosTabContent);
    const panelHeader = findChildByType(children, SovosSlidingPanelHeader);

    if (tabs) {
      const tabProps = {
        onTabClick: handleTabClick,
        tabs: tabs.map((tab) => ({
          label: tab.props.TabProps.label,
          icon: tab.props.TabProps.icon,
        })),
        selectedTab,
      };

      if (panelHeader) {
        return React.cloneElement(panelHeader, tabProps);
      }

      return <SovosSlidingPanelHeader {...tabProps} />;
    }

    return panelHeader;
  };

  const renderContent = () => {
    const tabs = findChildrenByType(children, SovosTabContent);
    let panelTabs = [];

    if (tabs.length) {
      panelTabs = tabs.map((t, index) => {
        const props = t.props.TabProps || {};
        return React.cloneElement(t, {
          // eslint-disable-next-line react/prop-types
          key: props.label || index,
          selected: index === selectedTab,
        });
      });
    }

    return [
      ...panelTabs,
      ...filterOutChildrenByType(
        children,
        SovosSlidingPanelHeader,
        SovosTabContent
      ),
    ];
  };

  const zIndex = open ? baseZIndex + order * 2 : 0;
  const paperWidth = getPanelWidth(order, windowWidth, theme);

  return (
    <Drawer
      anchor="right"
      BackdropComponent={OverlayLayer}
      BackdropProps={{
        order,
        windowWidth,
        zIndex: zIndex - 1,
        transitionDuration: theme.transitions.duration.standard,
      }}
      className={clsx('sovosSlidingPanel', classes?.root, className)}
      onClose={onClose}
      open={open}
      PaperProps={{
        style: {
          maxWidth: theme.spacing(181),
          width: paperWidth,
          zIndex,
        },
        elevation: 16,
      }}
      style={style}
      transitionDuration={theme.transitions.duration.standard}
      {...rest}
    >
      <ContainerLayer order={order} zIndex={zIndex}>
        {renderPanelHeader()}
        <StyledContent>{renderContent()}</StyledContent>
      </ContainerLayer>
    </Drawer>
  );
};

SovosSlidingPanel.propTypes = {
  /**
   * Used if the your app works with high levels of z-index.
   */
  baseZIndex: number,
  /**
   * The content of the sliding panel.
   *
   * When `SovosTabContent` components are present, tabs will be rendered
   * and the sliding panel will manage the current selected tab.
   *
   * The `SovosSlidingPanelHeader` child can contain its own specially
   * handled children. `SovosSlidingPanelHeaderActionButtons` will be
   * rendered in the top right corner of the header.
   * `SovosSlidingPanelHeaderAdditionalButtons` will be rendered in the
   * lower right corner of the header. Since tabs are automatically added
   * to the header and managed by the sliding panel, do not add Tab
   * components directly to the header.
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Callback called when this panel is being closed.
   */
  onClose: func.isRequired,
  /**
   * If true, the panel is open
   */
  open: bool.isRequired,
  /**
   * Inline styles applied to the root element
   */
  style: object,
};

SovosSlidingPanel.baseComponent = {
  name: 'Drawer',
};

SovosSlidingPanel.defaultProps = {
  baseZIndex: 1310,
  className: undefined,
  classes: undefined,
  style: undefined,
};

export default SovosSlidingPanel;
