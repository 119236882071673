import React from 'react';
import { any, elementType, object, string } from 'prop-types';
import { useController } from 'react-hook-form';

const ControlledField = ({
  control,
  defaultValue,
  InputField,
  label,
  name,
  rules,
  ...rest
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, defaultValue, name, rules });
  // console.log(defaultValue, value);
  const inputProps = {
    checked: !!value,
    error: !!error,
    helperText: error ? error.message : undefined,
    label,
    onChange,
    placeholder: label,
    value,
    ...rest,
  };

  return <InputField {...inputProps} />;
};

ControlledField.propTypes = {
  control: object,
  defaultValue: any,
  InputField: elementType.isRequired,
  label: string,
  name: string.isRequired,
  rules: object,
};

ControlledField.defaultProps = {
  control: undefined,
  defaultValue: undefined,
  label: undefined,
  rules: undefined,
};

export default ControlledField;
