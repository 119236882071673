import React, { useMemo, useState, useId } from 'react';
import {
  arrayOf,
  bool,
  element,
  elementType,
  exact,
  func,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosLinearProgress from '../../../sovos-linear-progress/SovosLinearProgress';
import SovosPaginationFooter from '../../../sovos-pagination-footer';
import SovosTypography from '../../../sovos-typography';
import statusTypes from '../../../sovos-table-group/helpers/statusTypes';
import NoResults from './NoResults';
import Toolbar from './Toolbar';

const defaultLabels = {
  loading: 'Loading Data',
};

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSingleSelect',
})(({ isSingleSelect }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '& .sovosTable__selectAll': {
    display: isSingleSelect ? 'none' : 'inline-flex',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    '& .sovosCheckbox ': {
      display: isSingleSelect ? 'none' : 'flex',
    },
  },

  '& .sovosCheckbox ': {
    '&.Mui-focusVisible': {
      '&::after': {
        outlineOffset: -3,
      },
    },
  },
}));

const NoTable = styled('div')(({ theme: { spacing } }) => ({
  boxSizing: 'border-box',
  flex: '1 0 0',
  paddingTop: spacing(3),

  '&.sovosTableGroup__noTable--no-toolbar': {
    flex: '1 0 0',
  },
}));

const Loading = styled('div')(({ theme: { palette, spacing } }) => ({
  boxSizing: 'border-box',
  flex: '1 0 0',
  margin: '0 auto',
  maxWidth: 1000,
  paddingTop: spacing(3),
  textAlign: 'center',
  width: '80%',
  '& p': {
    color: palette.text.secondary,
    marginTop: spacing(),
  },
}));

const TableWrapper = styled('div')({
  overflow: 'hidden',
});

const StyledPaginationFooter = styled(SovosPaginationFooter)({
  flex: '0 0 auto',
  marginTop: 'auto',
});

const Group = ({
  classes,
  className,
  'data-testid': dataTestId,
  dataPropName,
  generateRowSelection,
  isLoading,
  isSingleSelect,
  labels: labelsProp,
  noData,
  noResults,
  PaginationFooterProps,
  onRowSelectionPropName,
  showNoData,
  sx,
  TableComponent,
  TableProps,
  ToolbarProps,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableRef, setTableRef] = useState({ current: null });

  const id = useId();

  const getComputedFooterProps = () => {
    if (!PaginationFooterProps) return undefined;

    const {
      onPageChange: fnFromProp,
      className: classNameFromProps,
      ...rest
    } = PaginationFooterProps;

    // @todo: handle onPageChange object case
    const onPageChange = (...props) => {
      if (fnFromProp) {
        fnFromProp(...props);
      }

      if (tableRef.current) {
        tableRef.current.scrollTop = 0;
      }
    };

    return {
      onPageChange,
      className: clsx(classNameFromProps, classes?.footer),
      ...rest,
    };
  };

  const hasRows = useMemo(
    () =>
      TableProps &&
      TableProps[dataPropName] &&
      Array.isArray(TableProps[dataPropName]) &&
      !!TableProps[dataPropName].length &&
      TableProps[dataPropName].some((row) => typeof row === 'object'),
    [TableProps, dataPropName]
  );

  const getStatus = () => {
    if (isLoading) return statusTypes.LOADING;
    if (showNoData) return statusTypes.NO_DATA;
    if (!hasRows) return statusTypes.NO_RESULTS;
    return statusTypes.TABLE;
  };
  const status = getStatus();
  const hasToolbar = !!(
    ToolbarProps &&
    (ToolbarProps.actionIconButtons ||
      ToolbarProps.bulkActions ||
      ToolbarProps.children ||
      ToolbarProps.DataGroupingProps ||
      ToolbarProps.filters ||
      ToolbarProps.quickFilters ||
      ToolbarProps.search ||
      ToolbarProps.setVisibleColumns ||
      ToolbarProps.toolbarTitle)
  );
  const labels = { ...defaultLabels, ...labelsProp };

  const calculatedRowSelection = useMemo(
    () => generateRowSelection(selectedRows, setSelectedRows),
    [generateRowSelection, selectedRows]
  );

  const newTableProps = TableProps ? { ...TableProps } : undefined;
  if (newTableProps && calculatedRowSelection) {
    newTableProps[onRowSelectionPropName] =
      calculatedRowSelection.onRowSelection;
  }

  return (
    <Root
      className={clsx(classes?.root, className)}
      data-testid={dataTestId}
      isSingleSelect={isSingleSelect}
      sx={sx}
    >
      {hasToolbar && (
        <Toolbar
          disabled={isLoading}
          bulkActionsCount={calculatedRowSelection?.getSelectedCount()}
          className={classes?.toolbar}
          hasFixedHeader={TableProps?.fixedHeader}
          labels={labels}
          onClearSelection={calculatedRowSelection?.onClearRowSelection}
          tableColumns={TableProps?.columns}
          {...ToolbarProps}
        />
      )}

      {status === statusTypes.LOADING && (
        <Loading className={clsx('sovosTableGroup__loading', classes?.loading)}>
          <SovosLinearProgress aria-labelledby={id} />
          <SovosTypography id={id} variant="body1">
            {labels.loading}
          </SovosTypography>
        </Loading>
      )}

      {[statusTypes.NO_RESULTS, statusTypes.NO_DATA].includes(status) && (
        <NoTable
          className={clsx('sovosTableGroup__noTable', classes?.noTable, {
            'sovosTableGroup__noTable--no-toolbar': !hasToolbar,
            [classes?.noToolbar]: classes && !hasToolbar,
          })}
        >
          {status === statusTypes.NO_DATA ? (
            <NoResults variant={statusTypes.NO_DATA} {...noData} />
          ) : (
            <NoResults variant={statusTypes.NO_RESULTS} {...noResults} />
          )}
        </NoTable>
      )}

      {status === statusTypes.TABLE && (
        <>
          <TableWrapper
            className={clsx('sovosTableGroup__tableWrapper', classes?.table)}
          >
            {newTableProps && (
              <TableComponent
                {...newTableProps}
                ref={(ref) => {
                  if (ref !== tableRef.current) {
                    setTableRef({ current: ref });
                  }
                }}
              />
            )}
          </TableWrapper>
          {PaginationFooterProps && (
            <StyledPaginationFooter {...getComputedFooterProps()} />
          )}
        </>
      )}
    </Root>
  );
};

Group.propTypes = {
  className: string,
  classes: object,
  'data-testid': string,
  dataPropName: string,
  /**
   * Generates an object with 3 properties, onRowSelection (func or
   * object), onClearRowSelection (func), and getSelectedCount (func)
   */
  generateRowSelection: func,
  isLoading: bool,
  isSingleSelect: bool,
  labels: exact({
    bulkActions: string,
    columnButtonTooltip: node,
    columnDrawer: exact({
      applyButton: node,
      cancelButton: node,
      header: string,
      removeDisabledTooltipText: node,
      removeTooltipText: node,
      addTooltipText: node,
      title: node,
    }),
    filterButtonTooltip: node,
    filterDrawer: exact({
      applyButton: node,
      cancelButton: node,
      title: node,
    }),
    loading: string,
    searchButtonTooltip: node,
    searchPlaceholder: string,
  }),
  noResults: exact({
    headingLabel: string,
    helpLabel: string,
    actionButton: element,
  }),
  noData: exact({
    headingLabel: string,
    helpLabel: string,
    actionButton: element,
  }),
  onRowSelectionPropName: string,
  PaginationFooterProps: object,
  showNoData: bool,
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  TableComponent: elementType.isRequired,
  TableProps: object,
  ToolbarProps: object,
};

Group.defaultProps = {
  className: undefined,
  classes: undefined,
  'data-testid': undefined,
  dataPropName: 'data',
  generateRowSelection: undefined,
  isLoading: false,
  isSingleSelect: false,
  labels: defaultLabels,
  noData: undefined,
  noResults: undefined,
  onRowSelectionPropName: undefined,
  PaginationFooterProps: undefined,
  showNoData: false,
  sx: undefined,
  TableProps: undefined,
  ToolbarProps: undefined,
};

export default Group;
