export default {
  branding: {
    logoLight: 'SovosLogoSVG_light.svg',
    logoDark: 'SovosLogoSVG_dark.svg',
  },
  heroBanners: {
    colors: {
      dark: 'colors/hero-bg-darkBlue.png',
      default: 'colors/hero-bg-blue.png',
      error: 'colors/hero-bg-red.png',
      inProgress: 'colors/hero-bg-grayMedium.png',
      success: 'colors/hero-bg-green.png',
      warning: 'colors/hero-bg-yellow.png',
    },
    countries: {
      AT: 'countries/AT.jpg',
      BE: 'countries/BE.jpg',
      CN: 'countries/CN.jpg',
      DE: 'countries/DE.jpg',
      DK: 'countries/DK.jpg',
      ES: 'countries/ES.jpg',
      FI: 'countries/FI.jpg',
      FR: 'countries/FR.jpg',
      HU: 'countries/HU.jpg',
      IE: 'countries/IE.jpg',
      IT: 'countries/IT.jpg',
      LU: 'countries/LU.jpg',
      NL: 'countries/NL.jpg',
      NO: 'countries/NO.jpg',
      PL: 'countries/PL.jpg',
      SE: 'countries/SE.jpg',
      UK: 'countries/CN.jpg',
    },
    states: {
      AK: 'states/AK.jpg',
      AL: 'states/AL.jpg',
      AR: 'states/AR.jpg',
      AZ: 'states/AZ.jpg',
      CA: 'states/CA.jpg',
      CO: 'states/CO.jpg',
      CT: 'states/CT.jpg',
      DC: 'states/DC.jpg',
      DE: 'states/DE.jpg',
      FL: 'states/FL.jpg',
      GA: 'states/GA.jpg',
      GU: 'states/GU.jpg',
      HI: 'states/HI.jpg',
      IA: 'states/IA.jpg',
      ID: 'states/ID.jpg',
      IL: 'states/IL.jpg',
      IN: 'states/IN.jpg',
      KS: 'states/KS.jpg',
      KY: 'states/KY.jpg',
      LA: 'states/LA.jpg',
      MA: 'states/MA.jpg',
      MD: 'states/MD.jpg',
      ME: 'states/ME.jpg',
      MI: 'states/MI.jpg',
      MN: 'states/MN.jpg',
      MO: 'states/MO.jpg',
      MS: 'states/MS.jpg',
      MT: 'states/MT.jpg',
      NC: 'states/NC.jpg',
      ND: 'states/ND.jpg',
      NE: 'states/NE.jpg',
      NH: 'states/NH.jpg',
      NJ: 'states/NJ.jpg',
      NM: 'states/NM.jpg',
      NV: 'states/NV.jpg',
      NY: 'states/NY.jpg',
      OH: 'states/OH.jpg',
      OK: 'states/OK.jpg',
      OR: 'states/OR.jpg',
      PA: 'states/PA.jpg',
      PR: 'states/PR.jpg',
      RI: 'states/RI.jpg',
      SC: 'states/SC.jpg',
      SD: 'states/SD.jpg',
      TN: 'states/TN.jpg',
      TX: 'states/TX.jpg',
      UT: 'states/UT.jpg',
      VA: 'states/VA.jpg',
      VT: 'states/VT.jpg',
      WA: 'states/WA.jpg',
      WI: 'states/WI.jpg',
      WV: 'states/WV.jpg',
      WY: 'states/WY.jpg',
    },
  },
  locales: {
    en: 'en',
    'en-US': 'en-US',
    es: 'es',
    'es-ES': 'es-ES',
    pt: 'pt',
    'pt-BR': 'pt-BR',
  },
};
