import React, { cloneElement } from 'react';
import { arrayOf, func, node, object, shape, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';
import SovosLink from '../sovos-link';
import SovosList from '../sovos-list';
import Avatar from '../sovos-avatar';

const StyledSovosList = styled(SovosList)(
  ({ theme: { palette, spacing, typography } }) => ({
    padding: 0,

    '& .sovosCommentsList': {
      '&__avatar': {
        flex: '0 0 auto',
        margin: `${spacing(2)} ${spacing(2)} ${spacing(2)} 0`,
      },
      '&__commentContent': {
        whiteSpace: 'normal',
        wordBreak: 'normal',
        wordWrap: 'normal',
      },
      '&__comment': {
        flex: '1 1 auto',
        margin: `${spacing(2)} 0`,
      },
      '&__header': {
        display: 'flex',
      },
      '&__commentData': {
        flex: '1 1 auto',
      },
    },

    '& .sovosList__item': {
      alignItems: 'initial',
      borderBottom: `solid 1px ${palette.divider}`,
      height: 'auto',

      '&:last-of-type': {
        borderBottomWidth: 1,
      },
    },

    '& .sovosCommentsList__row': {
      '&__link': {
        display: 'inline-block',
        marginBottom: spacing(0.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 150,
        ...typography.body2,
      },
      '&__name': {
        fontWeight: 500,
      },
      '&__timestamp': {
        display: 'block',
        marginBottom: spacing(0.5),
      },
      '&__status': {
        flex: '0 0 auto',
      },
    },
  })
);

const getRowRenderer = () => (entry) => {
  const { comment, formattedDate, name, surname, avatarElement, status, link } =
    entry;

  return (
    <>
      <div className="sovosCommentsList__avatar">
        {avatarElement !== undefined ? (
          cloneElement(avatarElement, { size: 'small' })
        ) : (
          <Avatar
            className="sovosCommentsList__row__avatar"
            name={name}
            surname={surname}
            size="small"
          />
        )}
      </div>
      <article className="sovosCommentsList__comment">
        <header className="sovosCommentsList__header">
          <div className="sovosCommentsList__commentData">
            {(name || surname) && (
              <SovosTypography
                component="h3"
                variant="body1"
                color="text.primary"
                className="sovosCommentsList__row__name"
              >
                {name} {surname}
              </SovosTypography>
            )}
            <SovosTypography
              component="time"
              variant="body2"
              color="text.secondary"
              className="sovosCommentsList__row__timestamp"
            >
              {formattedDate}
            </SovosTypography>
            {link && link.href && link.href.length > 0 && (
              <SovosLink
                className="sovosCommentsList__row__link"
                href={link.href}
                title={link.text}
              >
                {link.text || 'Link'}
              </SovosLink>
            )}
          </div>
          <strong className="sovosCommentsList__row__status">{status}</strong>
        </header>
        <SovosTypography
          component="div"
          variant="body1"
          className="sovosCommentsList__row__commentContent"
          color="text.primary"
        >
          {comment}
        </SovosTypography>
      </article>
    </>
  );
};

const SovosCommentsList = ({
  classes,
  className,
  data,
  SovosListClasses,
  ...listProps
}) => (
  <StyledSovosList
    {...listProps}
    className={clsx('sovosCommentsList', className)}
    classes={{
      ...SovosListClasses,
      root: classes?.listRoot,
      row: classes?.listRow,
    }}
    data={data}
    rowRenderer={getRowRenderer(classes)}
  />
);

SovosCommentsList.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of comment entries to be rendered. Each entry should contain
   * comment, name, surname, formattedDate, and optional avatarElement,
   * status and link.
   */
  data: arrayOf(
    shape({
      comment: string.isRequired,
      name: string.isRequired,
      surname: string.isRequired,
      formattedDate: string.isRequired,
      avatarElement: node,
      status: node,
      link: shape({
        text: string,
        href: string.isRequired,
      }),
    })
  ).isRequired,
  /**
   * Function used to sort provided data.
   */
  sortFunction: func,
  /**
   * Override or extend the styles applied to the `SovosList` base component
   */
  SovosListClasses: object,
};

SovosCommentsList.defaultProps = {
  classes: undefined,
  className: undefined,
  sortFunction: undefined,
  SovosListClasses: undefined,
};

export default SovosCommentsList;
