import React, { useState, useMemo } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import TreeListItem, { treeListItemPropTypes } from './components/TreeListItem';

const StyledUl = styled('ul')(({ theme: { typography } }) => ({
  fontSize: typography.body1.fontSize,
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

const calculateSelectedItem = (items, targetId) => {
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].id === targetId) {
      return { toExpand: [], selectedItem: items[i].id };
    }

    if (items[i].nestedItems) {
      const { toExpand, selectedItem } = calculateSelectedItem(
        items[i].nestedItems,
        targetId
      );

      if (selectedItem) {
        return {
          toExpand: [
            items[i].id,
            ...toExpand.map((id) => `${items[i].id}_${id}`),
          ],
          selectedItem: `${items[i].id}_${selectedItem}`,
        };
      }
    }
  }

  return {
    expanded: {},
    selectedItem: '',
  };
};

const calculateExpandedItems = (items, itemsToExpand) => {
  const calculatedItems = [];
  for (let i = 0; i < items.length; i += 1) {
    if (!items[i].nestedItems) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (itemsToExpand.includes(items[i].id)) {
      calculatedItems.push(items[i].id);
    }

    if (items[i].nestedItems) {
      const nestedItems = calculateExpandedItems(
        items[i].nestedItems,
        itemsToExpand
      );

      const mappedNestedItems = nestedItems.map((id) => `${items[i].id}_${id}`);

      calculatedItems.push(...mappedNestedItems);
    }
  }
  return calculatedItems;
};

/**
 * ##### List with expandable items
 *
 * `SovosFixedWidthText` will automatically wrap `data.content` that is a
 * `string` and provide a tooltip on overflow. `data.content` that is a
 * `<NODE>` will not be wrapped.
 *
 * If you need truncation with a tooltip **and** a `<NODE>`, wrap your text
 * in `SovosFixedWidthText` yourself.
 */

const SovosTreeList = ({
  classes,
  className,
  data,
  initialExpandedItems: initialExpandedItemsProp,
  initialSelectedItem: initialSelectedItemProp,
  loadingMessage,
  multiple,
  onItemClick,
  onItemCollapsed,
  onItemExpanded,
  showCheckboxes,
  style,
  TreeListItemClasses,
  ...rest
}) => {
  const { initialExpandedItems, initialSelectedItem } = useMemo(() => {
    const expanded = {};
    let selectedItem = '';

    if (initialSelectedItemProp) {
      const { toExpand, selectedItem: calculatedSelectedItem } =
        calculateSelectedItem(data, initialSelectedItemProp);

      if (calculatedSelectedItem) {
        selectedItem = calculatedSelectedItem;
        toExpand.forEach((id) => {
          expanded[id] = true;
        });
      }
    }

    if (initialExpandedItemsProp) {
      const toExpand = calculateExpandedItems(data, initialExpandedItemsProp);

      toExpand.forEach((id) => {
        expanded[id] = true;
      });
    }

    return {
      initialExpandedItems: expanded,
      initialSelectedItem: selectedItem,
    };
  }, [data, initialSelectedItemProp, initialExpandedItemsProp]);

  const [expanded, setExpanded] = useState(initialExpandedItems);
  const [selectedItems, setSelectedItems] = useState([initialSelectedItem]);

  const toggleExpand = (e, id, item) => {
    e.stopPropagation();

    setExpanded((prevExpanded) => {
      const newExpanded = { ...prevExpanded };

      if (newExpanded[id]) {
        delete newExpanded[id];
        onItemCollapsed(item);
      } else {
        newExpanded[id] = true;
        onItemExpanded(item);
      }
      return newExpanded;
    });
  };

  const handleItemClick = (id, item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(id)) {
        const copySelected = [...prevSelected];
        copySelected.splice(prevSelected.indexOf(id), 1);
        return copySelected;
      }

      if (multiple) {
        return [...prevSelected, id];
      }

      return [id];
    });

    onItemClick(item, selectedItems);
  };

  return (
    <StyledUl
      className={clsx('sovosTreeList', classes?.root, className)}
      style={style}
      {...rest}
    >
      {data.map((ti) => (
        <TreeListItem
          classes={TreeListItemClasses}
          expandedItems={expanded}
          hasCheckbox={showCheckboxes}
          isExpandable={!!(ti.nestedItems || ti.nestedItemCount)}
          isTopLevel
          item={ti}
          key={ti.id}
          loadingMessage={loadingMessage}
          onClick={onItemClick ? handleItemClick : undefined}
          onExpand={toggleExpand}
          selectedItems={selectedItems}
        />
      ))}
    </StyledUl>
  );
};

SovosTreeList.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of nested list items with id, content, and type. Type can be
   * undefined (default), or one of 'DIVIDER', 'HEADER', or 'DISABLED'.
   * Dividers and headers are only permitted at the top level of the list.
   */
  data: arrayOf(treeListItemPropTypes).isRequired,
  /**
   * List of items id's to expand on initial render
   */
  initialExpandedItems: arrayOf(string),
  /**
   * Item id to select on initial render
   */
  initialSelectedItem: string,
  /**
   * Message displayed when asynchronously loading child items
   */
  loadingMessage: string,
  /**
   * Select multiple tree list items
   */
  multiple: bool,
  /**
   * Callback fired when an item in the list is clicked. When this callback
   * is not included, items do not highlight on hover and cannot be selected
   *
   * `function(item: object) => void`
   */
  onItemClick: func,
  /**
   * Callback fired when an item is collapsed.
   *
   * `function(item: object) => void`
   */
  onItemCollapsed: func,
  /**
   * Callback fired when an item is expanded. Can be used to asynchronously
   * load children
   *
   * `function(item: object) => void`
   */
  onItemExpanded: func,
  /**
   * Indicate selection(s) with checkbox
   */
  showCheckboxes: bool,
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Override or extend the styles applied to items in the list
   */
  TreeListItemClasses: object,
};

SovosTreeList.defaultProps = {
  classes: undefined,
  className: undefined,
  initialExpandedItems: undefined,
  initialSelectedItem: undefined,
  multiple: false,
  onItemClick: undefined,
  onItemCollapsed: () => {},
  onItemExpanded: () => {},
  showCheckboxes: false,
  style: undefined,
  TreeListItemClasses: {},
  loadingMessage: undefined,
};

export default SovosTreeList;
