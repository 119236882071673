import React from 'react';
import { node, object, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import {
  contentSidePadding,
  contentTopPadding,
} from '../internals/page-layout/contentPadding';

const Root = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  ...contentSidePadding(breakpoints, spacing),
  ...contentTopPadding(spacing),
}));

/**
 * **Note**: `SovosPageContentGeneric` should only be used as a child of
 * `SovosPageLayout` or `SovosPageContentTwoColumnMain`, and is responsible
 * for responsively setting the amount of padding
 */
const SovosPageContentGeneric = ({ className, classes, ...props }) => (
  <Root
    className={clsx('sovosPageContentGeneric', className, classes?.root)}
    {...props}
  />
);

SovosPageContentGeneric.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * The content of the page
   */
  children: node,
};

export default SovosPageContentGeneric;
