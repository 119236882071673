import React from 'react';
import clsx from 'clsx';
import { object, string } from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { getFocusStyles } from '../internals/utils';

const StyledMenuItem = styled(MenuItem)(
  ({ theme: { palette, spacing, typography } }) => ({
    '&.MuiMenuItem-root': {
      color: palette.text.primary,
      fontSize: typography.body1.fontSize,
      minHeight: spacing(4.5),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      '&.Mui-selected': {
        backgroundColor: palette.action.selected,
      },
      '&+.MuiDivider-root': {
        marginBottom: 0,
        marginTop: 0,
      },
    },
    '&:focus-visible': {
      ...getFocusStyles({
        offset: -2,
      }),
    },
  })
);

const SovosMenuItem = React.forwardRef(
  ({ className, classes, ...props }, ref) => (
    <StyledMenuItem
      className={clsx('sovosMenuItem', className, classes?.root)}
      ref={ref}
      {...props}
    />
  )
);

SovosMenuItem.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosMenuItem.defaultProps = {
  className: undefined,
  classes: undefined,
};

SovosMenuItem.baseComponent = {
  name: 'Menu Item',
  link: 'https://material-ui.com/components/menus/',
};

export default SovosMenuItem;
