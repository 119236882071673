import React from 'react';
import { string, node } from 'prop-types';
import SovosButtonsGroup from '../../../sovos-buttons-group';

const HeaderButtonsGroup = ({ children, className, ...rest }) => {
  if (React.Children.count(children) > 1) {
    return (
      <SovosButtonsGroup className={className} {...rest}>
        {children}
      </SovosButtonsGroup>
    );
  }

  return children;
};

HeaderButtonsGroup.propTypes = {
  /**
   * The content of the button group
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

HeaderButtonsGroup.defaultProps = {
  className: undefined,
};

export default HeaderButtonsGroup;
