import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import Divider from '@mui/material/Divider';

const SovosDivider = ({ className, ...rest }) => (
  <Divider className={clsx('sovosDivider', className)} {...rest} />
);

SovosDivider.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosDivider.defaultProps = {
  className: undefined,
};

SovosDivider.baseComponent = {
  name: 'Divider',
  link: 'divider/',
};

export default SovosDivider;
