import { darken, lighten, styled } from '@mui/material/styles';
import tableClassNames from '../helpers/tableClassNames';
import {
  makeHorizontalShadow,
  makeVerticalShadow,
} from '../helpers/makeShadows';

const rowBackground = (color) => ({
  '& td': {
    backgroundColor: color,
  },
  '& th': {
    backgroundColor: color,
  },
});

const StyledTable = styled('table', {
  shouldForwardProp: (prop) =>
    !['fixedColumnCount', 'hasTotals', 'tableWidth'].includes(prop),
})(
  ({
    fixedColumnCount,
    hasTotals,
    tableWidth,
    theme: { palette, spacing, typography },
  }) => {
    const selectedBackground = lighten(
      palette.action.active,
      1 - palette.action.selectedOpacity
    );

    return {
      maxHeight: '100%',
      tableLayout: 'fixed',
      borderSpacing: 0,
      // prevents financial and fixed columns from breaking in some browsers
      width: 0,

      [`& .${tableClassNames.baseCell}`]: {
        ...typography.body2,
        backgroundColor: palette.background.default,
        boxSizing: 'border-box',
        overflow: 'hidden',
        padding: `0 ${spacing(2)}`,
        whiteSpace: 'nowrap',
      },

      [`& .${tableClassNames.selectCell}`]: {
        padding: 0,
        '& .sovosCheckbox ': {
          '&::after': {
            outlineOffset: -2,
          },
        },
      },

      [`& .${tableClassNames.rowActionsCell}`]: {
        padding: 0,
        textAlign: 'right',
        width: spacing(4),
        zIndex: 10,
      },

      [`& .${tableClassNames.rightmostFixedColumnCell}`]: {
        boxShadow: makeHorizontalShadow(),
      },

      [`& .${tableClassNames.fixedElevationCell}`]: {
        zIndex: 20,
      },

      [`& .${tableClassNames.extraElevationCell}`]: {
        position: 'sticky',
        zIndex: 30,
      },

      [`& .${tableClassNames.headingCell}`]: {
        color: palette.text.secondary,
        fontWeight: 'normal',
        overflow: 'visible',
        textAlign: 'left',
        whiteSpace: 'normal',
        height: spacing(4), // this is effectively a min-height
      },

      [`& .${tableClassNames.financialHeadingCell}`]: {
        whiteSpace: 'nowrap',
      },

      // If there are fixed columns, the shadow on the fixed header breaks when
      // scrolling horizontally if we put the shadows on individual cells, so we put
      // it on a pseudo-element. . However, we can't do that when there are no fixed
      // columns, because in that case the shadow scrolls away.
      [`& .${tableClassNames.firstStickyFixedHeadingCell}`]: {
        '&:after': {
          bottom: 0,
          boxShadow: makeVerticalShadow(),
          content: '""',
          left: 0,
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          width: tableWidth,
        },
      },

      [`& .${tableClassNames.fixedHeadingCell}`]: {
        boxShadow: fixedColumnCount === 0 && makeVerticalShadow(),
        position: 'sticky',
        top: hasTotals ? spacing(4) : 0,
      },

      [`& .${tableClassNames.sortableHeadingCell}`]: {
        cursor: 'pointer',
      },

      [`& .${tableClassNames.sortedHeadingCell}`]: {
        color: palette.primary.main,
        fontWeight: typography.fontWeightMedium,
      },

      [`& .${tableClassNames.hiddenColumnTitle}`]: {
        clip: 'rect(1px, 1px, 1px, 1px)',
        clipPath: 'inset(50%)',
        height: 1,
        width: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
      },

      [`& .${tableClassNames.totalsCell}`]: {
        backgroundColor: palette.grey[200],
        fontWeight: 'medium',
        color: palette.text.primary,
        textAlign: 'left',
        height: spacing(4),
      },

      [`& .${tableClassNames.fixedTotalsCell}`]: {
        position: 'sticky',
        top: 0,
      },

      [`& .${tableClassNames.bodyCell}`]: {
        borderTop: `1px solid ${palette.divider}`,
        height: spacing(5),
        color: palette.text.primary,
      },

      [`& .${tableClassNames.fixedBodyCell}`]: {
        left: 0,
        position: 'sticky',
        zIndex: 10,
        fontWeight: 'normal',
      },

      [`& .${tableClassNames.rowActionButton}`]: {
        visibility: 'hidden',
      },

      [`& .${tableClassNames.rowHovered}`]: {
        [`& .${tableClassNames.rowActionButton}`]: {
          visibility: 'visible',
        },
        ...rowBackground(
          darken(palette.common.white, palette.action.hoverOpacity)
        ),
      },

      [`& .${tableClassNames.rowSelected}`]: rowBackground(selectedBackground),

      [`& .${tableClassNames.rowHoverSelected}`]: rowBackground(
        darken(selectedBackground, palette.action.hoverOpacity)
      ),

      [`& .${tableClassNames.rowClickable}`]: {
        cursor: 'pointer',
      },

      [`& .${tableClassNames.rowDisabled}`]: {
        color: palette.text.disabled,
        opacity: palette.action.disabledOpacity,
        pointerEvents: 'none',
      },
    };
  }
);

export default StyledTable;
