import React, { useMemo, useState } from 'react';
import {
  bool,
  arrayOf,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { v4 as uuid } from 'uuid';
import { styled, useTheme } from '@mui/material/styles';
import { Apps, SovosLogo } from 'mosaic-react-icons';
import cloneDeep from 'lodash.clonedeep';
import BaseIcon from './BaseIcon';
import Switcher from '../../../internals/components/switcher/Switcher';
import { square } from '../../../internals/utils/stylesHelpers';
import treeListItemTypes from '../../../sovos-tree-list/helpers/itemTypes';
import { spacingToNumber } from '../../../internals/utils';
import { useCustomTheme } from '../../../sovos-custom-theme-context';
import customThemeColors from '../../../internals/custom-themes/custom-theme-colors';

const StyledSwitcher = styled(Switcher)({
  flex: '0 0 auto',

  '& .sovosSwitcher__button': {
    minWidth: 0,
    width: 'auto',
  },
});

const ButtonContents = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selectedTheme',
})(
  ({
    selectedTheme,
    theme: { overrides, palette, shape: themeShape, spacing },
  }) => ({
    alignItems: 'center',
    background:
      selectedTheme === customThemeColors[0].title
        ? `linear-gradient(45deg, ${palette.primary.dark}, ${palette.primary.main})`
        : overrides?.sovosNavigation?.highlights ?? palette.primary.main,
    borderRadius: themeShape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    marginRight: 0,
    ...square(spacing(5)),
  })
);

const SovosIcon = styled(BaseIcon)({
  position: 'relative',
  top: 2,
});

const ProductMenu = ({ label, products, onSelectProduct, selectedProduct }) => {
  const state = useCustomTheme();
  const { spacing } = useTheme();
  const [hovered, setHovered] = useState(false);
  const showAppsIcon = hovered;

  const items = useMemo(() => {
    const getMenuItem = (product) => {
      const id = product.id || product.name || uuid();
      const item = {
        id,
        content: product.name,
        ...product,
      };

      if (product.nestedProducts) {
        item.nestedItems = product.nestedProducts.map(getMenuItem);
      }

      return item;
    };

    return cloneDeep(products).map(getMenuItem);
  }, [products]);

  const selectedItem = useMemo(
    () => ({
      id: selectedProduct.id || selectedProduct.name,
      ...selectedProduct,
    }),
    [selectedProduct]
  );

  const productIcon = selectedProduct.icon ? (
    <BaseIcon element={selectedProduct.icon} isVisible={!showAppsIcon} />
  ) : (
    <SovosIcon element={SovosLogo} isVisible={!showAppsIcon} />
  );

  const buttonContents = (
    <ButtonContents
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      selectedTheme={state?.selectedTheme}
    >
      <BaseIcon element={Apps} isVisible={showAppsIcon} />
      {productIcon}
    </ButtonContents>
  );

  return (
    <StyledSwitcher
      buttonContents={buttonContents}
      buttonTooltip={label}
      className="sovosNavigation__productSwitcher"
      items={items}
      onSelectItem={onSelectProduct}
      PopoverProps={{
        anchorOrigin: {
          horizontal: spacingToNumber(spacing(7)),
          vertical: 'top',
        },
      }}
      selectedItem={selectedItem}
    />
  );
};

ProductMenu.propTypes = {
  label: string.isRequired,
  /**
   * Array of objects, each with
   *
   * - `id` string or number. Required if there is any chance the names may
   *   not be unique. Not required for dividers and headers.
   * - `name` string
   * - `disabled` bool
   * - `type` string, one of , 'DIVIDER', or 'HEADER', or undefined
   * - `nestedProducts` array of objects
   */
  products: arrayOf(
    shape({
      id: oneOfType([number, string]),
      name: ({ name, type }, _propName, component) => {
        if (type === 'DIVIDER') return null;

        if (typeof name === 'string') return null;

        return new Error(
          `Invalid products array supplied to ${component}. Each item must have a \`name\` unless its type is 'DIVIDER' or 'HEADER'.`
        );
      },
      disabled: bool,
      type: oneOf(Object.values(treeListItemTypes)),
    })
  ).isRequired,

  selectedProduct: object,
  /**
   * An icon representing the current product
   */
  onSelectProduct: func.isRequired,
};

ProductMenu.defaultProps = {
  selectedProduct: undefined,
};

export default ProductMenu;
