import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { node, object, string } from 'prop-types';
import {
  contentSidePadding,
  contentTopPadding,
} from '../internals/page-layout/contentPadding';

const Root = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  ...contentSidePadding(breakpoints, spacing),
  ...contentTopPadding(spacing),
  height: '100%',
  overflow: 'auto',
}));

/**
 * **Note**: Must be a child of `SovosPageContentTwoColumn`
 */
const SovosPageContentTwoColumnSidebar = ({
  children,
  className,
  'data-testid': dataTestId,
  style,
}) => (
  <Root
    className={clsx('sovosPageContentTwoColumnSidebar', className)}
    data-testid={dataTestId}
    style={style}
  >
    {children}
  </Root>
);

SovosPageContentTwoColumnSidebar.propTypes = {
  /**
   * The content of the sidebar
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Inline styles applied to the root element
   */
  style: object,
};

SovosPageContentTwoColumnSidebar.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  style: undefined,
};

export default SovosPageContentTwoColumnSidebar;
