import React from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import clsx from 'clsx';
import SovosCheckbox from '../../sovos-checkbox';
import SovosTooltip from '../../sovos-tooltip';
import FinancialCellContent from './FinancialCellContent';

import SELECT_COLUMN_ID from '../helpers/selectColumnId';
import tableClassNames from '../helpers/tableClassNames';
import isLastFixedColumn from '../helpers/isLastFixedColumn';
import isWidthCalculated from '../helpers/isWidthCalculated';

const HeaderRow = ({
  areAllRowsSelected,
  areAnyRowsSelected,
  columns,
  columnSortId,
  columnStyles,
  disableSelectAll,
  fixedColumnCount,
  fixedHeader,
  onColumnSort,
  onSelectAllClick,
  rowActions,
  setFinancialCellRef,
}) => {
  const renderSelectAllCheckbox = () => (
    <SovosCheckbox
      aria-label="Select all rows"
      checked={areAllRowsSelected}
      className="sovosTable__selectAll"
      color="primary"
      disabled={disableSelectAll}
      indeterminate={areAnyRowsSelected && !areAllRowsSelected}
      onChange={onSelectAllClick}
      onClick={(e) => e.stopPropagation()}
      size="tiny"
    />
  );

  const baseCellClasses = clsx(
    tableClassNames.headingCell,
    tableClassNames.baseCell,
    fixedHeader && tableClassNames.fixedHeadingCell
  );

  return (
    <tr data-testid="sovosTable__headerRow" key="hr">
      {columns.map((col, i) => {
        const isSortable =
          onColumnSort &&
          col.id !== SELECT_COLUMN_ID &&
          col.title &&
          col.notSortable !== true;
        const isFinancial = isWidthCalculated(col);
        const isSelect = col.id === SELECT_COLUMN_ID;

        const className = clsx(
          baseCellClasses,
          col.fixed
            ? tableClassNames.extraElevationCell
            : tableClassNames.fixedElevationCell,
          isLastFixedColumn(i, fixedColumnCount) &&
            tableClassNames.rightmostFixedColumnCell,
          columnSortId === col.id &&
            col.notSortable !== true &&
            tableClassNames.sortedHeadingCell,
          fixedHeader &&
            fixedColumnCount &&
            i === 0 &&
            tableClassNames.firstStickyFixedHeadingCell,
          isFinancial && tableClassNames.financialHeadingCell,
          isSortable && `sovosTable__columnSort-${col.id}`,
          isSortable && tableClassNames.sortableHeadingCell,
          isSelect && tableClassNames.selectCell
        );

        const props = {
          className,
          style: columnStyles[i],
          key: col.id,
          scope: 'col',
          onClick: isSortable ? () => onColumnSort(col) : undefined,
        };

        let content = isSelect ? renderSelectAllCheckbox() : col.title;

        if (col.hideTitle) {
          content = (
            <>
              <div className={tableClassNames.hiddenColumnTitle}>{content}</div>
              <FinancialCellContent setRef={setFinancialCellRef} />
            </>
          );
        } else if (isFinancial) {
          content = (
            <FinancialCellContent setRef={setFinancialCellRef}>
              {content}
            </FinancialCellContent>
          );
        }

        content = <th {...props}>{content}</th>;

        if (col.titleTooltipText && !col.hideTitle && col.title) {
          return (
            <SovosTooltip title={col.titleTooltipText} key={col.id}>
              {content}
            </SovosTooltip>
          );
        }

        return content;
      })}
      {rowActions && (
        <th
          data-testid="sovosTable__actionColumnHeader"
          className={clsx(tableClassNames.rowActionsCell, baseCellClasses)}
        />
      )}
    </tr>
  );
};

HeaderRow.propTypes = {
  areAllRowsSelected: bool,
  areAnyRowsSelected: bool,
  columns: array.isRequired,
  columnSortId: oneOfType([number, string]),
  columnStyles: arrayOf(object).isRequired,
  disableSelectAll: bool,
  onColumnSort: func,
  onSelectAllClick: func,
  fixedColumnCount: number.isRequired,
  fixedHeader: bool.isRequired,
  rowActions: bool.isRequired,
  setFinancialCellRef: func,
};

HeaderRow.defaultProps = {
  areAllRowsSelected: undefined,
  areAnyRowsSelected: undefined,
  columnSortId: undefined,
  disableSelectAll: false,
  onColumnSort: undefined,
  onSelectAllClick: undefined,
  setFinancialCellRef: undefined,
};

export default HeaderRow;
