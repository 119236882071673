import { Children } from 'react';

/**
 * Finds the first child in a collection of React children that matches the type
 *
 * @param {React.ReactNode} children
 * @param {string | React.ComponentClass} type
 * @returns {React.ReactNode | undefined}
 */
export const findChildByType = (children, type) =>
  Children.toArray(children).find((child) => child.type === type);

export const findChildrenByType = (children, ...types) =>
  Children.toArray(children).filter(
    (child) => child.type && types.includes(child.type)
  );

/**
 * Modifies a collection of React children by omitting children of specific type(s)
 *
 * @param {React.ReactNode} children
 * @param {...string | ...React.ComponentClass} type
 * @returns {[React.ReactNode]}
 */
export const filterOutChildrenByType = (children, ...types) =>
  Children.toArray(children).filter(
    (child) => !child.type || !types.includes(child.type)
  );

/**
 * Extracts contents from a child of a specific type from a collection of
 * React children
 *
 * @param {React.ReactNode} children
 * @param {string | React.ComponentClass} type
 * @returns {React.ReactNode | undefined}
 */
export const getChildContents = (children, type, defaultValue = undefined) => {
  const child = findChildByType(children, type);
  return child !== undefined ? child.props.children : defaultValue;
};

export const mapChildToParentPropsFactory = (type, map) => (children) => {
  const child = findChildByType(children, type);
  if (!child) return {};

  const childPropKeys = Object.keys(child.props);

  return Object.keys(map).reduce((parentProps, childPropKey) => {
    if (childPropKeys.includes(childPropKey)) {
      // eslint-disable-next-line no-param-reassign
      parentProps[map[childPropKey]] = child.props[childPropKey];
    }
    return parentProps;
  }, {});
};

export const elementOfPropType =
  (...types) =>
  (props, propName, componentName) => {
    if (
      props[propName] &&
      (!props[propName].type || !types.includes(props[propName].type))
    ) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Expected element of type '${types
          .map((t) => t.displayName || t.name)
          .join(', ')}' but received '${
          props[propName].type
            ? props[propName].type.displayName
            : typeof props[propName]
        }'`
      );
    }

    return undefined;
  };
