import React, { useCallback } from 'react';
import {
  arrayOf,
  number,
  oneOfType,
  shape,
  string,
  node,
  func,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AddCircle, RemoveCircle } from 'mosaic-react-icons';
import SovosTypography from '../sovos-typography';
import OrderableListItem from './components/OrderableListItem';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const listStyles = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
};

const Root = styled('section')(({ theme: { palette } }) => ({
  background: palette.common.white,
}));

const Header = styled('header')(({ theme: { spacing } }) => ({
  padding: `0 0 ${spacing(2)} 0`,
}));

const ActiveList = styled('ol')(listStyles);

const InactiveList = styled('ul')(listStyles);

const Separator = styled('hr')(({ theme: { palette, spacing } }) => ({
  border: 0,
  borderBottom: `1px solid ${palette.divider}`,
  height: 0,
  margin: `${spacing(3)} 0`,
}));

const SovosOrderableList = ({
  activeIcon,
  inactiveIcon,
  activeItems,
  className,
  'data-testid': dataTestId,
  inactiveItems,
  labels,
  minActiveItems,
  onChange,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  const activeList = activeItems;
  const inactiveList = inactiveItems;
  const disableRemove = minActiveItems >= activeList.length;

  const updateItem = (index, active) => {
    const itemCopy = active ? activeList[index] : inactiveList[index];

    if (active) {
      const inactiveListCopy = [...inactiveList, itemCopy];
      const activeListCopy = activeList.filter((item) => item !== itemCopy);
      onChange(activeListCopy, inactiveListCopy);
    } else {
      const activeListCopy = [...activeList, itemCopy];
      const inactiveListCopy = inactiveList.filter((item) => item !== itemCopy);
      onChange(activeListCopy, inactiveListCopy);
    }
  };

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const clonedList = activeList.map((item) => ({
        ...item,
      }));
      const dragItem = clonedList[dragIndex];

      clonedList.splice(dragIndex, 1);
      clonedList.splice(hoverIndex, 0, dragItem);

      onChange(clonedList, inactiveList);
    },
    [activeList, inactiveList, onChange]
  );

  const renderItems = (listItems, active) =>
    listItems.map((item, index) => (
      <OrderableListItem
        active={active}
        activeIcon={activeIcon}
        disableRemove={disableRemove && active}
        id={item.id}
        inactiveIcon={inactiveIcon}
        index={index}
        key={item.id}
        labels={{
          addTooltipText:
            labels?.addTooltipText || t('orderableList.addTooltip'),
          removeDisabledTooltipText:
            labels?.removeDisabledTooltipText ||
            t('orderableList.removeDisabledTooltipText'),
          removeTooltipText:
            labels?.removeTooltipText || t('orderableList.removeTooltip'),
        }}
        moveItem={moveItem}
        title={item.title}
        updateItem={updateItem}
      />
    ));

  return (
    <DndProvider backend={HTML5Backend}>
      <Root
        className={clsx('sovosOrderableList', className)}
        data-testid={dataTestId}
        {...rest}
      >
        <Header>
          <SovosTypography align="left" color="text.secondary" variant="body2">
            {labels?.header || t('orderableList.header')}
          </SovosTypography>
        </Header>

        {activeList.length > 0 && (
          <ActiveList>{renderItems(activeList, true)}</ActiveList>
        )}

        {activeList.length > 0 && inactiveList.length > 0 && <Separator />}

        {inactiveList.length > 0 && (
          <InactiveList>{renderItems(inactiveList, false)}</InactiveList>
        )}
      </Root>
    </DndProvider>
  );
};

SovosOrderableList.propTypes = {
  /**
   * ActiveIcon component
   */
  activeIcon: node,
  /**
   * Array of active list items
   */
  activeItems: arrayOf(
    shape({
      id: oneOfType([string, number]).isRequired,
      title: string.isRequired,
    })
  ),
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * InactiveIcon component
   */
  inactiveIcon: node,
  /**
   * Array of inactive list items
   */
  inactiveItems: arrayOf(
    shape({
      id: oneOfType([string, number]).isRequired,
      title: string.isRequired,
    })
  ),
  /**
   * Object of the following properties: addTooltipText, header,
   * removeDisabledTooltipText, and removeTooltipText
   */
  labels: shape({
    addTooltipText: node,
    header: string,
    removeDisabledTooltipText: node,
    removeTooltipText: node,
  }),
  /**
   * Minimun active items defaults to zero
   */
  minActiveItems: number,
  /**
   * Callback fired when the state of the list changes
   * `function(newActiveItems: object[], newInactiveItems: object[]) => void`
   */
  onChange: func.isRequired,
};

SovosOrderableList.defaultProps = {
  activeIcon: <RemoveCircle />,
  activeItems: [],
  className: undefined,
  'data-testid': undefined,
  inactiveIcon: <AddCircle />,
  inactiveItems: [],
  labels: undefined,
  minActiveItems: 0,
};

export default SovosOrderableList;
