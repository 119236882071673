import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const StyledSovosButtonsGroup = styled('div', {
  shouldForwardProp: (prop) => prop !== 'align',
})(({ align, theme: { spacing } }) => {
  const getAlignment = () => {
    if (align === 'right') return 'flex-end';
    if (align === 'center') return 'center';
    return 'flex-start';
  };

  return {
    // prevents non-icon buttons from growing in height when inline with icon
    // buttons
    alignItems: 'center',
    display: 'flex',
    justifyContent: getAlignment(),

    '& > button': {
      margin: `0 ${spacing(0.5)}`,
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    '& > .sovosIconButton': {
      margin: 0,
    },
  };
});

const SovosButtonsGroup = ({
  children,
  classes,
  align,
  className,
  ...rest
}) => (
  <StyledSovosButtonsGroup
    align={align}
    className={clsx('sovosButtonGroup', className)}
    {...rest}
  >
    {children}
  </StyledSovosButtonsGroup>
);

/**
 * A group of buttons that will be next to each other with 8px of spacing
 * between them except the first and the last one that won't have margin on
 * the edges. It also exposes an align attribute to show the buttons on the
 * left, center or right section of its parent.
 *
 * @type {{ align; style: shim }}
 */
SovosButtonsGroup.propTypes = {
  /**
   * Alignment of the buttons on the button group.
   */
  align: PropTypes.oneOf(['right', 'left', 'center']),
  /**
   * The content of the button group
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: PropTypes.object,
  /**
   * Extend the class name applied to the root element
   */
  className: PropTypes.string,
  /**
   * Inline styles applied to the root element
   */
  style: PropTypes.object,
};

SovosButtonsGroup.defaultProps = {
  align: 'left',
  classes: undefined,
  className: undefined,
  style: undefined,
};

export default SovosButtonsGroup;
