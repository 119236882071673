import { func } from 'prop-types';
import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { SaveAlt, Block } from 'mosaic-react-icons';
import { SovosMenuItem, SovosPageContentTable } from 'mosaic-react';
import { useErrorSnackbar } from 'mosaic-react/hooks';
import Dialog from '../commonComponents/Dialog';
import * as api from './api/users';
import { initialState, usersReducer } from './reducers/users';
import { initialState as userInitialState, userReducer } from './reducers/user';

const firstPage = 1;

const availableFilters = [
  { dataKey: 'firstName', label: 'First Name', type: 'textEntry' },
  { dataKey: 'lastName', label: 'Last Name', type: 'textEntry' },
  { dataKey: 'city', label: 'City', type: 'textEntry' },
  { dataKey: 'state', label: 'State', type: 'textEntry' },
];
const initialColumns = [
  {
    title: 'ID',
    id: 1,
    visible: true,
    width: 20,
    dataKey: 'id',
  },
  {
    title: 'First',
    id: 2,
    visible: true,
    width: 80,
    dataKey: 'first',
    fixed: true,
  },
  {
    title: 'Last',
    id: 3,
    visible: true,
    width: 100,
    dataKey: 'last',
    fixed: true,
  },
  {
    title: 'Username',
    id: 4,
    visible: true,
    width: 80,
    dataKey: 'username',
  },
  {
    title: 'Email',
    id: 5,
    visible: true,
    width: 'greedy',
    dataKey: 'email',
  },
  {
    title: 'Address',
    id: 6,
    visible: true,
    width: 'greedy',
    dataKey: 'address',
  },
  {
    title: 'City',
    id: 7,
    visible: true,
    width: 120,
    dataKey: 'city',
  },
  {
    title: 'State',
    id: 8,
    visible: true,
    width: 100,
    dataKey: 'state',
  },
  {
    title: 'Zip',
    id: 9,
    visible: true,
    width: 80,
    dataKey: 'zip',
  },
];

const UsersTable = ({ onEdit, onDeleted }) => {
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [deleting, setDeleting] = useState(false);
  const [sortProps, setSortProps] = useState({
    id: 1,
    isAscending: true,
  });
  const [sortColumn, sortSortColumn] = useState('id');
  const [appliedFilters, setAppliedFilters] = useState({});
  const [columns, setColumns] = useState(initialColumns);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [key, setKey] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const [usersState, setUsersState] = useReducer(usersReducer, initialState);

  const { list, isLoading, totalItems } = usersState;

  const [userState, setUserState] = useReducer(userReducer, userInitialState);

  const { isDeleting } = userState;

  const { addError } = useErrorSnackbar();

  useEffect(() => {
    function unpackFilters() {
      const unpackedFilters = {
        first: appliedFilters.firstName?.[0],
        last: appliedFilters.lastName?.[0],
        city: appliedFilters.city?.[0],
        state: appliedFilters.state?.e[0],
      };
      return unpackedFilters;
    }

    setSelected(undefined);

    const fetchAction = async () => {
      setUsersState({ type: 'LoadUsersBegin' });
      try {
        const data = await api.loadUsers(
          currentPage,
          itemsPerPage,
          sortColumn,
          sortProps.isAscending ? 'asc' : 'desc',
          searchTerm,
          unpackFilters()
        );
        setUsersState({
          type: 'LoadUsersSuccess',
          users: data.users,
          totalItems: data.totalItems,
        });
      } catch (error) {
        addError(error);
        setUsersState({ type: 'LoadUsersFailure' });
      }
    };

    fetchAction();
  }, [
    setUsersState,
    currentPage,
    itemsPerPage,
    sortColumn,
    sortProps,
    addError,
    searchTerm,
    appliedFilters,
  ]);

  useEffect(() => {
    if (deleting === true && isDeleting === false) {
      onDeleted();
      setDeleting(false);
    }
  }, [isDeleting, onDeleted, deleting]);

  function searchUsers(term) {
    setSearchTerm(term);
    setCurrentPage(firstPage);
  }

  const sort = useCallback(
    (column) => {
      const newAscending =
        sortProps.id === column.id ? !sortProps.isAscending : true;
      let newSortProp;
      switch (column.id) {
        case 1:
          newSortProp = 'id';
          break;
        case 2:
          newSortProp = 'first';
          break;
        case 3:
          newSortProp = 'last';
          break;
        case 4:
          newSortProp = 'username';
          break;
        case 5:
          newSortProp = 'email';
          break;
        case 6:
          newSortProp = 'address';
          break;
        case 7:
          newSortProp = 'city';
          break;
        case 8:
          newSortProp = 'state';
          break;
        case 9:
          newSortProp = 'zip';
          break;
        default:
          newSortProp = 'userId';
      }
      setSortProps({
        id: column.id,
        isAscending: newAscending,
      });
      sortSortColumn(newSortProp);
      setCurrentPage(firstPage);
    },
    [sortProps]
  );

  const rowSelection = useCallback((rows) => {
    setSelected(rows);
  }, []);

  const openDialog = (text) => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  function downloadAlert() {
    const plural = selected.length === 1 ? '' : 's';
    openDialog(`Downloading data for ${selected.length} user${plural}`);
  }

  function firedAlert() {
    openDialog(
      'You just fired all these employees. I hope you can live with yourself.'
    );
  }

  const onPageChange = (newPage, newItemsPerPage) => {
    setCurrentPage(newPage);
    setItemsPerPage(newItemsPerPage);
  };

  const bulkActions = [
    {
      icon: <SaveAlt />,
      tooltipText: 'Download users',
      onClick: downloadAlert,
    },
    {
      icon: <Block />,
      tooltipText: 'Remove users',
      onClick: firedAlert,
    },
  ];

  const getActions = (user) => {
    const edit = (
      <SovosMenuItem
        key="0"
        onClick={() => {
          onEdit(user.id);
          setKey((val) => val + 1);
        }}
      >
        Edit
      </SovosMenuItem>
    );
    const del = (
      <SovosMenuItem
        key="1"
        onClick={async () => {
          setUserState({ type: 'DeleteUserBegin' });
          try {
            await api.deleteUser(user.id);
            setUserState({ type: 'DeleteUserSuccess' });
          } catch (error) {
            console.error(error);
            setUserState({ type: 'DeleteUserFailure' });
          }
          setDeleting(true);
        }}
      >
        Delete
      </SovosMenuItem>
    );

    return [edit, del];
  };

  return (
    <>
      <SovosPageContentTable
        key={key}
        isLoading={isLoading}
        className="UsersTable__Table"
        filters={{
          items: availableFilters,
          onApply: setAppliedFilters,
          values: appliedFilters,
        }}
        bulkActions={bulkActions}
        PaginationFooterProps={{
          itemsPerPage,
          currentPage,
          totalItems,
          onPageChange,
        }}
        search={{
          term: searchTerm,
          onSearch: searchUsers,
        }}
        setVisibleColumns={setColumns}
        onRowSelection={rowSelection}
        TableProps={{
          columns,
          data: list,
          fixedHeader: true,
          onColumnSort: sort,
          columnSortId: sortProps.id,
          rowActions: getActions,
        }}
      />
      <Dialog
        text={dialogText}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

UsersTable.propTypes = {
  onEdit: func.isRequired,
  onDeleted: func.isRequired,
};

export default UsersTable;
