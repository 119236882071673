import React, { cloneElement, forwardRef } from 'react';
import { object, oneOf, string } from 'prop-types';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';

const transforms = {
  filled: {
    base: 'translate(12px, 20px) scale(1)',
    shrink: 'translate(12px, 10px) scale(0.75)',
  },
  outlined: {
    base: 'translate(14px, 20px) scale(1)',
    shrink: 'translate(14px, -6px) scale(0.75)',
  },
  standard: {
    base: 'translate(0, 24px) scale(1)',
    shrink: 'translate(0, 1.5px) scale(0.75)',
  },
};

const StyledTextField = styled(TextField)(
  ({
    label,
    size,
    variant,
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
      typography,
    },
  }) => {
    const filledColor = palette.grey.A200;
    const filledOpacity = 0.09;
    const filledNoLabel = () => {
      if (variant === 'filled' && !label) {
        return {
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          paddingTop: 0,
          paddingBottom: 0,
        };
      }
      return {};
    };

    const getFieldHeight = () => {
      if (variant === 'standard') {
        return null;
      }
      if (size === 'small') {
        return spacing(8.5);
      }
      return spacing(9.5);
    };

    const getInputHeight = () => {
      // allow for 2px bottom border
      if (variant === 'standard') {
        return 'auto';
      }
      if (size === 'small') {
        return spacing(6);
      }
      return spacing(7);
    };

    return {
      '&.MuiTextField-root': {
        minHeight: getFieldHeight(),
      },
      '& .MuiInputBase-root': {
        minHeight: getInputHeight(),
      },
      '& .MuiFilledInput-root': {
        backgroundColor: alpha(filledColor, filledOpacity),
        borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
        '&.MuiFilledInput-underline::before': {
          // bottom border color
          borderColor: alpha(palette.text.secondary, 0.5),
        },
        '&:hover': {
          backgroundColor: alpha(
            filledColor,
            filledOpacity + palette.action.hoverOpacity
          ),
          borderColor: palette.text.primary,

          '&.MuiFilledInput-underline::before': {
            // hover bottom border color
            borderColor: palette.text.primary,
            borderWidth: spacing(0.25),
          },
        },
        '& .MuiInputAdornment-root': {
          color: palette.action.active,
        },
        '&.Mui-focused': {
          backgroundColor: alpha(filledColor, filledOpacity),
        },
        '&.Mui-error': {
          '&.MuiFilledInput-underline::before': {
            // error border color
            borderColor: palette.error.main,
            borderWidth: spacing(0.25),
          },
        },
        // disabled
        '&.Mui-disabled, &.Mui-disabled:hover': {
          backgroundColor: alpha(palette.grey[400], 0.2),
          '&.MuiFilledInput-underline::before': {
            // disabled border color
            borderColor: alpha(palette.text.disabled, 0.5),
            borderBottomStyle: 'solid',
            borderWidth: 1,
          },

          '& .MuiInputAdornment-root': {
            color: palette.action.disabled,
          },
        },
      },
      '& .MuiFormHelperText-root': {
        ...typography.body2,
        color: palette.text.secondary,
        marginLeft: variant === 'standard' ? 0 : spacing(),
        marginTop: spacing(0.5),

        '&.Mui-error': {
          color: palette.error.main,
        },
      },
      '& .MuiInputBase-input': {
        ...filledNoLabel(),
        color: palette.text.primary,
        height: variant === 'standard' ? '1.48em' : undefined,

        '&:disabled': {
          color: palette.text.disabled,
        },
      },
      '& .MuiInputLabel-root': {
        '&:not(.Mui-focused)': {
          color: palette.text.secondary,
        },
        lineHeight: 1,
        overflow: 'visible',
        transform: transforms[variant]?.base,
        '&.MuiInputLabel-shrink': {
          transform: transforms[variant]?.shrink,
        },
        '&.Mui-disabled': {
          color: palette.text.disabled,
        },
        '&.Mui-error': {
          color: palette.error.main,
        },
      },

      '& .MuiOutlinedInput-root': {
        borderRadius,
        '& fieldset': {
          borderColor: alpha(palette.grey[900], 0.3),
        },
        '&:hover': {
          '& fieldset': {
            borderColor: palette.text.primary,
          },
        },
        '&.Mui-disabled': {
          '&& fieldset, &&:hover fieldset': {
            borderColor: alpha(palette.text.disabled, 0.5),
          },

          '& .MuiInputAdornment-root': {
            color: palette.action.disabled,
          },
        },
        '&.Mui-focused': {
          backgroundColor: 'none',
          '& fieldset, &:hover fieldset': {
            borderColor: palette.primary.main,
          },
        },
        '&.Mui-error': {
          '& fieldset, &:hover fieldset': {
            borderColor: palette.error.main,
          },
        },
        '& .MuiInputAdornment-root': {
          color: palette.action.active,
        },
      },
      '&.MuiFormControl-marginDense': {
        '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
          transform: 'translate(14px, 22px) scale(1)',
        },
      },
    };
  }
);

const SovosTextField = forwardRef(
  (
    {
      classes,
      className,
      color = 'primary',
      InputProps = {},
      label,
      size = 'medium',
      variant = 'filled',
      ...rest
    },
    ref
  ) => {
    const {
      classes: inputPropsClasses,
      endAdornment,
      startAdornment,
      ...inputPropsRest
    } = InputProps;

    const cloneAdornment = (adornment) => {
      if (!adornment) {
        return undefined;
      }

      // Autocomplete adds multiple end adornments when rendering chips in multi-select mode, handle an array by cloning
      // each element
      if (Array.isArray(adornment)) {
        return adornment.map(cloneAdornment);
      }

      return cloneElement(adornment, {
        classes: {
          ...(adornment.props?.classes || {}),
          root: classes?.adornmentRoot,
        },
      });
    };

    const isFilled = variant === 'filled';
    const inputProps = {
      classes: {
        ...inputPropsClasses,
        root: clsx(
          isFilled ? classes?.filledInputRoot : classes?.outlinedInputRoot,
          inputPropsClasses?.root
        ),
        disabled: classes?.disabled,
        error: clsx(classes?.error, inputPropsClasses?.error),
        focused: classes?.focused,
        input: classes?.input,
        underline: isFilled ? classes?.underline : undefined,
      },
      endAdornment: cloneAdornment(endAdornment),
      startAdornment: cloneAdornment(startAdornment),
      ...inputPropsRest,
    };

    const { FormHelperTextProps } = rest;

    return (
      <StyledTextField
        autoComplete="off"
        className={clsx('sovosTextField', className, classes?.root)}
        color={color}
        FormHelperTextProps={{
          ...(FormHelperTextProps || {}),
          classes: {
            root: clsx(classes?.helperRoot, FormHelperTextProps?.classes?.root),
          },
        }}
        InputProps={inputProps}
        InputLabelProps={{
          classes: {
            root: classes?.label,
            disabled: classes?.labelDisabled,
            marginDense: classes?.marginDense,
            outlined: classes?.outLinedLabel,
            shrink: classes?.shrink,
          },
        }}
        label={label}
        ref={ref}
        size={size}
        variant={variant}
        {...rest}
      />
    );
  }
);

SovosTextField.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * One of the Material UI theme colors
   */
  color: oneOf(['primary']),
  /**
   * Properties for the underlying Material UI Input component
   */
  InputProps: object,
  /**
   * Label of the input
   */
  label: string,
  /**
   * Size of the TextField
   */
  size: oneOf(['medium', 'small']),

  /**
   * Text field variant
   */
  variant: oneOf(['filled', 'outlined', 'standard']),
};

SovosTextField.baseComponent = {
  name: 'Text Field',
  link: 'text-field/',
};
export default SovosTextField;
