import React from 'react';
import { bool, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { ArrowRight } from 'mosaic-react-icons';
import { square } from '../../../internals/utils/stylesHelpers';

const StyledIcon = styled(ArrowRight)(({ theme: { spacing } }) => ({
  ...square(spacing(2.5)),
}));

const BaseIcon = ({ className, isExpanded }) => (
  <StyledIcon
    className={clsx(
      'sovosNavigationLinks__nestedLinksToggle',
      className,
      isExpanded && 'sovosNavigationLinks__nestedLinksToggle--expanded'
    )}
  />
);

BaseIcon.propTypes = {
  className: string,
  isExpanded: bool,
};

BaseIcon.defaultProps = {
  className: undefined,
  isExpanded: false,
};

export default BaseIcon;
