export default (str) => {
  if (typeof str === 'string') {
    const match = str.match(/^\d+\.?\d*/g);
    if (match) {
      return parseFloat(match[0], 10);
    }
  }

  return str;
};
