import { parse as fnParse, isAfter, isValid as fnIsValid } from 'date-fns';

/**
 * Returns an error message string if out of valid range or invalid date format
 *
 * @param {object} values - From date, to date
 * @param {string} format - Date format,
 * @param {object | string} minDate,
 * @param {object | string} maxDate,
 * @param {string} invalidStartDateMessage,
 * @param {string} invalidEndDateMessage,
 * @param {string} endDateAfterStartDateMessage,
 */
export default function getDateRangeMessage({
  endDateAfterStartDateMessage,
  format,
  invalidEndDateMessage,
  invalidStartDateMessage,
  maxDate,
  minDate,
  values,
} = {}) {
  const { from, to } = values;

  const parseDate = (date) => {
    if (date?.getTime && date.getTime()) return date;

    return fnParse(date, format, new Date());
  };

  const isInRange = (input) => {
    if (!minDate && !maxDate) return true;

    const date = parseDate(input, format);

    if (maxDate && date > parseDate(maxDate, format)) return false;
    if (minDate && date < parseDate(minDate, format)) return false;

    return true;
  };

  const isValid = (date) => fnIsValid(fnParse(date, format, new Date()));

  const exists = (date) => !!(date || date === undefined);

  if (exists(from) && (!isValid(from) || !isInRange(from)))
    return invalidStartDateMessage;

  if (exists(to) && (!isValid(to) || !isInRange(to)))
    return invalidEndDateMessage;

  if (exists(from) && exists(to) && isAfter(parseDate(from), parseDate(to)))
    return endDateAfterStartDateMessage;

  return '';
}
