import React from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  object,
  oneOfType,
  number,
  shape,
  string,
  elementType,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { AttachFile } from 'mosaic-react-icons';
import SovosTypography from '../sovos-typography';
import SovosTabContent from '../sovos-tab-content';
import SovosAttachmentsList from '../sovos-attachments-list';
import SovosButtonsGroup from '../sovos-buttons-group';
import SovosButton from '../sovos-button';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledEmptyListTypography = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.disabled,
    marginTop: spacing(3),
  })
);

const StyledSovosButtonsGroup = styled(SovosButtonsGroup)(
  ({ theme: { spacing } }) => ({
    marginTop: spacing(3),
  })
);

/**
 * **Note**: `SovosAttachmentsListTab` must be a child of `SovosTabGroup`
 */
const SovosAttachmentsListTab = ({
  className,
  data,
  'data-testid': dataTestId,
  downLoadAllButtonLabel,
  emptyListLabel,
  onAttach,
  onDownloadAll,
  primaryButtonLabel,
  selected,
  sx,
  TabProps: UnusedTabProps,
  ...attachmentsListProps
}) => {
  const { t } = useMosaicTranslation();
  return (
    <SovosTabContent
      className={clsx('sovosAttachmentsListTab', className)}
      data-testid={dataTestId}
      TabProps={{}}
      selected={selected}
      sx={sx}
    >
      {data.length === 0 && (
        <StyledEmptyListTypography component="div" variant="body1">
          {emptyListLabel || t('attachmentsListTab.emptyListLabel')}
        </StyledEmptyListTypography>
      )}
      {data.length > 0 && (
        <SovosAttachmentsList data={data} {...attachmentsListProps} />
      )}
      <StyledSovosButtonsGroup align="left">
        {onAttach && (
          <SovosButton onClick={onAttach} variant="text">
            {primaryButtonLabel || t('attachmentsListTab.primaryButtonLabel')}
          </SovosButton>
        )}
        {onDownloadAll && (
          <SovosButton onClick={onDownloadAll} variant="text">
            {downLoadAllButtonLabel || t('downloadAll')}
          </SovosButton>
        )}
      </StyledSovosButtonsGroup>
    </SovosTabContent>
  );
};
SovosAttachmentsListTab.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of attachments entries to be rendered. Each entry should contain
   * document, documentLink, timestamp and optional time label, name, and surname.
   */
  data: arrayOf(
    shape({
      id: number,
      document: string.isRequired,
      documentLink: string,
      name: string,
      surname: string,
      timestamp: number,
      timeLabel: string,
    })
  ).isRequired,
  /**
   * Ignore
   */
  'data-testid': string,
  /**
   * Download All button label
   */
  downLoadAllButtonLabel: string,
  /**
   * Text displayed when data has no attachments
   */
  emptyListLabel: string,
  /**
   * Callback to be invoked when `Add Attachment` clicked
   */
  onAttach: func,
  /**
   * Callback to be invoked when `Download All` clicked
   */
  onDownloadAll: func,
  /**
   * Callback fired when `delete` icon is clicked. `function(attachment: object)`
   */
  onDelete: func,
  /**
   * Callback fired when document name is clicked. documentLink will take
   * precedence over callback. `function(attachment: obejct)`
   */
  onDownload: func,
  /**
   * Add Attachment button label
   */
  primaryButtonLabel: string,
  /**
   * Function used to sort provided data.
   */
  sortFunction: func,
  /**
   * If true, this will be the initially displayed tab in the tab group
   */
  selected: bool,
  /**
   * Override or extend the styles applied to the SovosList base component
   */
  SovosListClasses: object,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Props passed to the SovosTab
   */
  TabProps: shape({
    icon: node,
    label: oneOfType([node, elementType]),
  }),
};

const icon = <AttachFile />;

const TabLabel = () => {
  const { t } = useMosaicTranslation();
  return t('attachmentsTab.label');
};

SovosAttachmentsListTab.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  downLoadAllButtonLabel: undefined,
  emptyListLabel: undefined,
  onAttach: undefined,
  onDelete: undefined,
  onDownload: undefined,
  onDownloadAll: undefined,
  primaryButtonLabel: undefined,
  selected: false,
  sortFunction: undefined,
  SovosListClasses: undefined,
  sx: undefined,
  TabProps: {
    icon,
    label: TabLabel,
  },
};

export default SovosAttachmentsListTab;
