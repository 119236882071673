/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formatDate, formatDateTime } from './helpers';
import { loadImport } from '../api/imports';

export const importData = createAsyncThunk('import/importData', async (id) =>
  loadImport(id)
);

const importReducer = createSlice({
  name: 'import',

  initialState: {
    isLoading: false,
    current: {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(importData.pending, (state) => {
        state.isLoading = true;
        state.current = undefined;
      })
      .addCase(importData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.current = {
          ...action.payload,
          formattedTimeStamp: formatDateTime(action.payload.timeStamp),
          formattedReportDate: formatDate(action.payload.reportDate),
        };
      })
      .addCase(importData.rejected, (state) => {
        state.isLoading = false;
        state.current = undefined;
      });
  },
});

export default importReducer.reducer;
