import React from 'react';
import { node } from 'prop-types';
import { styled } from '@mui/material/styles';

const StyledContainer = styled('div')(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
  boxSizing: 'border-box',
}));

const ContainerLayer = ({ children }) => {
  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  return (
    <StyledContainer
      className="sovosSlidingPanelContainer"
      onClick={handleContainerClick}
    >
      {children}
    </StyledContainer>
  );
};

ContainerLayer.propTypes = {
  children: node.isRequired,
};

export default ContainerLayer;
