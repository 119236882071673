import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  SovosDialog,
  SovosDialogActions,
  SovosDialogTitle,
} from 'mosaic-react';
import SovosButton from 'mosaic-react/sovos-button';

const Dialog = ({ text, isOpen, onClose }) => (
  <SovosDialog open={isOpen} onClose={onClose} width="small">
    <SovosDialogTitle>{text}</SovosDialogTitle>
    <SovosDialogActions>
      <SovosButton color="primary" onClick={onClose}>
        OK
      </SovosButton>
    </SovosDialogActions>
  </SovosDialog>
);

Dialog.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  text: string.isRequired,
};

export default Dialog;
