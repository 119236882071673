import React, { cloneElement } from 'react';
import { arrayOf, number, oneOfType, string } from 'prop-types';
import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';
import SovosToolbarGroup from '../sovos-toolbar-group';
import SovosIconButton from '../sovos-icon-button';
import SovosIconMenu from '../sovos-icon-menu';
import {
  elementOfPropType,
  findChildrenByType,
} from '../internals/utils/reactHelpers';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledToolbarGroup = styled(SovosToolbarGroup)({
  border: 0,
  overflow: 'hidden',
  paddingLeft: 0,
});

const Wrapper = styled('div')(({ theme: { palette, spacing } }) => ({
  alignItems: 'center',
  background: palette.primary.main,
  display: 'flex',
  height: '100%',
  marginRight: spacing(2),
  padding: `0 ${spacing()} 0 ${spacing(2)}`,
  transition: 'margin-left 400ms ease-in-out',
}));

const StyledTypography = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.primary.contrastText,
    paddingRight: spacing(),
  })
);

/**
 * `SovosToolbarBulkActions` is a hidden container that slides out from the
 * left if one or several rows are selected and slides back in if
 * everything is deselected. Therefore, bulk actions only display if at
 * least one row is selected.
 *
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarBulkActions = ({
  count,
  children,
  className,
  selectedText,
  ...props
}) => {
  const { palette } = useTheme();
  const { t } = useMosaicTranslation();

  return (
    <StyledToolbarGroup
      {...props}
      className={clsx('sovosToolbarBulkActions', className)}
      data-testid={
        count
          ? 'sovosToolbarBulkActions--visible'
          : 'sovosToolbarBulkActions--invisible'
      }
    >
      <Wrapper
        style={{
          marginLeft: count > 0 ? 0 : -300,
        }}
      >
        <StyledTypography component="span" variant="body1" noWrap>
          {`${count} ${selectedText || t('toolbarBulkActions.selected')}`}
        </StyledTypography>
        {children &&
          findChildrenByType(children, SovosIconButton, SovosIconMenu).map(
            (child) =>
              cloneElement(child, {
                size: 'small',
                color: palette.primary.contrastText,
              })
          )}
      </Wrapper>
    </StyledToolbarGroup>
  );
};

SovosToolbarBulkActions.propTypes = {
  /**
   * The content of the toolbar bulk actions. Should be a collection of
   * `SovosIconMenu` and `SovosIconButton`
   */
  children: oneOfType([
    elementOfPropType(SovosIconButton, SovosIconMenu),
    arrayOf(elementOfPropType(SovosIconButton, SovosIconMenu)),
  ]),
  /**
   * Number of selected items. If `0`, component is not visible
   */
  count: number.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Text label for number of selected items
   */
  selectedText: string,
};

SovosToolbarBulkActions.defaultProps = {
  children: null,
  className: undefined,
  selectedText: undefined,
};

export default SovosToolbarBulkActions;
