/* eslint-disable camelcase */
import React from 'react';
import { node, object, string } from 'prop-types';
import clsx from 'clsx';
import { Card } from '@mui/material';
import SovosCardActions from '../sovos-card-actions';
import SovosCardContent from '../sovos-card-content';
import SovosCardHeader from '../sovos-card-header';
import {
  findChildByType,
  filterOutChildrenByType,
} from '../internals/utils/reactHelpers';

const SovosCard = ({ children, className, style, ...otherProps }) => {
  const header = findChildByType(children, SovosCardHeader);
  const actions = findChildByType(children, SovosCardActions);
  const content = findChildByType(children, SovosCardContent);
  const childrenToKeep = filterOutChildrenByType(
    children,
    SovosCardActions,
    SovosCardContent,
    SovosCardHeader
  );

  const contentToRender = content || (
    <SovosCardContent>{childrenToKeep}</SovosCardContent>
  );

  return (
    <Card
      className={clsx('sovosCard', className)}
      style={style}
      {...otherProps}
    >
      {header}
      {contentToRender}
      {actions}
    </Card>
  );
};

SovosCard.propTypes = {
  /**
   * The content of the card
   */
  children: node,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Inline styles applied to the root element
   */
  style: object,
};

SovosCard.defaultProps = {
  children: null,
  className: undefined,
  style: undefined,
};

SovosCard.baseComponent = {
  name: 'Card',
  link: 'card/',
};

export default SovosCard;
