import React, { cloneElement } from 'react';
import { node, number, object, oneOf, oneOfType, string } from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

import {
  findChildByType,
  filterOutChildrenByType,
} from '../internals/utils/reactHelpers';

import SovosDialogActions from '../sovos-dialog-actions';
import SovosDialogContent from '../sovos-dialog-content';
import SovosDialogTitle from '../sovos-dialog-title';

const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';

const getWidthStyle = (width, spacing) => {
  if (width === SMALL) {
    return {
      maxWidth: spacing(50),
    };
  }

  if (width === MEDIUM) {
    return {
      minWidth: spacing(75),
      maxWidth: spacing(87.5),
    };
  }

  if (width === LARGE) {
    return {
      minWidth: spacing(75),
      maxWidth: '80vw',
      width: '80vw',
    };
  }

  return {
    width,
  };
};

/**
 * Dialogs can use the `SovosDialogTitle`, `SovosDialogContents`, and
 * `SovosDialogActions` components for styling and positioning their contents.
 */
const SovosDialog = ({ children, className, PaperProps, width, ...rest }) => {
  const { spacing } = useTheme();
  const title = findChildByType(children, SovosDialogTitle);
  const content = findChildByType(children, SovosDialogContent) || (
    <SovosDialogContent>
      {filterOutChildrenByType(children, SovosDialogTitle, SovosDialogActions)}
    </SovosDialogContent>
  );
  const actions = findChildByType(children, SovosDialogActions);

  const renderTitle = () => {
    const handleClose = title?.props?.onClose ?? rest.onClose;

    return title
      ? cloneElement(title, {
          onClose: handleClose ?? null,
        })
      : null;
  };

  return (
    <Dialog
      className={clsx('sovosDialog', className)}
      PaperProps={{
        style: {
          ...PaperProps.style,
        },
        sx: {
          borderRadius: 'shape.borderRadius',
          padding: spacing(3),
          ...getWidthStyle(width, spacing),
        },
        ...PaperProps,
      }}
      {...rest}
    >
      {renderTitle()}
      {content}
      {actions}
    </Dialog>
  );
};

SovosDialog.propTypes = {
  /**
   * The content of the dialog. Should be wrapped with `SovosDialogTitle`,
   * `SovosDialogContent`, and `SovosDialogActions`
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Props passed the MuiDialog paper component
   */
  PaperProps: object,
  /**
   * Width, can be 'small', 'medium', 'large' or an arbitrary number.
   *
   * - `small`: maxWidth: '400px'
   * - `medium`: minWidth: '600px', maxWidth: '700px'
   * - `large`: width: '80%', minWidth: '600px',
   */
  width: oneOfType([oneOf([SMALL, MEDIUM, LARGE]), number]),
};

SovosDialog.defaultProps = {
  className: undefined,
  PaperProps: {},
  width: MEDIUM,
};

SovosDialog.baseComponent = {
  name: 'Dialog',
  link: 'dialog/',
};

export default SovosDialog;
