import React, { useRef } from 'react';
import { bool, func, node, number, oneOfType, shape, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { useDrag, useDrop } from 'react-dnd';
import { DragIndicator } from 'mosaic-react-icons';
import SovosIconButton from '../../sovos-icon-button';
import SovosFixedWidthText from '../../sovos-fixed-width-text';

const ItemRoot = styled('li')(
  ({ theme: { palette, shape: themeShape, spacing, typography } }) => ({
    alignItems: 'center',
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.divider}`,
    borderRadius: themeShape.borderRadius,
    boxSizing: 'border-box',
    color: palette.text.secondary,
    cursor: 'default',
    display: 'flex',
    height: spacing(5),
    marginBottom: spacing(),
    padding: 0,
    position: 'relative',
    ...typography.body1,
    '&.Mui-active': {
      color: palette.primary.main,
      cursor: 'grab',
      opacity: 1,

      '&:active': {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,

        '& svg': {
          color: palette.primary.contrastText,
        },
      },
    },
    '&.sovos-dragging': {
      opacity: 0,
    },
  })
);

const DragIcon = styled(DragIndicator)(({ theme: { palette, spacing } }) => ({
  color: palette.primary.main,
  marginLeft: spacing(),
}));

const Title = styled(SovosFixedWidthText)(
  ({ theme: { spacing, typography } }) => ({
    flex: '1 1 auto',
    fontSize: typography.body1.fontSize,
    lineHeight: 1,
    marginRight: spacing(),
    marginLeft: spacing(2),
    '&.Mui-active': {
      marginLeft: spacing(),
    },
  })
);

const UpdateButton = styled(SovosIconButton)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.primary,
    margin: `0 ${spacing(0.5)} 0 auto`,
    '&.Mui-active': {
      color: palette.primary.main,
    },
  })
);

const ItemTypes = {
  ITEM: 'item',
};

const SovosOrderableListItem = ({
  active,
  activeIcon,
  disableRemove,
  id,
  inactiveIcon,
  index,
  labels,
  moveItem,
  title,
  updateItem,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: {
      id,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (active) {
    drag(drop(ref));
  }

  const getTooltipText = () => {
    if (active) {
      if (disableRemove) return labels.removeDisabledTooltipText;
      return labels.removeTooltipText;
    }
    return labels.addTooltipText;
  };

  return (
    <ItemRoot
      className={clsx({
        'Mui-active': active,
        'sovos-dragging': isDragging,
      })}
      ref={ref}
    >
      {active && <DragIcon fontSize="small" />}
      <Title className={clsx({ 'Mui-active': active })} text={title} />
      <UpdateButton
        className={clsx({ 'Mui-active': active })}
        disabled={disableRemove}
        onClick={() => updateItem(index, active)}
        tooltipText={getTooltipText()}
        size="small"
      >
        {active ? activeIcon : inactiveIcon}
      </UpdateButton>
    </ItemRoot>
  );
};

SovosOrderableListItem.propTypes = {
  active: bool.isRequired,
  activeIcon: node.isRequired,
  disableRemove: bool.isRequired,
  id: oneOfType([number, string]).isRequired,
  inactiveIcon: node.isRequired,
  index: number.isRequired,
  labels: shape({
    addTooltipText: node,
    header: string,
    removeDisabledTooltipText: node,
    removeTooltipText: node,
  }).isRequired,
  moveItem: func.isRequired,
  title: string.isRequired,
  updateItem: func.isRequired,
};

export default SovosOrderableListItem;
