import theme from '../../themes/sovos-theme';

const { palette } = theme;

const customThemeColors = [
  {
    title: 'Mosaic (default)',
    colors: {
      background: palette.primary.dark,
      highlights: palette.primary.main,
      hover: palette.primary.light,
      text: palette.primary.contrastText,
    },
  },
  {
    title: 'Happy Yellow',
    colors: {
      background: '#999999',
      highlights: '#ffe600',
      hover: '#a3a4a5',
      text: '#ffffff',
    },
  },
  {
    title: 'Relaxing Green',
    colors: {
      background: '#2c5234',
      highlights: '#86bC25',
      hover: '#2f5129',
      text: '#ffffff',
    },
  },
  {
    title: 'Energetic Red',
    colors: {
      background: '#d04a02',
      highlights: '#eb8c00',
      hover: '#d66020',
      text: '#ffffff',
    },
  },
  {
    title: 'Reliable Blue',
    colors: {
      background: '#00338d',
      highlights: '#0091da',
      hover: '#015fff',
      text: '#ffffff',
    },
  },
  {
    title: 'Custom',
    colors: {
      background: '#00338d',
      highlights: '#0091da',
      hover: '#ff0000',
      text: '#ffffff',
    },
  },
];

export default customThemeColors;
