import React from 'react';
import { node, object, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
}));

const SovosSettings = ({ classes, children, className, style, ...rest }) => (
  <Root
    className={clsx('sovosSettings', className, classes?.root)}
    style={style}
    {...rest}
  >
    {children}
  </Root>
);

SovosSettings.propTypes = {
  /**
   * The content of the settings. A collection of `SovosSettingsSection` components
   */
  children: node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Inline styles applied to the root element
   */
  style: object,
};

SovosSettings.defaultProps = {
  classes: undefined,
  className: undefined,
  style: undefined,
};

export default SovosSettings;
