import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { Check } from 'mosaic-react-icons';
import Chip from '../internals/components/chip/Chip';

const StyledChip = styled(Chip)(({ theme: { spacing } }) => ({
  '&.Mui-selected': {
    ' & .MuiChip-label': {
      paddingLeft: spacing(1),
    },
    '& .MuiChip-icon': {
      margin: 0,
      paddingLeft: spacing(1),
      width: spacing(3),
    },
  },
}));

/**
 * Used to toggle a choice off or on, similar to a checkbox
 */
const SovosChipFilter = ({ classes, className, selected, ...rest }) => (
  <StyledChip
    classes={classes}
    className={clsx('sovosChipFilter', className, {
      'Mui-selected': selected,
    })}
    icon={selected === true ? <Check data-testid="check" /> : null}
    selected={selected}
    {...rest}
  />
);

SovosChipFilter.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Content of the chip
   */
  label: string.isRequired,
  /**
   * Maximum width of the chip
   */
  maxWidth: number,
  /**
   * Callback fired when the chip is clicked. For `input` chips, this is
   * attached to the delete icon.
   */
  onClick: func,
  /**
   * If `true`, the chip is selected. This will change the background color
   * for `choice` and `filter` chips
   */
  selected: bool,
};

SovosChipFilter.defaultProps = {
  classes: undefined,
  className: undefined,
  maxWidth: undefined,
  onClick: undefined,
  selected: undefined,
};

SovosChipFilter.baseComponent = {
  name: 'Chip',
  link: 'chip/',
};

export default SovosChipFilter;
