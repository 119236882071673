import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SovosEditableDrawer from '../sovos-editable-drawer';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const SovosFilterDrawer = ({
  SaveButtonProps,
  ClearButtonProps,
  onClose,
  open,
  children,
  title,
  className,
  style,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  return (
    <SovosEditableDrawer
      title={title || t('filterDrawer.title')}
      PrimaryButtonProps={{
        onClick: SaveButtonProps.onClick,
        disabled: SaveButtonProps.disabled,
        children: SaveButtonProps.children,
        className: 'sovosFilterDrawer__saveButton',
      }}
      SecondaryButtonProps={{
        onClick: ClearButtonProps.onClick,
        children: ClearButtonProps.children || t('clear'),
        className: 'sovosFilterDrawer__clearButton',
      }}
      onClose={onClose}
      open={open}
      className={clsx('sovosFilterDrawer', className)}
      style={style}
      {...rest}
    >
      {children}
    </SovosEditableDrawer>
  );
};

SovosFilterDrawer.propTypes = {
  /**
   * Title content
   */
  title: PropTypes.node,
  /**
   * Props applied to the Apply button
   */
  SaveButtonProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node,
  }).isRequired,
  /**
   * Props applied to the Clear button
   */
  ClearButtonProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node,
  }).isRequired,
  /**
   * Callback for when the drawer is closed by clicking off of it
   */
  onClose: PropTypes.func.isRequired,
  /**
   * The drawer's open state
   */
  open: PropTypes.bool.isRequired,
  /**
   * The content of the filter drawer. Children should be a combination of
   * `SovosFilterCheckboxList`, `SovosFilterDateInput`,
   * `SovosFilterDrawerSection`, `SovosFilterDateRange`,
   * `SovosFilterNumberRange`, and `SovosFilterTextEntry`
   */
  children: PropTypes.node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: PropTypes.string,
  /**
   * Inline styles applied to the root element
   */
  style: PropTypes.object,
};

SovosFilterDrawer.defaultProps = {
  title: undefined,
  className: undefined,
  style: undefined,
};

export default SovosFilterDrawer;
