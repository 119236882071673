import React from 'react';
import clsx from 'clsx';
import { bool, object, oneOf, string } from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import SovosTooltip from '../sovos-tooltip';
import { isKnownMaterialUiColor } from '../internals/utils/materialUiHelpers';
import { getAfterFocusStyles } from '../internals/utils';
import buttonStyles from '../internals/utils/buttonStyles';

const { boxShadow } = buttonStyles;

function getButtonColor(backgroundColor, color, palette, variant) {
  if (color === 'error') return palette.error.main;

  const foreBgColor = variant === 'contained' ? backgroundColor : color;

  return isKnownMaterialUiColor(foreBgColor) || !foreBgColor
    ? palette.primary.main
    : foreBgColor;
}

const ButtonRoot = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColor' && prop !== 'dark' && prop !== 'fgColor',
})(
  ({
    backgroundColor,
    dark,
    disabled,
    fgColor,
    size,
    theme: { palette, shape, spacing, typography },
    variant,
  }) => {
    let minHeight = '';

    if (size === 'small') {
      minHeight = spacing(3);
    } else if (size === 'medium') {
      minHeight = spacing(4);
    } else if (size === 'large') {
      minHeight = spacing(6);
    }

    const buttonColor = getButtonColor(
      backgroundColor,
      fgColor,
      palette,
      variant
    );

    const textColor =
      isKnownMaterialUiColor(fgColor) || !fgColor || fgColor === 'error'
        ? palette.primary.contrastText
        : fgColor;

    const getDarkOverrides = () => {
      const { contrast } = palette.text;
      const danger = fgColor === 'error';

      const getDarkText = () => {
        if (disabled) return palette.text.disabled;
        if (danger) return palette.error.main;
        if (variant === 'outlined' || variant === 'text') return contrast;
        return palette.primary.main;
      };

      const getDarkOutline = () => {
        if (disabled) return palette.action.disabledBackground;
        if (danger) return palette.error.main;
        return contrast;
      };

      const darkHoverBg = danger
        ? alpha(palette.error.main, palette.action.hoverOpacity)
        : alpha(contrast, palette.action.hoverOpacity);

      return {
        '&.MuiButton-containedPrimary': {
          color: getDarkText(),
          backgroundColor: disabled
            ? palette.grey[50]
            : palette.primary.contrastText,
          boxShadow: 'none',

          '&:hover': {
            color: palette.primary.contrastText,
            backgroundColor: danger
              ? palette.error.main
              : palette.primary.light,

            boxShadow: 'none',
          },
        },

        '&.MuiButton-outlinedPrimary': {
          color: getDarkText(),
          borderColor: getDarkOutline(),
          '&:hover': {
            backgroundColor: darkHoverBg,
          },
        },

        '&.MuiButton-textPrimary': {
          color: getDarkText(),
          '&:hover': {
            backgroundColor: darkHoverBg,
          },
        },
      };
    };

    const defaultOverrides = disabled
      ? {
          '&.MuiButtonBase-root.Mui-disabled': {
            color: palette.text.disabled,
            boxShadow: variant === 'contained' ? boxShadow : undefined,
          },
        }
      : {
          '&.MuiButton-containedPrimary': {
            color: textColor,
            backgroundColor: buttonColor,
            '&:hover': {
              color: textColor,
              backgroundColor:
                fgColor === 'error' ? palette.error.dark : palette.primary.dark,
              boxShadow,
            },
          },

          '&.MuiButton-outlinedPrimary': {
            color: buttonColor,
            borderColor: buttonColor,
            '&:hover': {
              borderColor: buttonColor,
              backgroundColor: alpha(buttonColor, palette.action.hoverOpacity),
            },
          },

          '&.MuiButton-textPrimary': {
            color: buttonColor,
            '&:hover': {
              backgroundColor: alpha(buttonColor, palette.action.hoverOpacity),
            },
          },
        };

    const variantOverrides = dark ? getDarkOverrides() : defaultOverrides;

    return {
      minHeight,
      borderRadius: shape.borderRadius,
      boxShadow: variant === 'contained' ? boxShadow : undefined,
      minWidth: spacing(9),
      padding:
        size === 'small'
          ? `${spacing(0)} ${spacing(2)}`
          : `${spacing(0.75)} ${spacing(2)}`,
      '&:focus-visible': {
        boxShadow: 'none',
        ...getAfterFocusStyles(),
      },
      ...variantOverrides,
      ...typography.button,
    };
  }
);

const SovosButton = React.forwardRef((props, ref) => {
  const {
    color = 'primary',
    className,
    disabled = false,
    isDarkBackground = false,
    tooltipText,
    size = 'medium',
    variant = 'contained',
    ...rest
  } = props;

  const button = (
    <ButtonRoot
      className={clsx('sovosButton', className)}
      color={isKnownMaterialUiColor(color) ? color : 'primary'}
      dark={isDarkBackground}
      disabled={disabled}
      disableFocusRipple
      fgColor={color}
      size={size}
      ref={ref}
      variant={variant}
      {...rest}
    />
  );

  return tooltipText ? (
    <SovosTooltip title={tooltipText}>
      {disabled ? <span>{button}</span> : button}
    </SovosTooltip>
  ) : (
    button
  );
});

SovosButton.propTypes = {
  /**
   * Override background color
   */
  backgroundColor: string,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  // TODO:
  // color: oneOf(['primary', 'inherit', 'default', 'error']),
  /**
   * One of the Material UI theme colors, `primary`, `default`, etc., or
   * `error`, or a custom color
   */
  color: string,
  /**
   * If `true`, the button is disabled
   */
  disabled: bool,
  /**
   * If used on a dark background
   */
  isDarkBackground: bool,
  /**
   * The size of the component.
   */
  size: oneOf(['small', 'medium', 'large']),
  /**
   * Tooltip text
   */
  tooltipText: string,
  /**
   * Button variant
   */
  variant: oneOf(['contained', 'outlined', 'text']),
};

SovosButton.baseComponent = {
  name: 'Button',
  link: 'button/',
};

export default SovosButton;
