import React from 'react';
import { node, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';
import SovosFixedWidthText from '../sovos-fixed-width-text';

const StyledHeader = styled('header')(({ theme: { palette, spacing } }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${palette.divider}`,
  boxSizing: 'border-box',
  display: 'flex',
  flexBasis: spacing(6),
  flexGrow: 0,
  flexShrink: 0,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: `0 ${spacing(3)}`,

  '& .sovosDrawerHeader__title': {
    color: palette.text.primary,
    flex: '1 1 auto',
    lineHeight: 1.2,
    margin: 0,
    maxWidth: '100%',
    padding: `${spacing()} 0`,
  },
  '& .sovosDrawerHeader__content': {
    flex: '1 1 auto',
    padding: `${spacing()} 0`,
  },
}));

/**
 * **Note** Must be a child `SovosDrawer`
 */
const SovosDrawerHeader = ({ children, className, title, ...rest }) => (
  <StyledHeader className={clsx('sovosDrawerHeader', className)} {...rest}>
    <SovosTypography
      className="sovosDrawerHeader__title"
      component="h2"
      variant="h4"
    >
      {typeof title === 'string' ? <SovosFixedWidthText text={title} /> : title}
    </SovosTypography>
    <div className="sovosDrawerHeader__content">{children}</div>
  </StyledHeader>
);

SovosDrawerHeader.propTypes = {
  /**
   * The content of the drawer header
   */
  children: node,
  /**
   * The content of the drawer header
   */
  className: string,
  /**
   * The title content
   */
  title: node.isRequired,
};

SovosDrawerHeader.defaultProps = {
  children: undefined,
  className: undefined,
};

export default SovosDrawerHeader;
