import React, { forwardRef, useState } from 'react';
import {
  any,
  array,
  bool,
  func,
  number,
  object,
  oneOf,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { Autocomplete, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDropDown } from 'mosaic-react-icons';
import SovosTextField from '../sovos-text-field/SovosTextField';
import PaperRoot from '../internals/components/select/PaperRoot';
import SovosTooltip from '../sovos-tooltip';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledSovosTextField = styled(SovosTextField)(({ label }) => ({
  '& .MuiFilledInput-input': {
    marginTop: !label ? -19 : null,
  },
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-popupIndicatorOpen': {
    transform: 'none', // don't flip the arrow
  },
}));

// Unable to style the Popper element directly using the MuiAutocomplete-popper class exposed by Autocomplete
// TODO: find a way to use the overrides in Autocomplete and remove this component and
const StyledPopper = styled(Popper)(({ theme: { shape } }) => ({
  '&.MuiAutocomplete-popper': {
    // popper is above the control
    '&[x-placement="top"] .MuiAutocomplete-paper': {
      borderRadius: shape.borderRadius,
    },
  },
}));

const SovosAutocomplete = forwardRef(
  (
    {
      className,
      classes,
      clearOptions,
      closeText,
      disabled,
      helpMessageTemplate,
      helperText,
      label,
      loading,
      loadOptions,
      loadingText,
      minimumCharacters,
      multiple,
      noOptionsText,
      onBlur,
      onChange,
      openText,
      options,
      size,
      value,
      variant,
      ...rest
    },
    ref
  ) => {
    const { t } = useMosaicTranslation();
    const typeMessage = `${minimumCharacters} ${
      helpMessageTemplate || t('autocomplete.helpMessageTemplate')
    }`;
    const [loadingMessage, setLoadingMessage] = useState(typeMessage);
    const [open, setOpen] = useState(false);

    const handleInputChange = (event) => {
      if (event?.target.value) {
        if (loadOptions && event.target.value.length >= minimumCharacters) {
          setLoadingMessage(loadingText || t('autocomplete.loadingMessage'));
          loadOptions(event.target.value);
        }
      }

      return null;
    };

    const handleClose = () => {
      setOpen(false);
      setLoadingMessage(typeMessage);

      if (clearOptions) {
        clearOptions();
      }
    };

    const { filled, marginDense, outlined, shrink, ...acCLasses } =
      classes || {};

    return (
      <StyledAutocomplete
        blurOnSelect={!multiple}
        classes={acCLasses}
        className={clsx('sovosAutoComplete', className)}
        closeText=""
        disabled={disabled}
        fullWidth
        label={label}
        loading={loadOptions && options.length === 0 ? true : loading}
        loadingText={loadingMessage}
        multiple={multiple}
        noOptionsText={noOptionsText || t('noResults')}
        onChange={onChange}
        onClose={handleClose}
        onInputChange={handleInputChange}
        onOpen={() => setOpen(true)}
        open={open}
        openText=""
        options={options}
        PaperComponent={(props) => (
          // Unable to style the Paper element directly using the MuiAutocomplete-paper class exposed by Autocomplete
          // TODO: find a way to use the overrides in Autocomplete and remove this component and
          <PaperRoot variant={variant} {...props} />
        )}
        popupIcon={
          <SovosTooltip
            title={open ? closeText || t('close') : openText || t('open')}
          >
            <ArrowDropDown />
          </SovosTooltip>
        }
        PopperComponent={StyledPopper}
        ref={ref}
        renderInput={(params) => {
          const { InputLabelProps, inputProps, ...textFieldParams } = params;

          return (
            <StyledSovosTextField
              {...textFieldParams}
              InputLabelProps={{
                ...InputLabelProps,
                classes: {
                  ...InputLabelProps.classes,
                  marginDense,
                  outlined,
                  shrink,
                  filled,
                },
              }}
              inputProps={{
                ...inputProps,
                onBlur: (e) => {
                  if (inputProps.onBlur) inputProps.onBlur(e);
                  if (onBlur) onBlur(e);
                },
              }}
              helperText={helperText}
              label={label}
              variant={variant}
            />
          );
        }}
        size={size}
        value={value}
        variant={variant}
        {...rest}
      />
    );
  }
);

SovosAutocomplete.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Callback fired when options list is closed. Intended to clear options
   * loaded from async `loadOptions` calls
   */
  clearOptions: func,
  /**
   * Text to display tooltip close arrows.
   */
  closeText: string,
  /**
   * If `true`, the input is disabled
   */
  disabled: bool,
  /**
   * Text concatenated with `minimumCharacters` to create a search prompt
   * in async loading
   */
  helpMessageTemplate: string,
  /**
   * Helper text for the input
   */
  helperText: string,
  /**
   * Label for the input
   */
  label: string,
  /**
   * If `true`, the component is in a loading state. Should be set when
   * async `loadOptions` calls are started and completed
   */
  loading: bool,
  /**
   * Asynchronous call to load matching options when `minimumCharacters`
   * have been entered
   */
  loadOptions: func,
  /**
   * Text to display in loading state
   */
  loadingText: string,
  /**
   * Number of characters required to trigger an async load
   */
  minimumCharacters: number,
  /**
   * Allow multiple values. If `true`, `value` must be an array
   */
  multiple: bool,
  /**
   * Text to display when there are no options
   */
  noOptionsText: string,
  /**
   * Callback fired when input loses focus
   */
  onBlur: func,
  /**
   * Callback fired when input value is changed `function(event: object,
   * value: string | string[], reason: string) => void`
   */
  onChange: func,
  /**
   * Text to display tooltip open arrows.
   */
  openText: string,
  /**
   * Array of options. By default, this is assumed to be an array of
   * strings. If this is an array of objects, provide a `getOptionLabel` function
   */
  options: array.isRequired,
  /**
   * Size of the Autocomplete
   */
  size: oneOf(['medium', 'small']),
  /**
   * Value of the Autocomplete
   */
  value: any,
  /**
   * Variant of the text input
   */
  variant: oneOf(['filled', 'outlined', 'standard']),
};

SovosAutocomplete.defaultProps = {
  classes: undefined,
  className: undefined,
  clearOptions: undefined,
  disabled: false,
  helpMessageTemplate: undefined,
  helperText: undefined,
  label: undefined,
  loading: false,
  loadOptions: undefined,
  loadingText: undefined,
  minimumCharacters: 2,
  multiple: false,
  noOptionsText: undefined,
  onBlur: undefined,
  onChange: undefined,
  openText: undefined,
  closeText: undefined,
  size: 'medium',
  value: undefined,
  variant: 'filled',
};

SovosAutocomplete.baseComponent = {
  name: 'Autocomplete',
  link: 'autocomplete/',
};

export default SovosAutocomplete;
