import React, { useCallback, useState } from 'react';
import { string, number, any } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosTooltip from '../../sovos-tooltip';

const shouldOverflowStateChange = (textWidth, containerWidth, prevState) => {
  if (textWidth >= containerWidth && !prevState) {
    return true;
  }
  if (textWidth < containerWidth && prevState) {
    return true;
  }
  return false;
};

const StyledSpan = styled('span')({
  display: 'inline-block',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  verticalAlign: 'middle',
});

/**
 * Renders cell in a span that will fit into a specified width, adding a
 * tooltip if the text overflows.
 */
const FixedWidthCellContent = ({ children, width, className }) => {
  const [textDoesOverflow, setTextDoesOverflow] = useState(false);

  const textRef = useCallback(
    (node) => {
      if (node === null) {
        return;
      }
      const textWidth = node.scrollWidth;

      if (shouldOverflowStateChange(textWidth, width, textDoesOverflow)) {
        setTextDoesOverflow(!textDoesOverflow);
      }
    },
    [width, textDoesOverflow]
  );

  if (children == null || children === '') {
    return null;
  }

  let content = (
    <StyledSpan className={className} ref={textRef}>
      {children}
    </StyledSpan>
  );

  if (textDoesOverflow) {
    content = <SovosTooltip title={children}>{content}</SovosTooltip>;
  }

  return content;
};

FixedWidthCellContent.propTypes = {
  children: any,
  className: string,
  width: number,
};

FixedWidthCellContent.defaultProps = {
  children: undefined,
  className: undefined,
  width: undefined,
};

export default FixedWidthCellContent;
