import React from 'react';
import {
  array,
  arrayOf,
  bool,
  element,
  exact,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosMenuItem from '../../../sovos-menu-item';
import SovosToolbar from '../../../sovos-toolbar';
import SovosToolbarActions from '../../../sovos-toolbar-actions';
import SovosToolbarBulkActions from '../../../sovos-toolbar-bulk-actions';
import SovosToolbarDataGrouping from '../../../sovos-toolbar-data-grouping';
import SovosToolbarSearch from '../../../sovos-toolbar-search';
import SovosToolbarTitle from '../../../sovos-toolbar-title';
import SovosIconButton from '../../../sovos-icon-button';
import SovosIconMenu from '../../../sovos-icon-menu';
import QuickFilters, { quickFilterPropTypes } from './QuickFilters';
import Filters, { filterPropTypes } from './Filters';
import Columns from './Columns';

const StyledToolbar = styled(SovosToolbar)(({ theme: { palette } }) => ({
  '&.MuiToolbar-root': {
    zIndex: 40, // shadow needs to be above table fixed header

    '&--fixedHeader': {
      boxShadow: 'none',
      borderBottom: `1px solid ${palette.divider}`,
    },
  },
}));

const Toolbar = ({
  actionIconButtons,
  bulkActions,
  bulkActionsCount,
  children,
  className: toolbarClassName,
  disabled,
  DataGroupingProps,
  filters,
  hasFixedHeader,
  labels,
  onClearSelection,
  quickFilters,
  search,
  setVisibleColumns,
  tableColumns,
  toolbarTitle,
}) => {
  const handleSearch = (value) => {
    search.onSearch(value);
    onClearSelection();
  };

  const hasMenu = (bulkAction) => Array.isArray(bulkAction.onClick);

  let toolbarChildren = [];
  if (children) {
    toolbarChildren = children;
  } else {
    if (bulkActions) {
      toolbarChildren.push(
        <SovosToolbarBulkActions
          count={bulkActionsCount}
          key="bulkActions"
          selectedText={labels.bulkActions}
        >
          {bulkActions.map((action) => {
            const { className, icon, ...otherProps } = action;

            if (hasMenu(action)) {
              return (
                <SovosIconMenu
                  className={className}
                  disabled={disabled}
                  iconElement={icon}
                  key={action.tooltipText}
                  toolTipText={action.tooltipText}
                  {...otherProps}
                >
                  {action.onClick.map((item) => (
                    <SovosMenuItem
                      key={item.text}
                      onClick={() => item.onClick()}
                    >
                      {item.text}
                    </SovosMenuItem>
                  ))}
                </SovosIconMenu>
              );
            }
            return (
              <SovosIconButton
                className={className}
                disabled={disabled}
                key={action.tooltipText}
                {...otherProps}
              >
                {icon}
              </SovosIconButton>
            );
          })}
        </SovosToolbarBulkActions>
      );
    }

    if (toolbarTitle) {
      toolbarChildren.push(
        <SovosToolbarTitle key="title">{toolbarTitle}</SovosToolbarTitle>
      );
    }

    if (DataGroupingProps) {
      toolbarChildren.push(
        <SovosToolbarDataGrouping
          disabled={disabled}
          key="dataGrouping"
          {...DataGroupingProps}
        />
      );
    }

    if (quickFilters) {
      toolbarChildren.push(
        <QuickFilters
          disabled={disabled}
          key="quickFilters"
          quickFilters={quickFilters}
          onClearSelection={onClearSelection}
        />
      );
    }

    if (search || filters || setVisibleColumns || actionIconButtons) {
      const actionButtons = [];

      if (search) {
        actionButtons.push(
          <SovosToolbarSearch
            defaultValue={search.term}
            disabled={disabled}
            key={search.term || 'search'}
            onSearch={handleSearch}
            placeholder={labels.searchPlaceholder}
            searchTooltipText={labels.searchButtonTooltip}
          />
        );
      }

      if (actionIconButtons) {
        actionIconButtons.forEach((action, i) => {
          const { icon, ...iconButtonProps } = action;
          actionButtons.push(
            <SovosIconButton
              disabled={disabled}
              {...iconButtonProps}
              key={action.tooltipText || i}
            >
              {icon}
            </SovosIconButton>
          );
        });
      }

      if (setVisibleColumns) {
        actionButtons.push(
          <Columns
            buttonTooltipText={labels.columnButtonTooltip}
            disabled={disabled}
            labels={labels.columnDrawer}
            key="columns"
            setVisibleColumns={setVisibleColumns}
            tableColumns={tableColumns}
          />
        );
      }

      if (filters) {
        actionButtons.push(
          <Filters
            buttonTooltipText={labels.filterButtonTooltip}
            disabled={disabled}
            filters={filters}
            key="filters"
            labels={labels.filterDrawer}
            onClearSelection={onClearSelection}
          />
        );
      }

      toolbarChildren.push(
        <SovosToolbarActions key="actions">{actionButtons}</SovosToolbarActions>
      );
    }
  }

  return (
    <StyledToolbar
      className={clsx(
        { 'MuiToolbar-root--fixedHeader': hasFixedHeader },
        toolbarClassName
      )}
    >
      {toolbarChildren}
    </StyledToolbar>
  );
};

export const searchPropTypes = exact({
  term: string,
  onSearch: func.isRequired,
});

export const actionIconButtonPropTypes = arrayOf(
  exact({
    icon: element.isRequired,
    onClick: oneOfType([
      func,
      arrayOf(exact({ text: node.isRequired, onClick: func.isRequired })),
    ]).isRequired,
    tooltipText: string.isRequired,
  })
);

export const bulkActionsPropTypes = arrayOf(
  shape({
    icon: element.isRequired,
    onClick: oneOfType([
      func,
      arrayOf(exact({ text: node.isRequired, onClick: func.isRequired })),
    ]).isRequired,
    tooltipText: string.isRequired,
  })
);

Toolbar.propTypes = {
  actionIconButtons: actionIconButtonPropTypes,
  bulkActions: bulkActionsPropTypes,
  bulkActionsCount: number,
  children: arrayOf(node),
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  disabled: bool,
  DataGroupingProps: object,
  filters: filterPropTypes,
  hasFixedHeader: bool,
  labels: object,
  onClearSelection: func,
  quickFilters: quickFilterPropTypes,
  search: searchPropTypes,
  setVisibleColumns: func,
  tableColumns: array,
  toolbarTitle: node,
};

Toolbar.defaultProps = {
  actionIconButtons: undefined,
  bulkActions: undefined,
  bulkActionsCount: undefined,
  children: undefined,
  className: undefined,
  disabled: false,
  DataGroupingProps: undefined,
  filters: undefined,
  hasFixedHeader: false,
  labels: undefined,
  onClearSelection: () => {},
  quickFilters: undefined,
  search: undefined,
  setVisibleColumns: undefined,
  tableColumns: undefined,
  toolbarTitle: undefined,
};

export default Toolbar;
