import React, { forwardRef } from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  node,
  string,
  object,
  oneOf,
  oneOfType,
} from 'prop-types';
import clsx from 'clsx';

import { styled, useTheme } from '@mui/material/styles';
import SovosTextField from '../sovos-text-field';

const StyledSovosTextField = styled(SovosTextField)({
  '&.MuiTextField-root': {
    width: '100%',
  },
  '& .MuiSelect-select': {
    '&:focus': {
      background: 'none',
    },
  },
});

const SovosSelect = forwardRef(
  (
    {
      children,
      classes,
      className,
      color,
      'data-testid': dataTestId,
      disabled,
      error,
      helperText,
      label,
      onChange,
      size,
      style,
      sx,
      variant,
      value,
      ...rest
    },
    ref
  ) => {
    const { root, icon, iconOpen, input, paper, select, ...classesRest } =
      classes || {};
    const { shape, shadows, spacing } = useTheme();

    const getMenuStyles = () => {
      let marginTop = spacing(0.25);
      if (variant === 'filled' && !label) {
        marginTop = size === 'small' ? spacing(1.75) : spacing(2.25);
      } else if (variant === 'outlined' && size === 'small') {
        marginTop = spacing(0.75);
      } else if (variant === 'standard') {
        marginTop = 0;
      }

      return {
        '& .MuiPaper-root': {
          borderRadius:
            variant === 'filled' || variant === 'standard'
              ? `0 0 ${shape.borderRadius}px ${shape.borderRadius}px`
              : `${shape.borderRadius}px`,
          boxShadow: shadows[8],
          marginTop,
        },
      };
    };

    return (
      <StyledSovosTextField
        className={clsx('sovosSelect', className, root)}
        color={color}
        data-testid={dataTestId}
        disabled={disabled}
        error={error}
        helperText={helperText}
        InputProps={{
          classes: {
            root: input,
          },
        }}
        label={label}
        onChange={onChange}
        ref={ref}
        SelectProps={{
          classes: {
            icon,
            iconOpen,
            select,
            ...classesRest,
          },
          displayEmpty: !label,
          MenuProps: {
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            classes: { paper },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            sx: getMenuStyles(),
          },
          ...rest,
        }}
        select
        size={size}
        style={style}
        sx={sx}
        value={value}
        variant={variant}
      >
        {children}
      </StyledSovosTextField>
    );
  }
);

SovosSelect.propTypes = {
  /**
   * The content of the select. A collection of `SovosMenuItem`
   */
  children: node,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * One of the Material UI theme colors
   */
  color: oneOf(['primary']),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * If `true`, the input is disabled
   */
  disabled: bool,
  /**
   * If `true`, the component will display an error state
   */
  error: bool,
  /**
   * Help text displays under the input
   */
  helperText: string,
  /**
   * The label of the input
   */
  label: string,
  /**
   * Callback fired when input value is changed `function(event: object) => void`
   */
  onChange: func,
  /**
   * Size of the Select
   */
  size: oneOf(['medium', 'small']),
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Value of the Select
   */
  value: any,
  /**
   * Select variant
   */
  variant: oneOf(['filled', 'outlined', 'standard']),
};

SovosSelect.defaultProps = {
  children: undefined,
  className: undefined,
  classes: undefined,
  color: 'primary',
  'data-testid': undefined,
  disabled: undefined,
  error: undefined,
  helperText: undefined,
  label: undefined,
  onChange: undefined,
  size: 'medium',
  style: undefined,
  sx: undefined,
  value: '',
  variant: 'filled',
};

SovosSelect.baseComponent = {
  name: 'Select',
  link: 'select/',
};

export default SovosSelect;
