import React from 'react';
import {
  bool,
  node,
  object,
  shape,
  string,
  oneOfType,
  elementType,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const Root = styled('div')({
  height: '100%',

  '&.sovosTabContent--hidden': {
    display: 'none',
  },
});

/**
 * This component is expected to be a child of either `SovosPageLayout`,
 * `SovosTabGroup`, or `SovosSlidingPanel`. These parents will
 * automatically render tabs based on `TabProps`, track the selected tab,
 * and pass the `selected` prop to this component.
 */
const SovosTabContent = ({
  children,
  className,
  'data-testid': dataTestId,
  selected,
  style,
}) => (
  <Root
    className={clsx(
      'sovosTabContent',
      className,
      !selected && 'sovosTabContent--hidden'
    )}
    data-testid={dataTestId}
    style={style}
  >
    {children}
  </Root>
);

SovosTabContent.propTypes = {
  /**
   * The content of the tab content
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * When true, SovosTabContent is visible
   */
  selected: bool,
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Props passed to the SovosTab
   */
  // eslint-disable-next-line react/no-unused-prop-types
  TabProps: shape({
    icon: node,
    label: oneOfType([node, elementType]),
  }).isRequired,
};

SovosTabContent.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  selected: false,
  style: undefined,
};

export default SovosTabContent;
