/* eslint-disable no-alert */
import React, { useState } from 'react';
import SovosButton from 'mosaic-react/sovos-button';
import SovosPageLayout from 'mosaic-react/sovos-page-layout';
import Dialog from '../commonComponents/Dialog';
import InvoicesTable from './InvoicesTable';

const InvoicesPage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const openDialog = (text) => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  const exportClick = () => {
    openDialog(
      'This is definitely how you would export invoices if we let you.'
    );
  };

  const addButtonClick = () => {
    openDialog('Adding invoices not enabled in demp app.');
  };

  const titleBarActions = () => [
    <SovosButton
      onClick={exportClick}
      key="export"
      className="InvoicesPage__ExportButton"
      variant="text"
    >
      Export
    </SovosButton>,
    <SovosButton
      onClick={addButtonClick}
      key="addInvoice"
      className="InvoicesPage__AddButton"
    >
      Add
    </SovosButton>,
  ];

  return (
    <SovosPageLayout
      pageTitle="eInvoices - Sent"
      titleBarActions={titleBarActions()}
      className="InvoicesPage__PageLayout"
    >
      <InvoicesTable />
      <Dialog
        text={dialogText}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </SovosPageLayout>
  );
};

export default InvoicesPage;
