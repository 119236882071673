import React from 'react';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosList from '../sovos-list';
import SovosFile from '../sovos-file';

const StyledSovosList = styled(SovosList)(({ theme: { palette } }) => ({
  padding: 0,

  '& .sovosList__item': {
    alignItems: 'initial',
    borderBottom: `1px solid ${palette.divider}`,
    height: 'auto',

    '&:last-of-type': {
      borderBottomWidth: 1,
    },
  },
}));

const StyledSovosFile = styled(SovosFile)(() => ({
  height: 49,
  width: '100%',

  '& .sovosFile__fileButton': {
    paddingLeft: 0,
  },
}));

const getRowRenderer = (onDownload, onDelete, classes) => (entry) => {
  const { id, document, documentLink, timestamp, timeLabel, name, surname } =
    entry;

  const file = {
    documentLink,
    id,
    name: document,
    uploadedDate: timeLabel || timestamp,
    uploadedBy: name || surname ? { name, surname } : undefined,
  };

  return (
    <StyledSovosFile
      classes={{
        root: classes?.root,
        fileDownloadButton: classes?.fileDownloadButton,
      }}
      confirmDelete
      fileInfo={file}
      onClick={() => onDownload(entry)}
      onDelete={onDelete ? () => onDelete(entry) : null}
    />
  );
};

const SovosAttachmentsList = ({
  data,
  classes,
  className,
  onDownload,
  onDelete,
  SovosListClasses,
  ...listProps
}) => (
  <StyledSovosList
    className={clsx('sovosAttachmentList', className)}
    classes={{
      ...SovosListClasses,
    }}
    data={data}
    rowRenderer={getRowRenderer(onDownload, onDelete, classes)}
    {...listProps}
  />
);

SovosAttachmentsList.propTypes = {
  /**
   * Array of attachments entries to be rendered. Each entry should contain
   * document, documentLink, timestamp and optional time label, name, and surname.
   */
  data: arrayOf(
    shape({
      id: number,
      document: string.isRequired,
      documentLink: string.isRequired,
      name: string,
      surname: string,
      timestamp: number,
      timeLabel: string,
    })
  ).isRequired,
  /**
   * Callback fired when `delete` icon is clicked. `function(attachment: object)`
   */
  onDelete: func,
  /**
   * Callback fired when document name is clicked. documentLink will take
   * precedence over callback. `function(attachment: object)`
   */
  onDownload: func.isRequired,
  /**
   * Function used to sort provided data
   */
  sortFunction: func,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Override or extend the styles applied to the SovosList base component
   */
  SovosListClasses: object,
};

SovosAttachmentsList.defaultProps = {
  classes: undefined,
  className: undefined,
  onDelete: undefined,
  sortFunction: undefined,
  SovosListClasses: undefined,
};

SovosAttachmentsList.baseComponent = {
  name: 'SovosList',
};

export default SovosAttachmentsList;
