/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  SovosPageContentDataGrid,
  SovosIconMenu,
  SovosMenuItem,
} from 'mosaic-react';
import { useNavigate } from 'react-router-dom';
import { useErrorSnackbar } from 'mosaic-react/hooks';
import 'mosaic-react/installLicense';
import { FiberManualRecord as Dot, MoreVert } from 'mosaic-react-icons';
import { format } from 'date-fns';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { invoicesData, invoicesDataUpdate } from './services/invoices';

const ActionCell = (params) => {
  const {
    params: { api, getValue, id },
  } = params;

  const thisRow = {};

  const navigate = useNavigate();

  const onTableRowClick = (dataRow) => {
    navigate(`/invoices/${dataRow.id}`);
  };

  const onClick = (e) => {
    e.stopPropagation(); // don't select this row after clicking

    api
      .getAllColumns()
      .filter((c) => c.field && !!c)
      .forEach((c) => {
        thisRow[c.field] = getValue(id, c.field);
      });

    return onTableRowClick(thisRow);
  };

  return (
    <SovosIconMenu iconElement={<MoreVert />} size="small">
      <SovosMenuItem>
        <div onClick={onClick}>View Invoice</div>
      </SovosMenuItem>
    </SovosIconMenu>
  );
};

const firstPage = 1;

function getStatusColor(params) {
  let color;
  if (params.row.status === 'Authorized') {
    color = 'green';
  }
  if (params.row.status === 'Failed Authorization') {
    color = 'red';
  }
  return (
    <span style={{ display: 'flex', alignItems: 'flex-end' }}>
      <Dot style={{ color, fontSize: 16, marginRight: 5 }} />
      {params.row.status}
    </span>
  );
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const typeOptions = ['NFS-e', 'NF-e'];

const statesOptions = ['DC', 'FL', 'KS'];

const availableFilters = [
  { dataKey: 'invoice', label: 'Invoice', type: 'textEntry' },
  { dataKey: 'type', label: 'Type', type: 'textEntry' },
  { dataKey: 'purchasedBy', label: 'Purchased By', type: 'textEntry' },
  { dataKey: 'soldBy', label: 'Sold By', type: 'textEntry' },
];

const initialColumns = [
  {
    headerName: 'Invoice',
    width: 100,
    fixed: true,
    type: 'string',
    field: 'id',
    resizable: false,
  },
  {
    headerName: 'Customer',
    editable: true,
    width: 200,
    type: 'string',
    field: 'purchasedBy',
    valueGetter: (params) => params.value.companyName,
    resizable: false,
  },
  {
    headerName: 'Status',
    field: 'status',
    width: 200,
    renderCell: (params) => getStatusColor(params),
    resizable: false,
  },
  {
    headerName: 'Type',
    editable: true,
    width: 60,
    type: 'singleSelect',
    valueOptions: typeOptions,
    field: 'type',
    resizable: false,
  },
  {
    headerName: 'State',
    valueOptions: statesOptions,
    width: 60,
    field: 'state',
    type: 'singleSelect',
    editable: true,
    resizable: false,
  },
  {
    headerName: 'Seller',
    width: 200,
    type: 'string',
    field: 'soldBy',
    valueGetter: (params) => params.value.companyName,
    editable: true,
    resizable: false,
  },
  {
    headerName: '($) Taxable',
    type: 'string',
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    width: 100,
    field: 'taxable',
    resizable: false,
  },
  {
    headerName: '($) Tax Collected',
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    width: 120,
    type: 'string',
    field: 'taxCollected',
    resizable: false,
  },
  {
    headerName: '($) Total',
    valueFormatter: ({ value }) => currencyFormatter.format(value),
    width: 100,
    type: 'string',
    field: 'invoiceTotal',
    resizable: false,
  },
  {
    headerName: 'Issued',
    field: 'date',
    width: 100,
    type: 'date',
    valueFormatter: ({ value }) => format(new Date(value), 'MMM dd, yyyy'),
    editable: true,
    resizable: true,
  },
  {
    field: 'actions',
    type: 'actions',
    width: 10,
    renderCell: (params) => <ActionCell params={params} />,
  },
];

const InvoicesTable = () => {
  const apiRef = useGridApiRef();
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [appliedQuickFilters, setAppliedQuickFilters] = useState({
    status: undefined,
  });
  const [sortProps, setSortProps] = useState({
    field: 'id',
    isAscending: true,
  });

  const [sortColumn, setSortColumn] = useState('id');

  const [searchTerm, setSearchTerm] = useState(undefined);
  const { addError } = useErrorSnackbar();

  const invoices = useSelector((state) => state.invoices);
  const dispatch = useDispatch();

  const { list } = invoices;

  useEffect(() => {
    function unpackFilters() {
      const { status } = appliedQuickFilters;
      return {
        invoice: appliedFilters.invoice?.[0],
        type: appliedFilters.type?.[0],
        purchasedBy: appliedFilters.purchasedBy?.[0],
        soldBy: appliedFilters.soldBy?.[0],
        status: status === 'All' ? undefined : status,
      };
    }

    dispatch(
      invoicesData({
        filters: unpackFilters(),
        itemsPerPage,
        page: currentPage,
        searchTerm,
        sortOrder: sortColumn,
        sortProps: sortProps.isAscending ? 'asc' : 'desc',
      })
    ).catch((err) => addError(err));
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    searchTerm,
    sortColumn,
    sortProps.isAscending,
    addError,
    appliedFilters,
    appliedQuickFilters,
  ]);

  function searchInvoices(search) {
    setSearchTerm(search);
    setCurrentPage(firstPage);
  }

  const onPageChange = (newPage, newItemsPerPage) => {
    setCurrentPage(newPage);
    setItemsPerPage(newItemsPerPage);
  };

  const sort = useCallback(
    (column) => {
      const newAscending =
        sortProps.field === column.field ? !sortProps.isAscending : true;
      let newSortColumn;
      switch (column.field) {
        case 'status':
          newSortColumn = 'status';
          break;
        case 'id':
          newSortColumn = 'id';
          break;
        case 'type':
          newSortColumn = 'type';
          break;
        case 'state':
          newSortColumn = 'state';
          break;
        case 'purchasedBy':
          newSortColumn = 'purchasedBy';
          break;
        case 'soldBy':
          newSortColumn = 'soldBy';
          break;
        case 'invoiceTotal':
          newSortColumn = 'invoiceTotal';
          break;
        case 'date':
          newSortColumn = 'date';
          break;
        case 'taxable':
          newSortColumn = 'taxable';
          break;
        case 'taxCollected':
          newSortColumn = 'taxCollected';
          break;

        default:
          newSortColumn = 'id';
      }
      setSortProps({
        field: column.field,
        isAscending: newAscending,
      });
      setSortColumn(newSortColumn);
      setCurrentPage(firstPage);
    },
    [sortProps]
  );

  const quickFilters = [
    {
      dataKey: 'status',
      type: 'dropdown',
      menuItems: [
        <SovosMenuItem key={0} value="All">
          All
        </SovosMenuItem>,
        <SovosMenuItem key={1} value="Authorized">
          Authorized
        </SovosMenuItem>,
        <SovosMenuItem key={2} value="Failed Authorization">
          Failed authorization
        </SovosMenuItem>,
      ],
      value: appliedQuickFilters.status || 'All',
    },
  ];

  const handleCellEditCommit = (params, event) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true; // https://mui.com/components/data-grid/events/#disabling-the-default-behavior
    let rowUpdate = {};

    if (params.field === 'purchasedBy') {
      const model = apiRef.current.getEditRowsModel();
      const { value } = model[params.id].purchasedBy;
      const row = apiRef.current.getRow(params.id);

      // Merge the old row with the new value
      rowUpdate = {
        ...row,
        purchasedBy: { ...row.purchasedBy, companyName: value },
      };
    }

    if (params.field === 'soldBy') {
      const model = apiRef.current.getEditRowsModel();
      const { value } = model[params.id].soldBy;
      const row = apiRef.current.getRow(params.id);

      rowUpdate = {
        ...row,
        soldBy: { ...row.soldBy, companyName: value },
      };
    }

    if (params.field === 'date') {
      const model = apiRef.current.getEditRowsModel();
      const { value } = model[params.id].date;
      const row = apiRef.current.getRow(params.id);

      const valueDate = format(new Date(value), 'yyyy-MM-dd hh:mm:ss');

      rowUpdate = {
        ...row,
        date: valueDate,
      };
    }

    if (params.field === 'state') {
      const model = apiRef.current.getEditRowsModel();
      const { value } = model[params.id].state;
      const row = apiRef.current.getRow(params.id);

      rowUpdate = {
        ...row,
        state: value,
      };
    }

    if (params.field === 'type') {
      const model = apiRef.current.getEditRowsModel();
      const { value } = model[params.id].type;
      const row = apiRef.current.getRow(params.id);

      rowUpdate = {
        ...row,
        type: value,
      };
    }

    apiRef.current.updateRows([rowUpdate]);
    dispatch(invoicesDataUpdate(rowUpdate)).catch((err) => addError(err));
  };

  return (
    <SovosPageContentDataGrid
      onColumnHeaderClick={sort}
      sortModel={[
        { field: sortColumn, sort: sortProps.isAscending ? 'asc' : 'desc' },
      ]}
      columns={initialColumns}
      rows={list}
      className="InvoicesTable__InvoiceTable"
      isLoading={invoices.isLoading}
      apiRef={apiRef}
      onCellEditCommit={handleCellEditCommit}
      PaginationFooterProps={{
        itemsPerPage,
        currentPage,
        totalItems: invoices.totalItems,
        onPageChange,
      }}
      ToolbarProps={{
        quickFilters: {
          items: quickFilters,
          onApply: setAppliedQuickFilters,
          values: appliedQuickFilters,
        },

        search: {
          term: searchTerm,
          onSearch: searchInvoices,
        },

        filters: {
          items: availableFilters,
          onApply: setAppliedFilters,
          values: appliedFilters,
        },
      }}
    />
  );
};

export default InvoicesTable;
