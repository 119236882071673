/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const url = '/api/v1/filings';

export function loadFilings(page, itemsPerPage, sortProp, sortOrder) {
  const params = {};
  params._sort = sortProp;
  params._order = sortOrder;
  params._page = page;
  params._limit = itemsPerPage;

  return axios.get(url, { params }).then((response) => {
    const filings = response.data;
    const totalItems = response.headers['x-total-count'];
    return { totalItems, filings };
  });
}

export function loadFiling(id) {
  return axios.get(`${url}/${id}`).then((response) => response.data);
}
