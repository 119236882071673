import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { string, object, number, oneOfType, node } from 'prop-types';
import { styled } from '@mui/material/styles';
import { useWindowSize } from '../hooks';
import SovosTooltip from '../sovos-tooltip';
import { ResizeContext } from '../internals/utils';

const StyledSpan = styled('span')({
  display: 'inline-block',
  margin: 0,
  maxWidth: '100%',
  overflow: 'hidden',
  padding: 0,
  textOverflow: 'ellipsis',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
});

export const shouldOverflowStateChange = (scrollWidth, width, prevState) => {
  if (scrollWidth > width && !prevState) {
    return true;
  }
  if (scrollWidth <= width && prevState) {
    return true;
  }
  return false;
};

const getToolTipContent = (text, toolTipText, textDoesOverflow, separator) => {
  if (textDoesOverflow && toolTipText) {
    return (
      <>
        {text} {separator} {toolTipText}
      </>
    );
  }
  if (textDoesOverflow) {
    return text;
  }
  if (toolTipText) {
    return toolTipText;
  }
  return undefined;
};

/**
 * Fixed Width Text shows ellipsis & tooltip on non-wrapping text that
 * overflows it's container.
 */
const SovosFixedWidthText = ({
  className,
  separator,
  style,
  text,
  toolTipText,
  ...rest
}) => {
  const [textDoesOverflow, setTextDoesOverflow] = useState(false);
  const textRef = useRef(null);
  const { width: windowWidth } = useWindowSize();
  const { width: contextWidth } = useContext(ResizeContext);

  useEffect(() => {
    if (text == null) return;
    const textScrollWidth = textRef.current.scrollWidth;
    const textWidth = textRef.current.offsetWidth;
    if (
      shouldOverflowStateChange(textScrollWidth, textWidth, textDoesOverflow)
    ) {
      setTextDoesOverflow((prevState) => !prevState);
    }
  }, [textDoesOverflow, text, windowWidth, contextWidth]);

  if (text == null) return null;

  const toolTipContent = getToolTipContent(
    text,
    toolTipText,
    textDoesOverflow,
    separator
  );

  // The content element needs to have an empty, block display, child element to prevent Safari from showing
  // a native tooltip for the overflowing text.
  let content = (
    <StyledSpan
      aria-label={toolTipText}
      className={clsx('sovosFixedWidthText', className)}
      style={style}
      ref={textRef}
      key={text}
      {...rest}
    >
      <div />
      {text}
    </StyledSpan>
  );

  if (toolTipContent) {
    content = <SovosTooltip title={toolTipContent}>{content}</SovosTooltip>;
  }

  return content;
};

SovosFixedWidthText.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * A custom separator between the `text` and `toolTipText` in the tooltip
   */
  separator: node,
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * The text to be displayed and shown in a tooltip when truncated
   */
  text: oneOfType([string, number]),
  /**
   * Content to be displayed only in the tooltip, regardless of whether
   * `text` is truncated. When present, a tooltip will always be available
   */
  toolTipText: string,
};

SovosFixedWidthText.defaultProps = {
  className: undefined,
  separator: '•',
  style: {},
  text: undefined,
  toolTipText: undefined,
};

export default SovosFixedWidthText;
