import React, { useState, useRef, useCallback, useEffect } from 'react';
import { array, bool, func, node, number, object, string } from 'prop-types';
import clsx from 'clsx';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { Search as ActionSearch } from 'mosaic-react-icons';
import { alpha, styled } from '@mui/material/styles';
import debounce from 'lodash.debounce';
import { SovosTreeList } from '../../../sovos-tree-list';
import SovosTextField from '../../../sovos-text-field';
import SovosFixedWidthText from '../../../sovos-fixed-width-text';
import SovosResizeContainer from '../../../sovos-resize-container';
import SovosTooltip from '../../../sovos-tooltip';
import useMosaicTranslation from '../../i18n/useMosaicTranslation';

const ToggleButton = styled(Button)(({ theme: { palette } }) => ({
  borderRadius: 0,
  padding: 0,
  textTransform: 'none',
  width: '100%',

  '&.Mui-focusVisible': {
    color: alpha(palette.primary.contrastText, 0.4),
  },
}));

const SearchWrapper = styled('div')(({ theme: { spacing } }) => ({
  margin: `0 ${spacing()} ${spacing()} ${spacing()}`,
}));

const Search = styled(SovosTextField)(({ theme: { spacing } }) => ({
  '&.MuiTextField-root': {
    minHeight: 'auto',
  },
  '& .MuiInputBase-adornedStart': {
    paddingLeft: spacing(),
  },
}));

const StyledPopover = styled(Popover)(({ theme: { spacing } }) => ({
  '&.sovosSwitcher__popover--showSearch .MuiPopover-paper': {
    paddingTop: spacing(2),
  },

  '& .MuiPopover-paper': {
    maxWidth: spacing(60),
    minWidth: spacing(30),
    paddingTop: spacing(1),
    paddingBottom: spacing(),
  },
}));

const TreeListWrapper = styled('div')({
  maxHeight: 'calc(100vh - 240px)',
  overflow: 'auto',
});

const NoResults = styled('div')(
  ({ theme: { palette, spacing, typography } }) => ({
    ...typography.body1,
    alignItems: 'center',
    color: palette.text.secondary,
    display: 'flex',
    height: spacing(5),
    justifyContent: 'center',
    margin: spacing(),
  })
);

const defaultDidSelectionChange = (currentSelection, newSelection) =>
  currentSelection.id !== newSelection.id;

const Switcher = ({
  buttonContents,
  buttonTooltip,
  classes,
  className,
  didSelectionChange,
  isLoading,
  items,
  labels,
  minimumCharacters,
  onExpandItem,
  onSearch,
  onSelectItem,
  PopoverProps,
  selectedItem,
  showSearch,
  ...rest
}) => {
  const { t } = useMosaicTranslation();

  const [searchInput, setSearchInput] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const isDisabled = !onSearch && !(items.length > 1 || items[0].nestedItems);
  const searchMessage = `${minimumCharacters} ${
    labels?.searchTemplate || t('autocomplete.helpMessageTemplate')
  }`;

  useEffect(() => {
    // If the items have changed or the loading flag has changed, clear the search trigger
    if (isLoading || items) {
      setSearchTriggered(false);
      setFilteredItems(items);
    }
  }, [isLoading, items]);

  const getItems = () => {
    if (onSearch) {
      return items;
    }

    return filteredItems;
  };

  const itemMatches = (item, value) => {
    const itemName = item.name.toUpperCase();
    const searchString = value.toUpperCase();

    return !!itemName.includes(searchString);
  };

  const getExpandedIds = () => {
    // Get items that have been filtered
    const listItems = getItems();

    if (!listItems || !listItems.length || !searchInput || searchTriggered) {
      return undefined;
    }

    const itemsToExpand = new Set();

    const getItemsToExpand = (item) => {
      let result = false;

      if (itemMatches(item, searchInput) && !item.nestedItems) {
        result = true;
      }

      if (item.nestedItems) {
        const filtered = item.nestedItems.filter(
          (nested) =>
            getItemsToExpand(nested, result) || itemMatches(nested, searchInput)
        );
        if (filtered.length) result = true;
      }

      if (result) itemsToExpand.add(item.id);
      return result;
    };

    listItems.forEach((item) => getItemsToExpand(item));

    return Array.from(itemsToExpand);
  };

  // Filter search values, calculating items to include and items to expand.
  const search = (value) => {
    if (!value) {
      setFilteredItems(items);
      return;
    }

    if (value.length < minimumCharacters) return;

    if (onSearch) {
      onSearch(value);
      return;
    }

    const filteredResults = (arr) => {
      const filtered = arr.reduce((matches, item) => {
        const { nestedItems } = item;

        if (itemMatches(item, value)) {
          matches.push(item);
        } else if (nestedItems && nestedItems.length) {
          const nestedResults = filteredResults(nestedItems);

          if (nestedResults.length) {
            matches.push({ ...item, nestedItems: nestedResults });
          }
        }

        return matches;
      }, []);

      return filtered;
    };

    const results = filteredResults(items);

    setFilteredItems(results);
    setSearchTriggered(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValue = useCallback(
    debounce((value) => search(value), 500),
    [items]
  );

  const handleSearchInput = (event) => {
    const { value } = event.target;
    setSearchInput(value);
    if (onSearch && value.length >= minimumCharacters) {
      setSearchTriggered(true);
    } else if (!onSearch) {
      setSearchTriggered(true);
    }
    handleSearchValue(value);
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getButtonContent = () => {
    if (typeof buttonContents === 'object') {
      return buttonContents;
    }

    return (
      <SovosFixedWidthText text={buttonContents} style={{ flex: '1 0 auto' }} />
    );
  };

  const handleSelectItem = (item) => {
    if (!didSelectionChange(selectedItem, item)) {
      return;
    }

    onSelectItem(item);
    setAnchorEl(null);
  };

  const renderNoResults = () => {
    let message;
    if (isLoading || searchTriggered) {
      message = labels?.loading || t('autocomplete.loadingMessage');
    } else if (
      onSearch &&
      (!searchInput || searchInput.length < minimumCharacters)
    ) {
      message = searchMessage;
    } else {
      message = labels?.noResults || t('noResults');
    }

    return (
      <NoResults
        className={clsx('sovosSwitcher__noResults', classes?.noResults)}
      >
        {message}
      </NoResults>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderPopover = () => {
    const expanded = getExpandedIds();
    const listItems = getItems();

    return (
      <StyledPopover
        anchorOrigin={{ horizontal: 0, vertical: 'bottom' }}
        anchorEl={anchorEl}
        classes={{
          paper: classes?.popoverPaper,
        }}
        className={clsx(
          'sovosSwitcher__popover',
          showSearch && 'sovosSwitcher__popover--showSearch'
        )}
        data-testid="sovosSwitcher__popover"
        marginThreshold={0}
        onClose={handleClose}
        open={!!anchorEl}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        {...PopoverProps}
      >
        <SovosResizeContainer>
          {showSearch && (
            <SearchWrapper className={classes?.search}>
              <Search
                autoFocus
                fullWidth
                InputProps={{
                  classes: { adornedStart: classes?.searchFieldAdornment },
                  startAdornment: (
                    <InputAdornment position="start">
                      <ActionSearch />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchInput}
                placeholder={t('search')}
                size="small"
                value={searchInput}
                variant="outlined"
              />
            </SearchWrapper>
          )}
          {listItems &&
          listItems.length &&
          !(onSearch && (isLoading || searchTriggered)) ? (
            <TreeListWrapper className={classes?.treeListWrapper}>
              <SovosTreeList
                key={expanded && expanded.toString()}
                data={listItems}
                initialExpandedItems={expanded}
                initialSelectedItem={selectedItem.id}
                onItemClick={handleSelectItem}
                onItemExpanded={onExpandItem}
              />
            </TreeListWrapper>
          ) : (
            renderNoResults()
          )}
        </SovosResizeContainer>
      </StyledPopover>
    );
  };

  const renderButton = () => {
    const button = (
      <ToggleButton
        classes={{
          root: classes?.toggleButton,
          focusVisible: classes?.toggleButtonFocus,
        }}
        className="sovosSwitcher__button"
        data-testid="sovosSwitcher__button"
        disabled={isDisabled}
        fullWidth
        onClick={handleButtonClick}
      >
        {getButtonContent()}
      </ToggleButton>
    );

    if (buttonTooltip && !isDisabled) {
      return (
        <SovosTooltip title={buttonTooltip}>
          <div>{button}</div>
        </SovosTooltip>
      );
    }

    return button;
  };

  return (
    <div
      className={clsx('sovosSwitcher', className, classes?.root)}
      ref={buttonRef}
      {...rest}
    >
      {renderButton()}
      {renderPopover()}
    </div>
  );
};

Switcher.propTypes = {
  buttonContents: node.isRequired,
  buttonTooltip: string,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  didSelectionChange: func,
  isLoading: bool,
  items: array.isRequired,
  labels: object,
  minimumCharacters: number,
  onExpandItem: func,
  onSelectItem: func.isRequired,
  onSearch: func,
  PopoverProps: object,
  selectedItem: object.isRequired,
  showSearch: bool,
};

Switcher.defaultProps = {
  buttonTooltip: undefined,
  classes: undefined,
  className: undefined,
  didSelectionChange: defaultDidSelectionChange,
  isLoading: false,
  labels: undefined,
  minimumCharacters: 2,
  onExpandItem: undefined,
  onSearch: undefined,
  PopoverProps: {},
  showSearch: false,
};

export default Switcher;
