import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

const StyledTab = styled(Tab)(({ theme: { palette, spacing, typography } }) => {
  const height = spacing(6);

  return {
    border: '2px solid transparent',
    color: palette.text.primary,
    minHeight: height,
    minWidth: spacing(6),
    padding: [[0, spacing(2)]],
    ...typography.button,

    '&[class*="focusVisible"]': {
      borderColor: palette.primary.main,
    },
    '& > .MuiTab-iconWrapper': {
      fontSize: spacing(2),
      marginRight: spacing(0.5),
    },
    '&.MuiTab-labelIcon': {
      minHeight: height,
    },
  };
});

/**
 * This component can be used with `SovosTabs` to render a tab bar.
 * However, `SovosTabContents` and `SovosTabGroup` will generate tabs and
 * handle tab switching and should be used in most situations.
 */
const SovosTab = ({ className, ...rest }) => (
  <StyledTab
    className={clsx('sovosTab', className)}
    iconPosition="start"
    disableRipple
    {...rest}
  />
);

SovosTab.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosTab.defaultProps = {
  className: undefined,
};
SovosTab.baseComponent = {
  name: 'Tab',
  link: 'tab/',
};

export default SovosTab;
