import theme from '../../themes/sovos-theme';

/**
 * Return focus styles object
 *
 * @param {{ offset: number }} offset - Defaults to 4,
 * @returns {object} - Focus styles object
 */
export function getFocusStyles({ offset = 4 } = {}) {
  return {
    outlineColor: theme.palette.primary.dark,
    outlineOffset: offset,
    outlineStyle: 'solid',
    outlineWidth: 2,
  };
}

/**
 * Return focus styles object with an ::after pseudo element
 *
 * @param {{ offset: number }} offset - Defaults to 4,
 * @returns {object} - Focus styles object
 */
export function getAfterFocusStyles({ offset = 4 } = {}) {
  return {
    '&::after': {
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      ...getFocusStyles({ offset }),
    },
  };
}
