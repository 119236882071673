import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';
import { alpha, styled } from '@mui/material/styles';
import SovosDivider from '../../../sovos-divider';
import SovosTypography from '../../../sovos-typography';
import SovosFixedWidthText from '../../../sovos-fixed-width-text';

const Header = styled('li')(({ theme: { spacing, typography } }) => ({
  alignItems: 'center',
  display: 'flex',
  height: typography.body2.fontSize * typography.body2.lineHeight,
  margin: spacing(),
  '&.sovosNavigationLinks__sectionHeader--hasLabel': {
    margin: `${spacing(3)} ${spacing()} ${spacing()} ${spacing()}`,
  },
}));

const HeaderLabel = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: alpha(palette.primary.light, 0.75),
    margin: `0 ${spacing()}`,
    maxWidth: '100%',
    textTransform: 'uppercase',
  })
);

const Divider = styled(SovosDivider)(({ theme: { palette } }) => ({
  borderColor: alpha(palette.primary.light, 0.5),
  flex: '1 1 auto',
}));

const SectionHeader = ({ label }) => (
  <Header
    key={label}
    className={clsx(
      'sovosNavigationLinks__sectionHeader',
      label != null && 'sovosNavigationLinks__sectionHeader--hasLabel'
    )}
  >
    {label == null ? null : (
      <HeaderLabel variant="body2" component="h6">
        <SovosFixedWidthText text={label} />
      </HeaderLabel>
    )}
    <Divider />
  </Header>
);

SectionHeader.propTypes = {
  label: string,
};

SectionHeader.defaultProps = {
  label: undefined,
};

export default SectionHeader;
