import React, { Children, useMemo, useRef, useState } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTabContent from '../sovos-tab-content/SovosTabContent';
import {
  findChildrenByType,
  filterOutChildrenByType,
} from '../internals/utils/reactHelpers';
import SovosHeroBanner from '../sovos-hero-banner/SovosHeroBanner';
import SovosGenericHeroBanner from '../sovos-generic-hero-banner/SovosGenericHeroBanner';
import sovosIconButtonElement from '../internals/prop-types/sovosIconButtonElement';
import PageTitleBar from './components/PageTitleBar';
import TabBar from './components/TabBar';

const Root = styled('div')(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
  display: 'flex',
  flex: '1 1 100%',
  flexDirection: 'column',
  height: '100vh',
  position: 'relative',
}));

const Header = styled('header')(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.divider}`,
}));

const ContentContainer = styled('div')({
  flex: '1 0 0',
  overflowX: 'auto',
  overflowY: 'scroll',
});

const SovosPageLayout = ({
  children,
  className,
  confirmTabChange,
  'data-testid': dataTestId,
  initialSelectedTabIndex,
  onTabChanged,
  style,
  pageCrumbs,
  pageTitle,
  titleBarActions,
  titleIconButton,
}) => {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTabIndex);
  const container = useRef(null);

  const onTabClick = (event, value) => {
    const selectedTabProps = findChildrenByType(children, SovosTabContent)[
      value
    ].props;

    if (confirmTabChange) {
      const confirmed = confirmTabChange(selectedTabProps.value || value);

      if (!confirmed) {
        return;
      }
    }

    setSelectedTab(value);

    if (onTabChanged) {
      onTabChanged(selectedTabProps.value || value);
    }

    if (selectedTabProps.onActive) {
      selectedTabProps.onActive();
    }

    if (container.current) {
      container.current.scrollTop = 0;
    }
  };

  const tabsData = useMemo(() => {
    const tabChildren = findChildrenByType(children, SovosTabContent);

    if (tabChildren.length) {
      return tabChildren.map((tab) => {
        const thisTabProps = tab.props.TabProps || {};

        return {
          label: thisTabProps.label,
          icon: thisTabProps.icon,
        };
      });
    }

    return null;
  }, [children]);

  const showTabs = tabsData?.length > 0;

  const renderHeroBanner = () =>
    findChildrenByType(children, SovosHeroBanner, SovosGenericHeroBanner);

  const renderContent = () => {
    const tabs = findChildrenByType(children, SovosTabContent);
    const otherChildren = filterOutChildrenByType(
      children,
      SovosTabContent,
      SovosHeroBanner,
      SovosGenericHeroBanner
    );

    if (tabs.length) {
      const tabContent = tabs.find((tab, index) => index === selectedTab);
      const tabChildren = Children.toArray(tabContent.props.children).concat(
        otherChildren
      );

      return React.cloneElement(tabContent, {
        children: tabChildren,
        selected: true,
      });
    }

    return otherChildren;
  };

  return (
    <Root
      className={clsx('sovosPageLayout', className)}
      data-testid={dataTestId}
      style={style}
    >
      <Header>
        <PageTitleBar
          actions={titleBarActions}
          pageCrumbs={pageCrumbs}
          pageTitle={pageTitle}
          titleIconButton={titleIconButton}
        />
        {showTabs && (
          <TabBar
            onTabClick={onTabClick}
            selectedTab={selectedTab}
            tabsData={tabsData}
          />
        )}
        {renderHeroBanner()}
      </Header>
      <ContentContainer ref={container}>{renderContent()}</ContentContainer>
    </Root>
  );
};

SovosPageLayout.propTypes = {
  /**
   * The content of the page layout. Should be wrapped with
   * `SovosPageContentGeneric`, `SovosPageContentTable`, or
   * `SovosPageContentTwoColumn`, which can in turn be wrapped with
   * `SovosTabContent`
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Callback fired when tabs are clicked to confirm switching tabs
   */
  confirmTabChange: func,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Zero-based index of tab to be shown on page load
   */
  initialSelectedTabIndex: number,
  /**
   * Callback fired when tabs are clicked `function(selectedTab: object) => void`
   */
  onTabChanged: func,
  /**
   * Array of breadcrumb objects
   */
  pageCrumbs: arrayOf(
    shape({
      text: string.isRequired,
      onClick: func,
    })
  ),
  /**
   * String that displays the page title
   */
  pageTitle: string.isRequired,
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Node appended to a button group at the right of the page title bar
   */
  titleBarActions: node,
  /**
   * Icon button element
   */
  titleIconButton: sovosIconButtonElement,
};

SovosPageLayout.defaultProps = {
  className: undefined,
  confirmTabChange: undefined,
  'data-testid': undefined,
  initialSelectedTabIndex: 0,
  onTabChanged: undefined,
  pageCrumbs: [],
  style: {},
  titleBarActions: undefined,
  titleIconButton: undefined,
};

export default SovosPageLayout;
