import React from 'react';
import {
  arrayOf,
  bool,
  element,
  elementType,
  exact,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosNavigation from '../sovos-navigation/SovosNavigation';
import HEADER from '../sovos-navigation/constants/header';

const SkipToContent = styled('a')(
  ({ theme: { palette, spacing, zIndex } }) => ({
    backgroundColor: palette.primary.main,
    border: `1px solid ${palette.primary.dark}`,
    borderTop: 0,
    borderRadius: 0,
    color: palette.common.white,
    display: 'block',
    fontWeight: 500,
    left: '50%',
    top: 0,
    position: 'fixed',
    padding: spacing(),
    textDecoration: 'none',
    transform: 'translateX(-50%) translateY(-100%)',
    transition: 'transform 0.4s',
    zIndex: zIndex.modal,

    '&:focus, &:focus-visible': {
      outlineColor: 'transparent',
      transform: 'translateX(-50%) translateY(0)',
    },
  })
);

const Layout = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }) => ({
  display: 'flex',
  position: 'relative',
  width,
}));

const MainContent = styled('main')(() => ({
  margin: 0,
  overflowX: 'hidden',
  padding: 0,
  flex: '1 1 auto',
}));

const SovosGlobalLayout = ({
  children,
  className,
  'data-testid': dataTestId,
  NavigationProps,
  width,
}) => (
  <>
    <SkipToContent href="#main-content">Skip to content</SkipToContent>
    <Layout
      className={clsx('sovosGlobalLayout', className)}
      data-testid={dataTestId}
      width={width}
    >
      <SovosNavigation {...NavigationProps} />
      <MainContent tabIndex={-1} id="main-content">
        {children}
      </MainContent>
    </Layout>
  </>
);

SovosGlobalLayout.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * The content of the global layout
   */
  children: element.isRequired,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Properties for the sovos navigation component
   */
  NavigationProps: shape({
    accounts: arrayOf(
      shape({
        name: string.isRequired,
        taxYear: string,
        type: oneOf(['production', 'test', 'default']),
        nestedAccounts: arrayOf(object),
      })
    ),
    accountsAreLoading: bool,
    accountSwitcherLabels: object,
    didAccountChange: func,
    FooterProps: shape({
      labels: exact({
        helpIcon: string,
        settingsIcon: string,
        userIcon: string,
      }),
      onHelpClicked: func,
      settingsMenuItems: arrayOf(
        shape({
          label: string,
          action: func,
        })
      ),
      showHelpButton: bool,
      showSettingsButton: bool,
      userMenuItems: arrayOf(
        shape({
          label: string,
          action: func,
        })
      ).isRequired,
      userName: string.isRequired,
    }),
    initialSelectedLink: string.isRequired,
    links: arrayOf(
      shape({
        action: func,
        confirmNavigationDialogProps: shape({
          cancelButtonText: string.isRequired,
          continueButtonText: string.isRequired,
          content: oneOfType([func, string]).isRequired,
          shouldShowDialog: func.isRequired,
          title: string.isRequired,
        }),
        disabled: bool,
        icon: elementType.isRequired,
        id: oneOfType([string, number]),
        label: string.isRequired,
        nestedLinks: arrayOf(
          shape({
            action: func.isRequired,
            confirmNavigationDialogProps: shape({
              cancelButtonText: string.isRequired,
              continueButtonText: string.isRequired,
              content: oneOfType([func, string]).isRequired,
              shouldShowDialog: func.isRequired,
              title: string.isRequired,
            }),
            disabled: bool,
            id: oneOfType([string, number]),
            label: string.isRequired,
            toolTip: string,
          })
        ),
        toolTip: string,
        type: oneOf(['content', HEADER]),
      })
    ).isRequired,
    minimumAccountSearchCharacters: number,
    onAccountSearch: func,
    onExpandAccount: func,
    onSelectProduct: func,
    product: oneOfType([string, object]).isRequired,
    products: arrayOf(
      shape({
        id: oneOfType([number, string]),
        name: string,
        disabled: bool,
        type: oneOfType([string, object]),
      })
    ).isRequired,
    saveNavCollapsed: bool,
    selectedAccount: shape({
      name: string.isRequired,
      taxYear: string,
      type: oneOf(['production', 'test', 'default']),
    }),
    setAccount: func.isRequired,
    showSingleAccount: bool,
  }).isRequired,
  /**
   * Width style
   */
  width: oneOfType([string, number]),
};

SovosGlobalLayout.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  width: '100vw',
};

export default SovosGlobalLayout;
