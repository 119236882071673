import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { findChildrenByType } from '../internals/utils/reactHelpers';
import { normalizeClassnames } from '../internals/utils/classNameHelpers';
import SovosTab from '../sovos-tab';

const StyledTabs = styled(Tabs)(({ theme: { spacing } }) => {
  const height = spacing(6);

  return {
    backgroundColor: 'transparent',
    height,
    '& .MuiTabs-indicator': {
      height: spacing(0.5),
    },
    '& .MuiTabs-scrollButtons': {
      flex: `0 0 ${spacing(3)}`,
    },
  };
});

/**
 * This component can be used with `SovosTab` to render a tab bar. However,
 * `SovosTabContents` and `SovosTabGroup` will generate tabs and handle tab
 * switching and should be used in most situations.
 */
export const SovosTabs = ({
  children,
  classes,
  className,
  value,
  ...other
}) => {
  const tabs = findChildrenByType(children, Tab, SovosTab);

  return (
    <StyledTabs
      classes={classes}
      className={clsx('sovosTabs', className)}
      variant="scrollable"
      value={value}
      textColor="primary"
      indicatorColor="primary"
      {...other}
    >
      {tabs.map((tab) =>
        React.cloneElement(tab, {
          className: clsx(
            'sovosTabs__tab--label',
            `sovosTabs__tab--label-${normalizeClassnames(tab.props.value)}`,
            tab.props.className
          ),
        })
      )}
    </StyledTabs>
  );
};

SovosTabs.propTypes = {
  /**
   * Content of the tabs. Must be an array of Mui `Tab` or `SovosTab`
   */
  children: PropTypes.node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: PropTypes.object,
  /**
   * Extend the class name applied to the root element
   */
  className: PropTypes.string,
  /**
   * The value of the currently selected tab
   */
  value: PropTypes.any,
  /**
   * Callback fired when the selected tab changes
   *
   * `function(event: object, value: object) => void`
   */
  onChange: PropTypes.func.isRequired,
};

SovosTabs.defaultProps = {
  classes: undefined,
  value: undefined,
  className: undefined,
};

SovosTabs.baseComponent = {
  name: 'Tabs',
  link: 'tabs/',
};

export default SovosTabs;
