/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { SovosTypography } from 'mosaic-react';
import { useErrorSnackbar } from 'mosaic-react/hooks';
import { importsSummaryData } from './services/importsSummary';

const sidebarFormat = [
  {
    title: 'Summary',
    data: [
      { label: 'Batches imported', dataKey: 'batchCount' },
      { label: 'Total transactions', dataKey: 'transactionCount' },
      { label: 'Companies', dataKey: 'companyCount' },
    ],
  },
  {
    title: 'Upload history',
    data: [
      { label: 'First upload', dataKey: 'firstUpload' },
      { label: 'Last upload', dataKey: 'lastUpload' },
    ],
  },
  {
    title: 'Report history',
    data: [
      { label: 'First report', dataKey: 'firstReport' },
      { label: 'Last report', dataKey: 'lastReport' },
    ],
  },
];

const ImportsSidebar = () => {
  const { addError } = useErrorSnackbar();
  const { current } = useSelector((state) => state.importsSummary);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(importsSummaryData()).catch((err) => {
      addError(err);
    });
  }, [addError, dispatch]);

  return sidebarFormat.map((item) => (
    <section key={item.title}>
      <SovosTypography
        variant="h4"
        sx={{
          marginBottom: 2,
        }}
      >
        {item.title}
      </SovosTypography>
      {item.data.map((dataItem) => (
        <Box
          key={dataItem.label}
          sx={{
            display: 'flex',
            marginBottom: 2,
            '&:last-child': {
              marginBottom: 5,
            },
          }}
        >
          <SovosTypography
            color="text.secondary"
            sx={{
              flexGrow: 1,
              marginRight: 2,
            }}
            variant="h6"
          >
            {dataItem.label}
          </SovosTypography>
          <SovosTypography variant="h6">
            {current?.[dataItem.dataKey]}
          </SovosTypography>
        </Box>
      ))}
    </section>
  ));
};

export default ImportsSidebar;
