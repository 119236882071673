import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import CardActions from '@mui/material/CardActions';

/**
 * **Note** Must be a child of `SovosCard`
 */
const SovosCardActions = ({ className, ...rest }) => (
  <CardActions className={clsx('sovosCardActions', className)} {...rest} />
);

SovosCardActions.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosCardActions.defaultProps = {
  className: undefined,
};

SovosCardActions.baseComponent = {
  name: 'Card Actions',
  link: 'card-actions/',
};

export default SovosCardActions;
