import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import SovosButton from 'mosaic-react/sovos-button';
import SovosPageLayout from 'mosaic-react/sovos-page-layout';
import UserPanel from './UserPanel';
import UsersTable from './UsersTable';

const UsersPage = () => {
  const [userPanelOpen, setUserPanelOpen] = useState(false);
  const [label, setLabel] = useState(undefined);
  const [editId, setEditId] = useState(undefined);
  const [usersTableRefreshKey, setUsersTableRefreshKey] = useState(uuid());
  const [userPanelRefreshKey, setUserPanelRefreshKey] = useState(uuid());
  const firstUpdate = useRef(true);

  const refreshUsersTableKey = () => {
    const newKey = uuid();
    setUsersTableRefreshKey(newKey);
  };

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  const openUserPanel = (newLabel, id) => {
    setUserPanelRefreshKey(uuid());
    setEditId(id);
    setUserPanelOpen(true);
    setLabel(newLabel);
  };

  const closeUserPanel = () => {
    setUserPanelOpen(false);
    setLabel(undefined);
    setEditId(undefined);
  };

  const handleUserOnSubmit = () => {
    refreshUsersTableKey();
    closeUserPanel();
  };

  const addUserOpen = () => {
    openUserPanel('Add User');
  };

  const titleBarActions = () => [
    <SovosButton
      onClick={addUserOpen}
      key="addUser"
      className="UsersPage__AddButton"
    >
      Add
    </SovosButton>,
  ];

  const fetchUserForEdit = (id) => {
    openUserPanel('Edit User', id);
  };

  return (
    <SovosPageLayout
      pageTitle="Users"
      pageCrumbs={[
        {
          text: 'Settings',
        },
      ]}
      titleBarActions={titleBarActions()}
      className="UsersPage__PageLayout"
    >
      <UsersTable
        key={usersTableRefreshKey}
        onEdit={fetchUserForEdit}
        onDeleted={refreshUsersTableKey}
      />
      <UserPanel
        addUserPanelOpen={userPanelOpen}
        closePanel={closeUserPanel}
        onSubmit={handleUserOnSubmit}
        label={label}
        editId={editId}
        key={userPanelRefreshKey}
      />
    </SovosPageLayout>
  );
};

export default UsersPage;
