import React from 'react';
import { node, object } from 'prop-types';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import merge from 'lodash.merge';
import { useCustomTheme } from '../../../sovos-custom-theme-context';
import SovosTheme from '../../../themes/sovos-theme';
import generateCustomTheme from '../../custom-themes/generateCustomTheme';

const MergedThemeProvider = ({ children, theme = {} }) => {
  const { colorThemes, logoAlt, logoUrl, selectedTheme } = useCustomTheme();

  const selectedThemeIndex = colorThemes?.findIndex(
    (colorTheme) => colorTheme.title === selectedTheme
  );

  const getMergedTheme = () =>
    createTheme(
      merge(
        {},
        SovosTheme,
        theme,
        selectedTheme ?? colorThemes
          ? generateCustomTheme(
              colorThemes,
              logoAlt,
              logoUrl,
              selectedThemeIndex
            )
          : null
      )
    );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMergedTheme()}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

MergedThemeProvider.propTypes = {
  children: node.isRequired,
  theme: object,
};

export default MergedThemeProvider;
