import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import assetResolver, { assetKeys } from 'mosaic-assets-resolver';
import { findChildByType } from '../internals/utils/reactHelpers';
import SovosHeroBannerTitle from '../sovos-hero-banner-title';
import SovosHeroBannerSubtitle from '../sovos-hero-banner-subtitle';
import SovosHeroBannerStatus from '../sovos-hero-banner-status';
import { contentSidePadding } from '../internals/page-layout/contentPadding';

const DEFAULT = 'default';
const DARK = 'dark';
const SUCCESS = 'success';
const ERROR = 'error';
const WARNING = 'warning';
const INPROGRESS = 'inprogress';

const Root = styled('div')(({ theme: { breakpoints, spacing, palette } }) => ({
  backgroundColor: palette.primary.dark,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'row',
  height: 200,
  justifyContent: 'space-between',
  ...contentSidePadding(breakpoints, spacing),

  '& .sovosHeroBanner__leftColumn': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const { heroBanners } = assetKeys;

const SovosHeroBanner = ({
  backgroundImage,
  children,
  className,
  color,
  style,
  ...rest
}) => {
  const title = findChildByType(children, SovosHeroBannerTitle);
  const subtitle = findChildByType(children, SovosHeroBannerSubtitle);
  const status = findChildByType(children, SovosHeroBannerStatus);
  let image;

  if (backgroundImage) {
    image = backgroundImage;
  } else {
    switch (color) {
      case DARK:
        image = assetResolver.heroBanners(heroBanners.colors.dark);
        break;
      case ERROR:
        image = assetResolver.heroBanners(heroBanners.colors.error);
        break;
      case SUCCESS:
        image = assetResolver.heroBanners(heroBanners.colors.success);
        break;
      case WARNING:
        image = assetResolver.heroBanners(heroBanners.colors.warning);
        break;
      case INPROGRESS:
        image = assetResolver.heroBanners(heroBanners.colors.inProgress);
        break;
      case DEFAULT:
        image = assetResolver.heroBanners(heroBanners.colors.default);
        break;
      default:
        image = undefined;
    }
  }

  const bannerStyle = image
    ? {
        backgroundImage: `url(${image})`,
        ...style,
      }
    : {
        backgroundColor: color,
        ...style,
      };

  return (
    <Root
      className={clsx('sovosHeroBanner', className)}
      data-testid="sovosHeroBanner"
      style={bannerStyle}
      {...rest}
    >
      <div className="sovosHeroBanner__leftColumn">
        {title}
        {subtitle}
      </div>
      <div className="sovosHeroBanner__rightColumn">{status}</div>
    </Root>
  );
};

SovosHeroBanner.propTypes = {
  /**
   * Background Image
   */
  backgroundImage: PropTypes.string,
  /**
   * The content of the hero banner. Should be wrapped with
   * `SovosHeroBannerTitle`, `SovosHeroBannerSubtitle`, and `SovosHeroBannerStatus`
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  className: PropTypes.string,
  /**
   * Array of color options: default, dark, success, error, warning, and inprogress
   */
  color: PropTypes.oneOfType([
    PropTypes.oneOf([DEFAULT, DARK, SUCCESS, ERROR, WARNING, INPROGRESS]),
    PropTypes.string,
  ]),
  /**
   * Inline styles applied to the root element
   */
  style: PropTypes.object,
};

SovosHeroBanner.defaultProps = {
  backgroundImage: undefined,
  className: undefined,
  color: DEFAULT,
  style: {},
};

export default SovosHeroBanner;
