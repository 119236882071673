import React, { useEffect, useState } from 'react';
import {
  exact,
  arrayOf,
  shape,
  string,
  oneOfType,
  number,
  oneOf,
  func,
  object,
  node,
  elementType,
  bool,
} from 'prop-types';
import SovosToolbarFilter from '../../../sovos-toolbar-filter';
import SovosFilterDrawer from '../../../sovos-filter-drawer';
import SovosFilterTextEntry from '../../../sovos-filter-text-entry';
import SovosFilterDateInput from '../../../sovos-filter-date-input';
import SovosFilterCheckboxList from '../../../sovos-filter-checkbox-list';
import SovosFilterDateRange from '../../../sovos-filter-date-range';
import SovosFilterNumberRange from '../../../sovos-filter-number-range';
import { filterTypes } from '../../../sovos-table-group/helpers/filterTypes';

const {
  textEntry,
  checkboxList,
  dateInput,
  dateRangePicker,
  numberRangePicker,
} = filterTypes;

const components = {
  [textEntry]: SovosFilterTextEntry,
  [checkboxList]: SovosFilterCheckboxList,
  [dateInput]: SovosFilterDateInput,
  [dateRangePicker]: SovosFilterDateRange,
  [numberRangePicker]: SovosFilterNumberRange,
};

const Filters = ({
  disabled,
  filters,
  onClearSelection,
  buttonTooltipText,
  labels,
}) => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [workingValues, setWorkingValues] = useState({});

  const hasErrors = !!Object.values(errors).find((v) => v);

  const { items, onApply, values } = filters;

  useEffect(() => {
    if (values) {
      setWorkingValues(values);
    }
  }, [values]);

  const handleSetValue = (key, value) => {
    const newValues = { ...workingValues };
    newValues[key] = value;
    setWorkingValues(newValues);
  };

  const handleError = (key, hasError) => {
    const newErrors = { ...errors };
    newErrors[key] = hasError;
    setErrors(newErrors);
  };

  const handleSave = () => {
    onApply(workingValues);
    onClearSelection();
    setOpen(false);
  };

  const clearFilters = () => {
    setWorkingValues({});
    onApply({});
    onClearSelection();
    setOpen(false);
  };

  const handleClose = () => {
    setWorkingValues(values);
    setOpen(false);
  };

  const filterComponent = (type) => components[type] || type;

  return (
    <>
      <SovosToolbarFilter
        appliedFiltersCount={
          values
            ? Object.values(values).filter((arr) => arr.length).length
            : undefined
        }
        disabled={disabled}
        onClick={() => setOpen(true)}
        tooltipText={buttonTooltipText}
      />
      <SovosFilterDrawer
        open={open}
        SaveButtonProps={{
          onClick: handleSave,
          disabled: hasErrors,
          children: labels.applyButton,
        }}
        ClearButtonProps={{
          onClick: clearFilters,
          children: labels.cancelButton,
        }}
        onClose={handleClose}
        title={labels.title}
      >
        {items.map((filter) => {
          const { type, label, dataKey, ...otherProps } = filter;
          const key = dataKey || label;

          const filterProps = {
            ...otherProps,
            key,
            label,
            data: workingValues[key],
            onChange: (value) => handleSetValue(key, value),
            onError: (hasError) => handleError(key, hasError),
          };

          const Filter = filterComponent(type);

          if (!Filter) {
            console.error('SovosTableGroup received an invalid filter type.');
            return null;
          }

          return <Filter {...filterProps} />;
        })}
      </SovosFilterDrawer>
    </>
  );
};

export const filterPropTypes = exact({
  items: arrayOf(
    shape({
      label: string.isRequired,
      dataKey: oneOfType([string, number]),
      type: oneOfType([elementType, oneOf(Object.values(filterTypes))])
        .isRequired,
    })
  ).isRequired,
  onApply: func.isRequired,
  values: object,
});

Filters.propTypes = {
  disabled: bool,
  filters: filterPropTypes.isRequired,
  onClearSelection: func.isRequired,
  buttonTooltipText: node,
  labels: exact({
    applyButton: node,
    cancelButton: node,
    title: node,
  }),
};

Filters.defaultProps = {
  disabled: false,
  buttonTooltipText: undefined,
  labels: {},
};

export default Filters;
