/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const url = '/api/v1/users';

export function loadUsers(
  page,
  itemsPerPage,
  sortProp,
  sortOrder,
  searchTerm,
  filters
) {
  let params;

  if (searchTerm) {
    params = {
      q: searchTerm,
    };
  } else {
    params = {
      ...filters,
    };
  }

  params._sort = sortProp;
  params._order = sortOrder;

  if (page && itemsPerPage) {
    params._page = page;
    params._limit = itemsPerPage;
  }

  return axios.get(url, { params }).then((response) => {
    const users = response.data;
    const totalItems = parseInt(response.headers['x-total-count'], 10);
    return { totalItems, users };
  });
}

export function loadUser(id) {
  return axios.get(`${url}/${id}`).then((response) => response.data);
}

export function addUser(user) {
  return axios.post(url, user).then((response) => response.data);
}

export function editUser(user, id) {
  return axios.put(`${url}/${id}`, user).then((response) => response.data);
}

export function deleteUser(id) {
  return axios.delete(`${url}/${id}`).then((response) => response.data);
}
