import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { alpha, styled } from '@mui/material/styles';
import { ChevronRight } from 'mosaic-react-icons';
import SovosFixedWidthText from '../../sovos-fixed-width-text';
import i18n from '../../internals/i18n/i18n';

const ItemRoot = styled('li')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
});

const ItemButton = styled('button')(({ theme: { palette, spacing } }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  borderBottomColor: 'transparent',
  borderBottomStyle: 'solid',
  borderBottomWidth: spacing(0.5),
  borderRadius: 0,
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  maxWidth: 120,
  minWidth: 80,
  padding: `0 ${spacing(1)}`,
  '&:focus': {
    outline: 'none',
  },
  textAlign: 'left',

  '&.sovosToolbarDataGrouping__itemButton--active': {
    borderBottomColor: palette.primary.main,
  },

  '&.sovosToolbarDataGrouping__itemButton--selectable': {
    cursor: 'pointer',
    border: 'none',
    padding: `0 ${spacing(1)} ${spacing(0.5)} ${spacing(1)}`,
    '&:hover': {
      backgroundColor: alpha(palette.primary.main, palette.action.hoverOpacity),
    },
  },
  '&.sovosToolbarDataGrouping__itemButton--disabled': {
    borderBottomColor: palette.text.disabled,
    pointerEvents: 'none',
  },
}));

const ItemTitle = styled(SovosFixedWidthText)(
  ({ theme: { palette, typography } }) => ({
    color: palette.text.secondary,
    fontSize: typography.body2.fontSize,
    lineHeight: 'normal',
  })
);

const ItemValue = styled(SovosFixedWidthText)(
  ({ theme: { palette, typography } }) => ({
    color: palette.text.main,
    fontSize: typography.body1.fontSize,
    lineHeight: 'normal',
    '&.sovosToolbarDataGrouping__itemValue--active': {
      color: palette.primary.main,
    },
    '&.sovosToolbarDataGrouping__itemValue--populated': {
      color: palette.primary.main,
    },
    '&.sovosToolbarDataGrouping__itemValue--disabled': {
      color: palette.text.disabled,
    },
  })
);

const Divider = styled(ChevronRight)(({ theme: { palette, spacing } }) => ({
  color: palette.text.primary,
  '& svg': {
    height: spacing(3),
    width: spacing(3),
  },
}));

export const groupingValue = (value, isActive, labels) => {
  if (value) {
    return value;
  }
  if (isActive) {
    return (
      labels?.activeGroup ||
      i18n.t('toolbarDataGrouping.item.activeGroup', { ns: 'mosaic' })
    );
  }
  return (
    labels?.inactiveGroup ||
    i18n.t('toolbarDataGrouping.item.inactiveGroup', { ns: 'mosaic' })
  );
};

const ToolbarDataGroupingItem = ({
  active,
  disabled,
  labels,
  onClick,
  selectable,
  showDivider,
  title,
  value,
}) => {
  const valueText = groupingValue(value, active, labels);

  return (
    <ItemRoot>
      <ItemButton
        type="button"
        onClick={onClick}
        className={clsx('sovosToolbarDataGrouping__itemButton', {
          'sovosToolbarDataGrouping__itemButton--active': active,
          'sovosToolbarDataGrouping__itemButton--disabled': disabled,
          'sovosToolbarDataGrouping__itemButton--selectable': selectable,
        })}
      >
        <ItemTitle text={title} />
        <ItemValue
          text={valueText}
          className={clsx('sovosToolbarDataGrouping__itemValue', {
            'sovosToolbarDataGrouping__itemValue--active': active,
            'sovosToolbarDataGrouping__itemValue--disabled': disabled,
            'sovosToolbarDataGrouping__itemValue--populated': value,
          })}
        />
      </ItemButton>
      {showDivider && <Divider />}
    </ItemRoot>
  );
};

ToolbarDataGroupingItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  labels: PropTypes.shape({
    activeGroup: PropTypes.string,
    inactiveGroup: PropTypes.string,
  }),
  title: PropTypes.string,
  onClick: PropTypes.func,
  selectable: PropTypes.bool,
  showDivider: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

ToolbarDataGroupingItem.defaultProps = {
  active: false,
  disabled: false,
  labels: undefined,
  title: undefined,
  onClick: undefined,
  selectable: false,
  value: undefined,
};

export default ToolbarDataGroupingItem;
