export default {
  rangeMiddle: 'rangeMiddle',
  rangeStart: 'rangeStart',
  rangeEnd: 'rangeEnd',
  month: 'month',
  currentMonth: 'currentMonth',
  monthSelected: 'monthSelected',
  year: 'year',
  yearSelected: 'yearSelected',
};
