import React from 'react';
import { string, oneOfType, number, bool, func, oneOf } from 'prop-types';
import { emphasize, styled } from '@mui/material/styles';
import clsx from 'clsx';
import SovosButton from '../../sovos-button';
import buttonVariants from '../utils/calendarButtonVariants';

const Root = styled(SovosButton)(({ theme: { palette, spacing } }) => {
  const borderRadius = spacing(2);
  return {
    borderRadius,
    boxSizing: 'border-box',
    fontWeight: 500,
    height: spacing(4),
    marginLeft: spacing(0.25),
    marginRight: spacing(0.25),
    minWidth: spacing(4),
    padding: 0,
    transition: 'none', // disable Mui transitions
    '&.MuiButton-textPrimary': {
      color: palette.text.primary,
      width: spacing(4),

      '&.Mui-disabled': {
        color: palette.text.disabled,
      },

      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    },
    '&:focus, &:focus-visible': {
      backgroundColor: palette.action.focus,
    },
    '&.sovosMonthRangePicker__calendarButton': {
      '&--year': {
        '&.MuiButton-textPrimary': {
          width: spacing(8.5),
        },
      },
      '&--selected': {
        '&.MuiButton-textPrimary': {
          backgroundColor: palette.primary.main,
          color: 'white',

          '&:hover': {
            backgroundColor: palette.primary.main,
          },
          '&:focus, &:focus-visible': {
            backgroundColor: palette.primary.dark,
          },
        },
      },
      '&--currentMonth': {
        backgroundColor: palette.action.selected,
        '&:hover': {
          backgroundColor: emphasize(
            palette.action.selected,
            palette.action.hoverOpacity
          ),
        },
      },
      '&--rangeStart': {
        '&.MuiButton-textPrimary': {
          borderRadius: `${borderRadius} 0 0 ${borderRadius}`,
          marginRight: 0,
          width: spacing(4.25),
        },
      },
      '&--rangeMiddle': {
        '&.MuiButton-textPrimary': {
          borderRadius: 0,
          marginRight: 0,
          marginLeft: 0,
          width: spacing(4.5),
        },
      },
      '&--rangeEnd': {
        '&.MuiButton-textPrimary': {
          marginLeft: 0,
          borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
          width: spacing(4.25),
        },
      },
    },
  };
});

const NoDate = styled('span')(
  ({ theme: { palette, shape: themeShape, spacing } }) => ({
    borderRadius: themeShape.borderRadiusRound,
    boxSizing: 'border-box',
    fontWeight: 500,
    height: spacing(4),
    marginLeft: spacing(0.25),
    marginRight: spacing(0.25),
    minWidth: spacing(4),
    padding: 0,
    transition: 'none', // disable Mui transitions
    '&.MuiButton-textPrimary': {
      color: palette.text.primary,
      width: spacing(4),
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    },
  })
);

const CalendarButton = ({ ariaLabel, date, disabled, onClick, variant }) => {
  const isSelected =
    [
      buttonVariants.rangeStart,
      buttonVariants.rangeEnd,
      buttonVariants.rangeMiddle,
      buttonVariants.monthSelected,
      buttonVariants.yearSelected,
    ].indexOf(variant) !== -1;

  const isYear =
    [buttonVariants.year, buttonVariants.yearSelected].indexOf(variant) !== -1;

  return date ? (
    <Root
      aria-label={ariaLabel}
      className={clsx(
        'sovosMonthRangePicker__calendarButton',
        isSelected && 'sovosMonthRangePicker__calendarButton--selected',
        isYear && 'sovosMonthRangePicker__calendarButton--year',
        `sovosMonthRangePicker__calendarButton--${buttonVariants[variant]}`
      )}
      disabled={disabled}
      disableRipple
      onClick={onClick}
      variant="text"
    >
      {date}
    </Root>
  ) : (
    <NoDate />
  );
};

CalendarButton.propTypes = {
  ariaLabel: string,
  date: oneOfType([string, number]).isRequired,
  disabled: bool,
  onClick: func.isRequired,
  variant: oneOf(Object.values(buttonVariants)),
};

CalendarButton.defaultProps = {
  ariaLabel: undefined,
  disabled: false,
  variant: undefined,
};

export default CalendarButton;
