import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SovosLoginPage } from 'mosaic-react';
import auth from '../authentication';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state || { from: { pathname: '/dashboard' } };

  const handleLogin = ({ email }) => {
    const [name] = email.split('@');
    const userName = name.charAt(0).toUpperCase() + name.slice(1);

    auth.login(userName);
    navigate(from.from.pathname, { replace: true });
  };

  return (
    <SovosLoginPage
      labels={{
        ...SovosLoginPage.defaultProps.labels,
        header: 'Welcome to the Demo app!',
        subtitle:
          'Here and only here, you can enter anything for username and password',
      }}
      loading={false}
      loginError={false}
      onSubmit={handleLogin}
    />
  );
};

export default Login;
