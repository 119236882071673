import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CheckCircle,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from 'mosaic-react-icons';
import {
  SovosButton,
  SovosDialog,
  SovosDialogActions,
  SovosDialogContent,
  SovosDialogTitle,
  SovosIconButton,
  SovosInputAdornment,
  SovosTextField,
  SovosTypography,
} from 'mosaic-react';
import { checkPasswordRules } from '../utils/checkPasswordRules';

const RuleLine = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
});

const RuleDescription = styled('span')({ marginLeft: 1 });

const CheckedIcon = styled(CheckCircle)({ color: 'success.main' });

const UncheckedIcon = styled(CheckCircleOutline)({ color: 'action.disabled' });

const initialPasswordCheck = {
  uppercase: false,
  specialChar: false,
  minLength: false,
};

export const passwordResetModalLabelPropsShape = shape({
  modalTitleText: string,
  dialogContentText: string,
  upperCaseRuleText: string,
  specialCharRuleText: string,
  minLengthRuleText: string,
  cancelButtonLabelText: string,
  savePasswordButtonLabelText: string,
  passwordTextFieldLabelText: string,
});

const PasswordResetModal = ({
  open,
  closeModal,
  onPasswordChange,
  LabelProps,
}) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRulesCheckStatus, setPasswordRulesCheckStatus] =
    useState(initialPasswordCheck);
  const [textFieldType, setTextFieldType] = useState('password');

  const {
    modalTitleText,
    dialogContentText,
    upperCaseRuleText,
    specialCharRuleText,
    minLengthRuleText,
    cancelButtonLabelText,
    savePasswordButtonLabelText,
    passwordTextFieldLabelText,
  } = LabelProps;

  const toggleShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true);
      setTextFieldType('text');
    }
    if (showPassword === true) {
      setShowPassword(false);
      setTextFieldType('password');
    }
  };

  const passwordRulesCheck = (newPassword) => {
    const checkResult = checkPasswordRules(newPassword);
    setPasswordRulesCheckStatus(checkResult);
  };

  const onPasswordFieldChange = (event) => {
    setPassword(event.target.value);
    passwordRulesCheck(event.target.value);
  };

  const onModalClose = () => {
    setPassword('');
    closeModal();
  };

  const updatePassword = () => {
    onPasswordChange(password);
    setPassword('');
    closeModal();
  };

  const renderRuleIcon = (isValid) =>
    isValid ? <CheckedIcon /> : <UncheckedIcon />;

  return (
    <SovosDialog open={open} onClose={closeModal} width="small">
      <SovosDialogTitle>{modalTitleText}</SovosDialogTitle>
      <SovosDialogContent>
        <SovosTypography gutterBottom>{dialogContentText}</SovosTypography>
        <SovosTextField
          InputProps={{
            endAdornment: (
              <SovosInputAdornment position="end">
                <SovosIconButton
                  onClick={toggleShowPassword}
                  tooltipText={`${showPassword ? 'Show' : 'Hide'} password`}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </SovosIconButton>
              </SovosInputAdornment>
            ),
          }}
          label={passwordTextFieldLabelText}
          onChange={(e) => onPasswordFieldChange(e)}
          sx={{
            marginTop: 2,
            width: '100%',
            '& ::-ms-reveal': {
              display: 'none', // Tell Edge not to show its own icon to reveal password
            },
          }}
          type={textFieldType}
          value={password}
        />
        <Box sx={{ marginTop: 2 }}>
          <RuleLine>
            {renderRuleIcon(passwordRulesCheckStatus.uppercase)}
            <RuleDescription>{upperCaseRuleText}</RuleDescription>
          </RuleLine>
          <RuleLine>
            {renderRuleIcon(passwordRulesCheckStatus.specialChar)}
            <RuleDescription>{specialCharRuleText}</RuleDescription>
          </RuleLine>
          <RuleLine>
            {renderRuleIcon(passwordRulesCheckStatus.minLength)}
            <RuleDescription>{minLengthRuleText}</RuleDescription>
          </RuleLine>
        </Box>
      </SovosDialogContent>
      <SovosDialogActions>
        <SovosButton variant="text" onClick={onModalClose}>
          {cancelButtonLabelText}
        </SovosButton>
        <SovosButton
          disabled={
            !passwordRulesCheckStatus.uppercase ||
            !passwordRulesCheckStatus.specialChar ||
            !passwordRulesCheckStatus.minLength
          }
          onClick={updatePassword}
        >
          {savePasswordButtonLabelText}
        </SovosButton>
      </SovosDialogActions>
    </SovosDialog>
  );
};

PasswordResetModal.propTypes = {
  open: bool.isRequired,
  closeModal: func.isRequired,
  onPasswordChange: func.isRequired,
  LabelProps: passwordResetModalLabelPropsShape,
};

PasswordResetModal.defaultProps = {
  LabelProps: {
    modalTitleText: 'Update Password',
    dialogContentText:
      'Choose a new password for your account. To create a strong password, use combinations of uncommon words, add numbers, symbols and non-obvious uppercase characters. Avoid using the same password for multiple locations.',
    upperCaseRuleText: 'One uppercase letter',
    specialCharRuleText: 'One special character',
    minLengthRuleText: '8 characters minimum',
    cancelButtonLabelText: 'Cancel',
    savePasswordButtonLabelText: 'Update Password',
    passwordTextFieldLabelText: 'New Password',
  },
};

export default PasswordResetModal;
