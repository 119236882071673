import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';

const DEFAULT = 'default';
const WIDE = 'wide';

const Root = styled(SovosTypography)(({ theme: { palette, spacing } }) => ({
  alignItems: 'center',
  color: palette.primary.contrastText,
  display: 'flex',
  height: spacing(15),
  justifyContent: 'center',
  marginLeft: spacing(10),
  marginTop: spacing(4),

  '&.sovosHeroBanner__status': {
    '&--default': {
      width: 320,
    },
    '&--wide': {
      width: 360,
    },
  },
}));

/**
 * **Note** Must be a child `SovosHeroBanner`
 */
const SovosHeroBannerStatus = ({ children, className, width, ...rest }) => (
  <Root
    className={clsx(
      'sovosHeroBanner__status',
      width === WIDE
        ? 'sovosHeroBanner__status--wide'
        : 'sovosHeroBanner__status--default',
      className,
      width
    )}
    variant="subtitle2"
    {...rest}
  >
    {children}
  </Root>
);

SovosHeroBannerStatus.propTypes = {
  /**
   * The content of the hero banner status
   */
  children: PropTypes.node,
  /**
   * Extend the class name applied to the root element
   */
  className: PropTypes.string,
  /**
   * Width of the status container
   */
  width: PropTypes.oneOf([DEFAULT, WIDE]),
};

SovosHeroBannerStatus.defaultProps = {
  className: undefined,
  width: DEFAULT,
  children: undefined,
};

export default SovosHeroBannerStatus;
