import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import MuiChip from '@mui/material/Chip';
import SovosFixedWidthText from '../../../sovos-fixed-width-text';
import { getAfterFocusStyles } from '../../utils';

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'maxWidth' && prop !== 'selected',
})(({ maxWidth, selected, theme: { palette, spacing, typography } }) => ({
  ...typography.body1,
  height: spacing(4),
  maxWidth,
  backgroundColor: selected ? undefined : palette.action.disabledBackground,

  '& .MuiChip-label': {
    padding: `${spacing(0.75)} ${spacing(2)}`,
  },

  '&:focus-visible': {
    ...getAfterFocusStyles(),
  },
}));

const Chip = ({ classes, disabled, label, maxWidth, selected, ...rest }) => {
  const renderedLabel = maxWidth ? <SovosFixedWidthText text={label} /> : label;

  return (
    <StyledChip
      classes={classes}
      color={selected ? 'primary' : 'default'}
      disabled={disabled}
      label={renderedLabel}
      maxWidth={maxWidth}
      selected={selected}
      {...rest}
    />
  );
};

Chip.propTypes = {
  classes: object,
  disabled: bool,
  label: string.isRequired,
  maxWidth: number,
  onClick: func,
  selected: bool,
};

Chip.defaultProps = {
  classes: undefined,
  disabled: false,
  maxWidth: undefined,
  onClick: undefined,
  selected: undefined,
};

export default Chip;
