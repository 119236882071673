import React from 'react';
import { node, object, string } from 'prop-types';
import clsx from 'clsx';
import { alpha, styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';

const StyledTypography = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: alpha(palette.common.black, 0.8),
    paddingTop: spacing(2),
    paddingBottom: spacing(),
  })
);

/**
 * `SovosSettingsTitle` contains text for the settings title.
 *
 * **Note**: Must be a child of `SovosSettingsSection`
 */
const SovosSettingsTitle = ({ children, classes, className, ...rest }) => (
  <StyledTypography
    variant="h5"
    className={clsx('sovosSettingsTitle', classes?.root, className)}
    {...rest}
  >
    {children}
  </StyledTypography>
);

SovosSettingsTitle.propTypes = {
  /**
   * The content of the title
   */
  children: node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosSettingsTitle.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default SovosSettingsTitle;
