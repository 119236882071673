import React from 'react';
import {
  arrayOf,
  bool,
  func,
  objectOf,
  shape,
  string,
  instanceOf,
  oneOfType,
} from 'prop-types';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import SovosGrid from '../sovos-grid';
import { getDateRangeMessage } from '../internals/utils';
import SovosDatePicker from '../sovos-date-picker';
import SovosFilterDrawerSection from '../sovos-filter-drawer-section';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const dateRangeShape = {
  from: string,
  to: string,
};

const StyledDatePicker = styled(SovosDatePicker)(({ theme: { spacing } }) => ({
  '&.MuiTextField-root': {
    minHeight: 'auto',
  },

  '& .MuiFilledInput-root': {
    paddingRight: spacing(1.5),
  },
  '& .MuiInputAdornment-root': {
    margin: 0,
  },
}));
/**
 * For use in `SovosFilterDrawer`
 */
const SovosFilterDateRange = ({
  className,
  data,
  'data-testid': dataTestId,
  format,
  fromLabel,
  label,
  multiple,
  maxDate,
  minDate,
  onChange,
  onError,
  toLabel,
  invalidEndDateMessage,
  invalidStartDateMessage,
  endDateAfterStartDateMessage,
  ...rest
}) => {
  const { t } = useMosaicTranslation();

  const { palette, spacing, typography } = useTheme();
  const getDefaultEntry = () => ({ from: null, to: null });

  const onFilterChange = (value) => {
    const isValid = value.every(
      (row) =>
        !getDateRangeMessage({
          endDateAfterStartDateMessage:
            endDateAfterStartDateMessage ||
            t('filterDateRange.endDateAfterStartDate'),
          format: format || t('filterDateRange.format'),
          invalidEndDateMessage:
            invalidEndDateMessage || t('filterDateRange.invalidEndDate'),
          invalidStartDateMessage:
            invalidStartDateMessage || t('filterDateRange.invalidStartDate'),
          maxDate,
          minDate,
          values: row,
        })
    );
    if (onError) {
      onError(!isValid);
    }
    onChange(value);
  };

  const getRow = ({ entry, handleChange }) => {
    const { from, to } = entry;
    const errorMessage = getDateRangeMessage({
      endDateAfterStartDateMessage:
        endDateAfterStartDateMessage ||
        t('filterDateRange.endDateAfterStartDate'),
      format: format || t('filterDateRange.format'),
      invalidEndDateMessage:
        invalidEndDateMessage || t('filterDateRange.invalidEndDate'),
      invalidStartDateMessage:
        invalidStartDateMessage || t('filterDateRange.invalidStartDate'),
      maxDate,
      minDate,
      values: entry,
    });

    return (
      <SovosGrid
        container
        spacing={2}
        sx={{
          marginBottom: spacing(),
        }}
      >
        <SovosGrid item sm={6}>
          <StyledDatePicker
            format={format || t('filterDateRange.format')}
            invalidDateMessage=""
            label={fromLabel || t('filterDateRange.fromLabel')}
            maxDate={to || maxDate}
            minDate={minDate}
            maxDateMessage=""
            minDateMessage=""
            name="from"
            onChange={(value) => handleChange(null, { from: value, to })}
            value={from}
            TextFieldProps={{
              size: 'small',
              error: !!errorMessage,
            }}
          />
        </SovosGrid>
        <SovosGrid item sm={6}>
          <StyledDatePicker
            format={format || t('filterDateRange.format')}
            invalidDateMessage=""
            label={toLabel || t('filterDateRange.toLabel')}
            minDate={from || minDate}
            maxDate={maxDate}
            minDateMessage=""
            maxDateMessage=""
            name="to"
            onChange={(value) => handleChange(null, { from, to: value })}
            value={to}
            TextFieldProps={{
              size: 'small',
              error: !!errorMessage,
            }}
          />
        </SovosGrid>
        {!!errorMessage && (
          <SovosGrid item>
            <Box
              component="p"
              sx={{
                margin: 0,
                transform: `translateY(-${spacing()})`,
                height: spacing(1.5),
                ...typography.body2,
                color: palette.error.main,
              }}
            >
              {errorMessage}
            </Box>
          </SovosGrid>
        )}
      </SovosGrid>
    );
  };

  getRow.propTypes = {
    entry: objectOf(shape(dateRangeShape).isRequired),
    handleChange: func.isRequired,
  };

  getRow.defaultProps = {
    entry: getDefaultEntry(),
  };

  return (
    <SovosFilterDrawerSection
      className={clsx('sovosFilterDateRange', className)}
      data={data}
      data-testid={dataTestId}
      defaultValue={getDefaultEntry()}
      getRow={getRow}
      label={label}
      multiple={multiple}
      onChange={onFilterChange}
      {...rest}
    />
  );
};

SovosFilterDateRange.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * An array of objects specifying selected date ranges
   */
  data: arrayOf(shape(dateRangeShape)),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Message for when the end date is before the start date
   */
  endDateAfterStartDateMessage: string,
  /**
   * Date format string used as a placeholder and validator for the input
   */
  format: string,
  /**
   * Label for `from` field
   */
  fromLabel: string,
  /**
   * Message displayed when the end date is invalid
   */
  invalidEndDateMessage: string,
  /**
   * Message displayed when the start date is invalid
   */
  invalidStartDateMessage: string,
  /**
   * Section label
   */
  label: string.isRequired,
  /**
   * Maximum allowed date
   */
  maxDate: oneOfType([instanceOf(Date), string]),
  /**
   * Minimum allowed date
   */
  minDate: oneOfType([instanceOf(Date), string]),
  /**
   * When true, the user can add an arbitrary number of date ranges
   */
  multiple: bool,
  /**
   * Callback fired when the input value is changed `function(data:
   * object[]) => void`
   */
  onChange: func.isRequired,
  /**
   * Callback fired when input is validated. Argument is true if there is a
   * validation error. `function(error: bool) => void`
   */
  onError: func,
  /**
   * Label for `to` field
   */
  toLabel: string,
};

SovosFilterDateRange.defaultProps = {
  className: undefined,
  data: [],
  'data-testid': undefined,
  endDateAfterStartDateMessage: undefined,
  format: undefined,
  fromLabel: undefined,
  invalidEndDateMessage: undefined,
  invalidStartDateMessage: undefined,
  maxDate: undefined,
  minDate: undefined,
  multiple: false,
  onError: undefined,
  toLabel: undefined,
};

export default SovosFilterDateRange;
