import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Edit } from 'mosaic-react-icons';
import SovosTypography from '../sovos-typography';
import SovosIconButton from '../sovos-icon-button';
import SovosToolbarGroup from '../sovos-toolbar-group';
import ToolbarDataGroupingItem from './components/ToolbarDataGroupingItem';
import ToolbarDataGroupingDrawer from './components/ToolbarDataGroupingDrawer';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledList = styled('ol')(({ theme: { spacing } }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  listStyle: 'none',
  margin: `0 0 0 ${spacing()}`,
  padding: 0,
}));

const StyledTypography = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.secondary,
    padding: `0 ${spacing()}`,
  })
);

// returns true for the first grouping without a value
export const isGroupingActive = (idx, enabledGroupings) => {
  const firstGroupingWithoutValue = enabledGroupings.findIndex((g) => !g.value);

  if (firstGroupingWithoutValue === -1 && idx === enabledGroupings.length) {
    return true;
  }
  if (idx === firstGroupingWithoutValue) {
    return true;
  }
  return false;
};

const renderEmptyGroupings = (label) => (
  <StyledTypography
    className="sovosToolbarDataGrouping__emptyGroupings"
    variant="body1"
  >
    {label}
  </StyledTypography>
);

const renderGroupingItems = (
  enabledGroupings,
  onClearGroupings,
  finalGroupingTitle,
  disabled
) => {
  const isFinalGroupingActive = isGroupingActive(
    enabledGroupings.length,
    enabledGroupings
  );
  let selectable = true;

  return (
    <StyledList className="sovosToolbarDataGrouping__groupings">
      {enabledGroupings.map((g, idx) => {
        const active = isGroupingActive(idx, enabledGroupings);
        // any grouping after and including the active one is not selectable
        if (active) {
          selectable = false;
        }

        return (
          <ToolbarDataGroupingItem
            key={g.id || g.title}
            title={g.title}
            value={g.value}
            active={active}
            disabled={disabled}
            selectable={selectable}
            onClick={selectable ? () => onClearGroupings(idx) : undefined}
            showDivider
          />
        );
      })}

      <ToolbarDataGroupingItem
        title={finalGroupingTitle}
        key="final"
        disabled={disabled && isFinalGroupingActive}
        active={isFinalGroupingActive}
        showDivider={false}
      />
    </StyledList>
  );
};

/**
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarDataGrouping = ({
  availableGroupings,
  'data-testid': dataTestId,
  disabled,
  enabledGroupings,
  finalGroupingTitle,
  isEditable,
  labels,
  onEditClick,
  onUpdateGroupings,
  OrderableListLabelProps,
  ...rest
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { palette } = useTheme();
  const updateGrouping = (newGroupings) => {
    setDrawerOpen(false);
    onUpdateGroupings(newGroupings);
  };
  const { t } = useMosaicTranslation();

  const onClearGroupings = (grouping) => {
    const newGroupings = enabledGroupings.map((g, index) => {
      if (index >= grouping) {
        return { title: g.title };
      }
      return { ...g };
    });
    updateGrouping(newGroupings);
  };

  const handleEditClick = () =>
    onEditClick ? onEditClick() : setDrawerOpen(true);

  return (
    <>
      <SovosToolbarGroup data-testid={dataTestId} {...rest}>
        {enabledGroupings.length
          ? renderGroupingItems(
              enabledGroupings,
              onClearGroupings,
              finalGroupingTitle,
              disabled
            )
          : renderEmptyGroupings(
              labels?.emptyGroupings || t('toolbarDataGrouping.emptyGroupings')
            )}
        {isEditable && (
          <SovosIconButton
            color={disabled ? palette.action.disabled : 'primary'}
            data-testid={disabled ? 'disabled-groupings' : 'edit-groupings'}
            sx={
              disabled ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }
            }
            onClick={handleEditClick}
            tooltipText={
              labels?.editButton || t('toolbarDataGrouping.editButton')
            }
          >
            <Edit />
          </SovosIconButton>
        )}
      </SovosToolbarGroup>
      {isEditable && !onEditClick && (
        <ToolbarDataGroupingDrawer
          enabledGroupings={enabledGroupings}
          availableGroupings={availableGroupings}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onUpdateGroupings={updateGrouping}
          OrderableListLabelProps={{
            addTooltipText:
              OrderableListLabelProps?.addTooltipText ||
              t('toolbarDataGrouping.orderableListAddTooltip'),
            header:
              OrderableListLabelProps?.header ||
              t('toolbarDataGrouping.drawer.help'),
            removeTooltipText:
              OrderableListLabelProps?.removeTooltipText ||
              t('toolbarDataGrouping.orderableListremoveTooltip'),
          }}
        />
      )}
    </>
  );
};

SovosToolbarDataGrouping.propTypes = {
  /**
   * Array of grouping objects with the following properties: { id:
   * oneOfType([string, number]), title: string (*required), value: string }
   *
   * **Required** if `isEditable` is true
   */
  availableGroupings: (props) => {
    const { availableGroupings, isEditable, onEditClick } = props;
    if (onEditClick) return null;
    if (isEditable && !availableGroupings) {
      return new Error(
        "availableGroupings is required. Make sure you're providing an array of objects with { id, title, value } properties"
      );
    }
    return null;
  },
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * If set to true, will disable the component's edit button and all items
   */
  disabled: bool,
  /**
   * Array of enabled grouping objects
   */
  enabledGroupings: arrayOf(
    shape({
      id: oneOfType([string, number]),
      title: string.isRequired,
      value: string,
    })
  ),
  /**
   * If set to false, will not render edit button or drawer
   */
  isEditable: bool,
  /**
   * Title of final grouping
   */
  finalGroupingTitle: string.isRequired,
  /**
   * Labels for empty array of enabled groupings, and edit button tooltip text
   */
  labels: shape({
    emptyGroupings: string,
    editButton: string,
  }),
  /**
   * Callback function that overrides edit button's default function,
   * opening the edit drawer
   */
  onEditClick: func,
  /**
   * Callback function fired onClick of drawer 'Apply' button
   * `function(newGroupings: object[]) => void`
   */
  onUpdateGroupings: func.isRequired,
  /**
   * Labels for OrderableList component
   */
  OrderableListLabelProps: shape({
    addTooltipText: node,
    header: string,
    removeTooltipText: node,
  }),
};

SovosToolbarDataGrouping.defaultProps = {
  availableGroupings: undefined,
  'data-testid': undefined,
  disabled: false,
  enabledGroupings: undefined,
  isEditable: true,
  labels: {
    emptyGroupings: undefined,
    editButton: undefined,
  },
  onEditClick: undefined,
  OrderableListLabelProps: {
    addTooltipText: undefined,
    header: undefined,
    removeTooltipText: undefined,
  },
};

export default SovosToolbarDataGrouping;
