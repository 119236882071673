import React from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  string,
  shape,
  elementType,
} from 'prop-types';
import clsx from 'clsx';
import { DateRangeOutlined } from 'mosaic-react-icons';
import ShowMoreList from '../internals/components/list/ShowMoreList';
import SovosTabContent from '../sovos-tab-content';
import SovosHistoryList from '../sovos-history-list';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

/**
 * **Note**: `SovosHistoryListTab` must be a child of `SovosTabGroup`
 */
const SovosHistoryListTab = ({
  className,
  emptyListLabel,
  selected,
  showLessLabel,
  showMoreLabel,
  sx,
  TabProps: UnusedTabProps,
  ...listProps
}) => {
  const { t } = useMosaicTranslation();
  return (
    <SovosTabContent
      className={clsx('sovosHistoryListTab', className)}
      TabProps={{}}
      selected={selected}
      sx={sx}
    >
      <ShowMoreList
        component={SovosHistoryList}
        showLessLabel={showLessLabel || t('historyTab.showLessLabel')}
        showMoreLabel={showMoreLabel || t('historyTab.showMoreLabel')}
        emptyListLabel={emptyListLabel || t('historyTab.emptyListLabel')}
        {...listProps}
      />
    </SovosTabContent>
  );
};

SovosHistoryListTab.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of event entries to be rendered. Each entry should contain
   * action, timestamp, and optional avatarElement, formattedDate and username.
   */
  data: arrayOf(
    shape({
      avatarElement: node,
      action: node.isRequired,
      formattedDate: string,
      timestamp: number.isRequired,
      username: oneOfType([
        string,
        shape({
          name: string,
          surname: string,
        }),
      ]),
    })
  ).isRequired,
  /**
   * Text displayed when data has no events
   */
  emptyListLabel: string,
  /**
   * If true, this will be the initially displayed tab in the tab group
   */
  selected: bool,
  /**
   * Show Less button text
   */
  showLessLabel: string,
  /**
   * Show More button text
   */
  showMoreLabel: string,
  /**
   * Function used to sort provided data.
   */
  sortFunction: func,
  /**
   * Override or extend the styles applied to the SovosList base component
   */
  SovosListClasses: object,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Props passed to the SovosTab
   */
  TabProps: shape({
    icon: node,
    label: oneOfType([node, elementType]),
  }),
};

const icon = <DateRangeOutlined />;

function TabLabel() {
  const { t } = useMosaicTranslation();
  return t('historyTab.label');
}

SovosHistoryListTab.defaultProps = {
  className: undefined,
  emptyListLabel: undefined,
  selected: false,
  showLessLabel: undefined,
  showMoreLabel: undefined,
  sortFunction: undefined,
  SovosListClasses: undefined,
  sx: undefined,
  TabProps: {
    icon,
    label: TabLabel,
  },
};

export default SovosHistoryListTab;
