export const materialUIColors = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  inherit: 'inherit',
};

export function isKnownMaterialUiColor(color) {
  if (!color) {
    return false;
  }

  return [
    materialUIColors.default,
    materialUIColors.primary,
    materialUIColors.secondary,
    materialUIColors.inherit,
  ].includes(color.toLowerCase());
}
