/* eslint-disable camelcase */
import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { node, object, string } from 'prop-types';
import SovosTypography from '../sovos-typography';

const StyledHeader = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    alignItems: 'center',
    borderBottom: `1px solid ${palette.divider}`,
    boxSizing: 'border-box',
    color: palette.text.primary,
    display: 'flex',
    minHeight: spacing(4),
    lineHeight: 1,
    marginBottom: `-${spacing()}`,
    padding: `${spacing(2)} ${spacing(2)} ${spacing()} ${spacing(2)}`,
  })
);

/**
 * **Note** Must be a child of `SovosCard`
 */
const SovosCardHeader = ({ children, classes, className, ...rest }) => (
  <StyledHeader
    classes={classes}
    className={clsx('sovosCardHeader', className)}
    variant="subtitle1"
    {...rest}
  >
    {children}
  </StyledHeader>
);

SovosCardHeader.propTypes = {
  /**
   * The content of the card header. `children` will be wrapped in a
   * `SovosTypography` component
   */

  children: node,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosCardHeader.defaultProps = {
  children: undefined,
  classes: undefined,
  className: undefined,
};

export default SovosCardHeader;
