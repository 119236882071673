import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  arrayOf,
  bool,
  elementType,
  exact,
  func,
  number,
  shape,
  string,
  object,
  oneOf,
  oneOfType,
} from 'prop-types';
import clsx from 'clsx';
import { alpha, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import assetResolver, { assetKeys } from 'mosaic-assets-resolver';
import Slide from '@mui/material/Slide';
import Modal from '@mui/material/Modal';
import SovosTypography from '../sovos-typography';
import SovosDialog from '../sovos-dialog';
import SovosDialogActions from '../sovos-dialog-actions';
import SovosDialogContent from '../sovos-dialog-content';
import SovosDialogTitle from '../sovos-dialog-title';
import SovosButton from '../sovos-button';
import NavigationLinks from './components/navigation-links/NavigationLinks';
import SovosAccountSwitcher from '../sovos-account-switcher';
import ProductMenu from './components/product-menu/ProductMenu';
import NavigationFooter from './components/navigation-footer/NavigationFooter';
import {
  collapsedWidthFactor,
  navigationWidthFactor,
} from '../themes/sovos-sizes';
import { useResize } from '../hooks';
import { ResizeContext } from '../internals/utils';
import NavigationLinksMinimal from './components/navigation-links/NavigationLinksMinimal';
import HEADER from './constants/header';
import { isLinkSelected } from './components/navigation-links/navigationLinksUtils';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const Root = styled('nav')(
  ({ theme: { overrides, palette, spacing, transitions, typography } }) => {
    const navigationWidth = spacing(navigationWidthFactor);
    const collapsedWidth = spacing(collapsedWidthFactor);
    const collapsedSwitcherWidth = spacing(collapsedWidthFactor - 2);

    return {
      flex: `0 0 ${navigationWidth}`,
      height: '100vh',
      outline: 'none',
      overflowX: 'hidden',
      overflowY: 'auto',
      transition: `flex ${transitions.duration.enteringScreen}ms`,
      width: navigationWidth,
      // parent switches from flex-direction: row to column

      '&.sovosNavigation--minimal': {
        flex: `0 0 ${collapsedWidth}`,
        width: collapsedWidth,
      },

      '& .sovosNavigation__content': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        transition: `width ${transitions.duration.leavingScreen}ms`,
        width: navigationWidth,
      },

      '&.sovosNavigation--minimal .sovosNavigation__content': {
        transition: `width ${transitions.duration.enteringScreen}ms`,
        width: collapsedWidth,
      },

      '&.sovosNavigation--minimal .sovosAccountSwitcher': {
        marginTop: spacing(2),
      },

      '&:not(.sovosNavigation--minimal) .sovosNavigation__productSwitcher': {
        marginRight: spacing(1),
      },

      '& .SovosAccountSwitcher__buttonContents:not(.SovosAccountSwitcher__buttonContents--minimized)':
        {
          borderLeft: 'none',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          marginTop: spacing(2),
          marginRight: spacing(),
        },

      '&.sovosNavigation--minimal .sovosSwitcher__button': {
        margin: `0 ${spacing()}`,
        minWidth: collapsedSwitcherWidth,
        width: collapsedSwitcherWidth,
      },

      '& .sovosNavigation__header': {
        backgroundColor:
          overrides?.sovosNavigation?.headerBackground ?? palette.primary.dark,
        padding: `${spacing(3)} 0 ${spacing(2)} 0`,
      },

      '& .sovosNavigation__logoAndProductContainer': {
        alignItems: 'start',
        display: 'flex',
        justifyContent: 'center',
        minHeight: spacing(5.5),
      },

      '& .sovosNavigation__logoAndProduct': {
        display: 'block',
        flex: `1 0 ${spacing(navigationWidthFactor - 8.5)}`,
        marginLeft: spacing(2.5),
      },

      '&.sovosNavigation--minimal .sovosNavigation__logoAndProduct': {
        display: 'none',
      },

      '& img.sovosNavigation__logo': {
        display: 'block',
        height: spacing(2.75),
      },

      '& .sovosNavigation__productName': {
        ...typography.button,
        color:
          overrides?.sovosNavigation?.productNameColor ??
          alpha(palette.primary.light, 0.75),
        margin: `${spacing(0.25)} ${spacing()} 0 0`,
      },

      '& .sovosNavigation__linksContainer': {
        background: `linear-gradient(${
          overrides?.sovosNavigation?.gradientTop ?? palette.primary.dark
        }, ${
          overrides?.sovosNavigation?.gradientBottom ?? palette.primary.dark
        })`,
        flex: 'auto',
        paddingTop: spacing(),
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    };
  }
);

export const navigationClassName = 'sovosNavigation';

/**
 * In most applications this component will be rendered by
 * `SovosGlobalLayout`. It can be used directly for custom situations that
 * do not use the global layout. The design is responsive with automatic collapsing.
 */
const SovosNavigation = ({
  accounts,
  accountsAreLoading,
  accountSwitcherLabels,
  className,
  'data-testid': dataTestId,
  didAccountChange,
  FooterProps,
  initialSelectedLink,
  links,
  minimumAccountSearchCharacters,
  onAccountSearch,
  onExpandAccount,
  onSelectProduct,
  product,
  products,
  productSwitcherLabel,
  saveNavCollapsed,
  selectedAccount,
  selectedLink: selectedLinkProp,
  setAccount,
  showSingleAccount,
}) => {
  const { t } = useMosaicTranslation();
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(
    !!(saveNavCollapsed && sessionStorage.getItem('navIsCollapsed') === 'true')
  );
  const isBreakpointSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [isModal, setIsModal] = useState(false);

  const isMinimal = !isModal && (isBreakpointSmall || isCollapsed);

  const [linkState, setLinkState] = useState(initialSelectedLink);
  const selectedLink = selectedLinkProp || linkState;
  const [confirmNavigationLinks, setConfirmNavigationLinks] =
    useState(undefined);

  const contentRef = useRef(null);
  const [contentRect] = useResize(contentRef);

  useEffect(() => {
    if (!isBreakpointSmall) {
      setIsModal(false);
    }
  }, [isBreakpointSmall]);

  const handleModalClose = () => {
    if (isModal) setIsModal(false);
  };

  const handleCollapseClick = () => {
    if (isMinimal && isBreakpointSmall) {
      setIsModal(true);
      return;
    }

    if (isModal) {
      setIsModal(false);
      return;
    }

    // collapsed nav session storage
    if (saveNavCollapsed) {
      const navIsCollapsed = sessionStorage.getItem('navIsCollapsed');

      if (navIsCollapsed === 'false') {
        sessionStorage.setItem('navIsCollapsed', 'true');
      } else {
        sessionStorage.setItem('navIsCollapsed', 'false');
      }
    }

    setIsCollapsed((prevState) => !prevState);
  };

  const selectLink = (link) => {
    const { action, id, label } = link;
    action();

    if (!selectedLinkProp) {
      setLinkState(id ?? label);
    }
  };

  const handleSelectLink = (link) => {
    handleModalClose();

    if (!selectedLinkProp && isLinkSelected(link, linkState)) {
      return;
    }

    const currentLink = links.find(
      (l) =>
        isLinkSelected(l, selectedLink) ||
        (l.nestedLinks &&
          l.nestedLinks.find((nl) => isLinkSelected(nl, selectedLink)))
    );

    if (
      currentLink?.confirmNavigationDialogProps &&
      currentLink?.confirmNavigationDialogProps.shouldShowDialog()
    ) {
      setConfirmNavigationLinks([currentLink, link]);
    } else {
      selectLink(link);
    }
  };

  const handleSetAccount = (newContext) => {
    handleModalClose();
    setAccount(newContext);
  };

  const productMenuProps = useMemo(() => {
    const handleSelectProduct = (event) => {
      if (isModal) setIsModal(false);
      if (typeof onSelectProduct === 'function') onSelectProduct(event);
    };

    const calculatedProduct =
      typeof product === 'object' ? product : { id: product, name: product };

    return {
      label: productSwitcherLabel || t('navigation.productSwitcherLabel'),
      selectedProduct: calculatedProduct,
      products:
        Array.isArray(products) && products.length
          ? products
          : [calculatedProduct],
      onSelectProduct: handleSelectProduct,
    };
  }, [isModal, product, products, productSwitcherLabel, t, onSelectProduct]);

  const showContextSwitcher =
    showSingleAccount ||
    (selectedAccount &&
      ((accounts &&
        accounts.length > 0 &&
        (accounts.length > 1 ||
          (accounts[0].nestedAccounts &&
            accounts[0].nestedAccounts.length > 0))) ||
        onAccountSearch));

  const renderConfirmNavigationDialog = () => {
    if (!confirmNavigationLinks) {
      return undefined;
    }

    const [currentLink, targetLink] = confirmNavigationLinks;
    const {
      confirmNavigationDialogProps: {
        cancelButtonText,
        continueButtonText,
        content,
        title,
      },
    } = currentLink;

    return (
      <SovosDialog
        onClose={() => setConfirmNavigationLinks(undefined)}
        open
        width="small"
      >
        <SovosDialogTitle>{title}</SovosDialogTitle>
        <SovosDialogContent>
          {typeof content === 'function' ? content() : content}
        </SovosDialogContent>
        <SovosDialogActions>
          <SovosButton
            onClick={() => setConfirmNavigationLinks(undefined)}
            variant="text"
          >
            {cancelButtonText}
          </SovosButton>
          <SovosButton
            color="error"
            onClick={() => {
              setConfirmNavigationLinks(undefined);
              selectLink(targetLink);
            }}
          >
            {continueButtonText}
          </SovosButton>
        </SovosDialogActions>
      </SovosDialog>
    );
  };

  const Links =
    isMinimal && !isModal ? NavigationLinksMinimal : NavigationLinks;

  function getLogoSrc() {
    const { overrides } = theme;
    // force the logo src to evaluate late so that we can ensure the asset
    // resolver has been configured
    return overrides?.sovosNavigation?.logoUrl
      ? `${overrides?.sovosNavigation?.logoUrl}`
      : `${assetResolver.branding(assetKeys.branding.logoLight)}`;
  }

  const renderNavigation = () => (
    <Root
      aria-label="Main"
      className={clsx(
        'sovosNavigation',
        className,
        navigationClassName,
        isMinimal && `${navigationClassName}--minimal`
      )}
      data-testid={dataTestId}
    >
      <div className="sovosNavigation__content" ref={contentRef}>
        <header className="sovosNavigation__header">
          <div className="sovosNavigation__logoAndProductContainer">
            <div className="sovosNavigation__logoAndProduct">
              <img
                src={getLogoSrc()}
                alt={theme?.overrides?.sovosNavigation?.logoAlt || 'Sovos'}
                className="sovosNavigation__logo"
              />
              <SovosTypography className="sovosNavigation__productName">
                {productMenuProps.selectedProduct.name}
              </SovosTypography>
            </div>
            <ProductMenu {...productMenuProps} />
          </div>
          {showContextSwitcher && (
            <SovosAccountSwitcher
              accounts={accounts}
              didAccountChange={didAccountChange}
              isLoading={accountsAreLoading}
              isMinimal={isMinimal}
              labels={accountSwitcherLabels}
              minimumSearchCharacters={minimumAccountSearchCharacters}
              onExpandAccount={onExpandAccount}
              onSearch={onAccountSearch}
              selectedAccount={selectedAccount}
              setAccount={handleSetAccount}
            />
          )}
        </header>
        <div className="sovosNavigation__linksContainer">
          <Links
            key={selectedLink}
            links={links}
            onLinkClick={handleSelectLink}
            selectedLink={selectedLink}
          />
        </div>
        <NavigationFooter
          handleModalClose={handleModalClose}
          isCollapsed={isMinimal}
          onCollapseClicked={handleCollapseClick}
          {...FooterProps}
        />
      </div>
    </Root>
  );

  return (
    <ResizeContext.Provider value={contentRect}>
      {isModal ? (
        <Modal open={isModal} onClose={handleModalClose}>
          <Slide
            direction="right"
            in={isModal}
            timeout={theme.transitions.duration.enteringScreen}
          >
            {renderNavigation()}
          </Slide>
        </Modal>
      ) : (
        renderNavigation()
      )}
      {renderConfirmNavigationDialog()}
    </ResizeContext.Provider>
  );
};

SovosNavigation.propTypes = {
  /**
   * Array of available context objects
   *
   * **Note**: does not render when length is 0 or 1, as there is nothing
   * to _switch_ to
   */
  accounts: arrayOf(
    shape({
      name: string.isRequired,
      taxYear: string,
      type: oneOf(['production', 'test', 'default']),
      nestedAccounts: arrayOf(object),
    })
  ),
  /**
   * If `true`, `onAccountSearch` is loading results
   */
  accountsAreLoading: bool,
  /**
   * Labels for searching in account switcher
   */
  accountSwitcherLabels: exact({
    loading: string,
    noResults: string,
    searchTemplate: string,
  }),
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Custom context equality check
   */
  didAccountChange: func,
  /**
   * Properties for the sovos navigation footer child component
   */
  FooterProps: shape({
    labels: exact({
      collapseIcon: string,
      helpIcon: string,
      moreIcon: string,
      settingsIcon: string,
      userIcon: string,
    }),
    onHelpClicked: func,
    showSettingsButton: bool,
    showHelpButton: bool,
    settingsMenuItems: arrayOf(
      shape({
        label: string,
        action: func,
      })
    ),
    userName: string.isRequired,
    userMenuItems: arrayOf(
      shape({
        label: string,
        action: func,
      })
    ).isRequired,
  }),
  /**
   * Link selected on load. When specified, the component will manage the
   * link selection state.
   */
  initialSelectedLink: string,
  /**
   * Array of objects, each with:
   *
   * - `confirmationDialogProps`: object with
   *
   *   - `cancelButtonText`: string,
   *   - `continueButtonText`: string,
   *   - `content`: string or func, content of the dialog can be static or
   *       dynamically generated by a function
   *   - `shouldShowDialog`: func, if true, a dialog will be shown allowing the
   *       user to cancel or continue the navigation action
   *   - `title`: string,
   * - `id`: string or number
   * - `label`: string, content of the link
   * - `icon`: element, icon to display
   * - `action`: function, callback fired when the link is clicked
   * - `nestedLinks`: array, nested links
   * - `type`: 'content' or 'header', 'content is the default.
   *
   * `label` is always required. `icon` and either `action` OR
   * `nestedLinks` are required when `type` is not `header`
   *
   * `confirmationDialogProps` controls conditional navigation. The prop
   * object is optional but all values in the object are required. If
   * `confirmationDialogProps` is given and the `shouldShowDialog` function
   * returns true, the user will be given the option to cancel the navigation action
   */
  links: arrayOf(
    shape({
      action: (props) => {
        const { action, nestedLinks, type } = props;
        if (type === HEADER) {
          return null;
        }

        const baseError =
          '`SovosNavigation` received an invalid link: links should have either nestedLinks or an action function, but received';

        if (nestedLinks?.length) {
          if (action != null) {
            return new Error(`${baseError} both.`);
          }
        } else if (typeof action !== 'function') {
          return new Error(`${baseError} neither.`);
        }

        return null;
      },
      confirmNavigationDialogProps: shape({
        cancelButtonText: string.isRequired,
        continueButtonText: string.isRequired,
        content: oneOfType([func, string]).isRequired,
        shouldShowDialog: func.isRequired,
        title: string.isRequired,
      }),
      disabled: bool,
      icon: elementType,
      id: oneOfType([string, number]),
      label: string.isRequired,
      nestedLinks: arrayOf(
        shape({
          action: func.isRequired,
          confirmNavigationDialogProps: shape({
            cancelButtonText: string.isRequired,
            continueButtonText: string.isRequired,
            content: oneOfType([func, string]).isRequired,
            shouldShowDialog: func.isRequired,
            title: string.isRequired,
          }),
          disabled: bool,
          id: oneOfType([string, number]),
          label: string.isRequired,
          toolTip: string,
        })
      ),
      toolTip: string,
      type: oneOf(['content', HEADER]),
    })
  ).isRequired,
  /**
   * Minimum number of characters needed to trigger an asynchronous account search
   */
  minimumAccountSearchCharacters: number,
  /**
   * Callback fired when text is entered into the account search box. Used
   * to asynchronously search accounts. If `onAccountSearch` is provided,
   * `selectedAccount` is required. `function(searchVal: string) => void`
   */
  onAccountSearch: func,
  /**
   * Callback fired when a context is expanded. Used to asynchronously load
   * nested accounts `function(context: object) => void`
   */
  onExpandAccount: func,
  /**
   * Callback fired when product selected from product switcher
   */
  onSelectProduct: func,
  /**
   * Product selected on load
   */
  product: oneOfType([string, object]).isRequired,
  /**
   * Array of objects, each with
   *
   * - `id` string or number. Required if there is any chance the names may
   *   not be unique. Not required for dividers and headers.
   * - `name` string
   * - `disabled` bool
   * - `type` string, one of , 'DIVIDER', or 'HEADER', or undefined
   * - `nestedProducts` array of objects
   */
  products: arrayOf(
    shape({
      id: oneOfType([number, string]),
      name: string,
      disabled: bool,
      type: oneOfType([string, object]),
    })
  ),
  /**
   * Title attribute applied to the product switcher component
   */
  productSwitcherLabel: string,
  /**
   * Store navigation collapsed state in session storage
   */
  saveNavCollapsed: bool,
  /**
   * When missing, invalid, or not in the accounts array, the first context
   * will be selected.
   */
  selectedAccount: shape({
    name: string.isRequired,
    taxYear: string,
    type: oneOf(['production', 'test', 'default']),
  }),
  /**
   * Link defined by the application. When specified, the application must
   * manage the link selection state.
   */
  selectedLink: (props) => {
    const { initialSelectedLink, selectedLink } = props;
    if (selectedLink && typeof selectedLink !== 'string') {
      return new Error('selectedLink must be a string');
    }

    if (initialSelectedLink && selectedLink) {
      return new Error(
        'initialSelectedLink and selectedLink cannot both be defined'
      );
    }
    return null;
  },
  /**
   * Callback fired when a new context is selected
   */
  setAccount: func,
  /**
   * Display account name when there is only one account
   */
  showSingleAccount: bool,
};

SovosNavigation.defaultProps = {
  accounts: undefined,
  accountsAreLoading: false,
  accountSwitcherLabels: undefined,
  className: undefined,
  'data-testid': undefined,
  didAccountChange: undefined,
  FooterProps: null,
  initialSelectedLink: undefined,
  minimumAccountSearchCharacters: undefined,
  onAccountSearch: undefined,
  onExpandAccount: undefined,
  onSelectProduct: undefined,
  products: undefined,
  productSwitcherLabel: undefined,
  saveNavCollapsed: false,
  selectedAccount: undefined,
  selectedLink: undefined,
  setAccount: undefined,
  showSingleAccount: false,
};

export default SovosNavigation;
