import React from 'react';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  SovosGrid,
  SovosPageLayout,
  SovosPageContentGeneric,
  SovosLink,
  SovosTypography,
} from 'mosaic-react';
import pyramid from './assets/pyramid.png';
import packageInfo from '../../package.json';

const Paragraph = styled(SovosTypography)({
  marginBottom: '1em',
});

const AboutPage = () => {
  const { breakpoints, palette, spacing } = useTheme();

  return (
    <SovosPageLayout pageTitle="About" className="aboutPage">
      <SovosPageContentGeneric>
        <SovosGrid
          container
          spacing={3}
          justify="center"
          sx={{
            margin: `0 auto ${spacing(5)} auto`,
            maxWidth: spacing(160),
          }}
        >
          <SovosGrid item sm lg={4} xl={3}>
            <Box sx={{ height: '100%', position: 'relative' }}>
              <Box
                alt="Groovy pyramid"
                component="img"
                src={pyramid}
                sx={{
                  width: spacing(62.5),
                  maxWidth: '100%',
                }}
              />
              <Box
                component="i"
                sx={{
                  borderTop: `1px solid ${palette.text.primary}`,
                  left: '60%',
                  position: 'absolute',
                  right: 10,
                  top: '38%',
                  [breakpoints.down('xs')]: {
                    display: 'none',
                  },
                }}
              />
            </Box>
          </SovosGrid>
          <SovosGrid item sm lg={4} xl={3}>
            <SovosTypography
              sx={{ position: 'relative', top: '21%' }}
              variant="h5"
            >
              <b>Mosaic React:</b> A consistent, modern user interface which
              empowers customers with complete visibility & collaboration
              capabilities
            </SovosTypography>
          </SovosGrid>
        </SovosGrid>

        <div>
          <Paragraph component="p" variant="subtitle2">
            This demo app has been created with the intention of showing current{' '}
            <SovosLink href="http://s1-ui.corp.sovos.local/">
              best practices
            </SovosLink>{' '}
            .
          </Paragraph>
          <Paragraph component="p" variant="subtitle2">
            Reach out to the{' '}
            <SovosLink href="https://mysovos.corp.sovos.local/pages/viewpage.action?pageId=171037892">
              S1 UI Team
            </SovosLink>{' '}
            with any questions.
          </Paragraph>
          <SovosTypography variant="body2" color="text.secondary">
            &copy; Sovos Compliance, {new Date().getFullYear()}
          </SovosTypography>
          <SovosTypography variant="body2" color="text.secondary">
            version: {packageInfo.version}
          </SovosTypography>
        </div>
      </SovosPageContentGeneric>
    </SovosPageLayout>
  );
};

export default AboutPage;
