/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadInvoice } from '../api/invoices';

export const invoiceData = createAsyncThunk('invoice/invoiceData', async (id) =>
  loadInvoice(id)
);

const invoice = createSlice({
  name: 'invoice',

  initialState: {
    isLoading: false,
    current: undefined,
    isDirty: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(invoiceData.pending, (state) => {
        state.isLoading = true;
        state.current = undefined;
      })
      .addCase(invoiceData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.current = action.payload;
      })
      .addCase(invoiceData.rejected, (state) => {
        state.isLoading = false;
        state.current = undefined;
      });
  },
});

export default invoice.reducer;
