/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formatDate, formatDateTime } from './helpers';
import { loadImports } from '../api/imports';

export const importsData = createAsyncThunk(
  'imports/importsData',
  async ({ filters, itemsPerPage, page, sortOrder, sortProp }) => {
    const result = await loadImports(
      page,
      itemsPerPage,
      sortProp,
      sortOrder,
      filters
    );
    return result;
  }
);

function mapImports(record) {
  return {
    ...record,
    formattedTimeStamp: formatDateTime(record.timeStamp),
    formattedReportDate: formatDate(record.reportDate),
  };
}

const importsReducer = createSlice({
  name: 'imports',

  initialState: {
    isLoading: false,
    list: [],
    totalItems: 0,
  },

  extraReducers: (builder) => {
    builder
      .addCase(importsData.pending, (state) => {
        state.isLoading = true;
        state.list = [];
        state.totalItems = 0;
      })
      .addCase(importsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload.imports?.map(mapImports) || [];
        state.totalItems = parseInt(action.payload.totalItems, 10);
      })
      .addCase(importsData.rejected, (state) => {
        state.isLoading = false;
        state.list = [];
        state.totalItems = 0;
      });
  },
});

export default importsReducer.reducer;
