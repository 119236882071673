import { alpha } from '@mui/material/styles';
import customThemeColors from './custom-theme-colors';
import theme from '../../themes';

/**
 * Generate custom theme overrides from custom colors array
 *
 * @param {number} selectedIndex
 * @returns {object} - Overrides object to merge with MUI & Sovos themes
 */
const generateCustomTheme = (
  colorThemes = customThemeColors,
  logoAlt = null,
  logoUrl = null,
  selectedIndex = 0
) => {
  if (selectedIndex === 0) {
    return {
      overrides: {
        sovosNavigation: {
          logoAlt,
          logoUrl,
          ...theme.overrides.sovosNavigation,
        },
      },
    };
  }

  return {
    overrides: {
      sovosNavigation: {
        logoAlt,
        logoUrl,
        highlights: colorThemes[selectedIndex].colors.highlights,
        headerBackground: colorThemes[selectedIndex].colors.background,
        gradientTop: colorThemes[selectedIndex].colors.background,
        gradientBottom: colorThemes[selectedIndex].colors.background,
        footerBackground: colorThemes[selectedIndex].colors.background,
        navItemForeground: colorThemes[selectedIndex].colors.text,
        navItemHover: alpha(colorThemes[selectedIndex].colors.hover, 0.5),
        productNameColor: colorThemes[selectedIndex].colors.highlights,
      },
    },
  };
};

export default generateCustomTheme;
