import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  exact,
  func,
  instanceOf,
  number,
  oneOfType,
  string,
} from 'prop-types';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Popover from '@mui/material/Popover';
import { ArrowDropDown, DateRange } from 'mosaic-react-icons';
import SovosButton from '../sovos-button';
import SovosResizeContainer from '../sovos-resize-container';
import CalendarButton from './components/CalendarButton';
import buttonVariants from './utils/calendarButtonVariants';
import Months from '../internals/utils/months';
import getMonthVariant from './utils/getMonthVariant';
import getRangeString from './utils/getRangeString';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const OpenButton = styled(SovosButton)(
  ({ disabled, theme: { palette, spacing, typography } }) => ({
    backgroundColor: palette.common.white,
    borderRadius: 0,
    height: '100%',
    paddingLeft: spacing(),
    paddingRight: spacing(0.5),
    width: 'fit-content',
    ...typography.body1,
    '&:hover': {
      backgroundColor: palette.action.hover,
    },

    '& .openButton': {
      '&__span': {
        alignItems: 'center',
        display: 'flex',
      },
      '&__icon': {
        color: disabled ? palette.text.disabled : palette.text.primary,
      },
      '&__label': {
        color: disabled ? palette.text.disabled : palette.text.primary,
        paddingLeft: spacing(),
        paddingRight: spacing(),
        textTransform: 'none',
      },
    },
  })
);

const PopOver = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing(2),

  '& .popOver': {
    '&__yearRow': {
      display: 'flex',
      flexDirection: 'row',
      height: spacing(4.25),
      marginBottom: spacing(0.5),
    },
  },
}));

const ButtonRow = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: spacing(2),
  paddingLeft: spacing(2),
  paddingRight: spacing(2),

  '& .buttonRow': {
    '&__clearAndDone': {
      marginLeft: 'auto',
    },
    '&__button': {
      marginRight: spacing(),
    },
  },
}));

const defaultYears = [];
for (let i = 0; i < 3; i += 1) {
  defaultYears.push(new Date().getFullYear() - i);
}

const SovosMonthRangePicker = ({
  'data-testid': dataTestId,
  defaultLabel,
  disabled,
  end: endProp,
  endMonth,
  labels,
  months: deprecatedMonths,
  onChange,
  rangeSelectable,
  start: startProp,
  startMonth,
  years,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const months = deprecatedMonths || labels?.months || t('monthsAbbrev');

  const hasValidValues = startProp instanceof Date && endProp instanceof Date;

  const initialStart = hasValidValues ? startProp : undefined;
  const initialEnd = hasValidValues ? endProp : undefined;

  const openPopover = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setStart(initialStart);
    setEnd(initialEnd);
  };

  const clearDates = () => {
    setStart(null);
    setEnd(null);
  };

  const handlePopoverClose = () => {
    if (start && !end) {
      onChange({ start, end: start });
    } else {
      onChange({ start, end });
    }

    clearDates();
    setAnchorEl(null);
  };

  const handleCancel = () => {
    clearDates();
    setAnchorEl(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.metaKey) {
      handlePopoverClose();
    }
    if (event.key === 'Escape') {
      handleCancel();
    }
  };

  const handleYearSelect = (row, adjustedYears) => {
    const year = adjustedYears[row];
    setStart(new Date(year, 0, 1));
    setEnd(new Date(year, months.length - 1, 1));
  };

  const handleSelectMonth = (row, monthIndex, adjustedYears) => {
    const month = monthIndex;
    const year = adjustedYears[row];
    const date = new Date(year, month, 1);
    if (rangeSelectable) {
      if (!start || (start && end)) {
        setStart(date);
        setEnd(null);
      } else if (start && !end) {
        let minDate;
        let maxDate;

        if (start <= date) {
          minDate = start;
          maxDate = date;
        } else {
          minDate = date;
          maxDate = start;
        }
        setEnd(maxDate);
        setStart(minDate);
      }
    } else {
      setStart(date);
      setEnd(date);
    }
  };

  const isMonthDisabled = (monthIndex, year, adjustedYears) => {
    if (
      year === adjustedYears[adjustedYears.length - 1] &&
      monthIndex < startMonth
    ) {
      return true;
    }
    if (year === adjustedYears[0] && monthIndex > endMonth) {
      return true;
    }
    return null;
  };

  const renderMonthButtons = (month, monthIndex, year, row, adjustedYears) => {
    const btnDate = new Date(year, monthIndex, 1);
    const now = new Date();

    const buttonVariant = getMonthVariant(
      start,
      end,
      now,
      months,
      year,
      month,
      btnDate
    );

    return (
      <CalendarButton
        disabled={isMonthDisabled(monthIndex, year, adjustedYears)}
        variant={buttonVariant}
        date={month[0]}
        ariaLabel={Months.monthsFull[monthIndex]}
        onClick={() => handleSelectMonth(row, monthIndex, adjustedYears)}
        key={new Date(year, monthIndex, 1).toDateString()}
      />
    );
  };

  const renderPopoverContent = () => {
    const sortedYears = years.sort();
    let adjustedYears;
    let highlightYear;
    if (!showMore && sortedYears.length > 2) {
      adjustedYears = sortedYears.slice(
        sortedYears.length - 3,
        sortedYears.length
      );
    } else {
      adjustedYears = [...sortedYears];
    }
    adjustedYears.reverse();
    if (start && end) {
      if (
        start.getMonth() === 0 &&
        end.getMonth() === 11 &&
        start.getFullYear() === end.getFullYear()
      ) {
        highlightYear = start.getFullYear();
      }
    }

    return (
      <PopOver>
        <SovosResizeContainer>
          {adjustedYears.map((year, row) => (
            <div key={year} className="popOver__yearRow">
              <CalendarButton
                variant={
                  highlightYear === year
                    ? buttonVariants.yearSelected
                    : buttonVariants.year
                }
                date={year}
                disabled={!rangeSelectable}
                onClick={() => handleYearSelect(row, adjustedYears)}
              />
              {months.map((month, monthIndex) =>
                renderMonthButtons(month, monthIndex, year, row, adjustedYears)
              )}
            </div>
          ))}
        </SovosResizeContainer>
      </PopOver>
    );
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <OpenButton
        className="openButton"
        data-testid={dataTestId}
        disabled={disabled}
        onClick={openPopover}
        variant="text"
        {...rest}
      >
        <span className="openButton__span">
          <DateRange className="openButton__icon" size="small" />
          <span
            data-testid="sovosMonthRangePicker__Label"
            className="openButton__label"
          >
            {getRangeString(
              initialStart,
              initialEnd,
              months,
              defaultLabel || t('monthRangePicker.default')
            )}
          </span>
          <ArrowDropDown className="openButton__icon" size="small" />
        </span>
      </OpenButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onKeyDown={handleKeyDown}
        onClose={handleCancel}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        className="popOver"
      >
        <div onKeyDown={handleKeyDown}>
          {renderPopoverContent()}
          <ButtonRow className="buttonRow">
            {years.length > 3 && (
              <SovosButton
                onClick={() => setShowMore(!showMore)}
                className={clsx(
                  showMore
                    ? 'sovosMonthRangePicker__ShowLess'
                    : 'sovosMonthRangePicker__ShowMore',
                  'buttonRow__button'
                )}
                variant="text"
              >
                {showMore
                  ? labels?.showLess || t('showLess')
                  : labels?.showMore || t('showMore')}
              </SovosButton>
            )}
            <SovosButton
              onClick={clearDates}
              className="sovosMonthRangePicker__Clear"
              variant="text"
            >
              {labels?.clear || t('clear')}
            </SovosButton>
            <div className="buttonRow__clearAndDone">
              <SovosButton
                onClick={handleCancel}
                className={clsx(
                  'sovosMonthRangePicker__Cancel',
                  'buttonRow__button'
                )}
                variant="text"
              >
                {labels?.cancel || t('cancel')}
              </SovosButton>
              <SovosButton
                onClick={handlePopoverClose}
                className="sovosMonthRangePicker__Done"
              >
                {labels?.done || t('actions.done')}
              </SovosButton>
            </div>
          </ButtonRow>
        </div>
      </Popover>
    </>
  );
};

SovosMonthRangePicker.propTypes = {
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * **Deprecated** Use labels.buttonDefault
   */
  defaultLabel: oneOfType([string.isRequired, number.isRequired]),
  /**
   * If true, the button will be disabled.
   */
  disabled: bool,
  /**
   * Range end value
   */
  end: instanceOf(Date),
  /**
   * Last available month in the end year. 0-based: 2 = March
   */
  endMonth: number,
  /**
   * Internationalization labels. If any of these are passed, the rest are required
   */
  labels: exact({
    buttonDefault: oneOfType([string.isRequired, number.isRequired]),
    showMore: string,
    showLess: string,
    clear: string,
    cancel: string,
    done: string,
    months: arrayOf(string),
  }),
  /**
   * **Deprecated** Use labels.months
   */
  months: arrayOf(string),
  /**
   * Callback fired when the input value is changed `function(value:
   * {start: Date, end: Date}) => void`
   */
  onChange: func.isRequired,
  /**
   * When false, only a single month can be selected, not a range
   */
  rangeSelectable: bool,
  /**
   * Range start value
   */
  start: instanceOf(Date),
  /**
   * First available month in the start year. (**0 based: 11 = December**)
   */
  startMonth: number,
  /**
   * Array of years to show in the picker
   */
  years: arrayOf(number),
};

SovosMonthRangePicker.defaultProps = {
  'data-testid': undefined,
  defaultLabel: undefined,
  disabled: false,
  end: undefined,
  endMonth: 11,
  labels: undefined,
  months: Months.monthsAbbreviated,
  rangeSelectable: true,
  start: undefined,
  startMonth: 0,
  years: defaultYears,
};

export default SovosMonthRangePicker;
