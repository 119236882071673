import { useEffect, useState } from 'react';

export const useResize = (ref) => {
  const [contentRect, setContentRect] = useState({});
  useEffect(() => {
    if (!window.ResizeObserver || !ref?.current) return undefined;

    const ro = new ResizeObserver(([element]) =>
      setContentRect(element.contentRect)
    );

    ro.observe(ref.current);

    return () => {
      ro.disconnect();
    };
  }, [ref]);

  return [contentRect];
};

export default useResize;
