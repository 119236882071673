import React from 'react';
import { styled } from '@mui/material/styles';
import {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import SovosGrid from '../sovos-grid';
import SovosTextField from '../sovos-text-field';
import SovosFilterDrawerSection from '../sovos-filter-drawer-section/SovosFilterDrawerSection';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const filterOutUpDown = (e) => {
  if (['ArrowUp', 'ArrowDown'].includes(e.key)) e.preventDefault();
};

const isNumber = (val) => !Number.isNaN(Number(val));

const checkValue = (newVal, oldVal) =>
  isNumber(newVal) || ['-', '.'].includes(newVal) ? newVal : oldVal;

const checkBlurValue = (e) => (isNumber(e.target.value) ? e.target.value : '');

const StyledGrid = styled(SovosGrid)(
  ({ theme: { palette, spacing, typography } }) => ({
    marginBottom: spacing(),

    '& .sovosFilterNumberRange__error': {
      color: palette.error.main,
      height: spacing(1.5),
      margin: 0,
      transform: `translateY(-${spacing()})`,
      ...typography.body2,
    },
  })
);

const NumberField = styled(SovosTextField)({
  '&.MuiTextField-root': {
    minHeight: 'auto',
  },
});

const numberRangeDefaultEntry = {
  from: oneOfType([string, number]).isRequired,
  to: oneOfType([string, number]).isRequired,
};

/**
 * For use in `SovosFilterDrawer`
 */
const SovosFilterNumberRange = ({
  className,
  data,
  'data-testid': dataTestId,
  fromLabel,
  label,
  onChange,
  onError,
  toLabel,
  multiple,
  startValueLessThanEndValueMessage,
  validate: customValidation,
  validationErrorMessage,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  const getDefaultEntry = () => ({ from: '', to: '' });

  const validationError =
    validationErrorMessage || t('filterNumberRange.validationErrorMessage');

  const getErrors = ({ from, to }) => {
    const errors = {};
    if (customValidation) {
      if (from && !customValidation(from)) {
        errors.from = true;
      }

      if (to && !customValidation(to)) {
        errors.to = true;
      }
    }

    if (!errors.from && !errors.to && from && to && +from >= +to) {
      errors.overlap = true;
    }

    return Object.keys(errors).length ? errors : false;
  };

  const onFilterChange = (value) => {
    const isValid = value.every((row) => !getErrors(row));
    if (onError) {
      onError(!isValid);
    }
    onChange(value);
  };

  const getRow = ({ entry, handleChange }) => {
    const { from, to } = entry;
    const {
      from: hasFromError,
      to: hasToError,
      overlap: hasOverlapError,
    } = getErrors(entry) || {};

    const getValue = (e, oldVal) => {
      const val = e.target.value;
      return customValidation ? val : checkValue(val, oldVal);
    };

    const onFromKeyUp = (e) => {
      filterOutUpDown(e);

      if (e.key === 'ArrowUp') {
        handleChange(e, { from: +from + 1, to });
      }
      if (e.key === 'ArrowDown') {
        handleChange(e, { from: +from - 1, to });
      }
    };

    const onToKeyUp = (e) => {
      filterOutUpDown(e);

      if (e.key === 'ArrowUp') {
        handleChange(e, { from, to: +to + 1 });
      }
      if (e.key === 'ArrowDown') {
        handleChange(e, { from, to: +to - 1 });
      }
    };

    return (
      <StyledGrid container spacing={2}>
        <SovosGrid item sm={6}>
          <NumberField
            size="small"
            error={hasFromError || hasOverlapError}
            helperText={hasFromError && validationError}
            value={from.toString()}
            label={fromLabel || t('filterNumberRange.fromLabel')}
            onKeyDown={onFromKeyUp}
            onChange={(e) => handleChange(e, { from: getValue(e, from), to })}
            onBlur={
              customValidation
                ? undefined
                : (e) => handleChange(e, { from: checkBlurValue(e), to })
            }
          />
        </SovosGrid>
        <SovosGrid item sm={6}>
          <NumberField
            size="small"
            error={hasToError || hasOverlapError}
            helperText={hasToError && validationError}
            value={to.toString()}
            label={toLabel || t('filterNumberRange.toLabel')}
            onKeyDown={onToKeyUp}
            onChange={(e) => handleChange(e, { from, to: getValue(e, to) })}
            onBlur={
              customValidation
                ? undefined
                : (e) => handleChange(e, { from, to: checkBlurValue(e) })
            }
          />
        </SovosGrid>
        {hasOverlapError && (
          <SovosGrid item>
            <p className="sovosFilterNumberRange__error">
              {startValueLessThanEndValueMessage ||
                t('filterNumberRange.startValueLessThanEndValue')}
            </p>
          </SovosGrid>
        )}
      </StyledGrid>
    );
  };

  getRow.propTypes = {
    entry: objectOf(shape(numberRangeDefaultEntry).isRequired),
    handleChange: func.isRequired,
  };

  getRow.defaultProps = {
    entry: getDefaultEntry(),
  };

  return (
    <SovosFilterDrawerSection
      className={clsx('sovosFilterNumberRange', className)}
      data={data}
      data-testid={dataTestId}
      defaultValue={getDefaultEntry()}
      getRow={getRow}
      label={label}
      multiple={multiple}
      onChange={onFilterChange}
      {...rest}
    />
  );
};

SovosFilterNumberRange.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * An array of objects with `from` and `to` properties
   */
  data: arrayOf(shape(numberRangeDefaultEntry)),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Label for `from` field
   */
  fromLabel: string,
  /**
   * Section label
   */
  label: string.isRequired,
  /**
   * When true, the user can add an arbitrary number of ranges
   */
  multiple: bool,
  /**
   * Callback fired when the input value is changed. `function(data:
   * object[]) => void`
   */
  onChange: func.isRequired,
  /**
   * Callback fired when input is validated. Argument is true if there is a
   * validation error. `function(error: boolean) => void`
   */
  onError: func,
  /**
   * Error message for when start value is larger than end value
   */
  startValueLessThanEndValueMessage: string,
  /**
   * Label for `to` field
   */
  toLabel: string,
  /**
   * Custom validation function for number inputs. Should return false when
   * the value is invalid. `function(value: string) => boolean`
   *
   * When missing, the default validation is anything that can be parsed by
   * `Number()`
   */
  validate: func,
  /**
   * Error message to show when custom validation has failed. Has no effect
   * when `validate` is missing
   */
  validationErrorMessage: string,
};

SovosFilterNumberRange.defaultProps = {
  className: undefined,
  data: [],
  'data-testid': undefined,
  fromLabel: undefined,
  multiple: false,
  onError: undefined,
  startValueLessThanEndValueMessage: undefined,
  toLabel: undefined,
  validate: undefined,
  validationErrorMessage: undefined,
};

export default SovosFilterNumberRange;
