/* eslint-disable no-console */
import React, { Fragment, useState, useReducer } from 'react';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import {
  SovosButton,
  SovosCircularProgress,
  SovosEditableSlidingPanel,
  SovosCheckboxField,
  SovosGrid,
  SovosMenuItem,
  SovosTypography,
  SovosTextField,
  SovosDatePicker,
  SovosSelect,
  SovosDivider,
} from 'mosaic-react';
import { useErrorSnackbar, useMount } from 'mosaic-react/hooks';
import Dialog from '../commonComponents/Dialog';
import ControlledField from '../commonComponents/ControlledField';
import Deadline from './components/Deadline';
import Assignee from './components/Assignee';
import { loadFiling as api } from './api/filings';
import { initialState, filingReducer } from './reducers/filing';

const workflow = [
  {
    title: 'Data Gathering',
    offsetKey: 'dataOffset',
    assigneeKey: 'dataAssignee',
  },
  {
    title: 'Reconcile',
    offsetKey: 'reconcileOffset',
    assigneeKey: 'reconcileAssignee',
  },
  { title: 'Review', offsetKey: 'reviewOffset', assigneeKey: 'Assignee' },
  { title: 'Submit', offsetKey: 'submitOffset', assigneeKey: 'submitAssignee' },
  {
    title: 'Payment',
    offsetKey: 'paymentOffset',
    assigneeKey: 'paymentAssignee',
  },
  {
    title: 'Complete',
    offsetKey: 'completeOffset',
    assigneeKey: 'completeAssignee',
  },
];

const FilingField = styled(ControlledField)(({ theme: { spacing } }) => ({
  '&.MuiTextField-root': {
    width: spacing(28),
  },
}));

const FilingPanel = ({ editId, filingPanelOpen, label, closePanel }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const { addError } = useErrorSnackbar();
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = useForm();
  const { spacing } = useTheme();

  const [state, setState] = useReducer(filingReducer, initialState);
  const { current, isLoading } = state;

  useMount(() => {
    if (editId) {
      const fetchFilings = async () => {
        setState({ type: 'LoadFilingBegin' });
        try {
          const data = await api(editId);
          setState({ type: 'LoadFilingSuccess', filing: data });
          // reset();
        } catch (error) {
          addError(error);
          setState({ type: 'LoadFilingFailure' });
        }
      };

      fetchFilings();
    }
  });

  const openDialog = (text) => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  const onSubmit = handleSubmit(() => {
    openDialog('Save not implemented in the demo');
    closePanel();
  });

  const onDelete = () => {
    openDialog('Delete not implemented in the demo');
    closePanel();
  };

  function getAdditionalButtons() {
    if (editId) {
      return [
        <SovosButton key="deleteFiling" onClick={onDelete}>
          Delete
        </SovosButton>,
      ];
    }
    return undefined;
  }

  const defaultDoesNotExpire =
    current && current.doesNotExpire != null ? current.doesNotExpire : true;
  const doesNotExpire = watch('doesNotExpire');

  const defaultFrequency = (current && current.frequency) || 'Monthly';
  const frequency = watch('frequency');

  return (
    <>
      <SovosEditableSlidingPanel
        additionalButtons={getAdditionalButtons()}
        className="FilingPanel__Panel"
        isDirty={() => isDirty}
        onClose={closePanel}
        open={filingPanelOpen}
        SaveButtonProps={{
          onClick: onSubmit,
          disabled: !isDirty,
        }}
        title={label}
      >
        {editId && (isLoading || isEmpty(current)) ? (
          <SovosCircularProgress aria-label="Loading Data" />
        ) : (
          <>
            <SovosGrid container data-testid="filing-base-inputs" spacing={3}>
              <SovosGrid item xs={4}>
                <FilingField
                  control={control}
                  defaultValue={current.company}
                  InputField={SovosTextField}
                  label="Company"
                  name="company"
                />
              </SovosGrid>
              <SovosGrid item xs={8}>
                <FilingField
                  control={control}
                  defaultValue={current.country}
                  InputField={SovosTextField}
                  label="Country"
                  name="country"
                />
              </SovosGrid>
              <SovosGrid item xs={4}>
                <FilingField
                  control={control}
                  defaultValue={
                    current.effectiveDate
                      ? format(parseISO(current.effectiveDate), 'yyyy/MM/dd')
                      : null
                  }
                  format="yyyy/MM/dd"
                  InputField={SovosDatePicker}
                  label="Start Date"
                  mask="____/__/__"
                  name="effectiveDate"
                />
              </SovosGrid>
              <SovosGrid item xs={4}>
                <FilingField
                  control={control}
                  defaultValue={
                    current.expirationDate
                      ? format(parseISO(current.expirationDate), 'yyyy/MM/dd')
                      : null
                  }
                  disabled={
                    doesNotExpire === undefined
                      ? defaultDoesNotExpire
                      : doesNotExpire
                  }
                  format="yyyy/MM/dd"
                  InputField={SovosDatePicker}
                  label="End Date"
                  mask="____/__/__"
                  name="expirationDate"
                />
              </SovosGrid>
              <SovosGrid item xs={4}>
                <ControlledField
                  control={control}
                  defaultValue={defaultDoesNotExpire}
                  InputField={SovosCheckboxField}
                  label="Does not expire"
                  name="doesNotExpire"
                />
              </SovosGrid>
              <SovosGrid item xs={4}>
                <FilingField
                  control={control}
                  defaultValue={defaultFrequency}
                  InputField={SovosSelect}
                  label="Filing Frequency"
                  name="frequency"
                >
                  <SovosMenuItem value="Monthly">Monthly</SovosMenuItem>
                  <SovosMenuItem value="Quarterly">Quarterly</SovosMenuItem>
                  <SovosMenuItem value="Annually">Annually</SovosMenuItem>
                  <SovosMenuItem value="Custom">Custom</SovosMenuItem>
                </FilingField>
              </SovosGrid>
              <SovosGrid item xs={8}>
                <FilingField
                  control={control}
                  defaultValue={current.dueDate || null}
                  disabled={
                    (frequency === undefined ? defaultFrequency : frequency) !==
                    'Custom'
                  }
                  format="yyyy/MM/dd"
                  InputField={SovosDatePicker}
                  label="Due Date"
                  mask="____/__/__"
                  name="dueDate"
                />
              </SovosGrid>
            </SovosGrid>
            <SovosTypography
              variant="subtitle2"
              sx={{
                typography: 'h6',
                marginTop: 5,
                marginBottom: 3,
              }}
            >
              Customize Workflow
            </SovosTypography>
            <SovosGrid container>
              {['Task', 'Deadline', 'Assignee'].map((title) => (
                <SovosGrid key={title} item xs={4}>
                  <SovosTypography variant="body1">{title}</SovosTypography>
                </SovosGrid>
              ))}
              <SovosGrid
                item
                sx={{ marginBottom: spacing(), marginTop: spacing() }}
                xs={12}
              >
                <SovosDivider />
              </SovosGrid>

              {workflow.map((item) => (
                <Fragment key={item.title}>
                  <SovosGrid item xs={4}>
                    {item.title}
                  </SovosGrid>
                  <SovosGrid item xs={4}>
                    <ControlledField
                      control={control}
                      defaultValue={current[item.offsetKey]}
                      InputField={Deadline}
                      name={item.offsetKey}
                    />
                  </SovosGrid>
                  <SovosGrid item xs={4}>
                    <ControlledField
                      control={control}
                      defaultValue={current[item.assigneeKey]}
                      InputField={Assignee}
                      name={item.assigneeKey}
                    />
                  </SovosGrid>
                </Fragment>
              ))}
            </SovosGrid>
          </>
        )}
      </SovosEditableSlidingPanel>
      <Dialog
        text={dialogText}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

FilingPanel.propTypes = {
  filingPanelOpen: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired,
  label: PropTypes.string,
  editId: PropTypes.number,
};

FilingPanel.defaultProps = {
  label: undefined,
  editId: undefined,
};

export default FilingPanel;
