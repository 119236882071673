import React from 'react';
import { string } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosLink from '../../sovos-link/SovosLink';
import SovosTypography from '../../sovos-typography/SovosTypography';

const Root = styled('section')(({ theme: { spacing } }) => ({
  marginBottom: spacing(5),
}));

function ErrorPageLink({ linkText, linkUrl, title }) {
  return (
    <Root>
      <SovosTypography variant="h4">{title}</SovosTypography>

      <SovosTypography>
        <SovosLink href={linkUrl}>{linkText}</SovosLink>
      </SovosTypography>
    </Root>
  );
}

ErrorPageLink.propTypes = {
  linkText: string.isRequired,
  linkUrl: string.isRequired,
  title: string.isRequired,
};

export default ErrorPageLink;
