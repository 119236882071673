import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';

const SovosFormControlLabel = ({ className, ...rest }) => (
  <FormControlLabel
    className={clsx('sovosFormControlLabel', className)}
    {...rest}
  />
);

SovosFormControlLabel.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosFormControlLabel.defaultProps = {
  className: undefined,
};

SovosFormControlLabel.baseComponent = {
  name: 'Form Control Label',
  link: 'form-control-label/',
};

export default SovosFormControlLabel;
