/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadImportsSummary } from '../api/imports';

export const importsSummaryData = createAsyncThunk(
  'importsSummary/importsSummaryData',
  async () => loadImportsSummary()
);

const importsSummary = createSlice({
  name: 'importsSummary',

  initialState: {
    isLoading: false,
    current: {
      batchCount: undefined,
      transactionCount: undefined,
      companyCount: undefined,
      firstUpload: undefined,
      lastUpload: undefined,
      firstReport: undefined,
      lastReport: undefined,
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(importsSummaryData.pending, (state) => {
        state.isLoading = true;
        state.current = undefined;
      })
      .addCase(importsSummaryData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.current = action.payload;
      })
      .addCase(importsSummaryData.rejected, (state) => {
        state.isLoading = false;
        state.current = undefined;
      });
  },
});

export default importsSummary.reducer;
