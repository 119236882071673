import React from 'react';
import { bool, func, node, shape, string } from 'prop-types';
import clsx from 'clsx';
import SovosDialog from '../sovos-dialog/index';
import SovosDialogActions from '../sovos-dialog-actions';
import SovosDialogContent from '../sovos-dialog-content';
import SovosDialogTitle from '../sovos-dialog-title';
import SovosButton from '../sovos-button';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const SovosConfirmationDialog = ({
  ActionButtonProps,
  CancelButtonProps: CancelButtonPropsProp,
  negative,
  className,
  title,
  content,
  ...dialogProps
}) => {
  const { t } = useMosaicTranslation();

  const defaultCancelButtonProps = {
    children: t('cancel'),
    onClick: undefined,
  };

  const CancelButtonProps = {
    ...defaultCancelButtonProps,
    ...CancelButtonPropsProp,
  };

  const cancelButton = (
    <SovosButton
      className="sovosConfirmationDialog__cancelButton"
      data-testid="sovosConfirmationDialog__cancelButton"
      id="cancel-button"
      key="cancel"
      variant={negative ? undefined : 'text'}
      {...CancelButtonProps}
    />
  );

  const actionButton = (
    <SovosButton
      className="sovosConfirmationDialog__actionButton"
      color={negative ? 'error' : 'primary'}
      data-testid="sovosConfirmationDialog__actionButton"
      id="action-button"
      key="action"
      variant={negative ? 'text' : undefined}
      {...ActionButtonProps}
    />
  );

  const buttons = negative
    ? [actionButton, cancelButton]
    : [cancelButton, actionButton];

  return (
    <SovosDialog
      className={clsx('sovosConfirmationDialog', className)}
      {...dialogProps}
      width="small"
    >
      <SovosDialogTitle>{title}</SovosDialogTitle>
      {content ?? <SovosDialogContent>{content}</SovosDialogContent>}
      <SovosDialogActions>{buttons}</SovosDialogActions>
    </SovosDialog>
  );
};

SovosConfirmationDialog.propTypes = {
  /**
   * False if the confirmation dialog represents a "negative" action (like
   * delete), otherwise true. Negative dialogs have different default
   * action button colors.
   */
  negative: bool,
  /**
   * Action button properties
   */
  ActionButtonProps: shape({
    children: node.isRequired,
    onClick: func,
  }).isRequired,
  /**
   * Cancel button properties
   */
  CancelButtonProps: shape({
    children: node,
    onClick: func,
  }),
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * The title of the dialog
   */
  title: node.isRequired,
  /**
   * Additional content for the dialog
   */
  content: node,
};

SovosConfirmationDialog.defaultProps = {
  CancelButtonProps: {
    children: undefined,
    onClick: undefined,
  },
  className: undefined,
  negative: false,
  content: undefined,
};

SovosConfirmationDialog.baseComponent = {
  name: 'SovosDialog',
};

export default SovosConfirmationDialog;
