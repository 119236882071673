import React from 'react';
import { bool, func, object, oneOf, string } from 'prop-types';
import clsx from 'clsx';
import { alpha, styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import SovosButton from '../sovos-button/SovosButton';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const CONFIRMATION = 'confirmation';
const WARNING = 'warning';
const ERROR = 'error';

const SHORT = 'short';
const MEDIUM = 'medium';
const LONG = 'long';

const StyledSnackbar = styled(Snackbar)(
  ({ theme: { palette, spacing, typography } }) => ({
    bottom: spacing(3),
    left: spacing(3),
    right: spacing(3),
    transform: 'none',
    pointerEvents: 'none',

    '& .MuiSnackbarContent-root': {
      boxSizing: 'border-box',
      color: palette.primary.contrastText,
      padding: `${spacing()} ${spacing(2)} 0`,
      minHeight: spacing(6),
      minWidth: spacing(60),
      maxWidth: spacing(85),
      pointerEvents: 'auto',
      ...typography.body1,

      [`&.sovosSnackbar--${CONFIRMATION}`]: {
        backgroundColor: palette.primary.main,
      },
      [`&.sovosSnackbar--${WARNING}`]: {
        color: palette.warning.contrastText,
        backgroundColor: palette.warning.main,
        '& .MuiButton-textPrimary.sovosSnackbar__actionButton': {
          color: palette.text.primary,
          '&:hover': {
            backgroundColor: alpha(
              palette.text.primary,
              palette.action.hoverOpacity
            ),
          },
        },
      },
      [`&.sovosSnackbar--${ERROR}`]: {
        backgroundColor: palette.error.main,
        color: palette.error.contrastText,
      },
    },
    '& .MuiSnackbarContent-message': {
      padding: 0,
      paddingBottom: spacing(),
    },
    '& .MuiSnackbarContent-action': {
      marginRight: 0,
    },
    '& .MuiButton-textPrimary.sovosSnackbar__actionButton': {
      color: palette.primary.contrastText,
      marginBottom: spacing(),
      '&:hover': {
        backgroundColor: alpha(
          palette.common.white,
          palette.action.hoverOpacity
        ),
      },
    },
  })
);

const SovosSnackbar = React.memo(
  ({
    actionLabel,
    classes,
    className,
    defaultErrorActionLabel,
    message,
    onAction,
    onClose,
    open,
    style,
    timeout,
    variant,
    ...rest
  }) => {
    const { t } = useMosaicTranslation();

    const getAutoHideDuration = () => {
      switch (timeout) {
        case SHORT:
          return 4000;
        case LONG:
          return 10000;
        case MEDIUM:
        default:
          return 7000;
      }
    };

    const handleOnAction = () => {
      if (onAction) {
        onAction();
      } else {
        onClose();
      }
    };

    const handleOnClose = (event, reason) => {
      if (reason !== 'clickaway') {
        onClose();
      }
    };

    const getSnackBarAction = () => {
      let label = actionLabel;
      if (!actionLabel && variant === ERROR) {
        label = defaultErrorActionLabel || t('close');
      }

      if (label) {
        return (
          <SovosButton
            disableRipple
            classes={{
              root: classes?.actionButton,
            }}
            className="sovosSnackbar__actionButton"
            onClick={handleOnAction}
            variant="text"
          >
            {label}
          </SovosButton>
        );
      }

      return null;
    };

    return (
      <StyledSnackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={getAutoHideDuration()}
        classes={{
          root: classes?.root,
        }}
        className={clsx('sovosSnackbar', className)}
        onClose={handleOnClose}
        open={open}
        style={style}
        {...rest}
      >
        <SnackbarContent
          className={clsx(
            `sovosSnackbar--${variant.toLowerCase()}`,
            classes && classes[variant.toLowerCase()]
          )}
          classes={{
            root: classes?.content,
            message: classes?.message,
            action: classes?.action,
          }}
          message={message}
          action={getSnackBarAction()}
        />
      </StyledSnackbar>
    );
  }
);

SovosSnackbar.propTypes = {
  /**
   * When present, a text button will be displayed.
   */
  actionLabel: string,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Error variant text button label
   */
  defaultErrorActionLabel: string,
  /**
   * Message displayed in the snackbar
   */
  message: string.isRequired,
  /**
   * Callback fired when action button is clicked
   */
  onAction: func,
  /**
   * Callback fired when the Snackbar is closed
   */
  onClose: func.isRequired,
  /**
   * If true, snackbar is displayed
   */
  open: bool,
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Short, medium, and long durations are 4, 7, and 10 seconds, respectively
   */
  timeout: oneOf([SHORT, MEDIUM, LONG]),
  /**
   * Variant of the Snackbar
   */
  variant: oneOf([CONFIRMATION, WARNING, ERROR]),
};

SovosSnackbar.defaultProps = {
  actionLabel: undefined,
  classes: undefined,
  className: undefined,
  defaultErrorActionLabel: undefined,
  onAction: undefined,
  open: false,
  style: undefined,
  timeout: MEDIUM,
  variant: CONFIRMATION,
};

export default SovosSnackbar;
