import React from 'react';
import { string } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosTypography from '../../sovos-typography';

const Root = styled(SovosTypography)(({ theme: { palette, spacing } }) => ({
  marginBottom: spacing(5),
  color: palette.text.secondary,
}));

const LoginFlowSubtitle = ({ className, label }) => (
  <Root className={className} variant="h6">
    {label}
  </Root>
);

LoginFlowSubtitle.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Label that displays
   */
  label: string.isRequired,
};

LoginFlowSubtitle.defaultProps = {
  className: undefined,
};
export default LoginFlowSubtitle;
