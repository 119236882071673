import React, { useRef, useState } from 'react';
import { arrayOf, bool, exact, func, shape, string } from 'prop-types';
import clsx from 'clsx';
import { ListItemText, Menu } from '@mui/material/';
import {
  AccountCircleOutlined,
  FirstPageOutlined,
  HelpOutline,
  LastPageOutlined,
  MoreVert,
  SettingsOutlined,
} from 'mosaic-react-icons';
import { alpha, styled, useTheme } from '@mui/material/styles';
import SovosIconButton from '../../../sovos-icon-button';
import SovosMenuItem from '../../../sovos-menu-item';
import SovosTypography from '../../../sovos-typography';
import SovosDivider from '../../../sovos-divider';
import { getIconSizes, spacingToNumber } from '../../../internals/utils';
import { collapsedWidthFactor } from '../../../themes/sovos-sizes';

const Root = styled('footer', {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapsed' && prop !== 'navFooterHeight',
})(
  ({
    isCollapsed,
    navFooterHeight,
    theme: { overrides, palette, spacing },
  }) => {
    const { footerBackground = palette.primary.dark } =
      overrides?.sovosNavigation || {};

    return {
      alignItems: 'center',
      backgroundColor: footerBackground,
      display: 'flex',
      flexDirection: isCollapsed ? 'column' : 'row',
      flexWrap: isCollapsed ? 'wrap' : 'nowrap',
      height: isCollapsed ? 'auto' : navFooterHeight,
      justifyContent: isCollapsed ? 'center' : 'flex-end',
      padding: isCollapsed ? spacing() : `0 ${spacing()}`,
    };
  }
);

const StyledIconButton = styled(SovosIconButton, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})(
  ({
    isCollapsed,
    theme: { overrides, palette, shape: themeShape, spacing },
  }) => {
    const { navItemForeground = palette.primary.contrastText } =
      overrides?.sovosNavigation || {};

    return {
      backgroundColor: alpha(palette.primary.light, 0.08),
      borderRadius: themeShape.borderRadius,
      color: navItemForeground,
      margin: `0 ${spacing(0.5)}`,
      '&:first-of-type': {
        margin: `0 ${isCollapsed ? 0 : 'auto'} ${spacing(
          isCollapsed ? 1 : 0
        )} 0`,
      },
      '&:last-of-type': {
        margin: `0 0 0 ${spacing(isCollapsed ? 0 : 0.5)}`,
      },
      '&:hover': {
        backgroundColor: alpha(palette.primary.light, 0.12),
        color: palette.primary.contrastText,
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(palette.primary.light, 0.12),
        color: palette.primary.contrastText,
      },
    };
  }
);

const StyledMenu = styled(Menu)({
  '& .MuiMenu-list': {
    display: 'flex',
    flexDirection: 'column',
  },
});

const StyledDivider = styled(SovosDivider)(
  ({ theme: { palette, spacing } }) => ({
    backgroundColor: palette.divider,
    margin: `${spacing(0.5)} ${spacing(2)}`,
  })
);

const UserMenuItem = styled('li')(({ theme: { spacing, typography } }) => ({
  order: '-1',
  '& .sovosNavigation__userMenuIcon': {
    height: spacing(4),
    marginRight: spacing(0.5),
    width: spacing(4),
  },
  '& .sovosNavigation__userMenuHeader': {
    alignItems: 'center',
    display: 'flex',
    height: spacing(6),
    padding: spacing(2),
  },
  '& .sovosNavigation__userMenuName': {
    fontWeight: typography.fontWeightBold,
    overflowWrap: 'break-word',
  },
}));

const MenuItemPropType = shape({
  label: string,
  action: func,
});

const NavigationFooter = ({
  handleModalClose,
  isCollapsed,
  labels,
  onCollapseClicked,
  onHelpClicked,
  settingsMenuItems,
  showSettingsButton,
  showHelpButton,
  userMenuItems,
  userName,
}) => {
  const { spacing } = useTheme();
  const buttonSizeKey = 'small';

  const buttonSize = () => {
    const sizes = getIconSizes(spacing);
    return sizes[buttonSizeKey].button.height;
  };

  const navFooterHeight = buttonSize() + spacingToNumber(spacing(2));

  const [menuOpen, setMenuOpen] = useState(false);
  const [menu, setMenu] = useState({});

  const moreMenuAnchor = useRef(null);
  const userMenuAnchor = useRef(null);
  const settingsMenuAnchor = useRef(null);

  const hideMenu = () => {
    setMenuOpen(false);
  };

  const handleHelpClicked = () => {
    onHelpClicked();
    handleModalClose();
  };

  const handleMenuClick = (action) => {
    hideMenu();
    handleModalClose();
    action();
  };

  const showMenu = (name, menuItems, anchor) => {
    setMenu({ name, menuItems, anchor });
    setMenuOpen(true);
  };

  const renderUserMenuHeader = () => {
    if (menu.name !== 'user') {
      return null;
    }

    return (
      <UserMenuItem>
        <header className="sovosNavigation__userMenuHeader">
          <AccountCircleOutlined className="sovosNavigation__userMenuIcon" />
          <SovosTypography
            variant="subtitle2"
            component="h4"
            className="sovosNavigation__userMenuName"
          >
            {userName}
          </SovosTypography>
        </header>
        <StyledDivider />
      </UserMenuItem>
    );
  };

  const renderMenuItems = (items) =>
    items?.map((item) => (
      <SovosMenuItem
        className={clsx(
          `sovosNavigationFooter__${menu.name}MenuItem--${item.label}`
        )}
        onClick={() => handleMenuClick(item.action)}
        key={item.label}
      >
        <ListItemText primary={item.label} />
      </SovosMenuItem>
    ));

  const renderMenu = () => {
    const menuTop =
      (navFooterHeight - buttonSize()) / 2 + spacingToNumber(spacing());
    const collapsedMenuX =
      (spacingToNumber(spacing(collapsedWidthFactor)) - buttonSize()) / 2 +
      buttonSize() +
      spacingToNumber(spacing());

    let menuItems;
    if (menu.name === 'more') {
      menuItems = renderMenuItems(userMenuItems);

      if (showSettingsButton && settingsMenuItems && settingsMenuItems.length) {
        menuItems.push(<StyledDivider key="more-divider" />);
        menuItems.push(...renderMenuItems(settingsMenuItems));
      }
    } else {
      menuItems = renderMenuItems(menu.menuItems);
    }

    return (
      <StyledMenu
        anchorEl={menu.anchor?.current}
        anchorOrigin={{
          horizontal: isCollapsed ? collapsedMenuX : 0,
          vertical: isCollapsed ? 'bottom' : -menuTop,
        }}
        className={`sovosNavigationFooter__${menu.name}Menu`}
        marginThreshold={10}
        onClose={hideMenu}
        open={menuOpen}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        {menuItems}
        {/* user menu header is last in source order to allow keyboard tab
        focus on the menu items. It is re-ordered to the top of the list with
        flexbox item 'order' property (classes.userMenuItem) */}
        {renderUserMenuHeader()}
      </StyledMenu>
    );
  };

  return (
    <Root
      className="sovosNavigationFooter"
      isCollapsed={isCollapsed}
      navFooterHeight={navFooterHeight}
    >
      {isCollapsed && (
        <StyledIconButton
          isCollapsed={isCollapsed}
          onClick={() => showMenu('more', [], moreMenuAnchor)}
          ref={moreMenuAnchor}
          size={buttonSizeKey}
          tooltipText={labels.moreIcon}
        >
          <MoreVert />
        </StyledIconButton>
      )}

      <StyledIconButton
        isCollapsed={isCollapsed}
        onClick={onCollapseClicked}
        size={buttonSizeKey}
        tooltipText={isCollapsed ? labels.expandIcon : labels.collapseIcon}
      >
        {isCollapsed ? <LastPageOutlined /> : <FirstPageOutlined />}
      </StyledIconButton>

      {!isCollapsed && (
        <StyledIconButton
          className="sovosNavigationFooter__userButton"
          isCollapsed={isCollapsed}
          onClick={() => showMenu('user', userMenuItems, userMenuAnchor)}
          ref={userMenuAnchor}
          size={buttonSizeKey}
          tooltipText={labels.userIcon}
        >
          <AccountCircleOutlined />
        </StyledIconButton>
      )}

      {!isCollapsed && showSettingsButton && (
        <StyledIconButton
          className="sovosNavigationFooter__settingsButton"
          isCollapsed={isCollapsed}
          onClick={() =>
            showMenu('settings', settingsMenuItems, settingsMenuAnchor)
          }
          ref={settingsMenuAnchor}
          size={buttonSizeKey}
          tooltipText={labels.settingsIcon}
        >
          <SettingsOutlined />
        </StyledIconButton>
      )}

      {!isCollapsed && showHelpButton && (
        <StyledIconButton
          className="sovosNavigationFooter__helpButton"
          isCollapsed={isCollapsed}
          onClick={handleHelpClicked}
          size={buttonSizeKey}
          tooltipText={labels.helpIcon}
        >
          <HelpOutline />
        </StyledIconButton>
      )}

      {renderMenu()}
    </Root>
  );
};

NavigationFooter.propTypes = {
  isCollapsed: bool,
  handleModalClose: func.isRequired,
  labels: exact({
    collapseIcon: string,
    expandIcon: string,
    helpIcon: string,
    moreIcon: string,
    settingsIcon: string,
    userIcon: string,
  }),
  onCollapseClicked: func,
  onHelpClicked: func,
  showSettingsButton: bool,
  showHelpButton: bool,
  settingsMenuItems: arrayOf(MenuItemPropType),
  userName: string.isRequired,
  userMenuItems: arrayOf(MenuItemPropType).isRequired,
};

NavigationFooter.defaultProps = {
  isCollapsed: false,
  labels: {
    collapseIcon: 'Collapse',
    expandIcon: 'Expand',
    helpIcon: 'Help',
    moreIcon: 'More',
    settingsIcon: 'Settings',
    userIcon: 'Your Account',
  },
  onCollapseClicked: () => {},
  onHelpClicked: () => {},
  showSettingsButton: false,
  showHelpButton: false,
  settingsMenuItems: [],
};

export default NavigationFooter;
