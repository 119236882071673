import React, { Children, cloneElement } from 'react';
import { node, string } from 'prop-types';
import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';
import SovosToolbarGroup from '../sovos-toolbar-group';

const StyledToolbarGroup = styled(SovosToolbarGroup)({
  marginLeft: 'auto',
  // should this be in toolbar search?
  '& .sovosToolbarSearch--expanded': {
    marginLeft: -1,
  },
});

/**
 * `SovosToolbarActions` component wraps, and properly positions child
 * components: `SovosToolbarSearch`, `SovosToolbarColumn`,
 * `SovosToolbarMoreMenu` or `SovosToolbarFilter`. **Note**: must be a
 * child of the `SovosToolbar` component
 */
const SovosToolbarActions = ({ children, className, ...props }) => {
  const { palette } = useTheme();

  return (
    <StyledToolbarGroup
      {...props}
      className={clsx('sovosToolbarActions', className)}
    >
      {Children.map(children, (c) => {
        if (!c) {
          return undefined;
        }
        const { color: colorProp } = c.props;

        return cloneElement(c, { color: colorProp || palette.text.secondary });
      })}
    </StyledToolbarGroup>
  );
};

SovosToolbarActions.propTypes = {
  /**
   * The content of the toolbar actions. Should be a collection of
   * `SovosToolbarFilter`, `SovosToolbarColumn`, `SovosToolbarSearch`,
   * `SovosIconMenu`, and `SovosIconButton`
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosToolbarActions.defaultProps = {
  className: undefined,
};

export default SovosToolbarActions;
