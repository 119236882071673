import React, { useMemo, useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  object,
  oneOf,
  string,
} from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import {
  DateRange as Calendar,
  KeyboardArrowLeft as Left,
  KeyboardArrowRight as Right,
} from 'mosaic-react-icons';
import SovosMenuItem from '../sovos-menu-item';
import SovosButton from '../sovos-button';
import SovosIconButton from '../sovos-icon-button/SovosIconButton';
import SovosTooltip from '../sovos-tooltip';
import { months as Months } from '../internals/utils';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const doubleClickMilliseconds = 200;

const Root = styled('div')({
  height: '100%',
  width: 'fit-content',
});

const Widget = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
});

const SelectionButton = styled(SovosButton)(
  ({ theme: { palette, spacing, typography } }) => ({
    ...typography.body1,
    borderRadius: 0,
    height: '100%',
    justifyContent: 'flex-start',
    minWidth: spacing(15),
    padding: `0 ${spacing()}`,

    '&.MuiButton-textPrimary': {
      color: palette.text.primary,
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
      '&:disabled': {
        color: palette.action.disabled,
      },
    },
  })
);

const CalendarIcon = styled(Calendar)(({ theme: { spacing } }) => ({
  paddingRight: spacing(),
  height: spacing(3),
  width: spacing(4),
}));

const DropdownContents = styled('div')(
  ({ theme: { palette, spacing, typography } }) => {
    const selectedStyle = {
      backgroundColor: palette.text.primary,
      color: palette.primary.contrastText,
    };
    return {
      display: 'flex',
      width: 200,
      padding: spacing(),

      '& .sovosMonthSelector__months': {
        paddingRight: spacing(),
        flex: 1,
      },

      '& .sovosMonthSelector__years': {
        borderLeft: `1px solid ${palette.divider}`,
        paddingLeft: spacing(),
        flex: 1,
      },

      '& .sovosMonthSelector__item': {
        ...typography.body1,
        lineHeight: spacing(4),
        height: spacing(4),
        padding: [[0, spacing()]],
        minHeight: spacing(4),
        zIndex: 999,
      },

      '& .sovosMonthSelector__item--selected': {
        '&.MuiButtonBase-root': {
          ...selectedStyle,
          '&:hover': selectedStyle,
        },
      },
    };
  }
);

const SovosMonthSelector = ({
  className,
  'data-testid': dataTestId,
  disabled,
  format,
  minDate,
  maxDate,
  monthsAbbreviated,
  monthsFull,
  nextMonthTooltipText,
  onChange,
  onToggle,
  previousMonthTooltipText,
  selectedMonth: initialMonth,
  selectedYear: initialYear,
  specificMonthTooltipText,
  style,
  thisMonthLabel,
  yearSort,
  ...rest
}) => {
  const [month, year] = useMemo(() => {
    const minYear = minDate.getFullYear();
    const minMonth = minDate.getMonth();
    const maxYear = maxDate.getFullYear();
    const maxMonth = maxDate.getMonth();
    let yr = initialYear;
    let mth = initialMonth;

    if (yr < minYear) {
      yr = minYear;
      mth = minMonth;
    } else if (yr === minYear && mth < minMonth) {
      mth = minMonth;
    } else if (yr > maxYear) {
      yr = maxYear;
      mth = maxMonth;
    } else if (yr === maxYear && mth > maxMonth) {
      mth = maxMonth;
    }

    return [mth, yr];
  }, [initialMonth, initialYear, minDate, maxDate]);

  const { t } = useMosaicTranslation();
  const [anchorElement, setAnchorElement] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const [selectedYear, setSelectedYear] = useState(year);

  const getOutput = (newMonth, newYear) => {
    switch (format) {
      case 'month-name':
        return {
          month: monthsFull[newMonth],
          year: newYear,
        };
      case 'number':
        return {
          month: newMonth,
          year: newYear,
        };
      default:
        throw new Error(
          `Unrecognized format '${format}' in SovosMonthSelector.`
        );
    }
  };

  const closeMenu = () => {
    setShowMenu(false);
    onToggle(false);
  };

  const openMenu = (event) => {
    setShowMenu(true);
    setAnchorElement(event.currentTarget);
    onToggle(true);
  };

  const canIncreaseMonth = () => {
    if (selectedYear !== maxDate.getFullYear()) {
      return true;
    }
    return selectedMonth < maxDate.getMonth();
  };

  const canDecreaseMonth = () => {
    if (selectedYear !== minDate.getFullYear()) {
      return true;
    }
    return selectedMonth > minDate.getMonth();
  };

  const updateMonthYear = ({ newMonth, newYear }) => {
    if (onChange) {
      onChange(getOutput(newMonth, newYear));
    }
    closeMenu();
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };

  const nextMonth = debounce(() => {
    if (!canIncreaseMonth()) {
      return;
    }
    const newState = { newYear: selectedYear, newMonth: selectedMonth + 1 };

    if (newState.newMonth > 11) {
      newState.newYear = selectedYear + 1;
      newState.newMonth = 0;
    }
    updateMonthYear(newState);
  }, doubleClickMilliseconds);

  const prevMonth = debounce(() => {
    if (!canDecreaseMonth()) {
      return;
    }
    const newState = { newYear: selectedYear, newMonth: selectedMonth - 1 };

    if (newState.newMonth < 0) {
      newState.newYear = selectedYear - 1;
      newState.newMonth = 11;
    }
    updateMonthYear(newState);
  }, doubleClickMilliseconds);

  const selectYear = (newYear) => {
    const newState = { newYear, newMonth: selectedMonth };

    if (newYear === minDate.getFullYear()) {
      newState.newMonth = Math.max(minDate.getMonth(), selectedMonth);
    }

    if (newYear === maxDate.getFullYear()) {
      newState.newMonth = Math.min(maxDate.getMonth(), selectedMonth);
    }

    updateMonthYear(newState);
  };

  const selectMonth = (newMonth) => {
    updateMonthYear({ newYear: selectedYear, newMonth });
  };

  const renderMonths = () =>
    monthsAbbreviated.map((item, index) => {
      const disabledDate =
        (selectedYear === minDate.getFullYear() &&
          index < minDate.getMonth()) ||
        (selectedYear === maxDate.getFullYear() && index > maxDate.getMonth());
      const isSelected = index === selectedMonth;

      return (
        <SovosMenuItem
          className={clsx(
            `sovosMonthSelector__month--${item}`,
            'sovosMonthSelector__item',
            isSelected && 'sovosMonthSelector__item--selected'
          )}
          key={`month-${item}`}
          disabled={disabledDate}
          onClick={disabledDate ? null : () => selectMonth(index)}
        >
          {item}
        </SovosMenuItem>
      );
    });

  const renderYears = () => {
    const years = [];
    for (let y = minDate.getFullYear(); y <= maxDate.getFullYear(); y += 1) {
      years.push(y);
    }

    if (yearSort === 'desc') {
      years.reverse();
    }

    return years.map((item) => {
      const isSelected = item === selectedYear;
      return (
        <SovosMenuItem
          className={clsx(
            `sovosMonthSelector__year--${item}`,
            'sovosMonthSelector__item',
            isSelected && 'sovosMonthSelector__item--selected'
          )}
          key={`year-${item}`}
          onClick={() => selectYear(item)}
        >
          {item}
        </SovosMenuItem>
      );
    });
  };

  const renderDropdownMenu = () => (
    <Popover
      open={showMenu}
      anchorEl={anchorElement}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      onClose={closeMenu}
      className="sovosMonthSelector__popover"
    >
      <DropdownContents>
        <div className="sovosMonthSelector__months">{renderMonths()}</div>

        <div className="sovosMonthSelector__years">{renderYears()}</div>
      </DropdownContents>
    </Popover>
  );

  const renderWidget = () => {
    const today = new Date();
    const label =
      today.getMonth() === selectedMonth && today.getFullYear() === selectedYear
        ? thisMonthLabel || t('monthSelector.thisMonth')
        : `${monthsAbbreviated[selectedMonth]} ${selectedYear}`;

    return (
      <Widget>
        <SovosTooltip
          title={
            specificMonthTooltipText || t('monthSelector.specificMonthTooltip')
          }
        >
          <SelectionButton
            disabled={disabled}
            className="sovosMonthSelector__selectButton"
            onClick={openMenu}
            variant="text"
          >
            <CalendarIcon />
            {label}
          </SelectionButton>
        </SovosTooltip>

        <SovosIconButton
          className="sovosMonthSelector__previousButton"
          onClick={prevMonth}
          tooltipText={
            previousMonthTooltipText || t('monthSelector.previousMonthTooltip')
          }
          disabled={!canDecreaseMonth() || disabled}
          size="small"
        >
          <Left />
        </SovosIconButton>

        <SovosIconButton
          className="sovosMonthSelector__nextButton"
          onClick={nextMonth}
          tooltipText={
            nextMonthTooltipText || t('monthSelector.nextMonthTooltip')
          }
          disabled={!canIncreaseMonth() || disabled}
          size="small"
        >
          <Right />
        </SovosIconButton>
      </Widget>
    );
  };

  return (
    <Root
      className={clsx('sovosMonthSelector', className)}
      data-testid={dataTestId}
      style={style}
      {...rest}
    >
      {renderWidget()}
      {renderDropdownMenu()}
    </Root>
  );
};

SovosMonthSelector.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * If `true`, the button is disabled
   */
  disabled: bool,
  /**
   * Formats the date
   */
  format: oneOf(['number', 'month-name']),

  /**
   * The minimum allowed date
   */
  minDate: instanceOf(Date),

  /**
   * The maximun allowed date
   */
  maxDate: instanceOf(Date),
  /**
   * Abbreviated months for the months menu
   */
  monthsAbbreviated: arrayOf(string),

  /**
   * Array of the months belonging to the selected year
   */
  monthsFull: arrayOf(string),

  /**
   * Next month tooltip text
   */
  nextMonthTooltipText: string,
  /**
   * Callback fired when the input value is changed `function(value:
   * {month: number|string, year: number}) => void`
   */
  onChange: func,

  /**
   * Callback fired when the menu is open or closed `function(open: bool) => void`
   */
  onToggle: func,

  /**
   * Month picked
   */
  selectedMonth: number,

  /**
   * Year picked
   */
  selectedYear: number,
  /**
   * Inline styles applied to the root element
   */
  style: object,

  /**
   * Selected month tooltip text
   */
  specificMonthTooltipText: string,

  /**
   * Previous month tooltip text
   */
  previousMonthTooltipText: string,

  /**
   * Current Month
   */
  thisMonthLabel: string,
  /**
   * Sort direction for years, ascending or descending
   */
  yearSort: oneOf(['asc', 'desc']),
};

SovosMonthSelector.defaultProps = {
  'data-testid': undefined,
  disabled: false,
  onToggle: () => {},
  onChange: () => {},
  minDate: new Date(new Date().getFullYear(), 0),
  maxDate: new Date(new Date().getFullYear(), 11),
  selectedYear: new Date().getFullYear(),
  selectedMonth: new Date().getMonth(),
  format: 'number',
  className: undefined,
  style: undefined,
  monthsAbbreviated: Months.monthsAbbreviated,
  monthsFull: Months.monthsFull,
  specificMonthTooltipText: undefined,
  previousMonthTooltipText: undefined,
  nextMonthTooltipText: undefined,
  thisMonthLabel: undefined,
  yearSort: 'asc',
};

export default SovosMonthSelector;
