import React, { useState, useEffect, useReducer } from 'react';
import { func } from 'prop-types';
import { SovosPageContentTable } from 'mosaic-react';
import { useErrorSnackbar } from 'mosaic-react/hooks';
import { format, parseISO } from 'date-fns';
import ExpirationDate from './components/ExpirationDate';
import { loadFilings as api } from './api/filings';
import { initialState, filingsReducer } from './reducers/filings';

const firstPage = 1;

const columns = [
  {
    title: 'Country',
    id: 1,
    width: 160,
    dataKey: 'country',
  },
  {
    title: 'Return',
    id: 2,
    width: 'greedy',
    dataKey: 'return',
  },
  {
    title: 'Company',
    id: 3,
    width: 50,
    dataKey: 'company',
  },
  {
    title: 'Frequency',
    id: 4,
    width: 80,
    dataKey: 'frequency',
  },
  {
    title: 'Effective',
    id: 5,
    width: 80,
    component: ({ content }) =>
      format(parseISO(content.effectiveDate), 'yyyy/MM/dd'),
  },
  {
    title: 'Expires',
    id: 6,
    width: 50,
    // eslint-disable-next-line react/prop-types
    component: ({ content }) => <ExpirationDate content={content} />,
  },
];

function FilingsTable({ onEdit }) {
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [sortedId, setSortedId] = useState(1);
  const [sortColumn, setSortColumn] = useState('country');
  const [ascending, setAscending] = useState(true);
  const { addError } = useErrorSnackbar();

  const [state, setState] = useReducer(filingsReducer, initialState);
  const { isLoading, list, totalItems } = state;

  useEffect(() => {
    const fetchData = async () => {
      setState({ type: 'LoadFilingsBegin' });
      try {
        const data = await api(
          currentPage,
          itemsPerPage,
          sortColumn,
          ascending ? 'asc' : 'desc',
          addError
        );
        setState({
          type: 'LoadFilingsSuccess',
          filings: data.filings,
          totalItems: data.totalItems,
        });
      } catch (error) {
        addError(error);
        setState({ type: 'LoadFilingsFailure' });
      }
    };
    fetchData();
  }, [setState, currentPage, itemsPerPage, sortColumn, ascending, addError]);

  function sort(column) {
    const newAscending = sortedId === column.id ? !ascending : true;
    let newSortColumn;
    switch (column.id) {
      case 1:
        newSortColumn = 'country';
        break;
      case 2:
        newSortColumn = 'return';
        break;
      case 3:
        newSortColumn = 'company';
        break;
      case 4:
        newSortColumn = 'frequency';
        break;
      case 5:
        newSortColumn = 'effectiveDate';
        break;
      case 6:
        newSortColumn = 'expires';
        break;
      default:
        newSortColumn = 'country';
    }
    setAscending(newAscending);
    setSortColumn(newSortColumn);
    setSortedId(column.id);
    setCurrentPage(firstPage);
  }

  function tableRowClicked(dataRow) {
    onEdit(dataRow.id);
  }

  const onPageChange = (newPage, newItemsPerPage) => {
    setCurrentPage(newPage);
    setItemsPerPage(newItemsPerPage);
  };

  return (
    <SovosPageContentTable
      isLoading={isLoading}
      PaginationFooterProps={{
        itemsPerPage,
        currentPage,
        totalItems,
        onPageChange,
      }}
      className="FilingsTable__FilingsTable"
      TableProps={{
        columns,
        data: list,
        fixedHeader: true,
        onColumnSort: sort,
        onRowClick: tableRowClicked,
        columnSortId: sortedId,
      }}
    />
  );
}

FilingsTable.propTypes = {
  onEdit: func.isRequired,
};

export default FilingsTable;
