import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTabs from '../sovos-tabs';
import SovosTabContent from '../sovos-tab-content/SovosTabContent';
import { findChildrenByType } from '../internals/utils/reactHelpers';
import SovosResizeContainer from '../sovos-resize-container';
import SovosAttachmentsListTab from '../sovos-attachments-list-tab';
import SovosHistoryListTab from '../sovos-history-list-tab';
import SovosCommentsListTab from '../sovos-comments-list-tab';
import SovosTab from '../sovos-tab';

const StyledResizeContainer = styled(SovosResizeContainer)(
  ({ theme: { palette } }) => ({
    backgroundColor: palette.background.default,
  })
);

const StyledTabs = styled(SovosTabs)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
}));

/**
 * `SovosTabGroup` supports the following components as children:
 * `SovosAttachmentsListTab`, `SovosCommentsListTab`,
 * `SovosHistoryListTab`, and `SovosTabContent`. It will render a tab group
 * and manage the currently selected tabs.
 *
 * For custom tab applications, `SovosTab` and `SovosTabs` should be used.
 */
const SovosTabGroup = ({ children, className, style, ...rest }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (event, value) => {
    setSelectedTab(value);
  };

  const tabChildren = findChildrenByType(
    children,
    SovosTabContent,
    SovosAttachmentsListTab,
    SovosHistoryListTab,
    SovosCommentsListTab
  );

  const tabContent = [];
  const tabs = [];

  if (tabChildren.length) {
    tabChildren.forEach((t, index) => {
      const { label: labelProp, ...iconProps } = t.props.TabProps;

      const label = typeof labelProp === 'function' ? labelProp() : labelProp;
      const key = typeof labelProp === 'string' && label ? label : index;

      tabContent.push(
        React.cloneElement(t, {
          key,
          selected: index === selectedTab,
        })
      );

      tabs.push(
        <SovosTab label={label} value={index} key={key} {...iconProps} />
      );
    });
  }

  return (
    <StyledResizeContainer
      className={clsx('sovosTabGroup', className)}
      style={style}
      {...rest}
    >
      <StyledTabs value={selectedTab} onChange={handleTabClick}>
        {tabs}
      </StyledTabs>
      {tabContent}
    </StyledResizeContainer>
  );
};

SovosTabGroup.propTypes = {
  /**
   * The content of the tab group. Each child represents the content of 1
   * tab. Should be `SovosAttachmentsListTab`, `SovosCommentsListTab`,
   * `SovosHistoryListTab`, and `SovosTabContent`
   */
  children: PropTypes.node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: PropTypes.string,
  /**
   * Inline styles applied to the root element
   */
  style: PropTypes.object,
};

SovosTabGroup.defaultProps = {
  className: undefined,
  style: undefined,
};

export default SovosTabGroup;
