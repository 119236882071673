import React from 'react';
import { string, oneOfType, node } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';
import SovosToolbarGroup from '../sovos-toolbar-group';
import SovosFixedWidthText from '../sovos-fixed-width-text';

const StyledToolbarGroup = styled(SovosToolbarGroup)({
  flex: '1 1 auto',
  minWidth: 0,
});

const StyledTypography = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.primary,
    lineHeight: 'auto',
    paddingRight: spacing(2),
    position: 'relative',
  })
);

/**
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarTitle = ({ children, className, ...rest }) => (
  <StyledToolbarGroup
    className={clsx('sovosToolbarTitle', className)}
    {...rest}
  >
    <StyledTypography component="span" variant="subtitle1" noWrap>
      {typeof children === 'string' ? (
        <SovosFixedWidthText text={children} />
      ) : (
        children
      )}
    </StyledTypography>
  </StyledToolbarGroup>
);

SovosToolbarTitle.propTypes = {
  /**
   * The content of the title. If a string, will show a tooltip on overflow.
   */
  children: oneOfType([string, node]).isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosToolbarTitle.defaultProps = {
  className: undefined,
};

export default SovosToolbarTitle;
