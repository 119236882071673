const tableRoot = 'sovosTable';

const generateTableElementClassName = (element, modifier) =>
  `${tableRoot}__${element}${modifier ? `--${modifier}` : ''}`;

const tableClassNames = {
  tableRoot,

  baseCell: generateTableElementClassName('cell'),
  selectCell: generateTableElementClassName('cell', 'select'),
  rowActionsCell: generateTableElementClassName('cell', 'rowActions'),
  rightmostFixedColumnCell: generateTableElementClassName(
    'cell',
    'rightmostFixed'
  ),
  fixedElevationCell: generateTableElementClassName('cell', 'fixedElevation'),
  extraElevationCell: generateTableElementClassName('cell', 'extraElevation'),

  headingCell: generateTableElementClassName('headingCell'),
  financialHeadingCell: generateTableElementClassName(
    'headingCell',
    'financial'
  ),
  firstStickyFixedHeadingCell: generateTableElementClassName(
    'headingCell',
    'firstSticky'
  ),
  fixedHeadingCell: generateTableElementClassName('headingCell', 'fixed'),
  sortableHeadingCell: generateTableElementClassName('headingCell', 'sortable'),
  sortedHeadingCell: generateTableElementClassName('headingCell', 'sorted'),
  hiddenColumnTitle: generateTableElementClassName('columnTitle', 'hidden'),

  totalsCell: generateTableElementClassName('tableCell'),
  fixedTotalsCell: generateTableElementClassName('tableCell', 'fixed'),

  bodyCell: generateTableElementClassName('bodyCell'),
  fixedBodyCell: generateTableElementClassName('bodyCell', 'fixed'),

  row: generateTableElementClassName('row'),
  rowHovered: generateTableElementClassName('row', 'hovered'),
  rowSelected: generateTableElementClassName('row', 'selected'),
  rowHoverSelected: generateTableElementClassName('row', 'hover-selected'),
  rowClickable: generateTableElementClassName('row', 'clickable'),
  rowDisabled: generateTableElementClassName('row', 'disabled'),

  rowActionButton: generateTableElementClassName('rowActionButton'),
  rowActionMenuItem: generateTableElementClassName('rowActionMenuItem'),
};
export default tableClassNames;
