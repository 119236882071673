/* eslint-disable react/no-array-index-key */
import React from 'react';
import { array, bool, func, object, oneOfType, string } from 'prop-types';
import clsx from 'clsx';
import { Add, Clear } from 'mosaic-react-icons';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';
import SovosIconButton from '../sovos-icon-button/SovosIconButton';
import SovosResizeContainer from '../sovos-resize-container';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const Root = styled('div')(({ theme: { palette, spacing } }) => ({
  borderBottom: `1px solid ${palette.divider}`,
  boxSizing: 'border-box',
  padding: `${spacing(2)} 0`,
  width: '100%',
  '&:first-of-type': {
    paddingTop: 0,
  },
  '&:last-of-type': {
    borderBottom: 'none',
    paddingBottom: 0,
  },

  '& .sovosFilterDrawerSection': {
    '&__heading': {
      alignItems: 'center',
      height: spacing(4),
      lineHeight: spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(),
    },
    '&__row': {
      whiteSpace: 'nowrap',
      width: '100%',
      display: 'flex',
      alignItems: 'center',

      '&__clearButton': {
        marginLeft: spacing(0.5),
      },
    },
  },
}));

const SovosFilterDrawerSection = ({
  addButtonTooltipText,
  className,
  data,
  'data-testid': dataTestId,
  dataValueName,
  defaultValue,
  getRow,
  label,
  multiple,
  onBlur,
  onChange,
  onRemove,
  removeButtonTooltipText,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  const getNewEntry = (entry, newValue) =>
    dataValueName ? { ...entry, [dataValueName]: newValue } : newValue;

  const handleOnChange = (newData) => {
    if (onChange) {
      onChange(newData);
    }
  };

  const handleChange = (index) => (event, newValue) => {
    const newData = data.map((entry, i) =>
      i === index
        ? getNewEntry(entry, newValue == null ? event.target.value : newValue)
        : entry
    );
    handleOnChange(newData);
  };

  const handleRemove = (index) => () => {
    const newData = data.filter((entry, i) => i !== index);
    if (onRemove) onRemove(newData);
    handleOnChange(newData);
  };

  const handleBlur = () => {
    const newData = data.filter((entry) => entry != null);
    if (onBlur) onBlur(newData);
    handleOnChange(newData);
  };

  const handleAdd = () => {
    handleOnChange([...data, defaultValue]);
  };

  const getRows = () =>
    data.map((entry, index) => (
      <div key={index} className="sovosFilterDrawerSection__row">
        {getRow({
          index,
          entry,
          handleChange: handleChange(index),
          handleBlur,
        })}
        {multiple && (
          <SovosIconButton
            className="sovosFilterDrawerSection__row__clearButton"
            data-testid="sovosFilterDrawerSection__clearButton"
            onClick={handleRemove(index)}
            size="small"
            tooltipText={removeButtonTooltipText || t('actions.remove')}
          >
            <Clear />
          </SovosIconButton>
        )}
      </div>
    ));

  const addable = multiple || data.length < 1;
  const onClick = addable ? handleAdd : undefined;

  return (
    <Root
      className={clsx('sovosFilterDrawerSection', className)}
      data-testid={dataTestId}
      {...rest}
    >
      <header className={clsx('sovosFilterDrawerSection__heading')}>
        <SovosTypography component="h6" variant="body1">
          {label}
        </SovosTypography>
        {addable && (
          <SovosIconButton
            className="sovosFilterDrawerSection__addButton"
            data-testid="sovosFilterDrawerSection__addButton"
            onClick={onClick}
            size="small"
            tooltipText={addButtonTooltipText || t('add')}
          >
            <Add />
          </SovosIconButton>
        )}
      </header>
      <SovosResizeContainer className="resizeContainer">
        {getRows()}
      </SovosResizeContainer>
    </Root>
  );
};

SovosFilterDrawerSection.propTypes = {
  /**
   * Tooltip for the add button
   */
  addButtonTooltipText: string,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of objects, that are used to generate rows. If there is onChange
   * function, it'll be called when specific row is changed
   */
  data: array,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Name of the changed value in row in data structure. Do not define it
   * if data is only list of values
   */
  dataValueName: string,
  /**
   * The default value for the section
   */
  defaultValue: oneOfType([string, object]),
  /**
   * Function that return row.
   */
  getRow: func.isRequired,
  /**
   * Section label
   */
  label: string.isRequired,
  /**
   * Callback function called on any filter blur. Return changed data structure.
   */
  onBlur: func,
  /**
   * Callback fired when the input value is changed `function(data:
   * object[]) => void`
   */
  onChange: func,
  /**
   * Callback fired when an item is removed. `function(data: object[]) => void`
   */
  onRemove: func,
  /**
   * When true, the user can add an arbitrary number of values
   */
  multiple: bool,
  /**
   * Tooltip for the remove button
   */
  removeButtonTooltipText: string,
};

SovosFilterDrawerSection.defaultProps = {
  addButtonTooltipText: undefined,
  className: '',
  data: [],
  'data-testid': undefined,
  dataValueName: undefined,
  defaultValue: null,
  multiple: false,
  onBlur: undefined,
  onChange: undefined,
  onRemove: undefined,
  removeButtonTooltipText: undefined,
};

export default SovosFilterDrawerSection;
