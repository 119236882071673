import React from 'react';
import { arrayOf, func, object, shape, string } from 'prop-types';
import clsx from 'clsx';
import SovosCheckboxField from '../sovos-checkbox-field';
import SovosFilterDrawerSection from '../sovos-filter-drawer-section';

const getCheckboxRow = ({ checkedItems, entry: { label }, handleChange }) => (
  <SovosCheckboxField
    checked={checkedItems.findIndex((item) => item.label === label) >= 0}
    onChange={handleChange}
    className="sovosFilterCheckboxList__input"
    size="small"
    label={label}
  />
);

getCheckboxRow.propTypes = {
  checkedItems: arrayOf(object).isRequired,
  entry: object.isRequired,
  handleChange: func.isRequired,
};

const getRow = (checkedItems) => (props) =>
  getCheckboxRow({ checkedItems, ...props });

/**
 * For use in `SovosFilterDrawer`
 */
const SovosFilterCheckboxList = ({
  className,
  data,
  'data-testid': dataTestId,
  label,
  listItems,
  onChange,
  ...rest
}) => {
  const handleChange = (list) => {
    const newData = Array.from(data);

    // We get a list of checkbox items with a checked state appended when the
    // value changes Loop through the list of items and remove any from the
    // selected list that have been explicitly unchecked and append any newly
    // checked items
    list.forEach((li) => {
      const existingIdx = newData.findIndex((d) => d.label === li.label);
      if (existingIdx >= 0 && li.checked === false) {
        newData.splice(existingIdx, 1);
      } else if (existingIdx < 0 && li.checked === true) {
        newData.push({ label: li.label, value: li.value });
      }
    });

    onChange(newData);
  };

  // Switch list items and data. The section will render a row for each data
  // item, which should be individual checkboxes. The checked state needs to
  // separate from the list of items
  return (
    <SovosFilterDrawerSection
      className={clsx('sovosFilterCheckboxList', className)}
      data={listItems}
      data-testid={dataTestId}
      dataValueName="checked"
      getRow={getRow(data)}
      label={label}
      onChange={handleChange}
      {...rest}
    />
  );
};

SovosFilterCheckboxList.propTypes = {
  className: string,
  /**
   * Array of objects specifying checked checkboxes
   */
  data: arrayOf(
    shape({
      label: string.isRequired,
    })
  ),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Section label
   */
  label: string.isRequired,
  /**
   * Array of objects specifying the checkboxes to render
   */
  listItems: arrayOf(
    shape({
      label: string.isRequired,
      value: string,
    })
  ).isRequired,
  /**
   * Callback fired when the input value is changed `function(data:
   * object[]) => void`
   */
  onChange: func.isRequired,
};

SovosFilterCheckboxList.defaultProps = {
  className: undefined,
  data: [],
  'data-testid': undefined,
};

export default SovosFilterCheckboxList;
