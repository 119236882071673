import { navigationWidthFactor } from '../../../themes/sovos-sizes';
import spacingToNumber from '../../../internals/utils/spacingToNumber';

function getPanelBuffer(spacing) {
  return spacingToNumber(spacing(6));
}

export function getPanelWidth(order, windowWidth, theme) {
  const { breakpoints, spacing } = theme;

  const panelBuffer = getPanelBuffer(spacing);

  const navWidth =
    windowWidth >= breakpoints.values.md
      ? spacingToNumber(spacing(navigationWidthFactor))
      : 0;
  const initialWidth =
    windowWidth >=
    breakpoints.values.lg +
      spacingToNumber(spacing(navigationWidthFactor)) +
      panelBuffer
      ? `${breakpoints.values.lg}px`
      : `100vw - (${navWidth + panelBuffer}px)`;

  const calculatedWidth = `calc(${initialWidth} - (${panelBuffer * order}px)`;

  // The width of the sliding panel varies based on window size and should be 50px from the edge of the navigation
  // bar or 50px from the edge of the window if the navigation is hidden
  return calculatedWidth;
}

export function getOverlayWidth(order, windowWidth, theme) {
  if (order === 0) {
    return '100vw';
  }

  return getPanelWidth(order - 1, windowWidth, theme);
}
