import React from 'react';
import { oneOfType, func, string, number } from 'prop-types';

const FinancialCellContent = ({ setRef, children }) => (
  <span ref={setRef}>{children}</span>
);

FinancialCellContent.propTypes = {
  children: oneOfType([string, number]),
  setRef: func.isRequired,
};

FinancialCellContent.defaultProps = {
  children: undefined,
};

export default FinancialCellContent;
