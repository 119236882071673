import { useContext } from 'react';
import SovosErrorSnackbarContext from '../sovos-error-snackbar/SovosErrorSnackbarContext';

export const useErrorSnackbar = () => {
  const context = useContext(SovosErrorSnackbarContext);

  return { addError: context?.addError };
};

export default useErrorSnackbar;
