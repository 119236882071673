import React from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import clsx from 'clsx';
import FinancialCellContent from './FinancialCellContent';
import SELECT_COLUMN_ID from '../helpers/selectColumnId';
import tableClassNames from '../helpers/tableClassNames';
import isLastFixedColumn from '../helpers/isLastFixedColumn';
import isWidthCalculated from '../helpers/isWidthCalculated';

const TotalsBar = ({
  columns,
  totals,
  totalsLabel,
  columnStyles,
  fixedColumnCount,
  fixedHeader,
  rowActions,
  setFinancialCellRef,
}) => {
  const baseCellClasses = clsx(
    tableClassNames.baseCell,
    tableClassNames.totalsCell,
    fixedHeader && tableClassNames.fixedTotalsCell
  );

  const totalsLabelColumn = columns[0].id === SELECT_COLUMN_ID ? 1 : 0;

  return (
    <tr>
      {columns.map((col, i) => {
        const isFinancial = isWidthCalculated(col);
        const isSelect = col.id === SELECT_COLUMN_ID;
        const total = totals[col.dataKey];
        const isTotalsLabelCell = totalsLabelColumn === i && !total;

        const className = clsx(
          baseCellClasses,
          col.fixed
            ? tableClassNames.extraElevationCell
            : tableClassNames.fixedElevationCell,
          isLastFixedColumn(i, fixedColumnCount) &&
            tableClassNames.rightmostFixedColumnCell,
          isSelect && tableClassNames.selectCell
        );

        const props = {
          className,
          style: columnStyles[i],
          key: col.id,
          scope: 'col',
        };

        let content = isTotalsLabelCell ? totalsLabel : total;

        if (isFinancial) {
          content = (
            <FinancialCellContent setRef={setFinancialCellRef}>
              {content}
            </FinancialCellContent>
          );
        }

        return <th {...props}>{content}</th>;
      })}
      {rowActions && (
        <th className={clsx(tableClassNames.rowActionsCell, baseCellClasses)} />
      )}
    </tr>
  );
};

TotalsBar.propTypes = {
  columns: array.isRequired,
  totals: object.isRequired,
  totalsLabel: string.isRequired,
  columnStyles: arrayOf(object).isRequired,
  fixedColumnCount: number.isRequired,
  fixedHeader: bool.isRequired,
  rowActions: bool.isRequired,
  setFinancialCellRef: func,
};

TotalsBar.defaultProps = {
  setFinancialCellRef: undefined,
};

export default TotalsBar;
