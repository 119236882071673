import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import account from '../settings/reducers/accountReducer';
import importReducer from '../imports/services/import';
import imports from '../imports/services/imports';
import importsSummary from '../imports/services/importsSummary';
import invoice from '../invoices/services/invoice';
import invoices from '../invoices/services/invoices';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: {
    account,
    import: importReducer,
    imports,
    importsSummary,
    invoice,
    invoices,
  },

  middleware: customizedMiddleware,
});

export default store;
