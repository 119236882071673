import React from 'react';
import clsx from 'clsx';
import { string, shape, object } from 'prop-types';
import merge from 'lodash.merge';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';
import SovosGrid from '../sovos-grid/SovosGrid';
import ErrorPageLink from './components/ErrorPageLink';
import ErrorCode from './components/ErrorCode';
import {
  Root,
  Content,
  Header,
  Description,
} from './components/styledComponents';

const defaultErrorCode = '400';

/**
 * This is a specific layout designed to be used for generic HTTP error pages.
 */
const SovosErrorPage = ({
  actionUrl,
  className,
  'data-testid': dataTestId,
  errorCode: errorCodeProp,
  helpUrl,
  labels,
}) => {
  const { t } = useMosaicTranslation();

  const defaultLabels = t('errorPageCodes', { returnObjects: true });

  const mergedLabels = merge(defaultLabels, labels);

  const errorCode = mergedLabels[errorCodeProp]
    ? errorCodeProp
    : defaultErrorCode;

  const errorLabels = mergedLabels[errorCode];

  const items = [
    {
      title: errorLabels.action,
      linkText: errorLabels.actionLink,
      linkUrl: actionUrl,
    },
    {
      title: mergedLabels.helpLinkTitle,
      linkText: mergedLabels.helpLinkText,
      linkUrl: helpUrl,
    },
  ];

  return (
    <Root
      className={clsx('sovosErrorPage', className)}
      data-testid={dataTestId}
    >
      <SovosGrid container justifyContent="center" spacing={3}>
        <SovosGrid
          item
          sm={6}
          md={5}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <ErrorCode code={errorCode} />
        </SovosGrid>
        <SovosGrid item sm={6} md={5}>
          <Content>
            <Header variant="h1">{errorLabels.header}</Header>
            <Description variant="h4">{errorLabels.description}</Description>

            {items.map((item) => (
              <ErrorPageLink
                key={item.title}
                title={item.title}
                linkText={item.linkText}
                linkUrl={item.linkUrl}
              />
            ))}
          </Content>
        </SovosGrid>
      </SovosGrid>
    </Root>
  );
};

SovosErrorPage.propTypes = {
  /**
   * Url string of the suggested action
   */
  actionUrl: string.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Error code string
   */
  errorCode: string.isRequired,
  /**
   * Url string for help
   */
  helpUrl: string.isRequired,
  /**
   * Object of text labels for error page
   */
  labels: shape({
    errorCodes: object,
    helpLinkTitle: string,
    helpLinkText: string,
  }),
};

SovosErrorPage.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  labels: {
    errorCodes: undefined,
    helpLinkTitle: undefined,
    helpLinkText: undefined,
  },
};

export default SovosErrorPage;
