import React, { useContext } from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import SovosSnackbar from '../sovos-snackbar';
import SovosErrorSnackbarContext from './SovosErrorSnackbarContext';

const SovosErrorSnackbar = ({
  className,
  'data-testid': dataTestId,
  ...rest
}) => {
  const errorContext = useContext(SovosErrorSnackbarContext);
  const { errors, dismissError } = errorContext;
  const { length } = errors;

  const hasErrors = errors && length !== 0;
  const topError = hasErrors
    ? errors[length - 1].message || errors[length - 1]
    : '';

  return (
    <SovosSnackbar
      className={clsx('sovosErrorSnackbar', className)}
      data-testid={dataTestId}
      message={topError}
      onClose={dismissError}
      open={hasErrors}
      variant="error"
      {...rest}
    />
  );
};

SovosErrorSnackbar.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
};

SovosErrorSnackbar.defaultProps = {
  className: undefined,
  'data-testid': undefined,
};

export default SovosErrorSnackbar;
