import React, { useState, useRef, useEffect, useMemo } from 'react';
import { node, string, elementType, func, object } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { useResize } from '../hooks';

/**
 * This component animates transitions of height for its contents.
 */
const SovosResizeContainer = ({
  className,
  children,
  component: Component,
  onResize,
  style: styleProp,
  timeout,
  ...rest
}) => {
  const StyledComponent = useMemo(
    () =>
      styled(Component)(({ theme: { transitions } }) => ({
        transitionTimingFunction: 'ease-in-out',
        transitionProperty: 'height',
        overflowX: 'visible',
        overflowY: 'clip',
        transitionDuration: `${transitions.duration.standard}ms`,
      })),
    [Component]
  );
  const [wrapperStyles, setWrapperStyles] = useState(undefined);
  const [width, setWidth] = useState(undefined);
  const wrapperRef = useRef(null);
  useResize(wrapperRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const heightProp = styleProp?.height;
    const prevHeight = wrapperStyles?.height;
    const height = heightProp || wrapperRef.current.scrollHeight;
    const currentWidth = wrapperRef.current.scrollWidth;

    if (onResize && (prevHeight !== height || width !== currentWidth)) {
      onResize({ height, width: currentWidth });
      setWidth(currentWidth);
    }

    if (height !== prevHeight) {
      const transitionDuration = (() => {
        if (typeof timeout === 'number') return `${timeout}ms`;
        if (timeout === 'auto') {
          if (!prevHeight) return 0;
          return `${Math.abs(height - prevHeight) / 1.6 + 50}ms`;
        }
        return undefined;
      })();

      const newStyles = {
        height,
        transitionDuration,
      };

      setWrapperStyles(newStyles);
    }
  });

  return (
    <StyledComponent
      className={clsx('SovosResizeContainer', className)}
      style={{ ...wrapperStyles, ...styleProp }}
      {...rest}
    >
      <div ref={wrapperRef}>{children}</div>
    </StyledComponent>
  );
};

SovosResizeContainer.propTypes = {
  /**
   * The content of the container
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * The component used for the root node. Either a string to use a HTML
   * element or a component
   */
  component: elementType,
  /**
   * Callback triggered when the height of the component's content changes,
   * at the start of the animation `function(newSize: {height: number,
   * width: number }) => void`
   */
  onResize: func,
  /**
   * Inline styles applied to the root element. Height can be passed in
   * here to disable resizing
   */
  style: object,
  /**
   * The duration for the transition, in milliseconds. Set to 'auto' to
   * automatically calculate transition time based on height. Default is
   * theme.transitions.duration.standard
   */
  timeout: ({ timeout: prop }) => {
    if (prop === 'auto' || typeof prop === 'number' || prop === undefined) {
      return undefined;
    }

    return new Error(
      `Invalid type supplied to SovosResizeContainer. Expected either a number of 'auto' but received ${prop}`
    );
  },
};

SovosResizeContainer.defaultProps = {
  className: undefined,
  component: 'div',
  onResize: undefined,
  style: undefined,
  timeout: undefined,
};

export default SovosResizeContainer;
