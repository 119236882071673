import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
  bool,
  node,
  string,
  oneOfType,
  arrayOf,
  func,
  object,
} from 'prop-types';
import assetResolver, { assetKeys } from 'mosaic-assets-resolver';
import SovosCircularProgress from '../../sovos-circular-progress';

const Root = styled('div')(({ theme: { palette } }) => ({
  alignItems: 'center',
  backgroundColor: palette.grey[100],
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
}));

const Card = styled('div')(
  ({ theme: { palette, shadows, shape: themeShape, spacing } }) => ({
    backgroundColor: palette.background.default,
    borderRadius: themeShape.borderRadius,
    boxShadow: shadows[2],
    padding: `${spacing(8)} ${spacing(5)}`,
    position: 'relative',
    width: spacing(66.25),
  })
);

const Logo = styled('div')(({ theme: { spacing } }) => ({
  height: spacing(3),
  marginBottom: spacing(10),
  width: spacing(12.625),
}));

const Loading = styled('div')(({ theme: { palette, zIndex } }) => ({
  backgroundColor: alpha(
    palette.action.disabled,
    palette.action.disabledOpacity
  ),
  bottom: 0,
  display: 'grid',
  left: 0,
  placeItems: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: zIndex.mobileStepper,
}));

const LoginFlowCard = ({
  children,
  className,
  'data-testid': dataTestId,
  loading,
  sx,
}) => (
  <Root className={className} data-testid={dataTestId} sx={sx}>
    <Card className="sovosLogin__Card">
      {loading && (
        <Loading className="sovosLogin__Loading">
          <SovosCircularProgress aria-label="loading data" />
        </Loading>
      )}
      <Logo className="sovosLogin__Logo">
        <img
          src={assetResolver.branding(assetKeys.branding.logoDark)}
          alt="Sovos Logo"
        />
      </Logo>
      {children}
    </Card>
  </Root>
);

LoginFlowCard.propTypes = {
  /**
   * The content of the button group
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Is loading disables form submit and displays progress indicator
   */
  loading: bool,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
};

LoginFlowCard.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  loading: false,
  sx: undefined,
};

export default LoginFlowCard;
