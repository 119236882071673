import { alpha } from '@mui/material/styles';

export const baseSize = 8;
const baseFontSize = 16;
const neutralWhite = '#ffffff';
const secondarySlate = '#545e75';

const baseTypography = {
  fontWeight: 400,
  fontSize: '0.875rem', // 14px
  letterSpacing: -0.1,
  lineHeight: 1.428571428571429, // 20px
};

const headingTypography = {
  h1: {
    fontWeight: 500,
    fontSize: '3rem', // 48px
    letterSpacing: -0.5,
    lineHeight: 1.166666666666667, // 56px
  },
  h2: {
    fontWeight: 500,
    fontSize: '2.25rem', // 36px
    letterSpacing: -0.2,
    lineHeight: 1.333333333333333, // 48px
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.75rem', // 28px
    letterSpacing: -0.2,
    lineHeight: 1.428571428571429, // 40px
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.5rem', // 24px
    letterSpacing: -0.2,
    lineHeight: 1.66666666666667, // 40px
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.125rem', // 18px
    letterSpacing: -0.2,
    lineHeight: 1.555555555555556, // 24px
  },
  h6: {
    fontWeight: 400,
    fontSize: '1rem', // 16px
    letterSpacing: -0.2,
    lineHeight: 1.5, // 24px
  },
};

export default {
  spacing: (factor = 1) => baseSize * factor,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    htmlFontSize: baseFontSize,
    fontFamily: `'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    pxToRem: (size) => `${size / baseFontSize}rem`,
    ...headingTypography,
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem', // 16px
      letterSpacing: -0.2,
      lineHeight: 1.5, // 24px
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem', // 14px
      letterSpacing: -0.1,
      lineHeight: 1.5, // 24px
    },
    body1: {
      ...baseTypography,
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.75rem', // 12px
      letterSpacing: 0,
      lineHeight: 1.333333333333333, // 16px
    },
    button: {
      fontWeight: 700,
      fontSize: '0.875rem', // 14px
      letterSpacing: 0.2,
      lineHeight: 1.428571428571429, // 20px
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      light: '#74bfff',
      main: '#015fff',
      dark: '#013cb9',
      contrastText: neutralWhite,
    },
    warning: {
      light: '#ffdd99',
      main: '#ffb666',
      dark: '#e68422',
      contrastText: '#000000',
    },
    error: {
      light: '#ffc3b6',
      main: '#db322d',
      dark: '#ab1117',
      contrastText: neutralWhite,
    },
    success: {
      light: '#c6f1d6',
      main: '#5abe8a',
      dark: '#22885d',
      contrastText: neutralWhite,
    },
    text: {
      primary: '#072448',
      secondary: secondarySlate,
      disabled: '#9da2a7',
      hint: '#70757a',
      contrast: neutralWhite,
    },
    grey: {
      50: '#f5faff',
      100: '#f0f5fa',
      200: '#e9eef3',
      300: '#dbe0e5',
      400: '#b8bdc2',
      500: '#999ea3',
      600: '#70757a',
      700: '#5c6166',
      800: '#3d4247',
      900: '#1c2126',
      A100: '#e0eaf8', // nav items
      A200: '#7390cf', // textFilled background
      A400: '#031b37', // nav footer
      A700: '#072a51', // nav header
    },
    divider: 'rgba(0, 0, 0, 0.1)',
    background: {
      default: neutralWhite,
      paper: neutralWhite,
    },
    action: {
      activatedOpacity: 0.16,
      active: secondarySlate,
      disabled: '#babdc0',
      disabledBackground: '#dbe0e5',
      disabledOpacity: 0.32,
      focus: alpha(secondarySlate, 0.16),
      focusOpacity: 0.16,
      hover: alpha(secondarySlate, 0.08),
      hoverOpacity: 0.08,
      selected: alpha(secondarySlate, 0.12),
      selectedOpacity: 0.12,
    },
  },
  shape: {
    borderRadius: 8,
    borderRadiusSmall: 4,
    borderRadiusRound: '50%',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '::-moz-focus-inner': {
          border: 0,
        },
        body: {
          ...baseTypography,
        },
        ...headingTypography,
        '*, body': {
          fontVariantNumeric: 'tabular-nums',
          scrollbarWidth: 'thin',
          scrollbarColor: `${secondarySlate} transparent`,
          '&::-webkit-scrollbar': {
            borderRadius: 3,
            height: 6,
            width: 6,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: secondarySlate,
            borderRadius: 3,
            outline: 0,
          },
        },
      },
    },
  },
  overrides: {
    sovosNavigation: {
      headerBackground: '#072a51',
      gradientTop: '#07335e',
      gradientBottom: '#072448',
      footerBackground: '#031b37',
      navItemHover: alpha('#74bfff', 0.08),
      navItemForeground: '#e0eaf8',
      highlights: '#015fff',
      productNameColor: alpha('#74bfff', 0.75),
    },
  },
};
