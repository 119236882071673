/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const SovosCircularProgress = ({
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  className,
  ...rest
}) => (
  <CircularProgress
    aria-label={ariaLabel}
    aria-labelledby={ariaLabelledBy}
    className={clsx('sovosCircularProgress', className)}
    {...rest}
  />
);

SovosCircularProgress.propTypes = {
  /**
   * Callback fired when Aria attributes are missing or both are present.
   */
  'aria-label': ({
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
  }) => {
    if (ariaLabel && ariaLabelledBy) {
      return new Error('Do not include both ariaLabel or ariaLabelledBy');
    }

    if (!ariaLabel && !ariaLabelledBy) {
      return new Error(
        'Either aria-label or aria-labelledby is required for accessibility'
      );
    }
    return null;
  },
  /**
   * Use ariaLabelledBy if the label text is available in the DOM and
   * referencing the DOM content.
   */
  'aria-labelledby': string,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosCircularProgress.defaultProps = {
  'aria-label': undefined,
  'aria-labelledby': undefined,
  className: undefined,
};

SovosCircularProgress.baseComponent = {
  name: 'Circular Progress',
  link: 'circular-progress/',
};

export default SovosCircularProgress;
