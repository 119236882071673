/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme: { palette, spacing, typography } }) => ({
  '& .MuiTooltip-tooltip': {
    alignItems: 'center',
    backgroundColor: alpha(palette.common.black, 0.8),
    color: palette.text.contrast,
    display: 'flex',
    fontSize: typography.body2.fontSize,
    minHeight: spacing(3),
    paddingLeft: spacing(),
    paddingRight: spacing(),
    hyphens: 'auto',
  },
}));

const SovosTooltip = ({ className, children, ...rest }) => {
  const wrappedChildren =
    typeof children === 'string' ? <span>{children}</span> : children;

  return (
    <StyledTooltip className={clsx('sovosTooltip', className)} {...rest}>
      {wrappedChildren}
    </StyledTooltip>
  );
};

SovosTooltip.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosTooltip.defaultProps = {
  className: undefined,
};

SovosTooltip.baseComponent = {
  name: 'Tooltip',
  link: 'tooltip/',
};

export default SovosTooltip;
