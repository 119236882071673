import React from 'react';
import clsx from 'clsx';
import {
  bool,
  func,
  shape,
  string,
  oneOfType,
  arrayOf,
  object,
} from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosButton from '../sovos-button';
import SovosLink from '../sovos-link/SovosLink';
import LoginFlowCard from '../internals/login-flow/LoginFlowCard';
import LoginFlowHeader from '../internals/login-flow/LoginFlowHeader';
import LoginFlowSubtitle from '../internals/login-flow/LoginFlowSubtitle';
import LoginFlowForm from '../internals/login-flow/LoginFlowForm';
import SovosTextField from '../sovos-text-field';
import { isValidEmail } from '../internals/login-flow/helpers';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const ForgotPassword = styled(SovosLink)({
  marginRight: 'auto',
});

const StyledTextField = styled(SovosTextField)(({ theme: { spacing } }) => ({
  marginBottom: spacing(1.5),
}));

const SovosLoginPage = ({
  className,
  'data-testid': dataTestId,
  errorMessage,
  forgotPasswordUrl,
  labels,
  loading,
  loginError,
  onSubmit,
  sx,
  validateUsername: validateUsernameProp,
}) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [emailError, setEmailError] = React.useState();
  const [passwordError, setPasswordError] = React.useState();
  const { t } = useMosaicTranslation();
  const isSubmitDisabled =
    !email || !password || loading || !!emailError || !!passwordError;

  const validateUsername = () => {
    let error = '';
    if (email === '') {
      error = labels?.emptyUsernameError || t('loginPage.emptyUsernameError');
    } else if (validateUsernameProp && !validateUsernameProp(email)) {
      error =
        labels?.invalidUsernameError || t('loginPage.invalidUsernameError');
    }

    setEmailError(error);
  };

  const validatePassword = () => {
    let error = '';
    if (password === '') {
      error = labels?.emptyPasswordError || t('loginPage.emptyPasswordError');
    }
    setPasswordError(error);
  };

  const textFieldProps = {
    disabled: loading,
  };

  const inputs = (
    <>
      <StyledTextField
        error={!!emailError}
        helperText={emailError}
        onBlur={validateUsername}
        onChange={(event) => setEmail(event.target.value)}
        placeholder={
          labels?.usernamePlaceholder || t('loginPage.usernamePlaceholder')
        }
        value={email}
        variant="outlined"
        {...textFieldProps}
      />
      <StyledTextField
        autoComplete="new-password"
        error={!!passwordError}
        helperText={passwordError}
        onBlur={validatePassword}
        onChange={(event) => setPassword(event.target.value)}
        placeholder={
          labels?.passwordPlaceholder || t('loginPage.passwordPlaceholder')
        }
        type="password"
        value={password}
        variant="outlined"
        {...textFieldProps}
      />
    </>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ email, password });
  };

  const buttons = (
    <>
      {forgotPasswordUrl && (
        <ForgotPassword href={forgotPasswordUrl} variant="h6">
          {labels?.forgotPassword || t('loginPage.forgotPassword')}
        </ForgotPassword>
      )}
      <SovosButton type="submit" disabled={isSubmitDisabled}>
        {labels?.login || t('loginPage.login')}
      </SovosButton>
    </>
  );

  return (
    <LoginFlowCard
      className={clsx('sovosLoginPage', className)}
      data-testid={dataTestId}
      loading={loading}
      sx={sx}
    >
      <LoginFlowHeader label={labels?.header || t('loginPage.header')} />
      <LoginFlowSubtitle label={labels?.subtitle || t('loginPage.subtitle')} />
      <LoginFlowForm
        hasError={loginError}
        errorMessage={errorMessage || t('loginPage.errorMessage')}
        formInputs={inputs}
        formButtons={buttons}
        submitFunction={handleSubmit}
      />
    </LoginFlowCard>
  );
};

SovosLoginPage.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Error message to show when login validation fails
   */
  errorMessage: string,
  /**
   * Url to reset/forgot password
   */
  forgotPasswordUrl: string,
  /**
   * Object of text labels for login page component
   */
  labels: shape({
    emptyPasswordError: string,
    emptyUsernameError: string,
    forgotPassword: string,
    header: string,
    invalidUsernameError: string,
    login: string,
    passwordPlaceholder: string,
    subtitle: string,
    usernamePlaceholder: string,
  }),
  /**
   * Is loading disables form submit and displays progress indicator
   */
  loading: bool.isRequired,
  /**
   * Did login fail
   */
  loginError: bool.isRequired,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Callback fired when form is submitted `function(event: object) => void`
   */
  onSubmit: func.isRequired,
  /**
   * Custom validation function for username field. If false,
   * `invalidUsernameError` message will be displayed. To skip validation,
   * pass in `null` `function(username: string) => boolean`
   */
  validateUsername: func,
};

SovosLoginPage.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  errorMessage: undefined,
  forgotPasswordUrl: undefined,
  labels: undefined,
  sx: undefined,
  validateUsername: isValidEmail,
};

export default SovosLoginPage;
