import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import Grid from '@mui/material/Grid';

const SovosGrid = ({ className, ...rest }) => (
  <Grid className={clsx('sovosGrid', className)} {...rest} />
);

SovosGrid.baseComponent = {
  name: 'Grid',
  link: 'grid/',
};

SovosGrid.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosGrid.defaultProps = {
  className: undefined,
};

export default SovosGrid;
