import React, { forwardRef, useState } from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  object,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
} from 'mosaic-react-icons';
import { useControlled, useIsFocusVisible } from '@mui/material/utils';
import { materialUIColors } from '../internals/utils/materialUiHelpers';
import { getAfterFocusStyles } from '../internals/utils';

export const getBoxColors = ({
  checked,
  color,
  disabled,
  error,
  indeterminate,
  palette,
}) => {
  let checkedColor;
  let uncheckedColor;

  if (error) {
    checkedColor = palette.error.main;
    uncheckedColor = palette.error.main;
  } else if (disabled) {
    checkedColor = palette.text.disabled;
    uncheckedColor = palette.text.disabled;
  } else {
    switch (color.toLowerCase()) {
      case materialUIColors.default:
        checkedColor = palette.text.secondary;
        break;
      case materialUIColors.primary:
        checkedColor = palette.primary.main;
        break;
      case materialUIColors.secondary:
        checkedColor = palette.secondary.main;
        break;
      default:
        checkedColor = color;
    }

    uncheckedColor = palette.text.secondary;
  }

  const boxColor = checked || indeterminate ? checkedColor : uncheckedColor;

  const calculateColor = (opacity) =>
    disabled ? 'transparent' : alpha(checkedColor, opacity);

  const hoverColor = calculateColor(palette.action.hoverOpacity);
  const hoverFocusColor = calculateColor(
    palette.action.focusOpacity + palette.action.hoverOpacity
  );

  return {
    boxColor,
    hoverColor,
    hoverFocusColor,
  };
};

const StyledSovosCheckbox = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'indeterminate' &&
    prop !== 'color' &&
    prop !== 'error' &&
    prop !== 'size',
})(
  ({
    checked,
    color,
    disabled,
    error,
    indeterminate,
    size,
    theme: { palette, spacing, shape },
  }) => {
    const { boxColor, hoverColor, hoverFocusColor } = getBoxColors({
      checked,
      color,
      disabled,
      error,
      indeterminate,
      palette,
    });
    const containerSize = (() => {
      if (size === 'tiny') return spacing(3);
      if (size === 'small') return spacing(4);
      return spacing(5);
    })();

    const iconSize = (() => {
      if (size === 'tiny') return spacing(1.75);
      if (size === 'small') return spacing(2.5);
      return spacing(3);
    })();

    return {
      alignItems: 'center',
      borderRadius: shape.borderRadiusRound,
      display: 'inline-flex',
      height: containerSize,
      justifyContent: 'center',
      position: 'relative',
      width: containerSize,
      '& svg': {
        color: boxColor,
        height: iconSize,
        width: iconSize,
      },
      '&:hover': {
        backgroundColor: hoverColor,
      },
      '&.Mui-focusVisible': {
        ...getAfterFocusStyles({ offset: 0 }),

        '&:hover': {
          backgroundColor: hoverFocusColor,
        },
      },
    };
  }
);

const StyledInput = styled('input')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  opacity: 0,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  '&:disabled': {
    cursor: 'default',
  },
}));

const SovosCheckbox = forwardRef(
  (
    {
      'aria-label': ariaLabel,
      checked: checkedProp,
      classes,
      className,
      color,
      'data-testid': dataTestId,
      defaultChecked,
      disabled,
      error,
      indeterminate,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      readOnly,
      size,
      sx,
      value,
      ...rest
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const [checked, setCheckedState] = useControlled({
      controlled: checkedProp,
      default: Boolean(defaultChecked),
      name: 'SovosCheckbox',
      state: 'checked',
    });

    const {
      isFocusVisibleRef,
      onFocus: handleFocusVisible,
      onBlur: handleBlurVisible,
    } = useIsFocusVisible();

    const handleOnBlur = (event) => {
      handleBlurVisible(event);
      if (isFocusVisibleRef.current === false) {
        setIsFocused(false);
      }

      if (onBlur) {
        onBlur(event);
      }
    };

    const handleOnFocus = (event) => {
      handleFocusVisible(event);

      if (isFocusVisibleRef.current === true) {
        setIsFocused(true);
      }

      if (onFocus) {
        onFocus(event);
      }
    };

    const handleOnChange = (event) => {
      const newValue = event.target.checked;
      setCheckedState(newValue);

      if (onChange) {
        onChange(event, newValue);
      }
    };

    let Icon;
    if (indeterminate) {
      Icon = <IndeterminateCheckBoxIcon data-testid="indeterminate-icon" />;
    } else if (checked) {
      Icon = <CheckBoxIcon data-testid="checkbox-icon" />;
    } else {
      Icon = <CheckBoxOutlineBlankIcon data-testid="blankoutline-icon" />;
    }

    return (
      <StyledSovosCheckbox
        checked={checked}
        className={clsx('sovosCheckbox', classes?.root, className, {
          'Mui-focusVisible': isFocused,
        })}
        color={color}
        data-testid={dataTestId}
        disabled={disabled}
        error={error}
        indeterminate={indeterminate}
        size={size}
        sx={sx}
      >
        <StyledInput
          aria-label={ariaLabel}
          checked={checked}
          className={clsx('sovosCheckbox__input', classes?.input)}
          disabled={disabled}
          name={name}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onClick={onClick}
          onFocus={handleOnFocus}
          readOnly={readOnly}
          ref={ref}
          type="checkbox"
          value={value}
          {...rest}
        />
        {Icon}
      </StyledSovosCheckbox>
    );
  }
);

SovosCheckbox.propTypes = {
  /**
   * Label for screen readers
   */
  'aria-label': string,
  /**
   * If `true`, the input is checked
   */
  checked: bool,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Material UI theme color of the component
   */
  color: oneOf(['primary', 'default']),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * If `true`, the input default is checked
   */
  defaultChecked: bool,
  /**
   * If `true`, the input is disabled
   */
  disabled: bool,
  /**
   * If `true`, the component will display an error state
   */
  error: bool,
  /**
   * If `true`, the input appears indeterminate
   */
  indeterminate: bool,
  /**
   * Name of the input
   */
  name: string,
  /**
   * Callback fired when the checkbox loses focus
   */
  onBlur: func,
  /**
   * Callback fired when input value is changed `function(event: object,
   * checked: bool) => void`
   */
  onChange: func,
  /**
   * Callback fired when the checkbox is clicked. Primarily used to stop
   * further propagation of the current event.
   */
  onClick: func,
  /**
   * Callback fired when the checkbox gets focus
   */
  onFocus: func,
  /**
   * @ignore
   * @deprecated -- remove for v12
   */
  readOnly: bool,
  /**
   * Size of the checkbox
   */
  size: oneOf(['tiny', 'small', 'medium']),
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Value of the input
   */
  value: any,
};

SovosCheckbox.defaultProps = {
  'aria-label': undefined,
  checked: undefined,
  classes: undefined,
  className: undefined,
  color: 'primary',
  'data-testid': undefined,
  disabled: false,
  defaultChecked: undefined,
  error: undefined,
  indeterminate: false,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  onClick: undefined,
  onFocus: undefined,
  readOnly: false,
  size: 'medium',
  sx: undefined,
  value: undefined,
};

SovosCheckbox.baseComponent = {
  name: 'Checkbox',
};

export default SovosCheckbox;
