import { styled } from '@mui/material/styles';
import SovosTypography from '../../sovos-typography';

export const Root = styled('div')(({ theme: { palette } }) => ({
  alignItems: 'center',
  backgroundColor: palette.grey[100],
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
}));

export const Content = styled('article')(
  ({ theme: { breakpoints, spacing } }) => ({
    [breakpoints.down('xs')]: {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
    },
  })
);

export const Header = styled(SovosTypography)(({ theme: { spacing } }) => ({
  marginBottom: spacing(),
}));

export const Description = styled(SovosTypography)(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(4),
  })
);
