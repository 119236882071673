import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const StyledSovosMenu = styled(Menu)(({ theme: { spacing } }) => ({
  '& .MuiMenu-list': {
    paddingBottom: spacing(),
    paddingTop: spacing(),
  },
}));

const SovosMenu = ({ className, ...rest }) => (
  <StyledSovosMenu className={clsx('sovosMenu', className)} {...rest} />
);

SovosMenu.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosMenu.defaultProps = {
  className: undefined,
};

SovosMenu.baseComponent = {
  name: 'Menu',
  link: 'menu/',
};

export default SovosMenu;
