import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const PaperRoot = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ variant, theme: { shadows, shape } }) => ({
  '&.MuiPaper-root': {
    borderRadius:
      variant === 'filled' || variant === 'standard'
        ? `0 0 ${shape.borderRadius}px ${shape.borderRadius}px`
        : shape.borderRadius,
    boxShadow: shadows[8],
    marginTop: 0,
  },
}));

export default PaperRoot;
