import React, { useReducer } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { func, bool, number, string } from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  SovosCircularProgress,
  SovosEditableSlidingPanel,
  SovosGrid,
  SovosTextField,
} from 'mosaic-react';
import { useMount, useErrorSnackbar } from 'mosaic-react/hooks';
import { userReducer, initialState } from './reducers/user';
import * as api from './api/users';
import ControlledField from '../commonComponents/ControlledField';

const StandardField = styled(ControlledField)(({ theme: { spacing } }) => ({
  width: spacing(30),
}));

const ShortField = styled(ControlledField)(({ theme: { spacing } }) => ({
  width: spacing(14),
}));

const UserPanel = ({
  addUserPanelOpen,
  closePanel,
  editId,
  label,
  onSubmit,
}) => {
  const { spacing } = useTheme();
  const { addError } = useErrorSnackbar();

  const [state, setState] = useReducer(userReducer, initialState);

  const { isLoading, current } = state;

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm();

  useMount(() => {
    const fetchData = async () => {
      if (editId) {
        setState({ type: 'LoadUserBegin' });
        try {
          const data = await api.loadUser(editId);
          setState({
            type: 'LoadUserSuccess',
            user: data,
          });
        } catch (error) {
          addError(error);
          setState({ type: 'LoadUserFailure' });
        }
      }
    };
    fetchData();
  });

  const resetForm = () => {
    setState({ type: 'ClearUser' });
    reset();
  };

  const handleClosePanel = () => {
    resetForm();
    closePanel();
  };

  const handleSaveClick = handleSubmit(async (data) => {
    if (editId) {
      setState({ type: 'EditUserBegin' });
      try {
        await api.editUser(data, editId);
        setState({ type: 'EditUserSuccess' });
      } catch (error) {
        addError(error);
        setState({ type: 'EditUserFailure' });
      }
    } else {
      setState({ type: 'AddUserBegin' });
      try {
        await api.addUser(data);
        setState({ type: 'AddUserSuccess' });
      } catch (error) {
        addError(error);
        setState({ type: 'AddUserFailure' });
      }
    }
    resetForm();
    onSubmit();
  });

  return (
    <SovosEditableSlidingPanel
      className="UserPanel"
      open={addUserPanelOpen}
      onClose={handleClosePanel}
      isDirty={() => isDirty}
      SaveButtonProps={{
        disabled: !isDirty,
        onClick: handleSaveClick,
        className: 'UserForm__SaveButton',
      }}
      title={label}
    >
      {editId && isLoading ? (
        <div>
          <SovosCircularProgress aria-label="Loading Data" />
        </div>
      ) : (
        <SovosGrid
          container
          data-testid="user-base-inputs"
          spacing={2}
          sx={{
            paddingTop: spacing(2),
            width: spacing(65),
          }}
        >
          <SovosGrid item xs={6}>
            <StandardField
              className="UserForm__FirstName"
              control={control}
              defaultValue={current.first}
              InputField={SovosTextField}
              label="First Name"
              name="first"
              rules={{
                required: 'Please enter a first name',
              }}
            />
          </SovosGrid>
          <SovosGrid item xs={6}>
            <StandardField
              className="UserForm__LastName"
              control={control}
              defaultValue={current.last}
              InputField={SovosTextField}
              label="Last Name"
              name="last"
              rules={{
                required: 'Please enter a last name',
              }}
            />
          </SovosGrid>
          <SovosGrid item xs={6}>
            <StandardField
              className="UserForm__Username"
              control={control}
              defaultValue={current.username}
              InputField={SovosTextField}
              label="Username"
              name="username"
              rules={{
                required: 'Please enter a username',
              }}
            />
          </SovosGrid>
          <SovosGrid item xs={6}>
            <StandardField
              className="UserForm__Email"
              control={control}
              defaultValue={current.email}
              InputField={SovosTextField}
              label="Email"
              name="email"
              rules={{
                required: 'Please enter an email',
              }}
            />
          </SovosGrid>
          <SovosGrid item xs={12}>
            <ControlledField
              className="UserForm__Address"
              control={control}
              defaultValue={current.address}
              InputField={SovosTextField}
              label="Address"
              name="address"
              rules={{
                required: 'Please enter an address',
              }}
              sx={{ width: '100%' }}
            />
          </SovosGrid>
          <SovosGrid item xs={6}>
            <StandardField
              className="UserForm__City"
              control={control}
              defaultValue={current.city}
              InputField={SovosTextField}
              label="City"
              name="city"
              rules={{
                required: 'Please enter a city',
              }}
            />
          </SovosGrid>
          <SovosGrid item xs={3}>
            <ShortField
              className="UserForm__State"
              control={control}
              defaultValue={current.state}
              InputField={SovosTextField}
              label="State"
              name="state"
              rules={{
                required: 'Please enter a state',
              }}
            />
          </SovosGrid>
          <SovosGrid item xs={3}>
            <ShortField
              className="UserForm__Zip"
              control={control}
              defaultValue={current.zip}
              InputField={SovosTextField}
              label="Zip"
              name="zip"
              rules={{
                required: 'Please enter a zip',
              }}
            />
          </SovosGrid>
        </SovosGrid>
      )}
    </SovosEditableSlidingPanel>
  );
};

UserPanel.propTypes = {
  addUserPanelOpen: bool.isRequired,
  closePanel: func.isRequired,
  editId: number,
  label: string,
  onSubmit: func.isRequired,
};

UserPanel.defaultProps = {
  editId: undefined,
  label: undefined,
};

export default UserPanel;
