import React from 'react';
import PropTypes from 'prop-types';
import {
  SovosGrid,
  SovosSettingsSection,
  SovosSettingsSubtitle,
  SovosSettingsTitle,
  SovosSettingsCard,
  SovosTextField,
} from 'mosaic-react';

const getOnChange = (parentValue, parentOnChange, key) =>
  function onChange(event) {
    parentOnChange({ ...parentValue, [key]: event.target.value });
  };

export const personalSectionLabelPropsShape = PropTypes.shape({
  titleText: PropTypes.string,
  subtitleText: PropTypes.string,
  firstNameFieldLabelText: PropTypes.string,
  lastNameFieldLabelText: PropTypes.string,
  emailFieldLabelText: PropTypes.string,
  alternateEmailLabelText: PropTypes.string,
  alternateEmailHelperText: PropTypes.string,
  SMSLabelText: PropTypes.string,
});

const PersonalSection = ({ user, onChange, LabelProps }) => {
  const {
    titleText,
    subtitleText,
    firstNameFieldLabelText,
    lastNameFieldLabelText,
    emailFieldLabelText,
    alternateEmailLabelText,
    alternateEmailHelperText,
    SMSLabelText,
  } = LabelProps;
  return (
    <SovosSettingsSection>
      <SovosSettingsTitle>{titleText}</SovosSettingsTitle>
      <SovosSettingsSubtitle>{subtitleText}</SovosSettingsSubtitle>
      <SovosSettingsCard>
        {user !== undefined ? (
          <SovosGrid container spacing={3}>
            <SovosGrid item>
              <SovosTextField
                value={user.first}
                className="PersonalSection__FirstName"
                onChange={getOnChange(user, onChange, 'first')}
                label={firstNameFieldLabelText}
              />
            </SovosGrid>
            <SovosGrid item>
              <SovosTextField
                value={user.last}
                className="PersonalSection__LastName"
                onChange={getOnChange(user, onChange, 'last')}
                label={lastNameFieldLabelText}
              />
            </SovosGrid>
            <SovosGrid item>
              <SovosTextField
                value={user.email}
                className="PersonalSection__Email"
                onChange={getOnChange(user, onChange, 'email')}
                label={emailFieldLabelText}
              />
            </SovosGrid>
            <SovosGrid item>
              <SovosTextField
                value={user.alternateEmail}
                className="PersonalSection__AlternateEmail"
                onChange={getOnChange(user, onChange, 'alternateEmail')}
                label={alternateEmailLabelText}
                helperText={alternateEmailHelperText}
              />
            </SovosGrid>
            <SovosGrid item>
              <SovosTextField
                value={user.sms}
                className="PersonalSection__SMS"
                onChange={getOnChange(user, onChange, 'sms')}
                label={SMSLabelText}
              />
            </SovosGrid>
          </SovosGrid>
        ) : (
          <div>loading...</div>
        )}
      </SovosSettingsCard>
    </SovosSettingsSection>
  );
};

PersonalSection.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  LabelProps: PropTypes.shape({
    titleText: PropTypes.string,
    subtitleText: PropTypes.string,
    firstNameFieldLabelText: PropTypes.string,
    lastNameFieldLabelText: PropTypes.string,
    emailFieldLabelText: PropTypes.string,
    alternateEmailLabelText: PropTypes.string,
    alternateEmailHelperText: PropTypes.string,
    SMSLabelText: PropTypes.string,
  }),
};

PersonalSection.defaultProps = {
  LabelProps: {
    titleText: 'Personal',
    subtitleText:
      'Configure your account detains and add recovery contact information',
    firstNameFieldLabelText: 'First Name',
    lastNameFieldLabelText: 'Last Name',
    emailFieldLabelText: 'Email Address',
    alternateEmailLabelText: 'Alternate Email',
    alternateEmailHelperText: 'Must be different from primary email',
    SMSLabelText: 'SMS number - optional',
  },
};

export default PersonalSection;
