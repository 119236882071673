import React from 'react';
import { styled } from '@mui/material/styles';
import { string } from 'prop-types';
import SovosTypography from '../../sovos-typography';

const Root = styled(SovosTypography)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
}));

const LoginFlowHeader = ({ className, label }) => (
  <Root className={className} variant="h3">
    {label}
  </Root>
);

LoginFlowHeader.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Header that displays on top
   */
  label: string.isRequired,
};

LoginFlowHeader.defaultProps = {
  className: undefined,
};

export default LoginFlowHeader;
