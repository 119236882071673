import React from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  object,
  oneOfType,
  string,
  shape,
  elementType,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { ChatOutlined } from 'mosaic-react-icons';
import ShowMoreList from '../internals/components/list/ShowMoreList';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';
import SovosTabContent from '../sovos-tab-content';
import SovosCommentsList from '../sovos-comments-list';
import SovosCommentForm from '../sovos-comment-form';

const StyledCommentForm = styled(SovosCommentForm)(
  ({ theme: { spacing } }) => ({
    marginTop: spacing(2),
  })
);

/**
 * **Note**: `SovosCommentsListTab` must be a child of `SovosTabGroup`
 */
const SovosCommentsListTab = ({
  className,
  'data-testid': dataTestId,
  emptyListLabel,
  newCommentAttachmentButtonTooltipText,
  newCommentErrorLabels,
  newCommentPlaceholderLabel,
  newCommentSubmitButtonTooltipText,
  onAddComment,
  onAddCommentAttachment,
  onAddCommentContentChange,
  selected,
  showLessLabel,
  showMoreLabel,
  sx,
  TabProps: UnusedTabProps,
  ...listProps
}) => {
  const { t } = useMosaicTranslation();

  return (
    <SovosTabContent
      className={clsx('sovosCommentsListTab', className)}
      data-testid={dataTestId}
      TabProps={{}}
      selected={selected}
      sx={sx}
    >
      {onAddComment && (
        <StyledCommentForm
          attachmentButtonTooltipText={newCommentAttachmentButtonTooltipText}
          className={className}
          commentPlaceholderLabel={newCommentPlaceholderLabel}
          data-testid="commentForm"
          errorLabels={newCommentErrorLabels}
          onAttachmentClicked={onAddCommentAttachment}
          onCommentContentChange={onAddCommentContentChange}
          onSubmit={onAddComment}
          submitButtonTooltipText={newCommentSubmitButtonTooltipText}
        />
      )}

      <ShowMoreList
        component={SovosCommentsList}
        showLessLabel={showLessLabel || t('commentsListTab.showLessLabel')}
        showMoreLabel={showMoreLabel || t('commentsListTab.showMoreLabel')}
        emptyListLabel={emptyListLabel || t('commentsListTab.emptyListLabel')}
        {...listProps}
      />
    </SovosTabContent>
  );
};

SovosCommentsListTab.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of comment entries to be rendered. Each entry should contain
   * comment, name, surname, formattedDate, and optional avatarElement,
   * status and link.
   */
  data: arrayOf(
    shape({
      comment: string.isRequired,
      name: string.isRequired,
      surname: string.isRequired,
      formattedDate: string.isRequired,
      avatarElement: node,
      status: node,
      link: shape({
        text: string,
        href: string.isRequired,
      }),
    })
  ).isRequired,
  /**
   * Ignore
   */
  'data-testid': string,
  /**
   * Text displayed when data has no comments
   */
  emptyListLabel: string,
  /**
   * New comment add attachment tooltip text
   */
  newCommentAttachmentButtonTooltipText: string,
  /**
   * Object `empty`: error message if comment length is 0, and `length`:
   * error message if comment length exceeds maximum number of characters.
   */
  newCommentErrorLabels: shape({
    empty: string.isRequired,
    length: string.isRequired,
  }),
  /**
   * New Comment placeholder attribute text
   */
  newCommentPlaceholderLabel: string,
  /**
   * New Comment submit button tooltip text
   */
  newCommentSubmitButtonTooltipText: string,
  /**
   * Callback fired when add comment button clicked
   */
  onAddComment: func,
  /**
   * Callback fired when add comment button clicked
   */
  onAddCommentAttachment: func,
  /**
   * Callback fired when the input value is changed `function(comment:
   * string) => void`
   */
  onAddCommentContentChange: func,
  /**
   * If true, this will be the initially displayed tab in the tab group
   */
  selected: bool,
  /**
   * Show Fewer comments button text
   */
  showLessLabel: string,
  /**
   * Show More comments button text
   */
  showMoreLabel: string,
  /**
   * Function used to sort provided data.
   */
  sortFunction: func,
  /**
   * Override or extend the styles applied to the SovosList base component
   */
  SovosListClasses: object,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Props passed to the SovosTab
   */
  TabProps: shape({
    icon: node,
    label: oneOfType([node, elementType]),
  }),
};

const icon = <ChatOutlined />;

const TabLabel = () => {
  const { t } = useMosaicTranslation();

  return t('commentsListTab.tabPropsLabel');
};

SovosCommentsListTab.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  emptyListLabel: undefined,
  newCommentAttachmentButtonTooltipText: undefined,
  newCommentErrorLabels: undefined,
  newCommentPlaceholderLabel: undefined,
  newCommentSubmitButtonTooltipText: undefined,
  onAddComment: undefined,
  onAddCommentAttachment: undefined,
  onAddCommentContentChange: undefined,
  selected: false,
  showLessLabel: undefined,
  showMoreLabel: undefined,
  sortFunction: undefined,
  SovosListClasses: undefined,
  sx: undefined,
  TabProps: {
    icon,
    label: TabLabel,
  },
};

export default SovosCommentsListTab;
