import React, { useContext, useReducer, createContext } from 'react';
import { node } from 'prop-types';
import customThemeColors from '../internals/custom-themes/custom-theme-colors';

const THEMES = customThemeColors;
const CustomThemesStateContext = createContext();
const CustomThemesDispatchContext = createContext();

export const ACTIONS = {
  SET_LOGO_ALT: 'set_logo_alt',
  SET_LOGO_URL: 'set_logo_url',
  CLEAR_LOGO: 'clear_logo',
  SELECT_THEME: 'select_theme',
  SET_THEMES_STATE: 'set_themes_state',
  UPDATE_CUSTOM_COLOR: 'update_custom_color',
  CONFIRM_OVERWRITE: 'confirm_overwrite',
  CANCEL_OVERWRITE: 'cancel_overwrite',
};

const getCustomTheme = (state) =>
  state.colorThemes[state.colorThemes.length - 1];

const getSelectedTheme = (state) => {
  const selectedThemeIndex = state.colorThemes.findIndex(
    (colorTheme) => colorTheme.title === state.selectedTheme
  );

  return state.colorThemes[selectedThemeIndex];
};

const getNewCustomTheme = (state) => {
  const customTheme = getCustomTheme(state);

  if (state.selectedTheme === customTheme.title) {
    return customTheme;
  }

  return {
    title: customTheme.title,
    colors: { ...getSelectedTheme(state).colors },
  };
};

const updateCustomThemeColor = (state, payload) => {
  const { value } = payload.event.target;
  const { title, colors } = getCustomTheme(state);

  return {
    title,
    colors: { ...colors, [payload.colorKey]: value },
  };
};

const updateCustomTheme = (state, payload, overwriteCustom = false) => [
  ...state.colorThemes.slice(0, state.colorThemes.length - 1),
  overwriteCustom
    ? getNewCustomTheme(state)
    : updateCustomThemeColor(state, payload),
];

function customThemesReducer(state, { payload, type }) {
  const customThemeTitle =
    state.colorThemes[state.colorThemes.length - 1].title;

  switch (type) {
    case ACTIONS.SET_LOGO_URL: {
      return {
        ...state,
        logoUrl: payload?.value,
      };
    }

    case ACTIONS.CLEAR_LOGO: {
      return {
        ...state,
        logoUrl: '',
      };
    }

    case ACTIONS.SET_LOGO_ALT: {
      return {
        ...state,
        logoAlt: payload?.value,
      };
    }

    case ACTIONS.SELECT_THEME: {
      return {
        ...state,
        selectedTheme: payload.title,
      };
    }

    case ACTIONS.SET_THEMES_STATE: {
      return {
        ...state,
        colorThemes: payload?.colorThemes,
        selectedTheme: payload?.selectedTheme,
        logoUrl: payload?.logoUrl,
        logoAlt: payload?.logoAlt,
      };
    }

    case ACTIONS.UPDATE_CUSTOM_COLOR: {
      return {
        ...state,
        colorThemes: updateCustomTheme(state, payload),
      };
    }

    case ACTIONS.CONFIRM_OVERWRITE: {
      return {
        ...state,
        colorThemes: updateCustomTheme(state, payload, true),
        selectedTheme: customThemeTitle,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

/**
 * Provides custom themes, selected theme and logo URL.
 */
const SovosCustomThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(customThemesReducer, {
    colorThemes: THEMES,
    selectedTheme: THEMES[0].title,
    logoUrl: '',
    logoAlt: '',
  });

  // prevent rerenders for components that only use dispatch by returning 2 separate providers
  return (
    <CustomThemesDispatchContext.Provider value={dispatch}>
      <CustomThemesStateContext.Provider value={state}>
        {children}
      </CustomThemesStateContext.Provider>
    </CustomThemesDispatchContext.Provider>
  );
};

SovosCustomThemeProvider.propTypes = {
  children: node.isRequired,
};

export const useCustomTheme = () => useContext(CustomThemesStateContext);
export const useDispatchCustomTheme = () =>
  useContext(CustomThemesDispatchContext);

export default SovosCustomThemeProvider;
