import React from 'react';
import { node, string, object } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme: { palette } }) => ({
  alignItems: 'center',
  borderRight: `1px solid ${palette.divider}`,
  display: 'flex',
  flex: '0 0 auto',
  justifyContent: 'space-between',
  paddingLeft: 0,
  position: 'relative',
  height: '100%',
  '&:last-child': {
    borderRight: 0,
  },
}));

/**
 * `SovosToolbarGroup` wraps elements of specific type. Can have left
 * border if it's not first.
 *
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarGroup = ({ children, classes, className, ...props }) => (
  <Root
    className={clsx('sovosToolbarGroup', className, classes?.root)}
    {...props}
  >
    {children}
  </Root>
);

SovosToolbarGroup.propTypes = {
  /**
   * The content of the toolbar group
   */
  children: node,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosToolbarGroup.defaultProps = {
  children: undefined,
  classes: undefined,
  className: undefined,
};

export default SovosToolbarGroup;
