/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

const SovosLinearProgress = ({
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  className,
  ...rest
}) => (
  <LinearProgress
    aria-label={ariaLabel}
    aria-labelledby={ariaLabelledBy}
    className={clsx('sovosLinearProgress', className)}
    {...rest}
  />
);

SovosLinearProgress.propTypes = {
  /**
   * Callback fired when Aria attributes are missing or both are present.
   */
  'aria-label': ({
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
  }) => {
    if (ariaLabel && ariaLabelledBy) {
      return new Error('Do not include both ariaLabel or ariaLabelledBy');
    }

    if (!ariaLabel && !ariaLabelledBy) {
      return new Error(
        'Either aria-label or aria-labelledby is required for accessibility'
      );
    }
    return null;
  },
  /**
   * Use ariaLabelledBy if the label text is available in the DOM and
   * referencing the DOM content.
   */
  'aria-labelledby': string,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosLinearProgress.defaultProps = {
  'aria-label': undefined,
  'aria-labelledby': undefined,
  className: undefined,
};

SovosLinearProgress.baseComponent = {
  name: 'Linear Progress',
  link: 'linear-progress/',
};

export default SovosLinearProgress;
