import React, { useCallback, useMemo, useState } from 'react';
import {
  arrayOf,
  bool,
  exact,
  element,
  func,
  node,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { styled, useTheme } from '@mui/material/styles';
import { spacingToNumber } from '../internals/utils';
import {
  searchPropTypes,
  actionIconButtonPropTypes,
  bulkActionsPropTypes,
} from '../internals/components/table-group/Toolbar';
import SovosCheckbox from '../sovos-checkbox';
import { filterPropTypes } from '../internals/components/table-group/Filters';
import { quickFilterPropTypes } from '../internals/components/table-group/QuickFilters';
import Group from '../internals/components/table-group/Group';

const Grid = styled(DataGrid)(
  ({
    disableColumnResize,
    checkboxSelection,
    theme: { palette, spacing, typography },
  }) => ({
    backgroundColor: palette.background.default,
    borderRadius: 0,
    border: 'none',
    minHeight: spacing(50),
    maxHeight: '100%',

    '& .MuiDataGrid-main': {
      overflowY: 'auto',
      overflowX: 'hidden',

      '& .MuiDataGrid-columnHeaders': {
        borderRadius: 'unset',

        '& .MuiDataGrid-columnHeaderTitle': {
          ...typography.body2,
        },

        '& .MuiDataGrid-columnHeader--filledGroup , & .MuiDataGrid-columnHeader--emptyGroup':
          {
            backgroundColor: palette.grey[200],
            color: palette.text.primary,

            '&:last-of-type': {
              minWidth: '100% !important',
            },
          },

        '& .MuiDataGrid-columnHeader--emptyGroup': {
          '&:first-of-type': {
            width: checkboxSelection ? `${spacing(4)} !important` : null,
            minWidth: checkboxSelection ? `${spacing(4)} !important` : null,
          },
        },

        '& .MuiDataGrid-columnHeader--filledGroup': {
          padding: checkboxSelection
            ? `0 ${spacing(2)} 0 ${spacing(0.5)}`
            : null,
        },

        '& .MuiDataGrid-columnHeader--filledGroup ~ .MuiDataGrid-columnHeader--filledGroup':
          {
            padding: checkboxSelection ? '0 10px' : null,
          },
      },

      '& .MuiDataGrid-columnHeaderTitleContainer': {
        border: 'none',
      },
    },

    '& .sovosDataGrid-header': {
      border: 'none',
      color: palette.text.secondary,
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },

    '& .sovosDataGrid-validationError': {
      backgroundColor: palette.error.light,
    },

    '& .MuiDataGrid-iconSeparator': {
      display: disableColumnResize ? 'none' : null,
      height: spacing(2),
    },

    '& .MuiDataGrid-columnSeparator--resizable': {
      color: palette.divider,

      '&:hover': {
        color: palette.primary.main,
      },
    },

    '& .MuiDataGrid-cellCheckbox': {
      // MUI doesn't allow to change the following width properties, once it's being set on the element.style
      minWidth: `${spacing(4)} !important`,
      maxWidth: `${spacing(4)} !important`,
      justifyContent: 'right',
      '& + div': {
        padding: `0 ${spacing(2)} 0 ${spacing(0.5)}`,
      },
    },

    '& .MuiDataGrid-columnHeaderCheckbox': {
      // MUI doesn't allow to change the following width properties, once it's being set on the element.style
      minWidth: `${spacing(4)} !important`,
      maxWidth: `${spacing(4)} !important`,
      width: `${spacing(4)} !important`,
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'right',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& + div': {
        padding: `0 ${spacing(2)} 0 ${spacing(0.5)}`,
      },
    },

    '& [aria-sort="ascending"], & [aria-sort="descending"]': {
      color: palette.primary.main,
    },

    '& .MuiDataGrid-iconButtonContainer': {
      '& button': {
        display: 'none',
      },
    },
  })
);

const SovosDataGrid = ({
  className,
  columnGroupingModel,
  columns: columnsProp,
  checkboxSelection,
  'data-testid': dataTestId,
  fieldHasError,
  getCellClassName: getCellClassNameProp,
  isLoading,
  isSingleSelect,
  labels,
  noData,
  noResults,
  onSelectionModelChange,
  rows,
  PaginationFooterProps,
  sortable,
  selectionModel,
  showNoData,
  sx,
  ToolbarProps,
  ...rest
}) => {
  const { spacing } = useTheme();
  const [selectionModelValue, setSelectionModelValue] = useState([]);

  const columns = useMemo(
    () =>
      columnsProp?.map((colDef) => {
        const { headerClassName } = colDef;

        return {
          disableColumnMenu: true,
          disableReorder: true,
          headerClassName: clsx('sovosDataGrid-header', headerClassName),
          sortable,
          ...colDef,
        };
      }),
    [columnsProp, sortable]
  );

  const getCellClassName = (params) => {
    if (!params.row[params.field]) {
      return undefined;
    }

    const cellClassName = getCellClassNameProp
      ? getCellClassNameProp(params)
      : undefined;

    if (fieldHasError && fieldHasError(params.row[params.field])) {
      return clsx('sovosDataGrid-validationError', cellClassName);
    }

    return cellClassName;
  };

  const generateRowSelection = useCallback(
    (selectedRows, setSelectedRows) => {
      if (!isSingleSelect && !onSelectionModelChange) {
        return undefined;
      }

      const onChange = onSelectionModelChange || (() => {});
      return {
        onRowSelection: (params, details) => {
          let newSelection = params;
          if (isSingleSelect) {
            newSelection = newSelection.filter(
              (row) => !selectedRows.includes(row)
            );
          }
          onChange(newSelection, details);
          setSelectedRows(newSelection);
          setSelectionModelValue(newSelection);
        },
        onClearRowSelection: () => {
          onChange([]);
          setSelectedRows([]);
          setSelectionModelValue([]);
        },
        getSelectedCount: () => selectedRows.length,
      };
    },
    [isSingleSelect, onSelectionModelChange]
  );

  const mergedClasses = {
    loading: 'sovosDataGrid__loading',
    noTable: 'sovosDataGrid__noTable',
    noToolbar: 'sovosDataGrid__noTable--no-toolbar',
    root: clsx('sovosDataGrid', className),
    table: 'sovosDataGrid__tableWrapper',
    toolbar: 'sovosDataGrid__toolbar',
  };

  return (
    <Group
      autoHeight
      classes={mergedClasses}
      dataPropName="rows"
      data-testid={dataTestId}
      generateRowSelection={generateRowSelection}
      isLoading={isLoading}
      isSingleSelect={isSingleSelect}
      labels={labels}
      noData={noData}
      noResults={noResults}
      onRowSelectionPropName="onSelectionModelChange"
      PaginationFooterProps={PaginationFooterProps}
      showNoData={showNoData}
      TableComponent={Grid}
      sx={sx}
      TableProps={{
        columnGroupingModel,
        checkboxSelection: checkboxSelection || !!onSelectionModelChange,
        components: {
          BaseCheckbox: (props) => <SovosCheckbox size="tiny" {...props} />,
        },
        columns,
        selectionModel:
          selectionModel || isSingleSelect ? selectionModelValue : undefined,
        onSelectionModelChange,
        disableSelectionOnClick: true,
        experimentalFeatures: { columnGrouping: true },
        getCellClassName,
        headerHeight: spacingToNumber(spacing(4)),
        hideFooter: true,
        rowHeight: spacingToNumber(spacing(5)),
        rows,
        ...rest,
      }}
      ToolbarProps={ToolbarProps}
    />
  );
};

SovosDataGrid.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  autoHeight: bool,
  /**
   * If true, add a checkbox that allows users to select rows
   */
  checkboxSelection: bool,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * If present, the totals bar will be rendered.
   */
  columnGroupingModel: arrayOf(object),
  /**
   * Data grid column definitions
   */
  columns: arrayOf(object).isRequired,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Disable column resize
   */
  disableColumnResize: bool,
  /**
   * Custom callback to determine if a given field has a validation error.
   * func(field: object) => bool
   */
  fieldHasError: func,
  /**
   * Function that applies class name for a cell, func(params:
   * GridCellParams) => string
   */
  getCellClassName: func,
  /**
   * When true, all other props except `labels.loading` are ignored and an
   * indeterminate progress bar is displayed.
   */
  isLoading: bool,
  /**
   * If true, only one row may be selected at a time
   */
  isSingleSelect: bool,
  /**
   * An object of strings and nodes for internationalization
   */
  labels: exact({
    bulkActions: string,
    columnButtonTooltip: node,
    columnDrawer: exact({
      applyButton: node,
      cancelButton: node,
      header: string,
      removeDisabledTooltipText: node,
      removeTooltipText: node,
      addTooltipText: node,
      title: node,
    }),
    filterButtonTooltip: node,
    filterDrawer: exact({
      applyButton: node,
      cancelButton: node,
      title: node,
    }),
    loading: string,
    searchButtonTooltip: node,
    searchPlaceholder: string,
  }),
  /**
   * Used to display an explicit no data message. Displayed when
   * `snowNoData` is true. An object with:
   *
   * - `headingLabel`: string
   * - `helpLabel`: string
   * - `actionButton`: element
   */
  noData: exact({
    headingLabel: string,
    helpLabel: string,
    actionButton: element,
  }),
  /**
   * Used to display a message when the table's `data` array is empty, e.g.
   * because of filtering. An object with:
   *
   * - `headingLabel`: string
   * - `helpLabel`: string
   * - `actionButton`: element
   */
  noResults: exact({
    headingLabel: string,
    helpLabel: string,
    actionButton: element,
  }),
  /**
   * Callback fired when rows are selected in the data grid, controls
   * hiding and showing of selection checkboxes. func(params:
   * GridSelectionsModel, details: GridCallbackDetails)
   */
  onSelectionModelChange: func,
  /**
   * An object of props for the PaginationFooter component. Refer to the
   * documentation for `SovosPaginationFooter`
   */
  PaginationFooterProps: object,
  /**
   * Data grid rows
   */
  rows: arrayOf(object).isRequired,
  /**
   * Disable column sortable
   */
  sortable: bool,
  /**
   * Set the selection model of the grid.
   */
  selectionModel: arrayOf(oneOfType([string, number])),
  /**
   * When true, the `noData` props are used to display a message, and the
   * table and pagination footer props are ignored
   */
  showNoData: bool,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * An object of props for the Toolbar:
   *
   * - `actionIconButtons`: actionIconButtonPropTypes
   *
   *   - Specifies additional icon buttons to be rendered in the toolbar. Array
   *       of objects with an `icon`, `onClick`, and `tooltipText`.
   * - `bulkActions`: bulkActionsPropTypes
   *
   *   - When present, selecting any cells will open the bulk actions bar.
   *       Array of objects with an `icon`, `tooltipText`, and `onClick`
   * - `children`: arrayOf(node)
   *
   *   - When present, a toolbar will be rendered with the contents of the
   *       array. All other toolbar props will be ignored. When using
   *       `children`, be sure to manage loading states in the toolbar as
   *       well as any additional state that the toolbar may require such
   *       as selected row count.
   * - `DataGroupingProps`: object
   *
   *   - When present, a data grouping will be displayed in the toolbar. See
   *       `SovosToolbarDataGrouping` documentation for more details.
   * - `filters`: filterPropTypes
   *
   *   - Object. Specifies which filter components to render in the filter
   *       drawer. `filterTypes` are in the /helpers directory.
   *
   *       - `items`: array of objects with `label`, `type`, and `dataKey`. Any
   *               other properties will be passed to the filter component.
   *               `Type` should be one of `textEntry`, `checkboxList`,
   *               `dateInput`, `dateRangePicker`, and `numberRangePicker`.
   *       - `onApply`: func
   *       - `values`: object
   * - `quickFilters`: quickFilterPropTypes
   *
   *   - Object. Similar to regular filters. Quick filter types are `toggle`,
   *       `dateSelector`, `dropdown`, `monthSelector`, and `monthRangePicker`.
   * - `search`: searchPropTypes
   *
   *   - Object. Controls the toolbar search component.
   *
   *       - `term`: string
   *       - `onSearch`: func
   * - `setVisibleColumns`: func
   *
   *   - When present, a column drawer will be available. The callback receives
   *       a new columns array with `visibility: false` set on hidden columns.
   * - `toolbarTitle`: node
   *
   *   - When present, the toolbar will be rendered with a title
   */
  ToolbarProps: exact({
    actionIconButtons: actionIconButtonPropTypes,
    bulkActions: bulkActionsPropTypes,
    children: arrayOf(node),
    DataGroupingProps: object,
    filters: filterPropTypes,
    quickFilters: quickFilterPropTypes,
    search: searchPropTypes,
    setVisibleColumns: func,
    toolbarTitle: node,
  }),
};

SovosDataGrid.defaultProps = {
  autoHeight: true,
  checkboxSelection: false,
  className: undefined,
  columnGroupingModel: undefined,
  'data-testid': undefined,
  disableColumnResize: true,
  fieldHasError: (field) => field && field.error,
  getCellClassName: undefined,
  isLoading: false,
  isSingleSelect: false,
  labels: undefined,
  noData: undefined,
  noResults: undefined,
  onSelectionModelChange: undefined,
  PaginationFooterProps: undefined,
  sortable: false,
  selectionModel: undefined,
  showNoData: false,
  sx: undefined,
  ToolbarProps: undefined,
};

SovosDataGrid.baseComponent = {
  name: 'Data Grid',
  link: 'https://mui.com/x/react-data-grid/',
};

export default SovosDataGrid;
