import React, { useCallback, useEffect, useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import {
  Visibility as VisibleIcon,
  VisibilityOff as HiddenIcon,
} from 'mosaic-react-icons';
import SovosEditableDrawer from '../sovos-editable-drawer/SovosEditableDrawer';
import SovosOrderableList from '../sovos-orderable-list';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

/**
 * This Column Drawer offers toggling of the visibility of the columns of a
 * SovosTable
 */
const SovosColumnDrawer = ({
  ApplyButtonProps,
  CancelButtonProps,
  className,
  columns,
  open,
  style,
  title,
  OrderableListProps,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  const getColumns = useCallback(
    (visible) =>
      columns.filter((col) => {
        if (col.title == null || col.title === '') return false;

        return visible ? col.visible : !col.visible;
      }),
    [columns]
  );

  const [visibleColumns, setVisibleColumns] = useState(getColumns(true));

  const [hiddenColumns, setHiddenColumns] = useState(getColumns(false));

  const resetColumns = useCallback(() => {
    setVisibleColumns(getColumns(true));
    setHiddenColumns(getColumns(false));
  }, [getColumns]);

  useEffect(() => {
    resetColumns();
  }, [getColumns, resetColumns, columns]);

  const onChange = (visible, hidden) => {
    setVisibleColumns(visible.map((col) => ({ ...col, visible: true })));
    setHiddenColumns(hidden.map((col) => ({ ...col, visible: false })));
  };

  const onApply = () => {
    ApplyButtonProps.onClick([...visibleColumns, ...hiddenColumns]);
  };

  const onCancel = () => {
    CancelButtonProps.onClick();
    resetColumns();
  };

  return (
    <SovosEditableDrawer
      className={clsx('sovosColumnDrawer', className)}
      onClose={onCancel}
      open={open}
      PrimaryButtonProps={{
        children: t('apply'),
        className: 'sovosColumnDrawer__applyButton',
        ...ApplyButtonProps,
        onClick: onApply,
      }}
      SecondaryButtonProps={{
        children: t('cancel'),
        className: 'sovosColumnDrawer__cancelButton',
        ...CancelButtonProps,
        onClick: onCancel,
      }}
      style={style}
      title={title || t('columnDrawer.title')}
      {...rest}
    >
      <SovosOrderableList
        activeIcon={<HiddenIcon />}
        activeItems={visibleColumns}
        inactiveIcon={<VisibleIcon />}
        inactiveItems={hiddenColumns}
        labels={{
          addTooltipText:
            OrderableListProps?.addTooltipText ||
            t('columnDrawer.addTooltipText'),
          header: OrderableListProps?.header || t('columnDrawer.header'),
          removeTooltipText:
            OrderableListProps?.removeTooltipText ||
            t('columnDrawer.removeTooltipText'),
          removeDisabledTooltipText:
            OrderableListProps?.removeDisabledTooltipText ||
            t('columnDrawer.removeDisabledTooltipText'),
        }}
        minActiveItems={1}
        onChange={onChange}
      />
    </SovosEditableDrawer>
  );
};

SovosColumnDrawer.propTypes = {
  /**
   * Props for the apply button with required `onClick` callback method
   * that fires on button click. Default children is 'Apply'
   */
  ApplyButtonProps: shape({
    children: string,
    onClick: func.isRequired,
  }).isRequired,
  /**
   * Props for the cancel button with required `onClick` callback method
   * that fires on button click. Default children is 'Cancel'
   */
  CancelButtonProps: shape({
    children: string,
    onClick: func.isRequired,
  }).isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of objects that organize the order and visibility of table columns
   */
  columns: arrayOf(
    shape({
      id: oneOfType([string, number]).isRequired,
      title: string.isRequired,
      visible: bool,
    })
  ).isRequired,
  /**
   * If true, the column drawer is open.
   */
  open: bool.isRequired,
  /**
   * Props applied to the orderable list element
   */
  OrderableListProps: shape({
    labels: object,
  }),
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Title of the column drawer
   */
  title: node,
};

SovosColumnDrawer.defaultProps = {
  className: undefined,
  style: undefined,
  title: undefined,
  OrderableListProps: {
    labels: {
      addTooltipText: undefined,
      header: undefined,
      removeTooltipText: undefined,
      removeDisabledTooltipText: undefined,
    },
  },
};

export default SovosColumnDrawer;
