import React, { cloneElement } from 'react';
import {
  arrayOf,
  func,
  node,
  number,
  object,
  oneOfType,
  string,
  shape,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';
import Avatar from '../sovos-avatar';
import SovosList from '../sovos-list';

const StyledSovosList = styled(SovosList)(
  ({ theme: { palette, spacing } }) => ({
    padding: 0,
    '& .sovosHistoryList': {
      '&__action': {
        alignItems: 'center',
        whiteSpace: 'normal',
        wordBreak: 'normal',
        wordWrap: 'normal',
      },

      '&__row': {
        '&__username': {
          flex: '0 0 auto',
          margin: `${spacing(2)} ${spacing(2)} ${spacing(2)} 0`,
        },
        '&__action': {
          flex: '1 1 auto',
          margin: `${spacing(2)} 0`,
        },
        '&__name': {
          fontWeight: 500,
        },
        '&__timestamp': {
          display: 'block',
          marginBottom: spacing(0.5),
        },
      },
    },

    '& .sovosList__item': {
      alignItems: 'initial',
      borderBottom: `solid 1px ${palette.divider}`,
      height: 'auto',

      '&:last-of-type': {
        borderBottomWidth: 1,
      },
    },
  })
);

/* eslint-disable react/prop-types */
const getRowRenderer =
  (classes) =>
  ({ action, avatarElement, formattedDate, timestamp, username }) => {
    let name;
    let surname = null;
    if (typeof username === 'string') {
      name = username;
    } else if (typeof username === 'object') {
      name = username.name;
      surname = username.surname;
    }

    return (
      <>
        <div className="sovosHistoryList__row__username">
          {avatarElement !== undefined ? (
            cloneElement(avatarElement, { size: 'small' })
          ) : (
            <Avatar
              className="sovosHistoryList__row__avatar"
              name={name}
              surname={surname}
              size="small"
            />
          )}
        </div>
        <article className="sovosHistoryList__row__action">
          {(name || surname) && (
            <SovosTypography
              component="h3"
              variant="body1"
              color="text.primary"
              className="sovosHistoryList__row__name"
            >
              {name} {surname}
            </SovosTypography>
          )}
          <SovosTypography
            className={clsx(
              'sovosHistoryList__row__timestamp',
              classes?.timestamp
            )}
            color="text.secondary"
            component="time"
            variant="body1"
          >
            {formattedDate || timestamp}
          </SovosTypography>
          <SovosTypography
            className="sovosHistoryList__action"
            color="text.primary"
            component="div"
            variant="body1"
          >
            {action}
          </SovosTypography>
        </article>
      </>
    );
  };
/* eslint-enable react/prop-types */

const SovosHistoryList = ({
  classes,
  className,
  data,
  SovosListClasses,
  ...listProps
}) => (
  <StyledSovosList
    {...listProps}
    classes={{
      ...SovosListClasses,
      root: classes?.listRoot,
      row: classes?.listRow,
    }}
    className={clsx('sovosHistoryList', className)}
    data={data}
    rowRenderer={getRowRenderer(classes)}
  />
);

SovosHistoryList.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of event entries to be rendered. Each entry should contain
   * action, timestamp, and optional avatarElement, formattedDate and username.
   */
  data: arrayOf(
    shape({
      avatarElement: node,
      action: node.isRequired,
      formattedDate: string,
      timestamp: number.isRequired,
      username: oneOfType([
        string,
        shape({
          name: string,
          surname: string,
        }),
      ]),
    })
  ).isRequired,
  /**
   * Function used to sort provided data.
   */
  sortFunction: func,
  /**
   * Override or extend the styles applied to the `SovosList` base component
   */
  SovosListClasses: object,
};

SovosHistoryList.defaultProps = {
  classes: undefined,
  className: undefined,
  sortFunction: (e1, e2) => e1.timestamp - e2.timestamp,
  SovosListClasses: undefined,
};

export default SovosHistoryList;
