/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadInvoices, editInvoices } from '../api/invoices';

export const invoicesData = createAsyncThunk(
  'invoices/invoicesData',
  async ({ filters, itemsPerPage, page, searchTerm, sortOrder, sortProp }) => {
    const result = await loadInvoices(
      page,
      itemsPerPage,
      sortProp,
      sortOrder,
      searchTerm,
      filters
    );
    return result;
  }
);

export const invoicesDataUpdate = createAsyncThunk(
  'invoices/invoicesDataUpdate',
  (data) => {
    const result = editInvoices(data);
    return result;
  }
);

const invoices = createSlice({
  name: 'invoices',

  initialState: {
    isLoading: false,
    list: [],
    totalItems: 0,
  },

  extraReducers: (builder) => {
    builder
      .addCase(invoicesData.pending, (state) => {
        state.isLoading = true;
        state.list = [];
        state.totalItems = 0;
      })
      .addCase(invoicesData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload.invoices || [];
        state.totalItems = parseInt(action.payload.totalItems, 10);
      })
      .addCase(invoicesData.rejected, (state) => {
        state.isLoading = false;
        state.list = [];
        state.totalItems = 0;
      });
  },
});

export default invoices.reducer;
