let slidingPanelStore = {};

export function getNumberOfSlides() {
  return Object.keys(slidingPanelStore).length;
}

export function resetStore() {
  slidingPanelStore = {};
}

export function removeSlide(id) {
  delete slidingPanelStore[id];
}

export function createNewSlide(id) {
  slidingPanelStore[id] = true;
}
