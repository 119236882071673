import React, { useMemo, useCallback } from 'react';
import {
  arrayOf,
  bool,
  exact,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import {
  RadioButtonUnchecked,
  CheckCircleOutline,
  UnfoldMore,
} from 'mosaic-react-icons';
import { alpha, styled, useTheme } from '@mui/material/styles';
import cloneDeep from 'lodash.clonedeep';
import { fontSize } from '../sovos-navigation/constants/styles';
import SovosFixedWidthText from '../sovos-fixed-width-text';
import SovosTooltip from '../sovos-tooltip';
import SovosTypography from '../sovos-typography';
import Switcher from '../internals/components/switcher/Switcher';
import { collapsedWidthFactor } from '../themes/sovos-sizes';
import { spacingToNumber } from '../internals/utils';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const findRecursively = (data, nestedProperty, comparisonFunction) => {
  for (let i = 0; i < data.length; i += 1) {
    if (comparisonFunction(data[i])) {
      return data[i];
    }

    if (data[i][nestedProperty]) {
      const foundItem = findRecursively(
        data[i][nestedProperty],
        nestedProperty,
        comparisonFunction
      );
      if (foundItem) return foundItem;
    }
  }
  return undefined;
};

const hasValidId = (account) => {
  if (!account) return false;
  if (!('id' in account)) return false;
  const { id } = account;
  if (typeof id === 'string') {
    if (id === '') return false;
    return true;
  }
  if (typeof id === 'number') return true;
  return false;
};

export const types = {
  PRODUCTION: 'production',
  TEST: 'test',
  DEFAULT: 'default',
};

const getAccountColors = (palette) => ({
  production: palette.success.main,
  test: palette.grey.A200,
  default: 'transparent',
});

const getIconStyles = (spacing) => ({
  width: spacing(2),
  height: spacing(2),
  marginLeft: spacing(0.5),
  alignSelf: 'center',
});

const fallbackAccounts = [{ name: 'error' }];

const StyledSwitcher = styled(Switcher)(
  ({
    isButtonClickable,
    selectedItem: selectedAccount,
    theme: {
      palette,
      shape: themeShape,
      spacing,
      typography,
      transitions: {
        duration: { short: transitionDuration },
      },
    },
  }) => {
    const getBorderColor = () => {
      const accountColors = getAccountColors(palette);
      return selectedAccount
        ? accountColors[selectedAccount.type || 'default']
        : accountColors.default;
    };

    return {
      '.SovosAccountSwitcher': {
        '&__buttonContents': {
          ...typography.body1,
          alignItems: 'center',
          backgroundColor: alpha(palette.primary.light, 0.08),
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: getBorderColor(),
          borderRadius: themeShape.borderRadius,
          color: palette.primary.contrastText,
          cursor: isButtonClickable ? 'pointer' : 'default',
          display: 'flex',
          fontSize,
          fontWeight: 400,
          height: spacing(5),
          justifyContent: undefined,
          padding: `0 ${spacing()} 0 ${spacing(2.5)}`,
          transition: `background-color ${transitionDuration}ms`,
          width: '100%',

          '&:hover': {
            backgroundColor: alpha(palette.primary.light, 0.2),
          },

          '&--minimized': {
            justifyContent: 'center',
            padding: 0,
            marginRight: 0,
          },
        },
        '&__buttonContentsLabel': {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          lineHeight: 1.2,
          textAlign: 'left',
          width: '100%',

          '& .icon': {
            ...getIconStyles(spacing),
          },
        },
        '&__name': {
          flexGrow: '1',
        },
        '&__taxYear': {
          fontWeight: typography.fontWeightBold,
        },
        '&__dropdownIndicator': {
          height: spacing(2.5),
          width: spacing(2.5),
        },
      },
    };
  }
);

const StyledMenuItem = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexGrow: 1,
  maxWidth: '100%',

  '& .text': {
    paddingRight: spacing(1.5),
    flexGrow: 1,
  },

  '& .icon': {
    ...getIconStyles(spacing),
  },
}));

const generateId = (account) =>
  `${account.name}${account.taxYear || ''}${account.type || ''}`;

const SovosAccountSwitcher = ({
  accounts: accountsProp,
  classes,
  className,
  didAccountChange,
  isLoading,
  isMinimal,
  labels,
  minimumSearchCharacters,
  onExpandAccount,
  onSearch,
  selectedAccount: selectedAccountProp,
  setAccount,
  SwitcherClasses,
  ...switcherProps
}) => {
  const { t } = useMosaicTranslation();
  const { palette, spacing } = useTheme();
  const accountColors = getAccountColors(palette);
  const externalSearch = !!onSearch;
  const areAccountsValid =
    Array.isArray(accountsProp) &&
    (externalSearch || (!!accountsProp.length && !!accountsProp[0].name));
  const accounts = areAccountsValid ? accountsProp : fallbackAccounts;
  const isButtonClickable =
    externalSearch || accounts.length > 1 || accounts[0].nestedAccounts;

  const selectedAccount = useMemo(() => {
    const shallowAccountComparison = (account) =>
      (hasValidId(account) ? account.id === selectedAccountProp.id : true) ||
      (account.name === selectedAccountProp.name &&
        account.taxYear === selectedAccountProp.taxYear &&
        account.type === selectedAccountProp.type);

    const accountComparison =
      typeof didAccountChange === 'function'
        ? (account) => !didAccountChange(account, selectedAccountProp)
        : shallowAccountComparison;

    const isInArray =
      !externalSearch &&
      selectedAccountProp &&
      !!findRecursively(accounts, 'nestedAccounts', accountComparison);

    const isValid =
      selectedAccountProp &&
      selectedAccountProp.name &&
      typeof selectedAccountProp.name === 'string';

    if (!isValid) {
      console.error('SovosAccountSwitcher received an invalid selectedAccount');
    }

    if (!externalSearch && !isInArray) {
      console.error(
        'SovosAccountSwitcher did not find selectedAccount in accounts array'
      );
    }

    const validAccount =
      externalSearch || isInArray || (isValid && !areAccountsValid)
        ? selectedAccountProp
        : accounts[0];

    if (hasValidId(validAccount)) return validAccount;

    return {
      id: generateId(validAccount),
      ...validAccount,
    };
  }, [
    areAccountsValid,
    accounts,
    didAccountChange,
    externalSearch,
    selectedAccountProp,
  ]);

  const renderAccountIcon = useCallback(
    (account, color) => {
      const { type } = account;
      let Icon;
      let label;
      switch (type) {
        case types.TEST:
          Icon = RadioButtonUnchecked;
          label = labels?.test || t('accountSwitcher.test');
          break;
        case types.PRODUCTION:
          Icon = CheckCircleOutline;
          label = labels?.production || t('accountSwitcher.production');
          break;
        default:
          return null;
      }

      return (
        <SovosTooltip title={label}>
          <Icon
            classes={{ root: classes?.icon }}
            className="icon"
            style={{ color }}
          />
        </SovosTooltip>
      );
    },
    [classes, labels, t]
  );

  const accountData = useMemo(() => {
    const getItemContent = (account) => {
      const { name, taxYear, type } = account;

      return (
        <StyledMenuItem className={classes?.menuItem}>
          <SovosFixedWidthText
            text={name}
            className={clsx('text', classes?.text)}
          />
          {taxYear && <span>{taxYear}</span>}
          {!!type && renderAccountIcon(account, accountColors[type])}
        </StyledMenuItem>
      );
    };

    const getMenuItem = (account) => {
      const { nestedAccounts, nestedAccountCount, ...rest } = account;
      const item = {
        id: hasValidId(account) ? account.id : generateId(account),
        content: getItemContent(account),
        nestedItemCount: nestedAccountCount,
        ...rest,
      };

      // SovosTreeList has its own types and these would conflict
      if (item.type) {
        item.accountType = item.type;
        delete item.type;
      }

      if (nestedAccounts) {
        item.nestedItems = nestedAccounts.map((nestedAccount) =>
          getMenuItem(nestedAccount)
        );
      }

      return item;
    };

    return accounts.length ? cloneDeep(accounts).map(getMenuItem) : [];
  }, [classes, accountColors, accounts, renderAccountIcon]);

  const handleSetAccount = (item) => {
    const accountMatcher = (account) => item.id === account.id;

    const newSelectedAccount = findRecursively(
      accountData,
      'nestedItems',
      accountMatcher
    );

    const result = { ...newSelectedAccount };

    if (result.accountType) {
      result.type = result.accountType;
    }

    delete result.accountType;
    delete result.content;
    delete result.nestedItems;

    setAccount(result);
  };

  const buttonContents = useMemo(() => {
    const showDropdown =
      !selectedAccount.taxYear && !selectedAccount.type && isButtonClickable;

    const renderTaxYear = () => (
      <SovosTypography
        variant={isMinimal ? 'body2' : 'body1'}
        className="SovosAccountSwitcher__year"
      >
        {selectedAccount && selectedAccount.taxYear.substring(0, 2)}
        <span
          className={clsx('SovosAccountSwitcher__taxYear', classes?.taxYear)}
        >
          {selectedAccount && selectedAccount.taxYear.substring(2)}
        </span>
      </SovosTypography>
    );

    return isMinimal ? (
      <div
        className={clsx(
          'SovosAccountSwitcher__buttonContents',
          'SovosAccountSwitcher__buttonContents--minimized',
          classes?.buttonContentsMinimized,
          classes?.buttonContents
        )}
        data-testid="SovosAccountSwitcher__buttonContents"
      >
        {selectedAccount.taxYear ? (
          renderTaxYear()
        ) : (
          <UnfoldMore
            className={clsx(
              'SovosAccountSwitcher__dropdownIndicator',
              classes?.dropdownIndicator
            )}
            data-testid="SovosAccountSwitcher__dropdown-indicator"
          />
        )}
      </div>
    ) : (
      <div
        className={clsx(
          'SovosAccountSwitcher__buttonContents',
          classes?.buttonContents
        )}
        data-testid="SovosAccountSwitcher__buttonContents"
      >
        <div
          className={clsx(
            'SovosAccountSwitcher__buttonContentsLabel',
            classes?.buttonLabel
          )}
        >
          <SovosFixedWidthText
            text={selectedAccount.name}
            className={clsx('SovosAccountSwitcher__name', classes?.name)}
          />
          {selectedAccount.taxYear && renderTaxYear()}
          {renderAccountIcon(
            selectedAccount,
            selectedAccount.type && accountColors[selectedAccount.type].text
          )}
          {showDropdown && (
            <UnfoldMore
              className={clsx(
                'SovosAccountSwitcher__dropdownIndicator',
                classes?.dropdownIndicator
              )}
              data-testid="SovosAccountSwitcher__dropdown-indicator"
            />
          )}
        </div>
      </div>
    );
  }, [
    classes,
    accountColors,
    isButtonClickable,
    isMinimal,
    renderAccountIcon,
    selectedAccount,
  ]);

  const { loading, noResults, searchTemplate } = labels || {};

  return (
    <StyledSwitcher
      buttonContents={buttonContents}
      buttonTooltip={isMinimal ? selectedAccount.name : null}
      classes={SwitcherClasses}
      className={clsx('sovosAccountSwitcher', className)}
      didSelectionChange={didAccountChange}
      items={accountData}
      isLoading={isLoading}
      labels={{ loading, noResults, searchTemplate }}
      minimumCharacters={minimumSearchCharacters}
      onExpandItem={onExpandAccount}
      onSearch={onSearch}
      onSelectItem={handleSetAccount}
      PopoverProps={{
        anchorOrigin: {
          horizontal: isMinimal
            ? spacingToNumber(spacing(collapsedWidthFactor))
            : 0,
          vertical: isMinimal ? 'top' : 'bottom',
        },
      }}
      selectedItem={selectedAccount}
      showSearch
      {...switcherProps}
    />
  );
};

const accountItemShape = {
  disabled: bool,
  id: oneOfType([number, string]),
  isLoading: bool,
  name: string.isRequired,
  nestedAccountCount: number,
  taxYear: string,
  type: oneOf(Object.values(types)),
};

accountItemShape.nestedAccounts = arrayOf(shape(accountItemShape));

const accountItemPropTypes = shape(accountItemShape);

SovosAccountSwitcher.propTypes = {
  /**
   * Array of objects, each with
   *
   * - `id` string or number. Required if name + taxYear + type may not be unique
   * - `name` string
   * - 'taxYear` string
   * - `type` string, one of , 'production', or 'test', or undefined
   * - `disabled` bool
   * - `nestedAccounts` array of objects
   */
  accounts: arrayOf(accountItemPropTypes).isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Override or extend the styles applied to the component
   */
  className: string,
  /**
   * Custom account equality check
   */
  didAccountChange: func,
  /**
   * If `true`, `onSearch` is loading results
   */
  isLoading: bool,
  /**
   * If `true`, component is rendered in minimal state
   */
  isMinimal: bool,
  /**
   * Custom labels for account types and searching, should only be used for
   * internationalization
   */
  labels: exact({
    loading: string,
    noResults: string,
    production: string,
    searchTemplate: string,
    test: string,
  }),
  /**
   * Minimum number of characters needed to trigger an asynchronous account search
   */
  minimumSearchCharacters: number,
  /**
   * Callback fired when a account is expanded. Used to asynchronously load
   * nested accounts `function(account: object) => void`
   */
  onExpandAccount: func,
  /**
   * Callback fired when text is entered into the search box. Used to
   * asynchronously search accounts. If `onSearch` is provided,
   * `selectedAccount` is required. `function(searchVal: string) => void`
   */
  onSearch: (props) => {
    const { onSearch, selectedAccount } = props;

    if (onSearch && typeof onSearch !== 'function') {
      return new Error('onSearch must be a function.');
    }

    if (onSearch && !selectedAccount) {
      return new Error(
        'When onSearch is passed in, selectedAccount is required.'
      );
    }

    return null;
  },
  /**
   * When missing, invalid, or not in the accounts array, the first account
   * will be selected.
   */
  selectedAccount: accountItemPropTypes,
  /**
   * Callback fired when a new account is selected
   */
  setAccount: func.isRequired,
  /**
   * Override or extend the styles applied to the Switcher component
   */
  SwitcherClasses: object,
};

SovosAccountSwitcher.defaultProps = {
  classes: undefined,
  className: undefined,
  didAccountChange: undefined,
  isLoading: false,
  isMinimal: false,
  labels: {
    loading: undefined,
    noResults: undefined,
    production: undefined,
    searchTemplate: undefined,
    test: undefined,
  },
  minimumSearchCharacters: undefined,
  onExpandAccount: undefined,
  onSearch: undefined,
  selectedAccount: undefined,
  SwitcherClasses: undefined,
};

export default SovosAccountSwitcher;
