import React, { useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SovosGlobalLayout } from 'mosaic-react';
import {
  Business,
  Dashboard,
  Info,
  Public,
  Settings,
  ShoppingCart,
} from 'mosaic-react-icons';
import ProtectedRoute from './ProtectedRoute';
import UsersPage from '../users/UsersPage';
import DashboardPage from '../dashboard/DashboardPage';
import AboutPage from '../about/AboutPage';
import NotFoundPage from '../notFound/NotFoundPage';
import InvoicesPage from '../invoices/InvoicesPage';
import InvoicePage from '../invoices/InvoicePage';
import ImportsPage from '../imports/ImportsPage';
import ImportPage from '../imports/ImportPage';
import FilingsPage from '../filings/FilingsPage';
import MyAccountPage from '../settings/MyAccount';
import LoginPage from '../login/LoginPage';
import auth from '../authentication';

const routes = [
  {
    label: 'My Dashboard',
    route: '/dashboard',
    icon: Dashboard,
    isDefault: true,
  },
  {
    label: 'GVAT',
    icon: ShoppingCart,
    nestedLinks: [
      {
        label: 'eInvoices - Sent',
        route: '/invoices/all',

        detailRoute: '/invoices/:id',
      },
      {
        label: 'Batch Import',
        route: '/imports/all',

        detailRoute: '/imports/:id',
      },
    ],
  },
  {
    label: 'Settings',
    icon: Settings,
    nestedLinks: [
      {
        label: 'Filing Calendar',
        route: '/filings/all',
      },
      {
        label: 'Users',
        route: '/settings/users',
      },
    ],
  },
  {
    label: 'About',
    route: '/about',
    icon: Info,
  },
];

const defaultRoute = routes.find((r) => r.isDefault).route;

const flattenedRoutes = routes
  .map((link) => [
    link.nestedLinks
      ? link.nestedLinks.map((nested) => ({ ...nested }))
      : { ...link },
  ])
  .flat(2);

function getActiveLink(location) {
  const pathWithoutNumbers = location.pathname
    .split('/')
    .map((str) => (!parseInt(str, 10) ? str : ':id'))
    .join('/');

  const route = flattenedRoutes.find(
    (r) =>
      r.route === pathWithoutNumbers || r.detailRoute === pathWithoutNumbers
  );

  return route?.label || defaultRoute;
}

const contexts = [
  { name: 'Context 1' },
  { name: 'Context 2' },
  {
    name: 'Context 3',
    nestedContexts: [
      {
        name: 'Nested context 1',
        nestedContexts: [
          { name: 'Deeply nested context 1', disabled: true },
          { name: 'Deeply nested context 2' },
        ],
      },
      { name: 'Nested context 2' },
    ],
  },
];

const products = [
  { id: '0', name: 'Sovos Demo App' },
  { id: '1', name: 'Global Compliance', icon: Public },
  { id: '2', name: 'TIR Enterprise', icon: Business },
];

function App() {
  const navigate = useNavigate();
  const [context, setContext] = useState(contexts[0]);
  const [product, setProduct] = useState(products[0]);
  const location = useLocation();

  const isLoggedIn = auth.isAuthenticated();
  const account = useSelector((state) => state.account);

  function mapLinks(link) {
    return {
      ...link,
      action: link.route
        ? () => {
            navigate(link.route);
          }
        : undefined,
      nestedLinks: link.nestedLinks && link.nestedLinks.map(mapLinks),
    };
  }

  function getNavProps() {
    return {
      links: routes.map(mapLinks),
      initialSelectedLink: getActiveLink(location),
      contexts,
      selectedContext: context,
      setContext,
      setAccount: () => {},
      product,
      products,
      onSelectProduct: setProduct,
      FooterProps: {
        onHelpClicked: () => {},
        userName: `${account.current.first} ${account.current.last}`,
        settingsMenuItems: [],
        userMenuItems: [
          {
            label: 'My Account',
            action: () => {
              navigate('/settings/myAccount');
            },
          },
          {
            label: 'Logout',
            action: () => {
              auth.logout();
              navigate('/login');
            },
          },
        ],
      },
    };
  }

  return (
    <Routes>
      <Route
        exact
        path="/login"
        element={
          isLoggedIn ? <Navigate to={defaultRoute} replace /> : <LoginPage />
        }
      />
      <Route path="/notFound" element={<NotFoundPage />} />
      <Route
        path="/*"
        element={
          <ProtectedRoute
            element={
              <SovosGlobalLayout NavigationProps={getNavProps()}>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Navigate to={defaultRoute} replace />}
                  />
                  <Route exact path="dashboard" element={<DashboardPage />} />
                  <Route exact path="invoices/all" element={<InvoicesPage />} />
                  <Route exact path="invoices/:id" element={<InvoicePage />} />
                  <Route exact path="imports/all" element={<ImportsPage />} />
                  <Route exact path="imports/:id" element={<ImportPage />} />
                  <Route exact path="filings/all" element={<FilingsPage />} />
                  <Route exact path="settings/users" element={<UsersPage />} />
                  <Route path="about" element={<AboutPage />} />
                  <Route
                    exact
                    path="settings/myAccount"
                    element={MyAccountPage}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/notFound" replace />}
                  />
                </Routes>
              </SovosGlobalLayout>
            }
          />
        }
      />
    </Routes>
  );
}

export default App;
