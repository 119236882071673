import { number, any, exact, arrayOf } from 'prop-types';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { SovosTableGroup } from 'mosaic-react';

const columns = [
  {
    title: 'Company Code',
    id: 1,
    width: 128,
    dataKey: 'companyCode',
  },
  {
    title: 'Accounting Document',
    id: 2,
    width: 128,
    dataKey: 'accountingDocument',
  },
  {
    title: 'Assignment Number',
    id: 3,
    width: 128,
    dataKey: 'assignmentNumber',
  },
  {
    title: 'Cost Center',
    id: 4,
    width: 72,
    dataKey: 'costCenter',
  },
  {
    title: 'Business Area',
    id: 5,
    width: 88,
    dataKey: 'businessArea',
  },
  {
    title: 'CPBR Indicator',
    id: 6,
    width: 'greedy',
    dataKey: 'cpbrIndicator',
  },
];

function ImportTable({ data }) {
  const { spacing } = useTheme();

  return (
    <SovosTableGroup
      toolbarTitle="Transactions"
      className="ImportTable"
      TableProps={{
        fixedHeader: true,
        maxHeight: `calc(100vh - ${spacing(41)})`,
        columns,
        data,
      }}
    />
  );
}

ImportTable.propTypes = {
  data: arrayOf(
    exact({
      id: number,
      companyCode: number,
      accountingDocument: number,
      assignmentNumber: number,
      costCenter: any,
      businessArea: number,
      cpbrIndicator: any,
    })
  ).isRequired,
};

export default ImportTable;
