import logoLightSvg from './assets/images/branding/SovosLogoSVG_light.svg';
import logoDarkSvg from './assets/images/branding/SovosLogoSVG_dark.svg';
import heroDarkBgImg from './assets/images/heroBanners/colors/hero-bg-darkBlue.png';
import heroDefaultBgImg from './assets/images/heroBanners/colors/hero-bg-blue.png';
import heroErrorBgImg from './assets/images/heroBanners/colors/hero-bg-red.png';
import heroInProgressBgImg from './assets/images/heroBanners/colors/hero-bg-grayMedium.png';
import heroSuccessBgImg from './assets/images/heroBanners/colors/hero-bg-green.png';
import heroWarningBgImg from './assets/images/heroBanners/colors/hero-bg-yellow.png';
import assetKeys from './assetKeys';

import enUSCommon from './assets/locales/en-US/common.json';
import enUSs1ui from './assets/locales/en-US/s1ui.json';

import esCommon from './assets/locales/es/common.json';
import esS1Ui from './assets/locales/es/s1ui.json';

import ptCommon from './assets/locales/pt-BR/common.json';
import ptS1Ui from './assets/locales/pt-BR/s1ui.json';

const { branding, heroBanners, locales } = assetKeys;
const localesEn = { ...enUSCommon, ...enUSs1ui };
const localesEs = { ...esCommon, ...esS1Ui };
const localesPt = { ...ptCommon, ...ptS1Ui };

export default {
  branding: {
    [branding.logoLight]: logoLightSvg,
    [branding.logoDark]: logoDarkSvg,
  },
  heroBanners: {
    [heroBanners.colors.dark]: heroDarkBgImg,
    [heroBanners.colors.default]: heroDefaultBgImg,
    [heroBanners.colors.error]: heroErrorBgImg,
    [heroBanners.colors.inProgress]: heroInProgressBgImg,
    [heroBanners.colors.success]: heroSuccessBgImg,
    [heroBanners.colors.warning]: heroWarningBgImg,
  },
  locales: {
    [locales.en]: localesEn,
    [locales['en-US']]: localesEn,
    [locales.es]: localesEs,
    [locales['es-ES']]: localesEs,
    [locales.pt]: localesPt,
    [locales['pt-BR']]: localesPt,
  },
};
