/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const url = '/api/v1/invoices';

export async function loadInvoices(
  page,
  itemsPerPage,
  sortProp,
  sortOrder,
  searchTerm,
  filters
) {
  let params;

  if (searchTerm) {
    params = {
      q: searchTerm,
    };
  } else {
    params = {
      id: filters.invoice,
      type: filters.type,
      status: filters.status,
      'purchasedBy.companyName': filters.purchasedBy,
      'soldBy.companyName': filters.soldBy,
    };
  }

  params._sort = sortProp;
  params._order = sortOrder;

  if (page && itemsPerPage) {
    params._page = page;
    params._limit = itemsPerPage;
  }

  const response = await axios.get(url, { params });
  const invoices = response.data;
  const totalItems = response.headers['x-total-count'];
  return { totalItems, invoices };
}

export async function loadInvoice(id) {
  const response = await axios.get(`${url}/${id}`);
  const invoices = response.data;
  return invoices;
}

export function editInvoices(data) {
  return axios.put(`${url}/${data.id}`, data);
}
