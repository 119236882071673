import React, { useState, useMemo } from 'react';
import { array, bool, func, node, exact, string } from 'prop-types';
import SovosToolbarColumn from '../../../sovos-toolbar-column';
import SovosColumnDrawer from '../../../sovos-column-drawer';

const Columns = ({
  buttonTooltipText,
  disabled,
  labels,
  setVisibleColumns,
  tableColumns,
}) => {
  const [columnDrawerOpen, setColumnDrawerOpen] = useState(false);

  const {
    addTooltipText,
    applyButton,
    cancelButton,
    header,
    removeDisabledTooltipText,
    removeTooltipText,
    title,
  } = labels;

  const onApplyVisibleColumns = (columns) => {
    setVisibleColumns(columns);
    setColumnDrawerOpen(false);
  };

  const columnsWithVisibility = useMemo(
    () =>
      tableColumns &&
      tableColumns.map((column) => {
        if (column.visible === undefined) {
          // eslint-disable-next-line no-param-reassign
          column.visible = true;
        }

        return column;
      }),
    [tableColumns]
  );

  return (
    <>
      <SovosToolbarColumn
        disabled={disabled}
        onClick={() => setColumnDrawerOpen((open) => !open)}
        tooltipText={buttonTooltipText}
      />
      <SovosColumnDrawer
        open={columnDrawerOpen}
        columns={columnsWithVisibility}
        ApplyButtonProps={{
          children: applyButton,
          onClick: onApplyVisibleColumns,
        }}
        CancelButtonProps={{
          children: cancelButton,
          onClick: () => setColumnDrawerOpen(false),
        }}
        OrderableListProps={{
          labels: {
            addTooltipText,
            header,
            removeDisabledTooltipText,
            removeTooltipText,
          },
        }}
        title={title}
      />
    </>
  );
};

Columns.propTypes = {
  buttonTooltipText: node,
  disabled: bool,
  setVisibleColumns: func,
  tableColumns: array,
  labels: exact({
    applyButton: node,
    cancelButton: node,
    header: string,
    removeDisabledTooltipText: node,
    removeTooltipText: node,
    addTooltipText: node,
    title: node,
  }),
};

Columns.defaultProps = {
  buttonTooltipText: undefined,
  disabled: false,
  setVisibleColumns: undefined,
  tableColumns: undefined,
  labels: {},
};

export default Columns;
