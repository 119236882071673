import React from 'react';
import { func, arrayOf, number, object } from 'prop-types';
import { styled } from '@mui/material/styles';
import { tabBarPadding } from '../../internals/page-layout/contentPadding';
import SovosTabs from '../../sovos-tabs';
import SovosTab from '../../sovos-tab';

const Root = styled(SovosTabs)(
  ({ theme: { breakpoints, palette, spacing } }) => ({
    ...tabBarPadding(breakpoints, spacing),
    backgroundColor: palette.background.default,
    padding: `0 ${spacing(2)}`,
    zIndex: 20,
  })
);

const TabBar = ({ onTabClick, selectedTab, tabsData }) => {
  function renderTab(tab, index) {
    return (
      <SovosTab
        key={`Tab${index}-${tab.label}`}
        icon={tab.icon}
        label={tab.label}
        value={index}
      />
    );
  }

  return (
    <Root
      className="sovosPageLayout__tabBar"
      onChange={onTabClick}
      value={selectedTab}
    >
      {tabsData.map(renderTab)}
    </Root>
  );
};

TabBar.propTypes = {
  onTabClick: func.isRequired,
  selectedTab: number.isRequired,
  tabsData: arrayOf(object).isRequired,
};

export default TabBar;
