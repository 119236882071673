import React, { useState } from 'react';
import { func, object, string, shape } from 'prop-types';
import clsx from 'clsx';
import { AttachFile, Send } from 'mosaic-react-icons/';
import { styled, useTheme } from '@mui/material/styles';
import SovosIconButton from '../sovos-icon-button';
import SovosTextField from '../sovos-text-field';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledCommentForm = styled('form')(
  ({ theme: { palette, shape: themeShape, spacing } }) => ({
    alignItems: 'flex-start',
    backgroundColor: palette.background.default,
    border: `1px solid ${palette.divider}`,
    borderRadius: themeShape.borderRadius,
    display: 'flex',
    padding: `${spacing(3)} ${spacing()} ${spacing(3)} ${spacing(2)}`,
  })
);

const StyledTextField = styled(SovosTextField)(
  ({ theme: { palette, spacing, typography } }) => ({
    // Use Mui-textfield root class
    '&.sovosCommentForm__commentField': {
      minHeight: spacing(5),
      paddingTop: spacing(0.75),
      width: '100%',

      '$ .MuiInput-root': {
        color: palette.text.secondary,
        ...typography.body1,

        '&.Mui-error': {
          whiteSpace: 'pre',
        },
      },
    },
  })
);

const SovosCommentForm = ({
  attachmentButtonTooltipText,
  className,
  commentPlaceholderLabel,
  errorLabels,
  onAttachmentClicked,
  onSubmit,
  onCommentContentChange,
  style,
  submitButtonTooltipText,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  const { palette } = useTheme();
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');

  const validate = (newComment) => {
    if (newComment && newComment.length > 500) {
      setError(errorLabels?.length || t('commentForm.errorLength'));
      return false;
    }

    setError('');
    return true;
  };

  const handleCommentChanged = (event) => {
    const newComment = event.target.value;

    if (onCommentContentChange) {
      onCommentContentChange(newComment);
    }

    setComment(newComment);
    validate(newComment);
  };

  const handleSubmit = () => {
    if (!comment || comment.length === 0) {
      setError(errorLabels?.empty || t('commentForm.errorEmpty'));
      return;
    }

    onSubmit(comment);
    setComment('');
    setError('');
  };

  return (
    <StyledCommentForm
      onSubmit={handleSubmit}
      className={clsx('sovosCommentForm', className)}
      style={style}
      {...rest}
    >
      <StyledTextField
        className="sovosCommentForm__commentField"
        error={!!error}
        helperText={error}
        InputProps={{
          disableUnderline: true,
        }}
        multiline
        name="add-comment"
        onChange={handleCommentChanged}
        placeholder={
          commentPlaceholderLabel || t('commentForm.commentPlaceholder')
        }
        value={comment}
        variant="standard"
      />

      {onAttachmentClicked ? (
        <SovosIconButton
          tooltipText={
            attachmentButtonTooltipText ||
            t('commentForm.attachmentButtonTooltipText')
          }
          className="sovosCommentForm__addAttachmentButton"
          onClick={onAttachmentClicked}
        >
          <AttachFile />
        </SovosIconButton>
      ) : null}

      <SovosIconButton
        className="sovosCommentForm__submitButton"
        color={palette.text.primary}
        onClick={handleSubmit}
        tooltipText={submitButtonTooltipText || t('commentForm.submitButton')}
      >
        <Send />
      </SovosIconButton>
    </StyledCommentForm>
  );
};

SovosCommentForm.propTypes = {
  /**
   * Tooltip for attachment button
   */
  attachmentButtonTooltipText: string,
  /**
   * Extend the class name applied to the root element
   */
  className: string,

  /**
   * Placeholder text for the comment form
   */
  commentPlaceholderLabel: string,

  /**
   * Text for the error message
   */
  errorLabels: shape({
    empty: string.isRequired,
    length: string.isRequired,
  }),

  /**
   * Callback fired when the submit button is clicked
   */
  onSubmit: func.isRequired,

  /**
   * Callback fired when the attachment icon is clicked
   */
  onAttachmentClicked: func,

  /**
   * Callback fired when the input value is changed `function(comment:
   * string) => void`
   */
  onCommentContentChange: func,

  /**
   * Inline styles applied to the root element
   */
  style: object,

  /**
   * Tooltip for the submit button
   */
  submitButtonTooltipText: string,
};

SovosCommentForm.defaultProps = {
  attachmentButtonTooltipText: undefined,
  className: undefined,
  commentPlaceholderLabel: undefined,
  errorLabels: undefined,
  onAttachmentClicked: undefined,
  onCommentContentChange: undefined,
  style: undefined,
  submitButtonTooltipText: undefined,
};

export default SovosCommentForm;
