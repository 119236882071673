import React from 'react';
import PropTypes from 'prop-types';
import { SovosCard, SovosGrid, SovosTypography } from 'mosaic-react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

const DashboardGridItem = ({ link, title, description, iconType }) => {
  const { spacing, palette } = useTheme();

  return (
    <SovosGrid item xs={12} sm={6} md={5} lg={4}>
      <SovosCard
        sx={{
          backgroundColor: alpha(palette.background.default, 0.9),
          height: `calc(100vh - ${spacing(40)})`,
          textAlign: 'center',
          '& .sovosCard__content': {
            height: `100%`,
            padding: 0,
          },
        }}
      >
        <Box
          component={Link}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            color: palette.info.dark,
            textDecoration: 'none',
          }}
          to={link}
        >
          <div>
            <Box
              component={iconType}
              sx={{
                fontSize: spacing(14),
                marginBottom: spacing(4),
              }}
            />
            <SovosTypography
              color="inherit"
              sx={{
                marginBottom: spacing(2),
              }}
              variant="h4"
            >
              {title}
            </SovosTypography>
            <SovosTypography variant="subtitle1" color="inherit">
              {description}
            </SovosTypography>
          </div>
        </Box>
      </SovosCard>
    </SovosGrid>
  );
};

DashboardGridItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconType: PropTypes.elementType.isRequired,
};

export default DashboardGridItem;
