import React from 'react';
import { node, object, string } from 'prop-types';
import clsx from 'clsx';
import { alpha, styled } from '@mui/material/styles';
import SovosSettingsTitle from '../sovos-settings-title';
import SovosSettingsSubtitle from '../sovos-settings-subtitle';
import SovosSettingsCard from '../sovos-settings-card';
import { findChildByType } from '../internals/utils/reactHelpers';

const Root = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  padding: spacing(2),
  position: 'relative',
  '&:last-child .sovosSettingsSection__divider': {
    display: 'none',
  },
  [breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const Header = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  [breakpoints.up('md')]: {
    boxSizing: 'inherit',
    flex: '1 1 33.337%',
    marginRight: spacing(),
  },
}));

const Divider = styled('hr')(({ theme: { palette, spacing } }) => ({
  background: 'transparent',
  color: 'transparent',
  border: `.5px solid ${alpha(palette.common.black, 0.05)}`,
  margin: 0,
  position: 'absolute',
  bottom: 0,
  left: spacing(2),
  right: spacing(2),
}));

/**
 * `SovosSettingsSection` positions the `SovosSettingsTitle`,
 * `SovosSettingsSubtitle`, and `SovosSettingsCard` components.
 *
 * **Note**: Must be a child of `SovosSettings`
 */
const SovosSettingsSection = ({ classes, children, className, ...rest }) => (
  <Root
    className={clsx('sovosSettingsSection', classes?.root, className)}
    {...rest}
  >
    <Header className={clsx('sovosSettingsSection__header', classes?.header)}>
      {findChildByType(children, SovosSettingsTitle)}
      {findChildByType(children, SovosSettingsSubtitle)}
    </Header>
    {findChildByType(children, SovosSettingsCard)}
    <Divider
      className={clsx('sovosSettingsSection__divider', classes?.divider)}
    />
  </Root>
);

SovosSettingsSection.propTypes = {
  /**
   * Content of the settings section. Should be wrapped in
   * `SovosSettingsTitle`, `SovosSettingsSubtitle`, and `SovosSettingsCard`
   */
  children: node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosSettingsSection.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default SovosSettingsSection;
