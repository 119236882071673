/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GetApp } from 'mosaic-react-icons';
import { SovosPageContentTable } from 'mosaic-react';
import { useErrorSnackbar } from 'mosaic-react/hooks';
import Dialog from '../commonComponents/Dialog';
import { importsData } from './services/imports';

const firstPage = 1;

const columns = [
  {
    title: 'Date and Time',
    id: 1,
    width: 120,
    dataKey: 'formattedTimeStamp',
  },
  {
    title: 'File Name',
    id: 2,
    width: 'greedy',
    dataKey: 'fileName',
  },
  {
    title: 'Username',
    id: 3,
    width: 'greedy',
    dataKey: 'userName',
  },
  {
    title: 'Company Code',
    id: 4,
    width: 100,
    dataKey: 'companyCode',
  },
  {
    title: 'First Report Date',
    id: 5,
    width: 100,
    dataKey: 'formattedReportDate',
  },
];

const availableFilters = [
  {
    label: 'Import date',
    dataKey: 'timeStamp',
    type: 'dateRangePicker',
    format: 'dd/MM/yyyy',
  },
  {
    label: 'First report date',
    dataKey: 'reportDate',
    type: 'dateRangePicker',
    format: 'dd/MM/yyyy',
  },
];

function ImportsTable() {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [sortedId, setSortedId] = useState(1);
  const [sortColumn, setSortColumn] = useState('country');
  const [ascending, setAscending] = useState(true);
  const { addError } = useErrorSnackbar();
  const [appliedFilters, setAppliedFilters] = useState({});

  const imports = useSelector((state) => state.imports);
  const dispatch = useDispatch();

  const { list } = imports;

  useEffect(() => {
    dispatch(
      importsData({
        filters: appliedFilters,
        itemsPerPage,
        page: currentPage,
        sortOrder: ascending ? 'asc' : 'desc',
        sortProp: sortColumn,
      })
    ).catch((err) => {
      addError(err);
    });
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    sortColumn,
    ascending,
    appliedFilters,
    addError,
  ]);

  function sort(column) {
    const newAscending = sortedId === column.id ? !ascending : true;
    const newSortColumn = (() => {
      switch (column.id) {
        case 1:
          return 'timeStamp';
        case 2:
          return 'fileName';
        case 3:
          return 'userName';
        case 4:
          return 'companyCode';
        case 5:
          return 'reportDate';
        default:
          return 'timeStamp';
      }
    })();

    setAscending(newAscending);
    setSortColumn(newSortColumn);
    setSortedId(column.id);
    setCurrentPage(firstPage);
  }

  const onPageChange = (newPage, newItemsPerPage) => {
    setCurrentPage(newPage);
    setItemsPerPage(newItemsPerPage);
  };

  const openDialog = (text) => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  const bulkActions = [
    {
      icon: <GetApp />,
      tooltipText: 'Export records',
      onClick: () => openDialog(`Exporting ${selectedRows.length} records.`),
    },
  ];

  const onRowClick = useCallback(
    (row) => {
      navigate(`/imports/${row.id}`);
    },
    [navigate]
  );

  return (
    <>
      <SovosPageContentTable
        isLoading={imports.isLoading}
        toolbarTitle="Imports"
        bulkActions={bulkActions}
        onRowSelection={setSelectedRows}
        filters={{
          items: availableFilters,
          onApply: setAppliedFilters,
          values: appliedFilters,
        }}
        PaginationFooterProps={{
          itemsPerPage,
          currentPage,
          totalItems: imports.totalItems,
          onPageChange,
          itemsPerPageOptions: [10, 20, 50],
        }}
        className="ImportsTable__ImportsTable"
        TableProps={{
          columns,
          data: list,
          onColumnSort: sort,
          onRowClick,
          columnSortId: sortedId,
        }}
      />
      <Dialog
        text={dialogText}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}

export default ImportsTable;
