export function contentSidePadding(breakpoints, spacing) {
  return {
    [breakpoints.down('md')]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    [breakpoints.up('md')]: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
    [breakpoints.up('lg')]: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
    [breakpoints.up('xl')]: {
      paddingLeft: spacing(6),
      paddingRight: spacing(6),
    },
  };
}

export function contentTopPadding(spacing) {
  return {
    paddingTop: spacing(3),
  };
}

export function headerPadding(breakpoints, spacing) {
  return {
    [breakpoints.down('md')]: {
      paddingInline: spacing(3),
    },
    [breakpoints.up('md')]: {
      paddingInline: spacing(4),
    },
    [breakpoints.up('xl')]: {
      paddingInline: spacing(6),
    },
  };
}

export function tabBarPadding(breakpoints, spacing) {
  return {
    [breakpoints.down('md')]: {
      paddingInline: spacing(1),
    },
    [breakpoints.up('md')]: {
      paddingInline: spacing(2),
    },
    [breakpoints.up('xl')]: {
      paddingInline: spacing(3),
    },
  };
}

export function contentToolbarActions(breakpoints, spacing) {
  return {
    [breakpoints.down('md')]: {
      paddingInline: spacing(2),
    },
    [breakpoints.up('md')]: {
      paddingInline: spacing(3),
    },
    [breakpoints.up('xl')]: {
      paddingInline: spacing(5),
    },
  };
}

export function contentTablePadding(breakpoints, spacing) {
  return {
    [breakpoints.down('md')]: {
      paddingInline: spacing(1),
    },
    [breakpoints.up('md')]: {
      paddingInline: spacing(1),
      marginInline: spacing(2),
    },
    [breakpoints.up('xl')]: {
      paddingInline: spacing(1),
      marginInline: spacing(3),
    },
  };
}
