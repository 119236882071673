import React, { useCallback, useRef, useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  string,
  node,
} from 'prop-types';
import clsx from 'clsx';
import { Search } from 'mosaic-react-icons';
import { useTheme, alpha, styled } from '@mui/material/styles';
import { useClickOutside } from '../hooks';
import { mergeClassNameIntoProps } from '../internals/utils/classNameHelpers';
import SovosIconButton from '../sovos-icon-button';
import SovosTextField from '../sovos-text-field';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const outlineStyle = (color, width = 1) => ({
  outline: `solid ${width} ${color}`,
  outlineOffset: `-${width}`,
});

const SearchForm = styled('form')(
  ({ theme: { palette, spacing, transitions } }) => {
    const width = spacing(40);
    const outlineWidth = spacing(0.25); // 2px

    return {
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      height: 'calc(100% + 2px)', // overlap the toolbar horizontal borders
      justifyContent: 'flex-end',
      maxWidth: width,
      transition: `width ${transitions.duration.standard}ms, outline ${transitions.duration.short}ms`,
      width: spacing(5),
      zIndex: 1000,
      ...outlineStyle(alpha(palette.text.secondary, 0)),

      '&.sovosToolbarSearch--expanded': {
        width,
        '&:hover': outlineStyle(
          alpha(palette.text.secondary, 0.5),
          outlineWidth
        ),
      },

      '&.sovosToolbarSearch--active': {
        ...outlineStyle(palette.primary.main, outlineWidth),
        '&:hover': outlineStyle(palette.primary.main, outlineWidth),
      },
    };
  }
);

const StyledTextField = styled(SovosTextField)(
  ({ theme: { spacing, transitions } }) => ({
    '&.MuiTextField-root': {
      flex: '1 0 auto',
      height: '100%',
    },

    '& .MuiInputBase-root': {
      height: '100%',
      paddingInlineEnd: spacing(),
      paddingInlineStart: spacing(2),
      overflow: 'hidden',
      transition: `width ${transitions.duration.standard}ms`,
      width: 0,

      '&[data-expanded="true"]': {
        width: '100%',
      },
    },
  })
);

/**
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarSearch = ({
  classes,
  className,
  'data-testid': dataTestId,
  defaultValue = '',
  disabled = false,
  IconButtonProps = {},
  onSearch,
  placeholder,
  searchTooltipText,
  sx,
}) => {
  const { palette } = useTheme();
  const [value, setValue] = useState(defaultValue);
  const [isActive, setIsActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useMosaicTranslation();

  const color = isActive ? palette.text.primary : palette.text.secondary;

  const rootRef = useRef(null);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      // Button click open the input if in-active
      if (!isExpanded && !isActive) {
        setIsExpanded(true);
        setIsActive(true);
        return;
      }

      // Fire onSearch if active
      if (isExpanded && onSearch) {
        onSearch(value);
      }
    },
    [isActive, isExpanded, onSearch, value]
  );

  const clearForm = ({ clear = false } = {}) => {
    setIsActive(false);

    if (isExpanded) setIsExpanded(false);

    if (clear) {
      setIsExpanded(false);
      if (value) {
        setValue('');
      }
    }
  };

  useClickOutside(rootRef, () => {
    setIsActive(false);
    if (value === '') setIsExpanded(false);
  });

  const handleFormKeyDown = (event) => {
    if (event.key === 'Escape') {
      clearForm({ clear: true });
    }
  };

  const handleBtnKeyDown = (event) => {
    if (event.key === 'Tab') {
      clearForm();
    }
  };

  if (!IconButtonProps.tooltipText) {
    // eslint-disable-next-line no-param-reassign
    IconButtonProps.tooltipText = searchTooltipText || t('search');
  }

  const renderSearchField = () =>
    isExpanded || value ? (
      <StyledTextField
        autoFocus={!!isExpanded}
        className={clsx('sovosToolbarSearch__input', classes?.text)}
        data-testid="sovosToolbarSearch__searchInput"
        InputProps={{
          'data-expanded': isExpanded ? 'true' : 'false',
          disableUnderline: true,
        }}
        name="toolbar-search-text"
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setIsActive(true)}
        placeholder={placeholder || t('search')}
        value={value}
        variant="standard"
      />
    ) : null;

  return (
    <SearchForm
      className={clsx('sovosToolbarSearch', className, classes?.root, {
        'sovosToolbarSearch--active': isActive,
        [classes?.active]: isActive && classes,
        'sovosToolbarSearch--expanded': isExpanded,
        [classes?.expanded]: isExpanded && classes,
      })}
      data-testid={dataTestId}
      onKeyDown={handleFormKeyDown}
      onSubmit={(e) => handleSubmit(e)}
      ref={rootRef}
      sx={sx}
    >
      {renderSearchField()}
      <SovosIconButton
        color={color}
        data-testid="sovosToolbarSearch__searchButton"
        disabled={disabled}
        onClick={handleSubmit}
        onKeyDown={handleBtnKeyDown}
        type={isExpanded ? 'submit' : 'button'}
        {...mergeClassNameIntoProps(
          IconButtonProps,
          clsx('sovosToolbarSearch__searchButton', {
            'sovosToolbarSearch__searchButton--expanded': isExpanded,
            [classes?.iconExpanded]: isActive && classes,
          })
        )}
      >
        <Search />
      </SovosIconButton>
    </SearchForm>
  );
};

SovosToolbarSearch.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Initial value of the search input
   */
  defaultValue: string,
  /**
   * If `true`, the button is disabled
   */
  disabled: bool,
  /**
   * Props passed to the Icon button component
   */
  IconButtonProps: object,

  /**
   * Callback fired when search icon button has been clicked or on Enter,
   * after search input has been opened `function(value: string) => void`
   */
  onSearch: func,
  /**
   * Text label for the search input placeholder attribute
   */
  placeholder: string,
  /**
   * Text label for the icon button `aria-label` attribute and tooltip
   */
  searchTooltipText: node,
  /**
   * The System prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
};

export default SovosToolbarSearch;
