import React from 'react';
import { node } from 'prop-types';
import SovosErrorSnackbarContext from './SovosErrorSnackbarContext';

const SovosErrorSnackbarProvider = ({ children }) => {
  const [errors, setErrors] = React.useState([]);

  const handleAddError = (error) => setErrors([...errors, error.message]);

  const handleDismissError = () => {
    setErrors([...errors].slice(1));
  };

  return (
    <SovosErrorSnackbarContext.Provider
      value={{
        errors,
        addError: handleAddError,
        dismissError: handleDismissError,
      }}
    >
      {children}
    </SovosErrorSnackbarContext.Provider>
  );
};

SovosErrorSnackbarProvider.propTypes = {
  /**
   * The content of the error snackbar provider
   */
  children: node.isRequired,
};

export default SovosErrorSnackbarProvider;
