import React from 'react';
import clsx from 'clsx';
import { node, string } from 'prop-types';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import SovosTooltip from '../sovos-tooltip';
import { getFocusStyles } from '../internals/utils';

const StyledLink = styled(Link)(() => ({
  '&:focus-visible': {
    ...getFocusStyles(),
  },
}));

const SovosLink = ({ className, children, title, ...rest }) => (
  <StyledLink
    className={clsx('sovosLink', className)}
    underline="hover"
    {...rest}
  >
    {title ? <SovosTooltip title={title}>{children}</SovosTooltip> : children}
  </StyledLink>
);

SovosLink.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Content of the link
   */
  children: node.isRequired,
  /**
   * Content for optional tooltip
   */
  title: node,
};

SovosLink.defaultProps = {
  className: undefined,
  title: undefined,
};

SovosLink.baseComponent = {
  name: 'Link',
  link: 'link/',
};

export default SovosLink;
