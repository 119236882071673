import { useEffect, useRef } from 'react';

export const useUpdate = (fn, deps) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current) {
      fn();
    } else {
      firstUpdate.current = false;
    }
  }, deps); // eslint-disable-line
};

export default useUpdate;
