import React from 'react';
import { styled } from '@mui/material/styles';
import { bool, string } from 'prop-types';
import clsx from 'clsx';
import SovosChipFilter from '../sovos-chip-filter';

const StyledChip = styled(SovosChipFilter)(({ theme: { spacing } }) => ({
  marginLeft: spacing(0.5),
  marginRight: spacing(0.5),
}));

/**
 * **Note**: must be a child of `SovosToolbarToggles`
 */
const SovosToolbarToggle = ({ className, selected, ...props }) => (
  <StyledChip
    className={clsx('sovosToolbarToggle', className)}
    selected={selected}
    {...props}
  />
);

SovosToolbarToggle.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Text label of the toggle
   */
  label: string.isRequired,
  /**
   * True if element state is on.
   */
  selected: bool,
};

SovosToolbarToggle.defaultProps = {
  className: undefined,
  selected: false,
};

export default SovosToolbarToggle;
