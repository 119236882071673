import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';
import SovosFixedWidthText from '../sovos-fixed-width-text';
import {
  findChildByType,
  filterOutChildrenByType,
} from '../internals/utils/reactHelpers';
import sovosIconButtonElement from '../internals/prop-types/sovosIconButtonElement';
import SovosTabs from '../sovos-tabs';
import SovosSlidingPanelHeaderActionButtons from '../sovos-sliding-panel-header-action-buttons';
import SovosSlidingPanelHeaderAdditionalButtons from '../sovos-sliding-panel-header-additional-buttons';
import SovosTab from '../sovos-tab';

const StyledHeader = styled('header', {
  shouldForwardProp: (prop) => prop !== 'showBorderBottom',
})(({ showBorderBottom, theme: { palette, spacing } }) => ({
  boxSizing: 'border-box',
  borderBottom: showBorderBottom ? `1px solid ${palette.divider}` : 'none',
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${spacing(3)}`,
}));

const TitleRow = styled('div')(({ theme: { spacing } }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: spacing(6),
}));

const TitleContainer = styled('div')(() => ({
  alignItems: 'flex-end',
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  overflow: 'hidden',
}));

const TabRow = styled('div')(({ theme: { spacing } }) => ({
  height: spacing(6),
  display: 'flex',

  '& .tabRow__tabs': {
    flex: '1 1 auto',
  },
}));

const TitleIconButton = styled('div')(({ theme: { spacing } }) => ({
  flex: '0 0 auto',
  marginLeft: spacing(),
  marginRight: 'auto',
}));

const buttonStyles = {
  alignItems: 'center',
  display: 'flex',
  flex: '1 0 auto',
  justifyContent: 'flex-end',
};

const ActionButtons = styled('div')(({ theme: { spacing } }) => ({
  margin: `${spacing(0.5)} 0 ${spacing(0.5)} ${spacing(2)}`,
  ...buttonStyles,
}));

const AdditionalButtons = styled('div')(({ theme: { spacing } }) => ({
  margin: `${spacing(0.5)} 0 ${spacing(0.5)} auto`,
  ...buttonStyles,
}));

/**
 * This component should be used inside a custom `SovosSlidingPanel`. Its
 * children can be `SovosSlidingPanelHeaderActionButtons`
 * `SovosSlidingPanelHeaderAdditionalButtons`, and/or arbitrary content.
 */
const SovosSlidingPanelHeader = ({
  children,
  classes: classesProp,
  className,
  onTabClick,
  selectedTab,
  showBorderBottom,
  style,
  tabs: tabsProp,
  TabsClasses,
  title,
  titleIconButton,
  ...rest
}) => {
  const renderAdditionalButtons = () =>
    findChildByType(children, SovosSlidingPanelHeaderAdditionalButtons);

  const renderActionButtons = () =>
    findChildByType(children, SovosSlidingPanelHeaderActionButtons);

  const renderTabSection = () => {
    if (tabsProp.length) {
      const tabLabels = tabsProp.map((tab, index) => {
        const { icon, label } = tab;
        return (
          <SovosTab
            icon={icon}
            label={label}
            value={index}
            key={label || index}
          />
        );
      });

      return (
        <SovosTabs
          value={selectedTab}
          onChange={onTabClick}
          classes={TabsClasses}
        >
          {tabLabels}
        </SovosTabs>
      );
    }

    return null;
  };

  const tabs = renderTabSection();
  const additionalButtons = renderAdditionalButtons();

  return (
    <StyledHeader
      className={clsx('sovosSlidingPanelHeader', className)}
      showBorderBottom={showBorderBottom}
      style={style}
      {...rest}
    >
      <TitleRow>
        <TitleContainer>
          {title && (
            <SovosTypography
              component="h2"
              variant="h4"
              className="sovosSlidingPanelHeader__title"
              data-testid="sovosSlidingPanelHeader__title"
            >
              {title !== '' && typeof title === 'string' ? (
                <SovosFixedWidthText text={title} />
              ) : (
                title
              )}
            </SovosTypography>
          )}
          {titleIconButton !== undefined && (
            <TitleIconButton>{titleIconButton}</TitleIconButton>
          )}
        </TitleContainer>
        <ActionButtons>{renderActionButtons()}</ActionButtons>
      </TitleRow>

      {filterOutChildrenByType(
        children,
        SovosSlidingPanelHeaderActionButtons,
        SovosSlidingPanelHeaderAdditionalButtons,
        SovosTab
      )}

      {(tabs || additionalButtons) && (
        <TabRow>
          <div className="tabRow__tabs">{tabs}</div>
          <AdditionalButtons>{additionalButtons}</AdditionalButtons>
        </TabRow>
      )}
    </StyledHeader>
  );
};

SovosSlidingPanelHeader.propTypes = {
  /**
   * Content of the header. This can include
   * `SovosSlidingPanelHeaderActionButtons`
   * `SovosSlidingPanelHeaderAdditionalButtons`, and/or arbitrary content
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component
   */
  classes: PropTypes.object,
  /**
   * Extend the class name applied to the root element
   */
  className: PropTypes.string,
  /**
   * @ignore passed In by `SovosSlidingPanel'
   */
  onTabClick: PropTypes.func,
  /**
   * @ignore passed In by `SovosSlidingPanel'
   */
  selectedTab: PropTypes.number,
  /**
   * When false, a border will not be displayed below the header
   */
  showBorderBottom: PropTypes.bool,
  /**
   * Inline styles applied to the root element
   */
  style: PropTypes.object,
  /**
   * @ignore passed In by `SovosSlidingPanel'
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ),
  /**
   * Override or extend the styles applied to the tabs
   */
  TabsClasses: PropTypes.object,
  /**
   * Title for the sliding panel
   */
  title: PropTypes.node,
  /**
   * When present, an icon button will display to the right of the title
   */
  titleIconButton: sovosIconButtonElement,
};

SovosSlidingPanelHeader.defaultProps = {
  children: null,
  className: undefined,
  classes: undefined,
  onTabClick: () => {},
  selectedTab: 0,
  showBorderBottom: true,
  style: undefined,
  tabs: [],
  TabsClasses: {},
  title: '',
  titleIconButton: undefined,
};

export default SovosSlidingPanelHeader;
