import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
  element,
  elementType,
  oneOf,
  number,
} from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { useResize } from '../hooks';
import StyledTable from './components/StyledTable';
import TotalsBar from './components/TotalsBar';
import HeaderRow from './components/HeaderRow';
import Row from './components/Row';
import tableClassNames from './helpers/tableClassNames';
import SELECT_COLUMN_ID from './helpers/selectColumnId';
import calculateColumnStyles from './helpers/calculateColumnStyles';
import defaultAreRowsEqual from './helpers/areRowsEqual';
import isWidthCalculated from './helpers/isWidthCalculated';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const TableWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'maxHeight',
})(({ maxHeight }) => ({ maxHeight, overflow: 'auto' }));

function useCombinedRefs(...refs) {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

const SovosTable = forwardRef((props, ref) => {
  const {
    areRowsEqual,
    className,
    columns: unsortedColumns,
    columnSortId,
    data: dataProp,
    'data-testid': dataTestId,
    fixedHeader,
    isRowSelectDisabled,
    maxHeight,
    onColumnSort,
    onRowClick,
    onRowSelection,
    rowActions,
    sx,
    totals,
    totalsLabel,
  } = props;

  const data = useMemo(
    () => (Array.isArray(dataProp) ? dataProp : []),
    [dataProp]
  );

  const { t } = useMosaicTranslation();
  const forwardedRef = ref;
  const [selectedRows, setSelectedRows] = useState([]);
  const [financialColumnWidths, setFinancialColumnWidths] = useState([]);
  const [columnStyles, setColumnStyles] = useState([]);
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const containerRef = useRef(null);
  const combinedRef = useCombinedRefs(forwardedRef, containerRef);
  const [contentRect] = useResize(combinedRef);
  const { spacing } = useTheme();

  // When onRowSelection is an object its props are functions that enable the
  // external application to take over handling row selection state.
  const externalStateManagement =
    typeof onRowSelection === 'object' ? onRowSelection : undefined;

  // Sorting the columns so that any that are fixed go first
  const columnCalculations = useMemo(() => {
    const fixedColumns = [];
    const unFixedColumns = [];

    unsortedColumns.forEach((col) => {
      if (col.visible !== false) {
        if (col.fixed) {
          fixedColumns.push(col);
        } else {
          unFixedColumns.push(col);
        }
      }
    });
    let fixedColumnCount = fixedColumns.length;

    const displaySelectColumn = onRowSelection;

    const selectColumnArr = [];
    if (displaySelectColumn) {
      const selectColumn = { id: SELECT_COLUMN_ID };
      if (fixedColumns.length) {
        selectColumn.fixed = true;
        fixedColumnCount += 1;
      }
      selectColumnArr.push(selectColumn);
    }

    const columns = [...selectColumnArr, ...fixedColumns, ...unFixedColumns];
    return {
      columns,
      fixedColumnCount,
    };
  }, [onRowSelection, unsortedColumns]);

  const { columns, fixedColumnCount } = columnCalculations;

  const widthsCalculator = useMemo(() => {
    const financialColumnCount = columns.filter(isWidthCalculated).length;

    let rows = data?.length;
    rows += totals ? 2 : 1;
    const financialCellCount = rows * financialColumnCount;

    return {
      columnIndex: 0,
      total: 0,
      columns: [],
      financialCellCount,
      financialColumnCount,
    };
  }, [columns, data, totals]);

  const setFinancialCellRef = useCallback(
    (cell) => {
      if (!cell) return;

      const max = widthsCalculator.columns[widthsCalculator.columnIndex];
      const current = cell.offsetWidth;

      if (!max || max < current) {
        widthsCalculator.columns[widthsCalculator.columnIndex] = current;
      }

      widthsCalculator.total += 1;
      widthsCalculator.columnIndex += 1;

      if (
        widthsCalculator.columnIndex === widthsCalculator.financialColumnCount
      ) {
        widthsCalculator.columnIndex = 0;
      }

      if (widthsCalculator.total === widthsCalculator.financialCellCount) {
        setFinancialColumnWidths(widthsCalculator.columns);
      }
    },
    [widthsCalculator]
  );

  useEffect(() => {
    if (financialColumnWidths.length < widthsCalculator.financialColumnCount)
      return;

    const currentTableWidth = combinedRef.current
      ? combinedRef.current.clientWidth
      : undefined;

    const calculatedStyles = calculateColumnStyles(
      columns,
      currentTableWidth,
      financialColumnWidths,
      !!rowActions,
      !!onRowSelection,
      spacing
    );

    setColumnStyles(calculatedStyles);
  }, [
    columns,
    financialColumnWidths,
    onRowSelection,
    rowActions,
    tableWidth,
    widthsCalculator,
    combinedRef,
    spacing,
  ]);

  // While the table animates in (in TableCard), a vertical scroll bar may
  // be introduced to the screen. If the user has "Show scroll bars" set to
  // "always", this will change the width of the table's containing div after
  // render, potentially introducing a horizontal scroll bar. We add a
  // ResizeObserver to notice the change and trigger a re-render.
  useEffect(() => {
    const { width, height } = contentRect;

    if (width !== tableWidth) {
      setTableWidth(width);
    }

    if (height !== tableHeight) {
      setTableHeight(height);
    }
  }, [contentRect, tableWidth, tableHeight]);

  const selectableRows = isRowSelectDisabled
    ? data.filter((row) => !isRowSelectDisabled(row))
    : [...data];

  // These five functions either handle row selection state or pass off to the
  // passed-in functions.
  const areAllRowsSelected = () =>
    externalStateManagement
      ? externalStateManagement.areAllRowsSelected()
      : selectableRows.length === selectedRows.length &&
        selectedRows.length > 0;

  const disableSelectAll = externalStateManagement
    ? externalStateManagement.disableSelectAll
    : selectableRows.length === 0;

  const areAnyRowsSelected = () =>
    externalStateManagement && externalStateManagement.areAnyRowsSelected
      ? externalStateManagement.areAnyRowsSelected()
      : selectedRows.length > 0;

  const isRowSelected = (row) =>
    externalStateManagement
      ? externalStateManagement.isRowSelected(row)
      : !!selectedRows.find((r) => areRowsEqual(row, r));

  const handleSelectAllClick = () => {
    if (externalStateManagement) {
      externalStateManagement.toggleSelectAll();
      return;
    }

    let newSelectedRows = [];
    if (!areAllRowsSelected()) {
      newSelectedRows = [...selectableRows];
    }

    setSelectedRows(newSelectedRows);
    onRowSelection(newSelectedRows);
  };

  const handleCheckboxClick = useCallback(
    (row) => {
      if (externalStateManagement) {
        externalStateManagement.toggleRowSelection(row);
        return;
      }

      setSelectedRows((prevSelectedRows) => {
        let newSelectedRows;
        if (prevSelectedRows.find((r) => row === r)) {
          newSelectedRows = prevSelectedRows.filter((r) => r !== row);
        } else {
          newSelectedRows = [...prevSelectedRows, row];
        }
        onRowSelection(newSelectedRows);

        return newSelectedRows;
      });
    },
    [externalStateManagement, onRowSelection]
  );

  return (
    <TableWrapper
      ref={combinedRef}
      className={clsx('sovosTableWrapper', className)}
      maxHeight={maxHeight}
      sx={sx}
    >
      <StyledTable
        className={tableClassNames.tableRoot}
        data-testid={dataTestId}
        fixedColumnCount={fixedColumnCount}
        hasTotals={!!totals}
        tableWidth={
          combinedRef.current ? combinedRef.current.clientWidth : undefined
        }
      >
        <thead>
          {totals && (
            <TotalsBar
              columns={columns}
              totals={totals}
              totalsLabel={totalsLabel || t('table.totals')}
              columnStyles={columnStyles}
              rowActions={!!rowActions}
              fixedColumnCount={fixedColumnCount}
              fixedHeader={fixedHeader}
              setFinancialCellRef={setFinancialCellRef}
            />
          )}

          <HeaderRow
            areAllRowsSelected={areAllRowsSelected()}
            areAnyRowsSelected={areAnyRowsSelected()}
            columns={columns}
            columnSortId={columnSortId}
            columnStyles={columnStyles}
            disableSelectAll={disableSelectAll}
            rowActions={!!rowActions}
            fixedColumnCount={fixedColumnCount}
            fixedHeader={fixedHeader}
            onColumnSort={onColumnSort}
            onSelectAllClick={handleSelectAllClick}
            setFinancialCellRef={setFinancialCellRef}
          />
        </thead>
        <tbody>
          {data.map((row, i) => {
            const isDisabled =
              !!isRowSelectDisabled && isRowSelectDisabled(row);

            // If there is an onRowClick handler, use it. Otherwise, select the
            // row on click if there is an onRowSelection handler and the row is
            // not disabled
            let handleRowClick;
            if (typeof onRowClick === 'function') {
              handleRowClick = onRowClick;
            } else if (
              onRowClick === 'select' &&
              !isDisabled &&
              onRowSelection
            ) {
              handleRowClick = handleCheckboxClick;
            }

            return (
              <Row
                columns={columns}
                columnStyles={columnStyles}
                fixedColumnCount={fixedColumnCount}
                isDisabled={isDisabled}
                isRowSelected={isRowSelected(row)}
                key={row?.id !== undefined ? row.id : i}
                onCheckboxClick={handleCheckboxClick}
                onRowClick={handleRowClick}
                row={row}
                rowActions={rowActions}
                rowIndex={i}
                setFinancialCellRef={setFinancialCellRef}
              />
            );
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
});

SovosTable.propTypes = {
  /**
   * Application-specific check of row equality used to determine whether a
   * row is selected. Performance can be optimized with something like
   * `(row1, row2) => row1.id === row2.id`
   */
  areRowsEqual: func,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Array of objects describing each column
   *
   * - `align`: `left`, `center`, or `right`. Position of the content within
   *   the cell. `left` is the default.
   * - `component`: Function. Generate the column's cell contents when they
   *   need to be calculated. Should be avoided in favor of `dataKey` for
   *   performance.
   * - `dataKey`: String indicating which property from the data array should
   *   be used for the column. A dataKey or a component is required;
   *   dataKey is preferred.
   * - `fixed`: Boolean. When true, the column will be moved to the left and
   *   will not scroll horizontally.
   * - `hideTitle`: Boolean. When true, the column title will not be
   *   displayed. Title should still be supplied and will be readable by
   *   screen readers and shown in the column drawer.
   * - `hideStatusLabel`: Boolean. For columns with `statuses`, the status
   *   label will not be displayed as a tooltip, not in the column.
   * - `id`: A required column id.
   * - `notSortable`: Boolean. When true, the column header will not be
   *   clickable even when the table can be sorted.
   * - `statuses`: an object that maps string values to colors. When passed
   *   in, the column will show the string as well as colored dots.
   * - `title`: String. The text to show in the column's header.
   * - `titleTooltipText`: String. When present, the column header will have a tooltip.
   * - `width`: Number or string. Default is 200. The column width in pixels.
   *   Width can also be `financial` which will cause the table to size the
   *   column to accommodate all of its contents, or `greedy`, which will
   *   cause the column to expand to take up remaining width after the
   *   other column widths are allocated. Width is always calculated for
   *   status columns.
   */
  columns: arrayOf(
    shape({
      align: oneOf(['left', 'center', 'right']),
      component: elementType,
      dataKey: (props) => {
        const { dataKey, component, title } = props;

        if (!dataKey && !component) {
          return new Error(
            `Column ${title} has neither a dataKey nor a component. One is required.`
          );
        }
        return null;
      },
      fixed: bool,
      hideTitle: bool,
      hideStatusLabel: bool,
      id: any.isRequired,
      notSortable: bool,
      statuses: (props) => {
        const { statuses, width, title } = props;
        const type = Array.isArray(statuses) ? 'array' : typeof statuses;

        if (statuses != null) {
          if (type !== 'object') {
            return new Error(
              `In column ${title}, expected \`statuses\` to be an object but received ${type}.`
            );
          }

          if (typeof width === 'number') {
            return new Error(
              'Columns with `statuses` cannot have a set width.'
            );
          }
        }

        return null;
      },
      title: string,
      titleTooltipText: string,
      width: oneOfType([number, string]),
    })
  ).isRequired,
  /**
   * Id of the column to be styled as the sort column. Required if
   * `onColumnSort` is present.
   */
  columnSortId: (props) => {
    const { columns, columnSortId, onColumnSort } = props;
    const missingColumn = !columns.find((col) => col.id === columnSortId);
    const notSortableId = columns.find(
      (col) => columnSortId === col.id && col.notSortable
    );
    const sortIdType = typeof columnSortId;

    if (!onColumnSort && columnSortId == null) return null;

    if (onColumnSort && columnSortId == null) {
      return new Error(
        'When onColumnSort function is passed in, columnSortId is required.'
      );
    }
    if (missingColumn) {
      return new Error(
        `columnSortId ${columnSortId} does not match the id of any column`
      );
    }
    if (notSortableId) {
      return new Error(
        `columnSortId is set on notSortable column id: ${columnSortId}`
      );
    }
    if (onColumnSort && !(sortIdType === 'number' || sortIdType === 'string')) {
      return new Error(
        `Invalid type supplied to columnSortId. Expected either a number or string but received: ${sortIdType}`
      );
    }
    return null;
  },
  /**
   * An array of objects. Each object should have a property for each
   * column's `dataKey` if there should be content in the cell.
   */
  data: arrayOf(object).isRequired,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * If present, a the totals bar will be rendered. Keys correspond to the
   * columns' dataKey.
   */
  totals: object,
  /**
   * String to render in the first cell of the totals bar.
   */
  totalsLabel: string,
  /**
   * Function to determine if a row should be un-selectable. Receives a row
   * object, returns a boolean.
   */
  isRowSelectDisabled: func,
  /**
   * Number of pixels or valid CSS expression. Allows scrolling the table
   * with fixed headers
   */
  maxHeight: oneOfType([number, string]),
  /**
   * Callback fired when a row header is clicked. Required if
   * `columnSortId` is present.
   */
  onColumnSort: (props) => {
    const { columnSortId, onColumnSort } = props;
    if (columnSortId != null && !onColumnSort) {
      return new Error(
        'When columnSortId is passed in, onColumnSort function is required.'
      );
    }

    return null;
  },
  /**
   * Row click does (1) nothing by default, (2) fires function when passed
   * in, (3) selects row when "select" is passed in and onRowSelect is present.
   */
  onRowClick: oneOfType([func, string]),
  /**
   * Passing in onRowSelection will cause the table to display a select
   * column. If it's a function, the function will return the currently
   * selected set of rows every time the selected group changes. If the
   * object of functions is passed in, the external application is
   * responsible for managing the selected state.
   */
  onRowSelection: oneOfType([
    func,
    shape({
      isRowSelected: func.isRequired,
      toggleRowSelection: func.isRequired,
      areAllRowsSelected: func.isRequired,
      areAnyRowsSelected: func,
      toggleSelectAll: func.isRequired,
      disableSelectAll: bool,
    }),
  ]),
  fixedHeader: bool,
  /**
   * When present, an icon button or icon menu will be present at the
   * right-most end of each row. RowActions can either be a function that
   * returns an array of MenuItems, and the row will display a standard
   * More icon, or an object with an icon and callback function which will
   * receive the row when clicked.
   */
  rowActions: oneOfType([
    shape({
      icon: element.isRequired,
      onClick: func.isRequired,
      tooltipText: string.isRequired,
    }),
    func,
  ]),
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
};

SovosTable.defaultProps = {
  areRowsEqual: defaultAreRowsEqual,
  className: undefined,
  columnSortId: undefined,
  'data-testid': undefined,
  totals: undefined,
  totalsLabel: undefined,
  isRowSelectDisabled: undefined,
  maxHeight: undefined,
  onColumnSort: undefined,
  onRowClick: undefined,
  onRowSelection: undefined,
  fixedHeader: false,
  rowActions: undefined,
  sx: undefined,
};

SovosTable.baseComponent = {
  name: 'Table',
};

SovosTable.name = 'SovosTable';

export default SovosTable;
