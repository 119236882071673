import React, { useState } from 'react';
import SovosButton from 'mosaic-react/sovos-button';
import SovosPageLayout from 'mosaic-react/sovos-page-layout';
import { v4 as uuid } from 'uuid';
import FilingsTable from './FilingsTable';
import FilingPanel from './FilingPanel';

function FilingsPage() {
  const [filingPanelOpen, setFilingPanelOpen] = useState(false);
  const [label, setLabel] = useState(undefined);
  const [editId, setEditId] = useState(undefined);
  const [filingPanelRefreshKey, setFilingPanelRefreshKey] = useState(uuid());

  function openFilingPanel(newLabel, id = undefined) {
    setFilingPanelRefreshKey(uuid());
    setEditId(id);
    setFilingPanelOpen(true);
    setLabel(newLabel);
  }

  const closeFilingPanel = () => {
    setFilingPanelOpen(false);
    setLabel(undefined);
    setEditId(undefined);
  };

  const openNewFiling = () => {
    openFilingPanel('New Filing');
  };

  const openFilingForEdit = (id) => {
    openFilingPanel('Edit Filing', id);
  };

  function titleBarActions() {
    return [
      <SovosButton
        onClick={openNewFiling}
        key="addFiling"
        style={{ marginRight: 20 }}
        className="FilingsPage__AddButton"
      >
        Add
      </SovosButton>,
    ];
  }

  return (
    <SovosPageLayout
      pageTitle="Filing Calendar"
      titleBarActions={titleBarActions()}
      className="FilingsPage__PageLayout"
    >
      <FilingsTable onEdit={openFilingForEdit} />
      <FilingPanel
        filingPanelOpen={filingPanelOpen}
        closePanel={closeFilingPanel}
        label={label}
        editId={editId}
        key={filingPanelRefreshKey}
      />
    </SovosPageLayout>
  );
}

export default FilingsPage;
