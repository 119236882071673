const initialPasswordCheck = {
  uppercase: false,
  specialChar: false,
  minLength: false,
};

const specialCharacters = [
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '?',
  '/',
  '[',
  ']',
  '{',
  '}',
  '`',
  '~',
  ';',
  ':',
  '-',
  '+',
];

export function checkMinimumLength(password) {
  return password.length >= 8;
}

export function checkUppercaseLetter(password) {
  for (let i = 0; i < password.length; i += 1) {
    if (password[i].charCodeAt() >= 65 && password[i].charCodeAt() <= 90) {
      return true;
    }
  }
  return false;
}

export function checkSpecialCharacter(password) {
  for (let j = 0; j < password.length; j += 1) {
    if (specialCharacters.includes(password[j])) {
      return true;
    }
  }
  return false;
}

export function checkPasswordRules(password) {
  const rules = { ...initialPasswordCheck };
  rules.uppercase = checkUppercaseLetter(password);
  rules.specialChar = checkSpecialCharacter(password);
  rules.minLength = checkMinimumLength(password);
  return rules;
}
