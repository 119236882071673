import React from 'react';
import { string } from 'prop-types';
import { styled } from '@mui/material/styles';

/*
There is a much simpler way to accomplish this with CSS text-shadow and a simple text child, as opposed to nested spans.
However, Safari will not render the text-shadow in an element that also uses clip-path. To get around this limitation, use
a drop-shadow filter. Since filters get applied to all child elements, filters need to be applied at the lowest level to 
affect only the text.
*/
const filter = 'drop-shadow(0px 4px 4px rgba(0,0,0,0.25))';

const Root = styled('h2', { shouldForwardProp: (prop) => prop !== 'code' })(
  ({ code, theme: { palette, spacing } }) => ({
    lineHeight: 1,
    color: palette.text.primary,
    fontSize: spacing(17),
    fontWeight: 'bold',
    position: 'relative',
    zIndex: 1,
    display: 'inline-block',
    margin: 0,
    padding: 0,

    '&::before': {
      content: '""',
      position: 'absolute',
      borderRadius: spacing(1),
      width: spacing(1.5),
      height: spacing(33),
      backgroundColor: palette.primary.light,
      transform: 'rotate(24deg)',
      left: '47%',
      top: '-64px',
      zIndex: 200,
    },

    '& .before': {
      filter,
    },

    '& .after': {
      position: 'absolute',
      left: '6px',
      top: ' -8px',
      zIndex: 100,
      clipPath: 'polygon(58% 0, 100% 0%, 100% 100%, 40% 100%)',
      background: palette.grey[100],
      '&::after': {
        content: `'${code}'`,
        filter,
      },
    },
  })
);

const ErrorCode = ({ code }) => (
  <Root code={code}>
    <span className="before">{code}</span>
    <span aria-hidden="true" className="after" />
  </Root>
);

ErrorCode.propTypes = {
  code: string.isRequired,
};

export default ErrorCode;
