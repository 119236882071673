import React from 'react';
import { elementType, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import { square } from '../../../internals/utils/stylesHelpers';

const BaseIcon = ({ className, element }) => {
  const StyledElement = styled(element)(({ theme: { spacing } }) => ({
    ...square(spacing(2)),
  }));

  return <StyledElement className={className} />;
};

BaseIcon.propTypes = {
  className: string,
  element: elementType.isRequired,
};

BaseIcon.defaultProps = {
  className: undefined,
};

export default BaseIcon;
