import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import HeaderButtonsGroup from '../internals/components/sliding-panel/HeaderButtonsGroup';

/**
 * This component must be a child of `SovosSlidingPanelHeader`.
 */
const SovosSlidingPanelHeaderAdditionalButtons = ({
  children,
  className,
  ...rest
}) => (
  <HeaderButtonsGroup
    className={clsx(className, 'sovosSlidingPanelHeaderAdditionalButtons')}
    {...rest}
  >
    {children}
  </HeaderButtonsGroup>
);

SovosSlidingPanelHeaderAdditionalButtons.propTypes = {
  /**
   * The content of the additional header buttons
   */
  children: PropTypes.node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: PropTypes.string,
};

SovosSlidingPanelHeaderAdditionalButtons.defaultProps = {
  className: undefined,
};

export default SovosSlidingPanelHeaderAdditionalButtons;
