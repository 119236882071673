import React from 'react';
import clsx from 'clsx';
import { node, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosTypography from '../sovos-typography';

const Root = styled(SovosTypography)(({ theme: { palette, spacing } }) => ({
  color: palette.primary.contrastText,
  height: spacing(6.25),
}));

/**
 * **Note** Must be a child `SovosHeroBanner`
 */
const SovosHeroBannerSubtitle = ({ children, className, ...rest }) => (
  <Root
    className={clsx('sovosHeroBannerSubtitle', className)}
    variant="subtitle1"
    {...rest}
  >
    {children}
  </Root>
);

SovosHeroBannerSubtitle.propTypes = {
  /**
   * The content of the hero banner subtitle
   */
  children: node,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosHeroBannerSubtitle.defaultProps = {
  children: undefined,
  className: undefined,
};

export default SovosHeroBannerSubtitle;
