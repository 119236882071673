import React, { useState, memo } from 'react';
import { array, arrayOf, bool, func, object, number, any } from 'prop-types';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { MoreVert } from 'mosaic-react-icons';
import SovosCheckbox from '../../sovos-checkbox';
import SovosIconMenu from '../../sovos-icon-menu';
import SovosIconButton from '../../sovos-icon-button';
import { spacingToNumber } from '../../internals/utils';
import FinancialCellContent from './FinancialCellContent';
import FixedWidthCellContent from './FixedWidthCellContent';
import StatusCellContent from './StatusCellContent';
import SELECT_COLUMN_ID from '../helpers/selectColumnId';
import isLastFixedColumn from '../helpers/isLastFixedColumn';
import tableClassNames from '../helpers/tableClassNames';

const Row = ({
  columns,
  columnStyles,
  fixedColumnCount,
  isDisabled,
  isRowSelected,
  onCheckboxClick,
  onRowClick,
  row,
  rowActions,
  rowIndex,
  setFinancialCellRef,
}) => {
  const [hovered, setHovered] = useState(false);
  const { spacing } = useTheme();

  const rowId = row?.id || rowIndex;

  const renderSelectCheckbox = () => (
    <SovosCheckbox
      checked={isRowSelected}
      className={clsx(
        'sovosTable__selectRow',
        `sovosTable__selectRow-${rowId}`
      )}
      disabled={isDisabled}
      color="primary"
      onChange={() => onCheckboxClick(row)}
      onClick={(e) => e.stopPropagation()}
      size="tiny"
    />
  );

  const renderActionsCell = (currentRow) => {
    const cellClassName = clsx(
      tableClassNames.baseCell,
      tableClassNames.bodyCell,
      tableClassNames.rowActionsCell
    );

    if (!hovered) {
      return <td className={cellClassName} />;
    }

    let contents;

    const buttonClassName = clsx(
      tableClassNames.rowActionButton,
      `${tableClassNames.rowActionButton}-${rowId}`
    );

    if (typeof rowActions === 'object') {
      const { icon, onClick, tooltipText } = rowActions;

      const handleClick = (e) => {
        e.stopPropagation();

        onClick(currentRow);
      };

      contents = (
        <SovosIconButton
          onClick={handleClick}
          tooltipText={tooltipText}
          className={buttonClassName}
          data-testid="sovosTable__actionButton"
          size="small"
        >
          {icon}
        </SovosIconButton>
      );
    } else if (typeof rowActions === 'function') {
      const items = rowActions(currentRow);

      contents = (
        <SovosIconMenu
          iconElement={<MoreVert />}
          className={buttonClassName}
          onClose={() => setHovered(false)}
          size="small"
        >
          {items.map((item, idx) =>
            React.cloneElement(item, {
              className: clsx(
                `${tableClassNames.rowActionMenuItem}-${idx}`,
                item.props.className
              ),
            })
          )}
        </SovosIconMenu>
      );
    }

    return <td className={cellClassName}>{contents}</td>;
  };

  const renderCellContent = (column, currentRow, colContentWidth) => {
    const {
      component,
      dataKey,
      hideStatusLabel,
      hideTitle,
      statuses,
      title,
      width,
    } = column;
    let content;

    if (component) {
      content = component({ content: currentRow });
    } else if (dataKey) {
      try {
        content = dataKey
          .split('.')
          .reduce((data, property) => data[property], currentRow);
      } catch {
        console.error(`Table received an invalid data key: ${dataKey}`);
      }
    }

    if (statuses) {
      return (
        <StatusCellContent
          statuses={statuses}
          setRef={setFinancialCellRef}
          hideStatusLabel={hideStatusLabel}
          columnTitle={title}
          hiddenTitle={hideTitle}
        >
          {content}
        </StatusCellContent>
      );
    }

    if (width === 'financial') {
      return (
        <FinancialCellContent setRef={setFinancialCellRef}>
          {content}
        </FinancialCellContent>
      );
    }

    return component ? (
      content
    ) : (
      <FixedWidthCellContent width={colContentWidth}>
        {content}
      </FixedWidthCellContent>
    );
  };

  return (
    <tr
      onClick={() => onRowClick && onRowClick(row)}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      data-testid={`${tableClassNames.row}-${rowId}`}
      className={clsx(
        tableClassNames.row,
        {
          [tableClassNames.rowClickable]: onRowClick,
          [tableClassNames.rowHovered]: hovered,
          [tableClassNames.rowSelected]: isRowSelected,
          [tableClassNames.rowHoverSelected]: hovered && isRowSelected,
          [tableClassNames.rowDisabled]: isDisabled,
        },
        `${tableClassNames.row}-${rowId}`
      )}
    >
      {columns.map((col, i) => {
        const colContentWidth =
          columnStyles[i]?.width - spacingToNumber(spacing(4));

        const cellContent =
          col.id === SELECT_COLUMN_ID
            ? renderSelectCheckbox(row)
            : renderCellContent(col, row, colContentWidth);

        const className = clsx(
          tableClassNames.baseCell,
          tableClassNames.bodyCell,
          {
            [tableClassNames.fixedBodyCell]: col.fixed,
            [tableClassNames.rightmostFixedColumnCell]: isLastFixedColumn(
              i,
              fixedColumnCount
            ),
            [tableClassNames.selectCell]: col.id === SELECT_COLUMN_ID,
          }
        );

        const props = {
          className,
          style: columnStyles[i],
          key: col.id,
          'data-testid': 'sovosTable__cell',
        };

        if (col.fixed) {
          props.scope = 'row';

          return <th {...props}>{cellContent}</th>;
        }
        return <td {...props}>{cellContent}</td>;
      })}
      {rowActions && renderActionsCell(row)}
    </tr>
  );
};

Row.propTypes = {
  columns: array.isRequired,
  columnStyles: arrayOf(object).isRequired,
  fixedColumnCount: number.isRequired,
  isDisabled: bool.isRequired,
  isRowSelected: bool.isRequired,
  onCheckboxClick: func,
  onRowClick: func,
  row: object.isRequired,
  rowActions: any,
  rowIndex: number.isRequired,
  setFinancialCellRef: func,
};

Row.defaultProps = {
  onCheckboxClick: undefined,
  onRowClick: undefined,
  rowActions: undefined,
  setFinancialCellRef: undefined,
};

export default memo(Row);
