import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import Collapse from '@mui/material/Collapse';

const SovosCollapse = ({ className, ...rest }) => (
  <Collapse className={clsx('sovosCollapse', className)} {...rest} />
);

SovosCollapse.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosCollapse.defaultProps = {
  className: undefined,
};

SovosCollapse.baseComponent = {
  name: 'Collapse',
  link: '/collapse/',
};

export default SovosCollapse;
