/* eslint-disable import/prefer-default-export */

/**
 * Check if event key is Enter
 *
 * @param {object} event
 */
export const isEnter = (event) => event.key === 'Enter';

/* eslint-enable import/prefer-default-export */
