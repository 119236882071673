/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { string, oneOfType, number } from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  SovosButton,
  SovosCircularProgress,
  SovosPageContentGeneric,
  SovosPageLayout,
  SovosTypography,
  SovosGrid,
} from 'mosaic-react';
import { useErrorSnackbar } from 'mosaic-react/hooks';
import Dialog from '../commonComponents/Dialog';
import ImportTable from './ImportTable';
import { importData } from './services/import';

const ValueDisplay = ({ label, value }) => {
  const { spacing } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        marginBottom: spacing(),
      }}
    >
      <SovosTypography
        color="text.secondary"
        sx={{
          width: spacing(16),
          marginRight: spacing(2),
        }}
        variant="h6"
      >
        {label}
      </SovosTypography>
      <SovosTypography
        sx={{
          width: spacing(25),
        }}
        variant="h6"
      >
        {value}
      </SovosTypography>
    </Box>
  );
};

ValueDisplay.propTypes = {
  label: string.isRequired,
  value: oneOfType([string, number]),
};

ValueDisplay.defaultProps = {
  value: undefined,
};

const ImportPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { addError } = useErrorSnackbar();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');

  const importRecord = useSelector((state) => state.import);
  const { current } = importRecord;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(importData(id)).catch((err) => {
      addError(err);
    });
  }, [addError, dispatch, id]);

  const openDialog = (text) => {
    setDialogText(text);
    setIsDialogOpen(true);
  };

  const titleBarActions = [
    <SovosButton
      onClick={() => openDialog('Exporting file')}
      key="export"
      className="ImportPage__ExportButton"
    >
      Export
    </SovosButton>,
  ];

  return (
    <SovosPageLayout
      pageTitle={current?.fileName || 'Loading record...'}
      pageCrumbs={[
        {
          text: 'Batch Imports',
          onClick: () => navigate('/imports/all'),
        },
      ]}
      titleBarActions={titleBarActions}
      className="ImportPage__PageLayout"
    >
      <SovosPageContentGeneric>
        {!importRecord.isLoading ? (
          <div>
            {current && current.id ? (
              <>
                <SovosGrid
                  container
                  sx={{
                    marginTop: 0.5,
                    marginBottom: 4,
                  }}
                >
                  <SovosGrid item md={6}>
                    <ValueDisplay label="File Name" value={current.fileName} />
                    <ValueDisplay
                      label="Import Profile"
                      value={current.importProfile}
                    />
                    <ValueDisplay
                      label="Date and Time"
                      value={current.formattedTimeStamp}
                    />
                  </SovosGrid>
                  <SovosGrid item md={6}>
                    <ValueDisplay label="Username" value={current.userName} />
                    <ValueDisplay
                      label="CompanyCode"
                      value={current.companyCode}
                    />
                    <ValueDisplay
                      label="First Report Date"
                      value={current.formattedReportDate}
                    />
                  </SovosGrid>
                </SovosGrid>
                <ImportTable data={current.transactions} />
              </>
            ) : (
              <div>loading error...</div>
            )}
          </div>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 7,
            }}
          >
            <SovosCircularProgress size={100} aria-label="Loading Data" />
          </Box>
        )}
      </SovosPageContentGeneric>
      <Dialog
        text={dialogText}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </SovosPageLayout>
  );
};

export default ImportPage;
