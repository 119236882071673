import React, { useState } from 'react';
import { arrayOf, elementType, object, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosTypography from '../../../sovos-typography';
import SovosButton from '../../../sovos-button';

const maxInitialItems = 5;

const EmptyList = styled(SovosTypography)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.disabled,
    marginTop: spacing(3),
  })
);

const MoreLessButton = styled(SovosButton)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));

const ShowMoreList = ({
  component: Component,
  data,
  emptyListLabel,
  showLessLabel,
  showMoreLabel,
  ...listProps
}) => {
  const [showMore, setShowMore] = useState(false);
  const listOverflows = data.length > maxInitialItems;
  const listData = listOverflows && !showMore ? data.slice(0, 4) : data;
  const moreButtonText = !showMore ? showMoreLabel : showLessLabel;

  return (
    <>
      {data.length === 0 && (
        <EmptyList component="div" variant="body1">
          {emptyListLabel}
        </EmptyList>
      )}
      {data.length > 0 && <Component data={listData} {...listProps} />}
      {listOverflows && (
        <MoreLessButton variant="text" onClick={() => setShowMore(!showMore)}>
          {moreButtonText}
        </MoreLessButton>
      )}
    </>
  );
};

ShowMoreList.propTypes = {
  component: elementType.isRequired,
  data: arrayOf(object).isRequired,
  emptyListLabel: string.isRequired,
  showLessLabel: string.isRequired,
  showMoreLabel: string.isRequired,
};

ShowMoreList.displayName = 'ShowMoreList';

export default ShowMoreList;
