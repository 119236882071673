import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import clsx from 'clsx';
import SovosDrawer from '../sovos-drawer';
import SovosDrawerHeader from '../sovos-drawer-header';
import ButtonsGroup from '../sovos-buttons-group';
import SovosButton from '../sovos-button';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const SovosEditableDrawer = ({
  children,
  className,
  PrimaryButtonProps,
  SecondaryButtonProps,
  title,
  ...drawerProps
}) => {
  const { t } = useMosaicTranslation();
  const {
    children: primaryChildren,
    color: primaryColor,
    className: primaryClassName,
    ...primaryProps
  } = PrimaryButtonProps;

  const {
    children: secondaryChildren,
    color: secondaryColor,
    className: secondaryClassName,
    ...secondaryProps
  } = SecondaryButtonProps;

  return (
    <SovosDrawer
      className={clsx('sovosEditableDrawer', className)}
      {...drawerProps}
    >
      <SovosDrawerHeader title={title}>
        <ButtonsGroup align="right">
          <SovosButton
            className={clsx(
              'SovosDrawerHeader__secondaryButton',
              secondaryClassName
            )}
            variant="text"
            {...secondaryProps}
          >
            {secondaryChildren || t('cancel')}
          </SovosButton>
          <SovosButton
            color={primaryColor || 'primary'}
            className={clsx(
              'SovosDrawerHeader__primaryButton',
              primaryClassName
            )}
            {...primaryProps}
          >
            {primaryChildren || t('apply')}
          </SovosButton>
        </ButtonsGroup>
      </SovosDrawerHeader>
      {children}
    </SovosDrawer>
  );
};

SovosEditableDrawer.propTypes = {
  /**
   * The content of the drawer
   */
  children: node,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Properties for the save button, `onClick` will be required in future versions
   */
  PrimaryButtonProps: shape({
    backgroundColor: string,
    children: node,
    color: string,
    disabled: bool,
    onClick: func,
    style: object,
    className: string,
  }),
  /**
   * Properties for the cancel button, `onClick` will be required in future versions
   */
  SecondaryButtonProps: shape({
    backgroundColor: string,
    children: node,
    color: string,
    disabled: bool,
    onClick: func,
    style: object,
    className: string,
  }),
  /**
   * Drawer title
   */
  title: node.isRequired,
};

SovosEditableDrawer.defaultProps = {
  children: undefined,
  className: undefined,
  PrimaryButtonProps: {},
  SecondaryButtonProps: {},
};

SovosEditableDrawer.baseComponent = {
  name: 'SovosDrawer',
};

export default SovosEditableDrawer;
