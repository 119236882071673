export const filterTypes = {
  checkboxList: 'checkboxList',
  dateInput: 'dateInput',
  dateRangePicker: 'dateRangePicker',
  numberRangePicker: 'numberRangePicker',
  textEntry: 'textEntry',
};

export const quickFilterTypes = {
  dateSelector: 'dateSelector',
  dropdown: 'dropdown',
  monthSelector: 'monthSelector',
  monthRangePicker: 'monthRangePicker',
  toggle: 'toggle',
};
