/* eslint-disable no-return-assign */
import React from 'react';
import clsx from 'clsx';
import { node, oneOf, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import { findChildByType } from '../internals/utils/reactHelpers';
import SovosPageContentTwoColumnMain from '../sovos-page-content-two-column-main';
import SovosPageContentTwoColumnSidebar from '../sovos-page-content-two-column-sidebar';

const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'sidebarWidth' && prop !== 'mainAlignment',
})(({ sidebarWidth, mainAlignment }) => {
  const sidebarPercent = sidebarWidth === 'third' ? '33%' : '25%';

  return {
    display: 'grid',
    gridTemplateColumns:
      mainAlignment === 'right'
        ? `${sidebarPercent} auto`
        : `auto ${sidebarPercent}`,
    height: '100%',
  };
});

/**
 * `SovosPageContentTwoColumn` should only be used as a child of
 * `SovosPageLayout`, and should include one of each
 * `SovosPageContentTwoColumnMain` and `SovosPageContentTwoColumnSidebar`.
 *
 * The contents of `SovosPageContentTwoColumnMain` must be
 * `SovosPageContentGeneric` or `SovosPageContentTable`. The sidebar
 * component applies the correct padding and does not require these components.
 */
const SovosPageContentTwoColumn = ({
  children,
  className,
  'data-testid': dataTestId,
  mainAlignment,
  sidebarWidth,
}) => {
  let leftColumnType = SovosPageContentTwoColumnSidebar;
  let rightColumnType = SovosPageContentTwoColumnMain;
  if (mainAlignment === 'left') {
    leftColumnType = SovosPageContentTwoColumnMain;
    rightColumnType = SovosPageContentTwoColumnSidebar;
  }

  return (
    <Root
      className={clsx('sovosPageContentTwoColumn', className)}
      data-testid={dataTestId}
      mainAlignment={mainAlignment}
      sidebarWidth={sidebarWidth}
    >
      {findChildByType(children, leftColumnType)}
      {findChildByType(children, rightColumnType)}
    </Root>
  );
};

SovosPageContentTwoColumn.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * The content of the page. Must be wrapped with
   * `SovosPageContentTwoColumnMain` and `SovosPageContentTwoColumnSidebar`
   */
  children: node.isRequired,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Alignment of the `SovosPageContentTwoColumnMain` child component
   */
  mainAlignment: oneOf(['right', 'left']),
  /**
   * Sidebar width
   */
  sidebarWidth: oneOf(['third', 'quarter']),
};

SovosPageContentTwoColumn.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  mainAlignment: 'right',
  sidebarWidth: 'third',
};

export default SovosPageContentTwoColumn;
