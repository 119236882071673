import React, { forwardRef } from 'react';
import { oneOf, func, object, node, string, bool } from 'prop-types';
import clsx from 'clsx';
import { alpha, styled } from '@mui/material/styles';
import SovosTooltip from '../sovos-tooltip';
import { getAfterFocusStyles, getIconSizes } from '../internals/utils';

export function generateIconColor(color, palette) {
  if (!color || color === 'default' || color === 'inherit')
    return palette?.action.active;
  if (color === 'primary') return palette?.primary.main;
  if (color === 'secondary') return palette?.secondary.main;

  return color;
}

const StyledButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size',
})(({ color, size, theme: { palette, shape, spacing } }) => {
  const sizes = getIconSizes(spacing);
  const iconSize = sizes[size] || sizes.medium;
  const iconColor = generateIconColor(color, palette);

  return {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: shape.borderRadiusRound,
    color: color === 'inherit' ? color : iconColor,
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    outline: 0,
    position: 'relative',
    verticalAlign: 'middle',
    ...iconSize.button,

    '&:hover': {
      backgroundColor: alpha(iconColor, palette.action.hoverOpacity),
    },

    '&:disabled': {
      color: palette.action.disabled,
      pointerEvents: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    '&:focus-visible': {
      ...getAfterFocusStyles({ offset: 0 }),
    },

    '&:focus, &:focus-visible': {
      '&:hover': {
        backgroundColor: alpha(
          iconColor,
          palette.action.focusOpacity + palette.action.hoverOpacity
        ),
      },
    },

    '& .sovosIconButton__icon': {
      ...iconSize.icon,
    },
  };
});

const SovosIconButton = forwardRef(
  (
    {
      children,
      classes,
      className,
      color,
      disabled,
      onBlur,
      onClick,
      onFocus,
      size,
      style,
      tooltipText,
      ...rest
    },
    ref
  ) => {
    let icon = React.Children.only(children);

    const {
      className: iconClassName,
      fontSize: iconFontSize,
      ...iconProps
    } = icon.props;

    icon = React.cloneElement(icon, {
      className: clsx('sovosIconButton__icon', classes?.icon, iconClassName),
      fontSize: iconFontSize || size,
      ...iconProps,
    });

    const iconButton = (
      <StyledButton
        className={clsx('sovosIconButton', className, classes?.root)}
        color={color}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        size={size}
        style={style}
        type="button"
        {...rest}
      >
        {icon}
      </StyledButton>
    );

    return (
      <SovosTooltip title={tooltipText}>
        {disabled ? (
          <span style={{ display: 'inline-block' }}>{iconButton}</span>
        ) : (
          iconButton
        )}
      </SovosTooltip>
    );
  }
);

SovosIconButton.displayName = 'SovosIconButton';

SovosIconButton.propTypes = {
  /**
   * The content of the icon button. Must be an `Icon`
   */
  children: node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Can be 'primary', 'default', or a valid css color. Default is the
   * palette `action` color.
   */
  color: string,
  /**
   * If `true`, the button is disabled
   */
  disabled: bool,
  /**
   * Callback fired when the button loses focus
   */
  onBlur: func,
  /**
   * Callback fired when the button is clicked.
   */
  onClick: func,
  /**
   * Callback fired when the button gains focus
   */
  onFocus: func,
  /**
   * Size variant
   */
  size: oneOf(['extraSmall', 'small', 'medium']),
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Tooltip text for the button
   */
  tooltipText: string.isRequired,
};

SovosIconButton.defaultProps = {
  classes: undefined,
  className: undefined,
  color: undefined,
  disabled: false,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  size: 'medium',
  style: undefined,
};

SovosIconButton.baseComponent = {
  name: 'IconButton',
};

export default SovosIconButton;
