import { createInstance } from 'i18next';
import assetResolver, { assetKeys } from 'mosaic-assets-resolver';

const i18n = createInstance({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      mosaic: assetResolver.translation(assetKeys.locales.en),
    },
    es: {
      mosaic: assetResolver.translation(assetKeys.locales.es),
    },
    pt: {
      mosaic: assetResolver.translation(assetKeys.locales.pt),
    },
  },
});

i18n.init();

export default i18n;
