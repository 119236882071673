import React, { useState } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Box } from '@mui/material';
import { Visibility, VisibilityOff } from 'mosaic-react-icons';
import {
  SovosButton,
  SovosIconButton,
  SovosInputAdornment,
  SovosSettingsCard,
  SovosSettingsSection,
  SovosSettingsSubtitle,
  SovosSettingsTitle,
  SovosTextField,
} from 'mosaic-react';
import PasswordResetModal, {
  passwordResetModalLabelPropsShape,
} from './PasswordResetModal';

export const passwordSectionLabelPropsShape = shape({
  titleText: string,
  subtitleText: string,
  updatePasswordButtonLabelText: string,
  lastChangedHelperText: string,
  textFieldLabel: string,
  PasswordResetModalLabelProps: passwordResetModalLabelPropsShape,
});

const PasswordSection = ({ user, onPasswordChange, LabelProps }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [textFieldType, setTextFieldType] = useState('password');

  const {
    titleText,
    subtitleText,
    updatePasswordButtonLabelText,
    lastChangedHelperText,
    textFieldLabel,
    PasswordResetModalLabelProps,
  } = LabelProps;

  const toggleShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true);
      setTextFieldType('text');
    }
    if (showPassword === true) {
      setShowPassword(false);
      setTextFieldType('password');
    }
  };

  const openModal = () => {
    setOpenPasswordModal(true);
  };

  const closeModal = () => {
    setOpenPasswordModal(false);
  };

  return (
    <>
      <SovosSettingsSection>
        <SovosSettingsTitle>{titleText}</SovosSettingsTitle>
        <SovosSettingsSubtitle>{subtitleText}</SovosSettingsSubtitle>
        <SovosSettingsCard>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <SovosTextField
              label={textFieldLabel}
              value={user.password}
              type={textFieldType}
              helperText={`${lastChangedHelperText}: ${user.passwordLastChanged}`}
              disabled
              InputProps={{
                endAdornment: (
                  <SovosInputAdornment position="end">
                    <SovosIconButton
                      onClick={toggleShowPassword}
                      tooltipText={`${showPassword ? 'Show' : 'Hide'} password`}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </SovosIconButton>
                  </SovosInputAdornment>
                ),
              }}
            />

            <SovosButton
              onClick={openModal}
              sx={{
                marginLeft: 4,
              }}
              variant="text"
            >
              {updatePasswordButtonLabelText}
            </SovosButton>
          </Box>
        </SovosSettingsCard>
      </SovosSettingsSection>
      <PasswordResetModal
        open={openPasswordModal}
        closeModal={closeModal}
        onPasswordChange={onPasswordChange}
        LabelProps={PasswordResetModalLabelProps}
      />
    </>
  );
};

PasswordSection.propTypes = {
  user: object.isRequired,
  onPasswordChange: func.isRequired,
  LabelProps: passwordSectionLabelPropsShape,
};

PasswordSection.defaultProps = {
  LabelProps: {
    titleText: 'Password',
    subtitleText: 'Reset your password',
    updatePasswordButtonLabelText: 'Update Password',
    lastChangedHelperText: 'Last changed',
    textFieldLabel: 'Password',
    PasswordResetModalLabelProps: undefined,
  },
};

export default PasswordSection;
