import spacingToNumber from '../../internals/utils/spacingToNumber';
import isWidthCalculated from './isWidthCalculated';
import SELECT_COLUMN_ID from './selectColumnId';

const calculateColumnOffsets = (widths, index) => {
  if (index === 0) {
    return 0;
  }

  let offset = 0;

  for (let i = 0; i < index; i += 1) {
    offset += widths[i];
  }

  return offset;
};

const calculateColumnWidths = (
  columns,
  tableWidth,
  financialColumnWidths,
  hasRowActions,
  spacing
) => {
  const defaultColumnWidth = spacingToNumber(spacing(25));

  const greedyColumnCount = columns.filter(
    (column) => column.width && column.width === 'greedy' && !column.fixed
  ).length;

  let totalAssignedWidths = 0;
  let financialColumnIndex = 0;

  const assignedWidths = columns.map((col) => {
    if (col.width === 'greedy') {
      return undefined;
    }

    let colWidth;

    if (col.id === SELECT_COLUMN_ID) {
      colWidth = spacingToNumber(spacing(4));
    } else {
      if (isWidthCalculated(col)) {
        colWidth = financialColumnWidths[financialColumnIndex];
        financialColumnIndex += 1;
      } else {
        const widthFromProps = parseInt(col.width, 10);

        if (widthFromProps) {
          colWidth = widthFromProps;
        } else {
          colWidth = defaultColumnWidth;
        }
      }

      colWidth += spacingToNumber(spacing(4));
    }
    totalAssignedWidths += colWidth;

    return colWidth;
  });

  const remainingWidth = tableWidth - totalAssignedWidths;
  const greedyColumnsWidth = hasRowActions
    ? remainingWidth - 36
    : remainingWidth;
  const greedyColumnWidth = Math.max(
    greedyColumnsWidth / greedyColumnCount,
    defaultColumnWidth
  );

  const assignedAndGreedyColumnWidths = assignedWidths.map((width) => {
    if (width) {
      return width;
    }

    return greedyColumnWidth;
  });

  return assignedAndGreedyColumnWidths;
};

export default (
  columns,
  tableWidth,
  financialColumnWidths,
  hasRowActions,
  hasSelectColumn,
  spacing
) => {
  const columnWidths = calculateColumnWidths(
    columns,
    tableWidth,
    financialColumnWidths,
    hasRowActions,
    spacing
  );

  const colStyles = columnWidths.map((width, i) => {
    const column = columns[i];

    const colStyle = {
      width,
      maxWidth: width,
    };

    const edgeSpace = spacing(0.5);

    // if there is a select column, the next column should have a no padding on
    // the left, otherwise the first column should have .5 bu on the left
    if ((hasSelectColumn && i === 1) || (!hasSelectColumn && i === 0)) {
      colStyle.paddingLeft = edgeSpace;
    }

    // the last non-action column should have .5 by padding on the right
    if (i === columns.length - 1) {
      colStyle.paddingRight = edgeSpace;
    }

    const { align, fixed } = column;

    if (column.id === SELECT_COLUMN_ID) {
      colStyle.textAlign = 'right';
      // colStyle.top = '8px';
      // colStyle.left = '8px';
    } else if (['left', 'right', 'center'].indexOf(align) > -1) {
      colStyle.textAlign = align;
    } else if (column.width === 'financial') {
      colStyle.textAlign = 'right';
    } else {
      colStyle.textAlign = 'left';
    }

    if (fixed) {
      colStyle.left = calculateColumnOffsets(columnWidths, i);
    }

    return colStyle;
  });

  return colStyles;
};
