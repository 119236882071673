import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  SovosErrorSnackbar,
  SovosErrorSnackbarProvider,
  SovosProvider,
} from 'mosaic-react';
import App from './App';

const assetHost = ASSET_HOST; // eslint-disable-line

const Root = ({ store }) => (
  <SovosProvider assetHost={assetHost}>
    <SovosErrorSnackbarProvider>
      <Provider store={store}>
        <BrowserRouter>
          <>
            <App />
            <SovosErrorSnackbar />
          </>
        </BrowserRouter>
      </Provider>
    </SovosErrorSnackbarProvider>
  </SovosProvider>
);

Root.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired,
};

export default Root;
