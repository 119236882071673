export default function getRangeString(start, end, months, defaultLabel) {
  if (!start && !end) {
    return defaultLabel;
  }

  if (start.getTime() === end.getTime()) {
    return `${months[start.getMonth()]} ${start.getFullYear()}`;
  }

  if (
    start.getMonth() === 0 &&
    end.getMonth() === 11 &&
    start.getFullYear() === end.getFullYear()
  ) {
    return `${start.getFullYear()}`;
  }

  return `${months[start.getMonth()]} ${start.getFullYear()} - ${
    months[end.getMonth()]
  } ${end.getFullYear()}`;
}
