import React from 'react';
import {
  arrayOf,
  bool,
  elementType,
  exact,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import SovosToolbarToggles from '../../../sovos-toolbar-toggles';
import SovosToolbarToggle from '../../../sovos-toolbar-toggle';
import SovosToolbarGroup from '../../../sovos-toolbar-group';
import SovosToolbarDropdown from '../../../sovos-toolbar-dropdown';
import SovosMonthSelector from '../../../sovos-month-selector';
import SovosMonthRangePicker from '../../../sovos-month-range-picker';
import SovosToolbarDateSelector from '../../../sovos-toolbar-date-selector';
import { quickFilterTypes } from '../../../sovos-table-group/helpers/filterTypes';

const { toggle, dateSelector, dropdown, monthSelector, monthRangePicker } =
  quickFilterTypes;
const components = {
  [dateSelector]: SovosToolbarDateSelector,
  [dropdown]: SovosToolbarDropdown,
  [monthSelector]: SovosMonthSelector,
  [monthRangePicker]: SovosMonthRangePicker,
};

const QuickFilters = ({ disabled, onClearSelection, quickFilters }) => {
  const { items: unsortedItems, onApply, values } = quickFilters;

  const toggles = [];
  const nonToggles = [];
  unsortedItems.forEach((filter) => {
    if (filter.type === toggle) {
      toggles.push(filter);
    } else {
      nonToggles.push(filter);
    }
  });

  const handleToggle = (filter) => {
    const newValue = values && values[filter] ? !values[filter] : true;
    const newValues = { ...values };
    newValues[filter] = newValue;

    onApply(newValues);
    onClearSelection();
  };

  const handleChange = (filter, filterOutput) => {
    const value = filterOutput.target
      ? filterOutput.target.value
      : filterOutput;
    const newValues = { ...values };
    newValues[filter] = value;

    onApply(newValues);
    onClearSelection();
  };

  const filterComponent = (type) => components[type] || type;

  const renderToggles = () => (
    <SovosToolbarToggles>
      {toggles.map((filter) => {
        const key = filter.dataKey || filter.label;

        return (
          <SovosToolbarToggle
            disabled={disabled}
            key={key}
            selected={values && values[key] === true}
            label={filter.label}
            onClick={() => handleToggle(key)}
          />
        );
      })}
    </SovosToolbarToggles>
  );

  const renderNonToggles = () =>
    nonToggles.map((filter) => {
      const { type, label, dataKey, menuItems, ...otherProps } = filter;
      const Filter = filterComponent(type);
      const key = dataKey || label;

      return (
        <SovosToolbarGroup key={key}>
          <Filter
            disabled={disabled}
            onChange={(filterOutput) => handleChange(key, filterOutput)}
            label={filter.label}
            {...otherProps}
          >
            {type === dropdown && menuItems}
          </Filter>
        </SovosToolbarGroup>
      );
    });

  return (
    <>
      {!!nonToggles.length && renderNonToggles()}
      {!!toggles.length && renderToggles()}
    </>
  );
};

export const quickFilterPropTypes = exact({
  items: arrayOf(
    shape({
      label: string,
      dataKey: oneOfType([string, number]),
      type: oneOfType([elementType, oneOf(Object.values(quickFilterTypes))])
        .isRequired,
    })
  ).isRequired,
  onApply: func.isRequired,
  values: object,
});

QuickFilters.propTypes = {
  disabled: bool,
  onClearSelection: func.isRequired,
  quickFilters: quickFilterPropTypes.isRequired,
};

QuickFilters.defaultProps = {
  disabled: false,
};

export default QuickFilters;
