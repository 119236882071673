import React from 'react';
import { node } from 'prop-types';
// import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import auth from '../authentication';

const ProtectedRoute = ({ element }) => {
  const location = useLocation();
  // const { isLoggedIn } = useSelector((state) => state.login);

  const isLoggedIn = auth.isAuthenticated();

  if (isLoggedIn) {
    return element;
  }

  return <Navigate to={`/login?from=${location}`} replace />;
};

ProtectedRoute.propTypes = {
  element: node.isRequired,
};

export default ProtectedRoute;
