import React from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosToolbarGroup from '../sovos-toolbar-group';
import SovosSelect from '../sovos-select';

const StyledSelect = styled(SovosSelect)(({ theme: { palette, spacing } }) => ({
  '&.MuiTextField-root': {
    alignItems: 'center',
    display: 'inline-flex',
    height: '100%',
    padding: 0,
    '&:hover, &:focus': {
      background: palette.action.hover,
    },
  },
  '& .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiSelect-select': {
    // we can't override the padding on the input element because of MUI selector specificity,
    // so force the padding to position the text correctly
    padding: `0 ${spacing(4.5)} 0 ${spacing(2)} !important`,
  },
  '& .MuiSelect-icon': {
    width: spacing(3),
    marginRight: spacing(0.5),
  },
}));

/**
 * `SovosToolbarDropdown` (opens a dropdown menu). **Note**: must be a
 * child of `SovosToolbarActions` component
 *
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarDropdown = ({
  className,
  'data-testid': dataTestId,
  label,
  sx,
  value,
  ...props
}) => (
  <SovosToolbarGroup
    className={clsx('sovosToolbarDropdown', className)}
    data-testid={dataTestId}
    sx={sx}
  >
    <StyledSelect
      aria-label={label}
      className="sovosToolbarDropdown__select"
      disableUnderline
      value={value}
      variant="standard"
      {...props}
    />
  </SovosToolbarGroup>
);

SovosToolbarDropdown.propTypes = {
  /**
   * The content of the toolbar dropdown. A collection of `SovosMenuItem`
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Text label for `aria-label` attribute
   */
  label: string,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Value of the input
   */
  value: any,
};

SovosToolbarDropdown.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  label: undefined,
  sx: undefined,
  value: '',
};

export default SovosToolbarDropdown;
