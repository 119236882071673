import {
  arrayOf,
  bool,
  elementType,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

const validateTooltipProp = (props) => {
  const { disabled, toolTip } = props;

  if (disabled && !toolTip) {
    return new Error(
      'The prop `link.toolTip` is required when the link is `disabled`.'
    );
  }

  return null;
};

export const nestedLinkPropsTypes = shape({
  action: func.isRequired,
  confirmNavigationDialogProps: shape({
    cancelButtonText: string.isRequired,
    continueButtonText: string.isRequired,
    content: oneOfType([func, string]).isRequired,
    shouldShowDialog: func.isRequired,
    title: string.isRequired,
  }),
  disabled: bool,
  label: string.isRequired,
  toolTip: validateTooltipProp,
});

export const linkPropTypes = shape({
  action: func,
  confirmNavigationDialogProps: shape({
    cancelButtonText: string.isRequired,
    continueButtonText: string.isRequired,
    content: oneOfType([func, string]).isRequired,
    shouldShowDialog: func.isRequired,
    title: string.isRequired,
  }),
  disabled: bool,
  icon: elementType,
  label: string.isRequired,
  nestedLinks: arrayOf(nestedLinkPropsTypes),
  toolTip: validateTooltipProp,
  type: string,
});

export const navigationLinksPropTypes = {
  links: arrayOf(linkPropTypes).isRequired,
  onLinkClick: func.isRequired,
  selectedLink: oneOfType([string, number]).isRequired,
};

export function hasNestedLinks(link) {
  return link.nestedLinks && link.nestedLinks.length > 0;
}

export function isLinkSelected(link, selectedLink) {
  return link.label === selectedLink || link.id === selectedLink;
}
