import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import Typography from '@mui/material/Typography';

const SovosTypography = React.forwardRef(({ className, ...rest }, ref) => (
  <Typography
    className={clsx('sovosTypography', className)}
    {...rest}
    ref={ref}
  />
));

SovosTypography.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosTypography.defaultProps = {
  className: undefined,
};

SovosTypography.baseComponent = {
  name: 'Typography',
  link: 'typography/',
};

export default SovosTypography;
