const USER = 'USER';
const storage = window.sessionStorage;

const getUser = () => storage.getItem(USER);

export default {
  login: (userName) => {
    storage.setItem(USER, userName);
  },
  logout: () => {
    storage.removeItem(USER);
  },
  isAuthenticated: () => {
    const user = getUser();
    return !!user;
  },
  getUserName: () => getUser(),
};
