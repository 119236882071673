import buttonVariants from './calendarButtonVariants';

export default function getMonthVariant(
  start,
  end,
  now,
  months,
  year,
  month,
  btnDate
) {
  if (
    start &&
    !end &&
    months[start.getMonth()] === month &&
    start.getFullYear() === year
  ) {
    return buttonVariants.monthSelected;
  }
  if (start && end) {
    const startMonth = start.getMonth();
    const endMonth = end.getMonth();
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();
    if (
      startMonth === endMonth &&
      months[startMonth] === month &&
      startYear === endYear &&
      startYear === year
    ) {
      return buttonVariants.monthSelected;
    }
    if (
      (startMonth !== endMonth ||
        (startMonth === endMonth && startYear !== endYear)) &&
      !(startMonth === 0 && endMonth === 11 && startYear === endYear)
    ) {
      if (btnDate.getTime() === start.getTime()) {
        return buttonVariants.rangeStart;
      }
      if (btnDate.getTime() === end.getTime()) {
        return buttonVariants.rangeEnd;
      }
      if (start < btnDate && end > btnDate) {
        return buttonVariants.rangeMiddle;
      }
    }
  }
  if (months[now.getMonth()] === month && now.getFullYear() === year) {
    return buttonVariants.currentMonth;
  }

  return buttonVariants.month;
}
