import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { normalizeClassnames, spacingToNumber } from '../../../internals/utils';
import SovosCollapse from '../../../sovos-collapse';
import SovosFixedWidthText from '../../../sovos-fixed-width-text';
import SectionHeader from './SectionHeader';
import HEADER from '../../constants/header';
import {
  hasNestedLinks,
  isLinkSelected,
  navigationLinksPropTypes,
} from './navigationLinksUtils';
import BaseLink from './BaseLink';
import BaseIcon from './BaseIcon';
import BaseSelectedIndicator from './BaseSelectedInidicator';
import BaseToggle from './BaseToggle';

const getIndicatorStyles = (spacing) => ({
  margin: spacingToNumber(spacing(0.625)), // 5px
  width: spacingToNumber(spacing(0.5)),
});

const StyledLink = styled(BaseLink)(({ theme: { spacing } }) => ({
  margin: `${spacing(0.25)} ${spacing()} 0 ${spacing()}`,
  paddingLeft: spacing(0.375), // 3px
  paddingRight: spacing(),
}));

const StyledIcon = styled(BaseIcon)(({ theme: { spacing } }) => ({
  marginRight: spacing(),
}));

const StyledSelectedIndicator = styled(BaseSelectedIndicator)(
  ({ theme: { spacing } }) => {
    const indicatorStyles = getIndicatorStyles(spacing);

    return {
      '&.sovosNavigationLinks__linkSelectedIndicator': {
        marginRight: indicatorStyles.margin,
        width: indicatorStyles.width,
      },
    };
  }
);

const StyledToggle = styled(BaseToggle)(({ theme: { transitions } }) => ({
  transition: `transform ${transitions.duration.short}ms`,
  '&.sovosNavigationLinks__nestedLinksToggle--expanded': {
    transform: 'rotate(90deg)',
  },
}));

const LinkText = styled(ListItemText)(({ theme: { spacing, typography } }) => {
  const indicatorStyles = getIndicatorStyles(spacing);
  return {
    '&:not(.MuiListItemText-inset) .MuiListItemText-primary': {
      ...typography.body1,
      flex: '1 0 auto',
      marginLeft: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '&.MuiListItemText-inset': {
      paddingLeft:
        spacingToNumber(spacing(6)) -
        indicatorStyles.width -
        indicatorStyles.margin,

      '& .MuiListItemText-primary': {
        ...typography.body2,
      },
    },
  };
});

function getLinkIdentifier(link) {
  return link.id ?? link.label;
}

function expandSelectedLink(links, selectedLink) {
  const parentLink = links.find((element) => {
    if (element.nestedLinks && element.nestedLinks.length > 0) {
      return (
        element.nestedLinks.find((e) => isLinkSelected(e, selectedLink)) !==
        undefined
      );
    }
    return false;
  });

  if (parentLink) {
    return { [getLinkIdentifier(parentLink)]: true };
  }

  return {};
}

const NavigationLinks = ({ selectedLink, onLinkClick, links }) => {
  const [expandedLinks, setExpandedLinks] = useState(
    expandSelectedLink(links, selectedLink)
  );

  const handleLinkClick = (link) => {
    const expand = hasNestedLinks(link);
    if (expand) {
      setExpandedLinks((prevState) => {
        const isOpen = prevState[getLinkIdentifier(link)] || false;
        return { ...prevState, [getLinkIdentifier(link)]: !isOpen };
      });
    } else {
      onLinkClick(link);
    }
  };

  const renderNestedLinkArrow = (link, isNested) => {
    if (!hasNestedLinks(link) || isNested) return null;
    const isExpanded = !!expandedLinks[getLinkIdentifier(link)];
    return <StyledToggle isExpanded={isExpanded} />;
  };

  const renderIcon = (link, isNested) => {
    if (isNested || !link.icon) {
      return null;
    }

    return <StyledIcon element={link.icon} />;
  };

  const renderNestedList = (link, isNested) => {
    if (!isNested && hasNestedLinks(link)) {
      return (
        <SovosCollapse
          in={expandedLinks[getLinkIdentifier(link)]}
          timeout="auto"
          unmountOnExit
        >
          <List disablePadding>
            {link.nestedLinks.map((nestedLink) =>
              // eslint-disable-next-line no-use-before-define
              renderLinkItem(nestedLink, true)
            )}
          </List>
        </SovosCollapse>
      );
    }

    return null;
  };

  const shouldShowSelectedIndicator = (link) => {
    if (isLinkSelected(link, selectedLink)) {
      return true;
    }

    if (hasNestedLinks(link) && !expandedLinks[getLinkIdentifier(link)]) {
      return !!link.nestedLinks.find((nl) => isLinkSelected(nl, selectedLink));
    }

    return false;
  };

  const renderLinkItem = (link, isNested) => {
    const normalizedLinkText = normalizeClassnames(link.label);
    const isSelected = shouldShowSelectedIndicator(link);

    if (link.type === HEADER) {
      return <SectionHeader key={link.label} label={link.label} />;
    }

    return (
      <Fragment key={link.label}>
        <StyledLink
          isDisabled={link.disabled}
          isNested={isNested}
          isSelected={isSelected}
          onClick={link.disabled ? null : () => handleLinkClick(link)}
        >
          <StyledSelectedIndicator element="div" isSelected={isSelected} />
          {renderIcon(link, isNested)}
          <LinkText
            inset={isNested}
            className={clsx(
              'sovosNavigationLinks__linkText',
              `sovosNavigationLinks__linkText-${normalizedLinkText}`
            )}
            primary={
              <SovosFixedWidthText
                text={link.label}
                toolTipText={link.toolTip}
              />
            }
          />
          {renderNestedLinkArrow(link, isNested)}
        </StyledLink>
        {renderNestedList(link, isNested)}
      </Fragment>
    );
  };

  return (
    <List className="sovosNavigationLinks">
      {links.map((link) => renderLinkItem(link))}
    </List>
  );
};

NavigationLinks.propTypes = navigationLinksPropTypes;

export default NavigationLinks;
