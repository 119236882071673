import React from 'react';
import { node, string } from 'prop-types';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme: { breakpoints, spacing } }) => ({
  padding: spacing(4),
  [breakpoints.up('md')]: {
    boxSizing: 'inherit',
    flex: '1 1 66.663%',
    marginLeft: spacing(),
    minWidth: 0,
  },
}));

/**
 * **Note**: Must be a child of `SovosSettingsSection`
 */
const SovosSettingsCard = ({ children, className, ...rest }) => (
  <StyledCard className={clsx('sovosSettingsCard', className)} {...rest}>
    {children}
  </StyledCard>
);

SovosSettingsCard.propTypes = {
  /**
   * The content of the settings card
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosSettingsCard.defaultProps = {
  className: undefined,
};

export default SovosSettingsCard;
