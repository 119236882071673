import React from 'react';
import clsx from 'clsx';
import { bool, func, node, shape, string } from 'prop-types';
import SovosConfirmationDialog from '../../sovos-confirmation-dialog/SovosConfirmationDialog';

const CloseDialog = ({
  className,
  open,
  title,
  content,
  OKButtonProps,
  CancelButtonProps,
  ...rest
}) => (
  <SovosConfirmationDialog
    className={clsx('sovosSlidingPanelCloseDialog', className)}
    ActionButtonProps={OKButtonProps}
    CancelButtonProps={CancelButtonProps}
    open={open}
    negative
    title={title}
    content={content}
    {...rest}
  />
);

CloseDialog.propTypes = {
  className: string,
  open: bool.isRequired,
  title: node.isRequired,
  content: node.isRequired,
  OKButtonProps: shape({
    children: node.isRequired,
    onClick: func.isRequired,
  }).isRequired,
  CancelButtonProps: shape({
    children: node.isRequired,
    onClick: func.isRequired,
  }).isRequired,
};

CloseDialog.defaultProps = {
  className: undefined,
};
export default CloseDialog;
