import React from 'react';
import { node, string } from 'prop-types';
import clsx from 'clsx';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

const StyledActions = styled(DialogActions)(({ theme: { spacing } }) => ({
  margin: `${spacing()} 0 0 0`,
  padding: 0,

  '&.MuiDialogActions-spacing': {
    '& > :not(:first-of-type)': {
      marginLeft: spacing(), // margin between action buttons
    },
  },
}));

/**
 * `SovosDialogActions` is a component to be used inside `SovosDialog` to
 * set up a dialog's actions.
 */
const SovosDialogActions = ({ className, children, ...props }) => (
  <StyledActions {...props} className={clsx('sovosDialogActions', className)}>
    {children}
  </StyledActions>
);

SovosDialogActions.propTypes = {
  /**
   * The content of the dialog actions. Must be one or more `SovosButtons`
   */
  children: node,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosDialogActions.defaultProps = {
  children: undefined,
  className: undefined,
};

export default SovosDialogActions;
