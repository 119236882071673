/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import {
  arrayOf,
  bool,
  element,
  exact,
  func,
  node,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import clsx from 'clsx';
import SovosTable from '../sovos-table';
import defaultAreRowsEqual from '../sovos-table/helpers/areRowsEqual';
import {
  searchPropTypes,
  actionIconButtonPropTypes,
  bulkActionsPropTypes,
} from '../internals/components/table-group/Toolbar';
import { filterPropTypes } from '../internals/components/table-group/Filters';
import { quickFilterPropTypes } from '../internals/components/table-group/QuickFilters';
import Group from '../internals/components/table-group/Group';

const defaultLabels = {
  loading: 'Loading Data',
};

const SovosTableGroup = ({
  actionIconButtons,
  areRowsEqual,
  bulkActions,
  classes,
  className,
  DataGroupingProps,
  filters,
  isSingleSelect,
  onRowSelection,
  quickFilters,
  search,
  setVisibleColumns,
  TableProps,
  toolbarChildren,
  toolbarTitle,
  ...rest
}) => {
  const mergedClasses = {
    loading: clsx('sovosTableGroup__loading', classes?.loading),
    noTable: clsx('sovosTableGroup__noTable', classes?.noTable),
    noToolbar: clsx('sovosTableGroup__noTable--no-toolbar', classes?.noToolbar),
    root: clsx('sovosTableGroup', className, classes?.root),
    table: clsx('sovosTableGroup__tableWrapper', classes?.table),
    toolbar: clsx('sovosTableGroup__toolbar', classes?.toolbar),
  };

  const generateRowSelection = useCallback(
    (selectedRows, setSelectedRows) => {
      if (typeof onRowSelection === 'object') {
        return {
          onRowSelection,
          onClearRowSelection: () => {}, // should this be a new prop?
          getSelectedCount: () => onRowSelection.selectedCount,
        };
      }

      if (typeof onRowSelection === 'function') {
        const isRowSelected = (row) =>
          !!selectedRows.find((r) => areRowsEqual(row, r));
        const areAllRowsSelected = () =>
          TableProps.data.every((r) => isRowSelected(r));

        return {
          onRowSelection: {
            isRowSelected,
            areAllRowsSelected,
            areAnyRowsSelected: () =>
              TableProps.data.some((r) => isRowSelected(r)),
            toggleSelectAll: () => {
              let newSelectedRows;
              if (areAllRowsSelected()) {
                newSelectedRows = selectedRows.filter(
                  (row) => !TableProps.data.find((r) => areRowsEqual(row, r))
                );
              } else {
                const rowsToSelect = TableProps.data.filter(
                  (row) => !isRowSelected(row)
                );
                newSelectedRows = [...selectedRows, ...rowsToSelect];
              }
              onRowSelection(newSelectedRows);
              setSelectedRows(newSelectedRows);
            },
            toggleRowSelection: (row) => {
              let newSelectedRows;
              if (isRowSelected(row)) {
                newSelectedRows = selectedRows.filter(
                  (r) => !areRowsEqual(row, r)
                );
              } else if (isSingleSelect) {
                newSelectedRows = [row];
              } else {
                newSelectedRows = [...selectedRows, row];
              }
              onRowSelection(newSelectedRows);
              setSelectedRows(newSelectedRows);
            },
          },
          onClearRowSelection: () => {
            setSelectedRows([]);
            onRowSelection([]);
          },
          getSelectedCount: () => selectedRows.length,
        };
      }
      return undefined;
    },
    [TableProps.data, isSingleSelect, areRowsEqual, onRowSelection]
  );

  return (
    <Group
      classes={mergedClasses}
      generateRowSelection={generateRowSelection}
      isSingleSelect={isSingleSelect}
      onRowSelectionPropName="onRowSelection"
      TableComponent={SovosTable}
      TableProps={{
        maxHeight: '100%',
        ...TableProps,
      }}
      ToolbarProps={{
        actionIconButtons,
        bulkActions,
        DataGroupingProps,
        filters,
        quickFilters,
        search,
        setVisibleColumns,
        children: toolbarChildren,
        toolbarTitle,
      }}
      {...rest}
    />
  );
};

SovosTableGroup.propTypes = {
  /**
   * Specifies additional icon buttons to be rendered in the toolbar. Array
   * of objects with an `icon`, `onClick`, and `tooltipText`.
   */
  actionIconButtons: actionIconButtonPropTypes,
  /**
   * Application-specific check of row equality used to determine whether a
   * row is selected. Performance can be optimized with something like
   * `(row1, row2) => row1.id === row2.id`. Will override `TableProps.areRowsEqual`
   */
  areRowsEqual: func,
  /**
   * When present, selecting any cells will open the bulk actions bar.
   * Array of objects with an `icon`, `tooltipText`, and `onClick`
   */
  bulkActions: bulkActionsPropTypes,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * When present, a data grouping will be displayed in the toolbar. See
   * `SovosToolbarDataGrouping` documentation for more details.
   */
  DataGroupingProps: object,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Object. Specifies which filter components to render in the filter
   * drawer. `filterTypes` are in the /helpers directory.
   *
   * - `items`: array of objects with `label`, `type`, and `dataKey`. Any
   *   other properties will be passed to the filter component. `Type`
   *   should be one of `textEntry`, `checkboxList`, `dateInput`,
   *   `dateRangePicker`, and `numberRangePicker`.
   * - `onApply`: func
   * - `values`: object
   */
  filters: filterPropTypes,
  /**
   * When true, all other props except `labels.loading` are ignored and an
   * indeterminate progress bar is displayed.
   */
  isLoading: bool,
  /**
   * When true, the `isSingleSelect` prop will select one rowin the table
   * at given time. Clicking a certain row selects it, clicking another
   * row, deselects the other.
   */
  isSingleSelect: bool,
  /**
   * An object of strings and nodes for internationalization
   */
  labels: exact({
    bulkActions: string,
    columnButtonTooltip: node,
    columnDrawer: exact({
      applyButton: node,
      cancelButton: node,
      header: string,
      removeDisabledTooltipText: node,
      removeTooltipText: node,
      addTooltipText: node,
      title: node,
    }),
    filterButtonTooltip: node,
    filterDrawer: exact({
      applyButton: node,
      cancelButton: node,
      title: node,
    }),
    loading: string,
    searchButtonTooltip: node,
    searchPlaceholder: string,
  }),
  /**
   * Used to display an explicit no data message. Displayed when
   * `snowNoData` is true. An object with:
   *
   * - `headingLabel`: string
   * - `helpLabel`: string
   * - `actionButton`: element
   */
  noData: exact({
    headingLabel: string,
    helpLabel: string,
    actionButton: element,
  }),
  /**
   * Used to display a message when the table's `data` array is empty, e.g.
   * because of filtering. An object with:
   *
   * - `headingLabel`: string
   * - `helpLabel`: string
   * - `actionButton`: element
   */
  noResults: exact({
    headingLabel: string,
    helpLabel: string,
    actionButton: element,
  }),
  /**
   * Passing in onRowSelection will cause the table card to display a
   * select column. If it's a function, the function will return the
   * currently selected set of rows every time the selected group changes.
   * If the object of functions is passed in, the external application is
   * responsible for managing the selected state.
   *
   * This is similar to onRowSelection in
   * [Table](http://s1-ui.corp.sovos.local/#/Table) props, but the table
   * card tracks selection across pagination changes. Additionally,
   * selectedCount can be passed to control the bulk actions component.
   */
  onRowSelection: oneOfType([
    func,
    exact({
      isRowSelected: func.isRequired,
      toggleRowSelection: func.isRequired,
      areAllRowsSelected: func.isRequired,
      areAnyRowsSelected: func,
      toggleSelectAll: func.isRequired,
      selectedCount: number,
    }),
  ]),
  /**
   * An object of props for the PaginationFooter component. Refer to the
   * documentation for `SovosPaginationFooter`
   */
  PaginationFooterProps: object,
  /**
   * Object. Similar to regular filters. Quick filter types are `toggle`,
   * `dateSelector`, `dropdown`, `monthSelector`, and `monthRangePicker`.
   */
  quickFilters: quickFilterPropTypes,
  /**
   * Object. Controls the toolbar search component.
   *
   * - `term`: string
   * - `onSearch`: :func
   */
  search: searchPropTypes,
  /**
   * When present, a column drawer will be available. The callback receives
   * a new columns array with `visibility: false` set on hidden columns.
   */
  setVisibleColumns: func,
  /**
   * When true, the `noData` props are used to display a message, and the
   * table and pagination footer props are ignored
   */
  showNoData: bool,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * An object of props for the Table component. Refer to the documentation
   * for `SovosTable`
   */
  TableProps: (props) => {
    const { TableProps } = props;

    if (!TableProps) return null;

    const type = typeof TableProps;
    if (type !== 'object') {
      return new Error(
        `Invalid type supplied to TableProps. Expected object but received ${type}`
      );
    }

    if (TableProps.onRowSelection) {
      return new Error(
        'TableProps received onRowSelection. Expected onRowSelection to be passed directly to TableGroup.'
      );
    }

    const { data } = TableProps;

    if (!data) return null;

    if (!Array.isArray(data) || data.some((row) => typeof row !== 'object')) {
      return new Error(
        'TableProps received an invalid data property. data should be an array of objects.'
      );
    }

    if (data.reduce((x) => x + 1, 0) !== data.length) {
      return new Error(
        `TableProps.data array's length property does not match the actual array's length.`
      );
    }
    return null;
  },
  /**
   * When present, a toolbar will be rendered with the contents of the
   * array. All other toolbar props (`actionIconButtons`, `bulkActions`,
   * `DataGroupingProps`, `filters`, `quickFilters`, `search`,
   * `setVisibleColumns`, and `toolbarTitle`) will be ignored. When using
   * `children`, be sure to manage loading states in the toolbar as well as
   * any additional state that the toolbar may require such as selected row count.
   */
  toolbarChildren: arrayOf(node),
  /**
   * When present, the toolbar will be rendered with a title
   */
  toolbarTitle: node,
};

SovosTableGroup.defaultProps = {
  actionIconButtons: undefined,
  areRowsEqual: defaultAreRowsEqual,
  bulkActions: undefined,
  className: undefined,
  classes: undefined,
  'data-testid': undefined,
  DataGroupingProps: undefined,
  filters: undefined,
  isLoading: false,
  isSingleSelect: false,
  labels: defaultLabels,
  noData: undefined,
  noResults: undefined,
  onRowSelection: undefined,
  PaginationFooterProps: undefined,
  quickFilters: undefined,
  search: undefined,
  setVisibleColumns: undefined,
  showNoData: false,
  sx: undefined,
  TableProps: undefined,
  toolbarChildren: undefined,
  toolbarTitle: undefined,
};

export default SovosTableGroup;
