import React from 'react';
import { func, node, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import CloseButton from '../internals/components/close-button/CloseButton';
import ButtonsGroup from '../sovos-buttons-group';
import SovosTypography from '../sovos-typography';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledTitle = styled('div')(({ theme: { palette, spacing } }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  height: spacing(6),
  justifyContent: 'space-between',
  padding: `0 0 ${spacing(2)} 0`,

  '& .title': {
    color: palette.text.primary,
    flex: '1 1 auto',
  },
  '& .buttonsGroup': {
    flex: '1 1 auto',
  },
}));

/**
 * `SovosDialogTitle` is a component to be used inside `SovosDialog` to set
 * up a dialog title.
 */
const SovosDialogTitle = ({
  children,
  className,
  closeDialogTooltipText,
  onClose,
  showCloseButton,
  ...props
}) => {
  const { t } = useMosaicTranslation();

  return (
    <StyledTitle className={clsx('sovosDialogTitle', className)} {...props}>
      <SovosTypography variant="h5" className="title">
        {children}
      </SovosTypography>
      {showCloseButton && onClose ? (
        <ButtonsGroup className="buttonsGroup" align="right">
          <CloseButton
            className="sovosDialog__closeButton"
            onClose={(e) => onClose(e, 'closebuttonClick')}
            tooltipText={
              closeDialogTooltipText || t('dialogTitle.closeDialogTooltip')
            }
            size="small"
          />
        </ButtonsGroup>
      ) : null}
    </StyledTitle>
  );
};

SovosDialogTitle.propTypes = {
  /**
   * The content of the dialog title. Must be a string
   */
  children: string,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Optional tooltip text applied to the close button, when present
   */
  closeDialogTooltipText: node,
  /**
   * Callback for the close button, when present
   */
  onClose: func,
  /**
   * When true, a close button will be displayed & `onClose` is required
   */
  showCloseButton: ({ onClose, showCloseButton }) => {
    if (showCloseButton && !onClose) {
      return new Error(
        'When showCloseButton is true, onClose callback function is required'
      );
    }
    return null;
  },
};

SovosDialogTitle.defaultProps = {
  children: undefined,
  className: undefined,
  closeDialogTooltipText: undefined,
  onClose: undefined,
  showCloseButton: false,
};

export default SovosDialogTitle;
