import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import SovosTooltip from '../../../sovos-tooltip';
import SovosMenuItem from '../../../sovos-menu-item';
import {
  hasNestedLinks,
  isLinkSelected,
  navigationLinksPropTypes,
} from './navigationLinksUtils';
import SectionHeader from './SectionHeader';
import { collapsedWidthFactor } from '../../../themes/sovos-sizes';
import HEADER from '../../constants/header';
import { spacingToNumber } from '../../../internals/utils';
import BaseLink from './BaseLink';
import BaseSelectedIndicator from './BaseSelectedInidicator';
import BaseIcon from './BaseIcon';
import BaseToggle from './BaseToggle';

const StyledLink = styled(BaseLink)(({ theme: { spacing } }) => ({
  margin: `${spacing(0.25)} ${spacing(0.5)} 0 ${spacing(0.5)}`,
  paddingLeft: spacing(0.25),
  paddingRight: spacing(0.25),
}));

const StyledSelectedIndicator = styled(BaseSelectedIndicator)(
  ({ theme: { spacing } }) => ({
    left: spacing(0.5),
    marginRight: spacing(0.5),
    position: 'absolute',
    width: spacing(0.5),
  })
);

const StyledIcon = styled(BaseIcon)(({ theme: { spacing } }) => ({
  marginLeft: spacing(1.75),
}));

const StyledToggle = styled(BaseToggle)(({ theme: { spacing } }) => ({
  position: 'absolute',
  right: `-${spacing(0.25)}`,
}));

const nullLink = { anchorEl: null, link: null };

const NavigationLinksMinimal = ({ selectedLink, onLinkClick, links }) => {
  const { spacing } = useTheme();
  const [expandedMenu, setExpandedMenu] = useState(nullLink);

  const closeMenu = () => setExpandedMenu(nullLink);

  const handleLinkClick = (event, link) => {
    const expand = hasNestedLinks(link);
    if (expand) {
      setExpandedMenu({
        anchorEl: event.currentTarget,
        link: link.id || link.label,
      });
    } else {
      onLinkClick(link, expand);
      closeMenu();
    }
  };

  const renderNestedLinkArrow = (link) => {
    if (!hasNestedLinks(link)) return null;
    return <StyledToggle />;
  };

  const renderNestedList = (link) => {
    const menuLeft =
      spacingToNumber(spacing(collapsedWidthFactor)) +
      spacingToNumber(spacing(0.5));

    if (hasNestedLinks(link)) {
      return (
        <Menu
          anchorEl={expandedMenu.anchorEl}
          anchorOrigin={{
            horizontal: menuLeft,
            vertical: 0,
          }}
          open={isLinkSelected(link, expandedMenu.link)}
          onClose={closeMenu}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
        >
          {link?.nestedLinks.map((nestedLink) => (
            <SovosMenuItem
              disabled={link.disabled}
              onClick={
                link.disabled ? null : (e) => handleLinkClick(e, nestedLink)
              }
              key={nestedLink.label}
            >
              <ListItemText primary={nestedLink.label} />
            </SovosMenuItem>
          ))}
        </Menu>
      );
    }

    return null;
  };

  function shouldShowSelectedIndicator(link) {
    if (isLinkSelected(link, selectedLink)) {
      return true;
    }

    if (hasNestedLinks(link)) {
      return !!link.nestedLinks.find((nl) => isLinkSelected(nl, selectedLink));
    }

    return false;
  }

  function renderLinkItem(link) {
    const isSelected = shouldShowSelectedIndicator(link);

    if (link.type === HEADER) {
      return <SectionHeader key={link.label} />;
    }

    return (
      <React.Fragment key={link.label}>
        <SovosTooltip title={link.label}>
          <StyledLink
            isDisabled={link.disabled}
            isSelected={isSelected}
            onClick={link.disabled ? null : (e) => handleLinkClick(e, link)}
          >
            {isSelected && (
              <StyledSelectedIndicator element="i" isSelected={isSelected} />
            )}
            {link.icon && <StyledIcon element={link.icon} />}
            {renderNestedLinkArrow(link)}
          </StyledLink>
        </SovosTooltip>
        {renderNestedList(link)}
      </React.Fragment>
    );
  }

  return (
    <List className="sovosNavigationLinks">
      {links.map((link) => renderLinkItem(link))}
    </List>
  );
};

NavigationLinksMinimal.propTypes = navigationLinksPropTypes;

export default NavigationLinksMinimal;
