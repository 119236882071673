import React from 'react';
import { SovosGrid, SovosPageLayout, SovosTypography } from 'mosaic-react';
import { Settings, ShoppingCart } from 'mosaic-react-icons';
import highway from './assets/highway.png';
import DashboardGridItem from './DashboardGridItem';

const DashboardPage = () => (
  <SovosPageLayout
    pageTitle="Dashboard"
    style={{ backgroundImage: `url(${highway})` }}
  >
    <SovosTypography
      sx={{
        color: 'primary.contrastText',
        marginTop: 6,
        marginBottom: 7,
        textAlign: 'center',
      }}
      variant="h2"
    >
      Welcome to Intelligent Compliance
    </SovosTypography>
    <SovosGrid
      container
      justifyContent="center"
      spacing={4}
      sx={{
        marginTop: -2,
      }}
    >
      <DashboardGridItem
        link="/invoices/all"
        iconType={ShoppingCart}
        title="GVAT"
        description="eInvoices & Filing Calendar"
      />
      <DashboardGridItem
        link="/settings/users"
        iconType={Settings}
        title="Settings"
        description="Users & Account Information"
      />
    </SovosGrid>
  </SovosPageLayout>
);

export default DashboardPage;
