/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { string, element, oneOf } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosTypography from '../../../sovos-typography';
import statusTypes from '../../../sovos-table-group/helpers/statusTypes';
import useMosaicTranslation from '../../i18n/useMosaicTranslation';

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

const Content = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  width: '50%',
  maxWidth: 500,
  '& button': {
    marginTop: spacing(1.5),
  },
}));

const StyledTypography = styled(SovosTypography)(({ theme: { spacing } }) => ({
  marginTop: spacing(),
}));

const NoResults = (props) => {
  const { t } = useMosaicTranslation();
  const defaultMessages = {
    noResults: {
      headingLabel: t('noResults'),
      helpLabel: t('noResultsLabels.helpLabel'),
    },
    noData: {
      headingLabel: t('noResultsLabels.headingLabelData'),
      helpLabel: t('noResultsLabels.helpLabelData'),
    },
  };

  const { variant, actionButton } = props;

  const getLabel = (type) => {
    const { [type]: label } = props;
    if (label) {
      return label;
    }
    if (variant === statusTypes.NO_DATA) {
      return defaultMessages.noData[type];
    }
    return defaultMessages.noResults[type];
  };

  return (
    <Root>
      <Content>
        <SovosTypography variant="h4">
          {getLabel('headingLabel')}
        </SovosTypography>
        <StyledTypography variant="body1">
          {getLabel('helpLabel')}
        </StyledTypography>
        {actionButton}
      </Content>
    </Root>
  );
};

NoResults.propTypes = {
  variant: oneOf([statusTypes.NO_DATA, statusTypes.NO_RESULTS]),
  headingLabel: string,
  helpLabel: string,
  actionButton: element,
};

NoResults.defaultProps = {
  variant: statusTypes.NO_RESULTS,
  headingLabel: undefined,
  helpLabel: undefined,
  actionButton: undefined,
};

export default NoResults;
