import React, { useEffect } from 'react';
import { node, object, string } from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import assetResolver from 'mosaic-assets-resolver';
import { useMount, useUpdate } from '../hooks';
import SovosTheme from '../themes/sovos-theme';
import SovosCustomThemeProvider from '../sovos-custom-theme-context';
import i18n from '../internals/i18n/i18n';
import MergedThemeProvider from '../internals/components/merged-theme-provider/MergedThemeProvider';

/**
 * Provides assets and theme, and loads fonts.
 */
const SovosProvider = ({ assetHost, children, lng = 'en', theme = {} }) => {
  useUpdate(() => {
    // Set assets location
    assetResolver.configure(assetHost);
  }, [assetHost]);

  useMount(async () => {
    // load fonts asynchronously, this is done to allow for server side rendering
    const loadFonts = () => {
      // eslint-disable-next-line global-require
      const WebFont = require('webfontloader');
      WebFont.load({
        google: {
          families: ['Inter:300,400,500,700', 'Material+Icons'],
        },
      });
    };

    // Set defaults
    const typography = theme.typography || SovosTheme.typography;
    document.body.style.fontFamily = typography.fontFamily;
    document.body.style.margin = 0;
    document.body.style.padding = 0;

    await loadFonts();
  });

  useEffect(() => {
    const currentLng = i18n.language;
    if (currentLng !== lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng]);

  return (
    <SovosCustomThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MergedThemeProvider theme={theme}>{children}</MergedThemeProvider>
      </LocalizationProvider>
    </SovosCustomThemeProvider>
  );
};

SovosProvider.propTypes = {
  /**
   * Url string for assets host
   */
  assetHost: string,
  /**
   * The content of the provider
   */
  children: node.isRequired,
  /**
   * The language used to localize component labels
   */
  lng: string,
  /**
   * Object for theme overrides
   */
  theme: object,
};

export default SovosProvider;
