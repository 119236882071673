export const initialState = {
  isLoading: false,
  current: {},
};

export function filingReducer(state, action) {
  switch (action.type) {
    case 'LoadFilingBegin':
      return { ...state, isLoading: true, current: {} };
    case 'LoadFilingSuccess':
      return { ...state, isLoading: false, current: action.filing };
    case 'LoadFilingFailure':
      return { ...state, isLoading: false, current: {} };
    default:
      return state;
  }
}
