import React from 'react';
import { bool, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const BaseSelectedIndicator = ({ className, element, isSelected }) => {
  const StyledElement = styled(element)(
    ({ theme: { overrides, spacing } }) => ({
      borderRadius: spacing(1),
      height: spacing(3),
      '&.sovosNavigationLinks__linkSelectedIndicator--selected': {
        backgroundColor: overrides?.sovosNavigation?.highlights ?? 'lime',
      },
    })
  );

  return (
    <StyledElement
      className={clsx(
        className,
        'sovosNavigationLinks__linkSelectedIndicator',
        {
          'sovosNavigationLinks__linkSelectedIndicator--selected': isSelected,
        }
      )}
    />
  );
};

BaseSelectedIndicator.propTypes = {
  className: string,
  element: string.isRequired,
  isSelected: bool.isRequired,
};

BaseSelectedIndicator.defaultProps = {
  className: undefined,
};

export default BaseSelectedIndicator;
