import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';

const SovosInputAdornment = ({ className, ...rest }) => (
  <InputAdornment
    className={clsx('sovosInputAdornment', className)}
    {...rest}
  />
);

SovosInputAdornment.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosInputAdornment.defaultProps = {
  className: undefined,
};

SovosInputAdornment.baseComponent = {
  name: 'Input Adornment',
  link: 'input-adornment/',
};

export default SovosInputAdornment;
