import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SovosButton,
  SovosDialog,
  SovosDialogTitle,
  SovosDialogContent,
  SovosDialogActions,
  SovosTypography,
  SovosLink,
} from 'mosaic-react';
import ToS from '../../assets/TOS-text';

export const termsOfServiceLabelPropsShape = PropTypes.shape({
  ToSLinkText: PropTypes.string,
  dialogTitleText: PropTypes.string,
  closeButtonLabelText: PropTypes.string,
});

function TermsOfService({ LabelProps, TermsOfService: TermsOfServiceProp }) {
  const [open, setOpen] = useState(false);
  const { ToSLinkText, dialogTitleText, closeButtonLabelText } = LabelProps;

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <SovosTypography variant="body2" component="span">
        <SovosLink
          component="button"
          variant="body2"
          color="inherit"
          onClick={onOpen}
        >
          {ToSLinkText}
        </SovosLink>
      </SovosTypography>
      <SovosDialog open={open} onClose={onClose} width="large">
        <SovosDialogTitle>{dialogTitleText}</SovosDialogTitle>
        <SovosDialogContent>{TermsOfServiceProp}</SovosDialogContent>
        <SovosDialogActions>
          <SovosButton onClick={onClose} variant="text">
            {closeButtonLabelText}
          </SovosButton>
        </SovosDialogActions>
      </SovosDialog>
    </>
  );
}

TermsOfService.propTypes = {
  LabelProps: termsOfServiceLabelPropsShape,
  TermsOfService: PropTypes.node,
};

TermsOfService.defaultProps = {
  LabelProps: {
    ToSLinkText: 'Terms of Service',
    dialogTitleText: 'Terms of Service',
    closeButtonLabelText: 'Close',
  },
  TermsOfService: ToS,
};

export default TermsOfService;
