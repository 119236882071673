import React from 'react';
import { func, node } from 'prop-types';
import { Close } from 'mosaic-react-icons';
import useMosaicTranslation from '../../i18n/useMosaicTranslation';
import SovosIconButton from '../../../sovos-icon-button';

const CloseButton = ({ onClose, tooltipText, ...rest }) => {
  const { t } = useMosaicTranslation();

  return (
    <SovosIconButton
      onClick={onClose}
      tooltipText={tooltipText || t('close')}
      {...rest}
    >
      <Close />
    </SovosIconButton>
  );
};

CloseButton.propTypes = {
  /**
   * Callback fired when the button is clicked
   */
  onClose: func.isRequired,
  /**
   * Tooltip for the button
   */
  tooltipText: node,
};

CloseButton.defaultProps = {
  tooltipText: undefined,
};

export default CloseButton;
