import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, node, shape, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosTypography from '../../sovos-typography';
import { headerPadding } from '../../internals/page-layout/contentPadding';
import SovosFixedWidthText from '../../sovos-fixed-width-text/SovosFixedWidthText';
import SovosButtonsGroup from '../../sovos-buttons-group/SovosButtonsGroup';
import sovosIconButtonElement from '../../internals/prop-types/sovosIconButtonElement';
import Breadcrumbs from './Breadcrumbs';
import { useWindowSize } from '../../hooks';

const Root = styled('div')(
  ({ theme: { breakpoints, palette, spacing, transitions } }) => ({
    ...headerPadding(breakpoints, spacing),
    backgroundColor: palette.background.default,
    flex: '0 0 auto',
    height: spacing(9),
    overflow: 'hidden',
    padding: `${spacing(2)} ${spacing(4)}`,
    transition: `height ${transitions.duration.standard}ms ease-out`,
    zIndex: 20,

    '&.sovosPageTitleBar--hasBreadcrumbs': {
      height: spacing(11),
    },
  })
);

const TitleRow = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '100%',
  whiteSpace: 'nowrap',
});

const PageTitle = styled(SovosTypography)(({ theme: { palette } }) => ({
  color: palette.text.primary,
  flex: '1 1 auto',
  overflow: 'hidden',
}));

const titleElementProps = {
  text: string.isRequired,
  onClick: func,
};

const tabElementProps = {
  icon: node,
  label: string.isRequired,
};

export const calculateBreadCrumbOverflowState = (
  prevContainerWidth,
  prevAncestorsCollapsed,
  prevParentCollapsed,
  containerWidth,
  scrollWidth
) => {
  let ancestorsCollapsed;
  let parentCollapsed;

  // breadcrumb container growing; expand breadcrumbs and let them be
  // re-collapsed if they don't fit
  if (prevContainerWidth < containerWidth) {
    parentCollapsed = false;
    ancestorsCollapsed = false;
  }

  // breadcrumb container too small, let's collapse some breadcrumbs!
  if (containerWidth < scrollWidth) {
    if (!prevParentCollapsed && prevAncestorsCollapsed) {
      parentCollapsed = true;
    }

    if (!prevAncestorsCollapsed) {
      ancestorsCollapsed = true;
    }
  }

  return [ancestorsCollapsed, parentCollapsed];
};

const PageTitleBar = ({ actions, pageCrumbs, pageTitle, titleIconButton }) => {
  const breadcrumbContainerRef = useRef();
  const [breadcrumbContainerWidth, setBreadCrumbContainerWidth] =
    useState(undefined);
  const [breadcrumbAncestorsCollapsed, setBreadCrumbAncestorsCollapsed] =
    useState(false);
  const [breadcrumbParentCollapsed, setBreadCrumbParentCollapsed] =
    useState(false);
  const { width: windowWidth } = useWindowSize();

  const hasBreadcrumbs = !!pageCrumbs?.length;

  useEffect(() => {
    if (!breadcrumbContainerRef.current) {
      return;
    }

    const { offsetWidth, scrollWidth } = breadcrumbContainerRef.current;
    const [ancestorsCollapsed, parentCollapsed] =
      calculateBreadCrumbOverflowState(
        breadcrumbContainerWidth,
        breadcrumbAncestorsCollapsed,
        breadcrumbParentCollapsed,
        offsetWidth,
        scrollWidth
      );
    if (ancestorsCollapsed != null || parentCollapsed != null) {
      setBreadCrumbContainerWidth(offsetWidth);
    }
    if (ancestorsCollapsed != null) {
      setBreadCrumbAncestorsCollapsed(ancestorsCollapsed);
    }
    if (parentCollapsed != null) {
      setBreadCrumbParentCollapsed(parentCollapsed);
    }
  }, [
    breadcrumbContainerWidth,
    breadcrumbParentCollapsed,
    breadcrumbAncestorsCollapsed,
    windowWidth,
  ]);

  return (
    <Root
      className={clsx(
        'sovosPageTitleBar',
        hasBreadcrumbs && 'sovosPageTitleBar--hasBreadcrumbs'
      )}
    >
      {hasBreadcrumbs && (
        <Breadcrumbs
          ref={breadcrumbContainerRef}
          parentCollapsed={breadcrumbParentCollapsed}
          ancestorsCollapsed={breadcrumbAncestorsCollapsed}
          pageCrumbs={pageCrumbs}
        />
      )}
      <TitleRow>
        <PageTitle variant="h4" component="h1" role="heading">
          <SovosFixedWidthText text={pageTitle} />
          {titleIconButton}
        </PageTitle>
        {actions?.length && (
          <SovosButtonsGroup
            className="sovosPageTitleBar__actions"
            data-testid="sovosPageTitleBar__actions"
          >
            {actions}
          </SovosButtonsGroup>
        )}
      </TitleRow>
    </Root>
  );
};

PageTitleBar.propTypes = {
  actions: node,
  pageCrumbs: arrayOf(shape(titleElementProps)),
  pageTitle: string.isRequired,
  titleIconButton: sovosIconButtonElement,
};

PageTitleBar.defaultProps = {
  actions: undefined,
  pageCrumbs: undefined,
  titleIconButton: undefined,
};

export const TitleElementProps = titleElementProps;
export const TabElementProps = tabElementProps;
export default PageTitleBar;
