import React from 'react';
import { bool, func, number } from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import { getOverlayWidth } from '../helper/width';

const StyledOverlay = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  minHeight: '100%',

  '& .sovosSlidingPanelOverlay__mask': {
    position: 'fixed',
    top: 0,
    right: 0,
    minHeight: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}));

const OverlayLayer = ({
  onClick,
  open,
  order,
  transitionDuration,
  windowWidth,
  zIndex,
  ...other
}) => {
  const theme = useTheme();

  const rootStyle = {
    zIndex,
  };

  const maskStyle = {
    width: order === 0 ? '100%' : getOverlayWidth(order, windowWidth, theme),
  };

  return (
    <Fade appear in={open} timeout={transitionDuration} {...other}>
      <StyledOverlay
        onClick={onClick}
        className="sovosSlidingPanelOverlay"
        style={rootStyle}
        data-testid="sovosSlidingPanelOverlay"
      >
        <div
          className="sovosSlidingPanelOverlay__mask"
          onClick={onClick}
          style={maskStyle}
        />
      </StyledOverlay>
    </Fade>
  );
};

OverlayLayer.propTypes = {
  onClick: func.isRequired,
  open: bool.isRequired,
  order: number.isRequired,
  transitionDuration: number.isRequired,
  windowWidth: number.isRequired,
  zIndex: number.isRequired,
};

export default OverlayLayer;
