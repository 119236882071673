import React, { forwardRef } from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  object,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import SovosFormControlLabel from '../sovos-form-control-label';
import SovosCheckbox from '../sovos-checkbox';

const Root = styled('div')({});

const StyledLabel = styled(SovosFormControlLabel)(
  ({ size, theme: { spacing } }) => ({
    // override Mui's default negative margin which expects a large checkbox
    marginLeft: size === 'small' && `-${spacing()}`,
  })
);

const StyledHelperText = styled(FormHelperText)(
  ({ theme: { spacing, typography } }) => ({
    ...typography.body2,
    marginTop: spacing(0.5),
  })
);

const SovosCheckboxField = forwardRef(
  (
    {
      checked,
      className,
      classes,
      color,
      'data-testid': dataTestId,
      disabled,
      error,
      helperText,
      id,
      indeterminate,
      label,
      onChange,
      readOnly,
      size,
      sx,
      value,
      ...props
    },
    ref
  ) => {
    const renderCheckbox = () => (
      <SovosCheckbox
        checked={checked}
        classes={classes}
        color={color}
        disabled={disabled}
        error={error}
        id={id}
        indeterminate={indeterminate}
        onChange={onChange}
        readOnly={readOnly}
        ref={ref}
        size={size}
        value={value}
        {...props}
      />
    );

    return (
      <Root
        className={clsx('sovosCheckboxField', className)}
        data-testid={dataTestId}
        sx={sx}
      >
        <StyledLabel
          className={classes?.root}
          control={renderCheckbox()}
          disabled={disabled}
          label={label}
          labelPlacement="end"
          size={size}
        />
        <StyledHelperText error={error}>{helperText}</StyledHelperText>
      </Root>
    );
  }
);

SovosCheckboxField.propTypes = {
  /**
   * Checkbox label for screen readers
   */
  'aria-label': string,
  /**
   * If `true`, the input is checked
   */
  checked: bool,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Material UI theme color of the component
   */
  color: oneOf(['primary', 'default']),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * If `true`, the input is disabled
   */
  disabled: bool,
  /**
   * If `true`, the component will display an error state
   */
  error: bool,
  /**
   * Id of the checkbox input
   */
  id: string,
  /**
   * If `true`, the input appears indeterminate
   */
  indeterminate: bool,
  /**
   * Checkbox helper text
   */
  helperText: string,
  /**
   * Checkbox label
   */
  label: string.isRequired,
  /**
   * Name of the input
   */
  name: string,
  /**
   * Callback fired when input value is changed `function(event: object,
   * checked: bool) => void`
   */
  onChange: func,
  /**
   * If `true`, the input will be readonly
   */
  readOnly: bool,
  /**
   * Size of the checkbox
   */
  size: oneOf(['small', 'medium']),
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Value of the input
   */
  value: any,
};

SovosCheckboxField.defaultProps = {
  'aria-label': undefined,
  checked: undefined,
  classes: undefined,
  className: undefined,
  color: 'primary',
  'data-testid': undefined,
  disabled: false,
  error: undefined,
  helperText: ' ',
  id: undefined,
  indeterminate: false,
  name: undefined,
  onChange: undefined,
  readOnly: false,
  size: 'medium',
  sx: undefined,
  value: undefined,
};

export default SovosCheckboxField;
