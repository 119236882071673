const makeShadow = (vertical = true) => {
  const layeredShadow = [];
  for (let i = 1; i <= 3; i += 1) {
    if (vertical) {
      layeredShadow.push(`0 ${i}px 0 rgba(0, 0, 0, .06)`);
    } else {
      layeredShadow.push(`${i}px 0 0 rgba(0, 0, 0, .06)`);
    }
  }

  return layeredShadow.join(',');
};

export const makeVerticalShadow = () => makeShadow(true);
export const makeHorizontalShadow = () => makeShadow(false);
