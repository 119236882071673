import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosToolbarGroup from '../sovos-toolbar-group';

const StyledToolbarGroup = styled(SovosToolbarGroup)(
  ({ theme: { spacing } }) => ({
    paddingLeft: spacing(),
    paddingRight: spacing(),
  })
);

/**
 * `SovosToolbarToggles` wrapper element for `SovosToolbarToggle`
 *
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarToggles = ({ className, ...rest }) => (
  <StyledToolbarGroup
    className={clsx('sovosToolbarToggles', className)}
    {...rest}
  />
);
SovosToolbarToggles.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosToolbarToggles.defaultProps = {
  className: undefined,
};

export default SovosToolbarToggles;
