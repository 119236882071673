import React from 'react';
import { bool, elementType, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { square } from '../../../internals/utils/stylesHelpers';

const BaseIcon = ({ className, element, isVisible }) => {
  const StyledElement = styled(element)(
    ({ theme: { palette, spacing, transitions } }) => ({
      pointerEvents: 'none',
      color: palette.primary.contrastText,
      opacity: 0,
      position: 'absolute',
      transition: `all ${transitions.duration.short}ms`,
      ...square(spacing(3)),

      '&.sovosNavigation__productIcon--visible': {
        opacity: 1,
      },
    })
  );

  return (
    <StyledElement
      className={clsx(
        className,
        'sovosNavigation__productIcon',
        isVisible && 'sovosNavigation__productIcon--visible'
      )}
    />
  );
};

BaseIcon.propTypes = {
  className: string,
  element: elementType.isRequired,
  isVisible: bool.isRequired,
};

BaseIcon.defaultProps = {
  className: undefined,
};

export default BaseIcon;
