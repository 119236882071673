import React, { useState } from 'react';
import { element, func, node, object, oneOf, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import SovosMenu from '../sovos-menu/SovosMenu';
import SovosMenuItem from '../sovos-menu-item/SovosMenuItem';
import SovosDivider from '../sovos-divider/SovosDivider';
import SovosIconButton from '../sovos-icon-button';
import { findChildrenByType } from '../internals/utils/reactHelpers';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledIconButton = styled(SovosIconButton)({
  padding: 0,
});

/**
 * Icon button that opens a menu. Most often used to show a "more" menu
 */

const SovosIconMenu = ({
  children,
  classes,
  className,
  color,
  'data-testid': dataTestId,
  IconButtonProps,
  iconElement,
  MenuProps,
  onClose,
  onOpen,
  size,
  style,
  tooltipText,
}) => {
  const { t } = useMosaicTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);

    if (onOpen) {
      onOpen();
    }
  };

  const onMenuClose = (event) => {
    event.stopPropagation();

    setAnchorEl(null);

    if (onClose) {
      onClose();
    }
  };

  const getHandleClick = (onClick) => (event) => {
    onMenuClose(event);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <StyledIconButton
        {...IconButtonProps}
        classes={{
          root: classes?.root,
        }}
        className={clsx('sovosIconMenu', className)}
        color={color}
        data-testid={dataTestId}
        onClick={openMenu}
        size={size}
        style={style}
        tooltipText={tooltipText || t('open')}
      >
        {iconElement}
      </StyledIconButton>
      <SovosMenu
        {...MenuProps}
        className="sovosIconMenu__menu"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        open={Boolean(anchorEl)}
      >
        {findChildrenByType(
          children,
          SovosMenuItem,
          SovosDivider,
          Divider,
          MenuItem
        ).map((menuItem) => {
          const { props: menuItemProps } = menuItem;
          return menuItem.type === Divider || menuItem.type === SovosDivider
            ? menuItem
            : React.cloneElement(menuItem, {
                ...menuItemProps,
                className: clsx(
                  'sovosIconMenu__menuItem',
                  menuItemProps.className
                ),
                onClick: getHandleClick(menuItemProps.onClick),
              });
        })}
      </SovosMenu>
    </>
  );
};

SovosIconMenu.propTypes = {
  /**
   * The content of the menu. Must be an array of `SovosMenuItem` or `SovosDivider`
   */
  children: node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Color of the icon button. Can be 'primary', 'secondary', or a valid
   * css color. Default is the text color.
   */
  color: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Object of props passed to the icon button
   */
  IconButtonProps: object,
  /**
   * An icon element for the icon button
   */
  iconElement: element.isRequired,
  /**
   * Object of props passed to the root of the menu
   */
  MenuProps: object,
  /**
   * Callback fired when menu is opened
   */
  onOpen: func,
  /**
   * Callback fired when menu is closed
   */
  onClose: func,
  /**
   * Size of the icon button
   */
  size: oneOf(['extraSmall', 'small', 'medium']),
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Tooltip text for the icon button
   */
  tooltipText: string,
};

SovosIconMenu.defaultProps = {
  className: undefined,
  classes: undefined,
  color: undefined,
  'data-testid': undefined,
  IconButtonProps: {},
  MenuProps: {},
  onOpen: undefined,
  onClose: undefined,
  size: undefined,
  style: undefined,
  tooltipText: undefined,
};

export default SovosIconMenu;
