import React, { forwardRef, useState } from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  instanceOf,
  object,
  oneOfType,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers-pro';
import { format as fnFormat, parse as fnParse } from 'date-fns';
import SovosTextField from '../sovos-text-field';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

// convert date object to string
const formatDate = (date, format) => {
  if (typeof date === 'string') return date;

  try {
    return fnFormat(date, format);
  } catch (error) {
    return undefined;
  }
};

// convert date string to date object
const parseDate = (date, format) => {
  if (date?.getTime && date.getTime()) return date;

  const dateObj = fnParse(date, format, new Date());
  return dateObj.toString() === 'Invalid Date' ? undefined : dateObj;
};

const StyledTextField = styled(SovosTextField)(({ theme: { spacing } }) => ({
  '& .MuiFilledInput-root': {
    paddingRight: spacing(3),
  },
}));

const DateInput = (props) => {
  const {
    classes,
    className,
    dataTestId,
    error,
    inputProps,
    label,
    onBlur: onBlurProp,
    ref,
    TextFieldProps,
    ...rest
  } = props;

  const { placeholder, onBlur, onFocus } = TextFieldProps?.inputProps || {};

  if (placeholder) inputProps.placeholder = placeholder;
  if (onBlur) inputProps.onBlur = onBlur;
  if (onFocus) inputProps.onFocus = onFocus;

  return (
    <StyledTextField
      {...rest}
      {...TextFieldProps}
      classes={{ filledInputRoot: classes?.input }}
      className={clsx('sovosDatePicker', className, TextFieldProps?.className)}
      data-testid={dataTestId}
      error={!!error}
      helperText={TextFieldProps?.helperText || error}
      inputProps={inputProps}
      label={TextFieldProps?.label ?? label}
      onBlur={onBlurProp}
      ref={ref}
    />
  );
};

DateInput.propTypes = {
  classes: object,
  className: string,
  dataTestId: string,
  error: string,
  inputProps: object,
  label: string,
  onBlur: func,
  ref: any,
  TextFieldProps: object,
};

DateInput.defaultProps = {
  classes: undefined,
  className: undefined,
  dataTestId: undefined,
  error: undefined,
  inputProps: undefined,
  label: undefined,
  onBlur: undefined,
  ref: undefined,
  TextFieldProps: undefined,
};

const SovosDatePicker = forwardRef(
  (
    {
      classes,
      className,
      'data-testid': dataTestId,
      format: formatProps,
      invalidDateMessage,
      maxDate,
      maxDateMessage,
      minDate,
      minDateMessage,
      onChange,
      value,
      TextFieldProps,
      sx,
      ...datePickerProps
    },
    ref
  ) => {
    const { t } = useMosaicTranslation();
    const [error, setError] = useState(false);
    const [showError, setShowError] = useState(false);

    const format = formatProps || t('datePicker.format');

    if (value === undefined && !error) {
      setError('invalidDate');
      setShowError(true);
    }

    if (value === null && showError) {
      setShowError(false);
    }

    const getErrorMessage = (err) => {
      if (!err) return undefined;
      if (err === 'minDate')
        return minDateMessage || t('datePicker.minDateMessage');
      if (err === 'maxDate')
        return maxDateMessage || t('datePicker.maxDateMessage');
      return invalidDateMessage || t('datePicker.invalidDateMessage');
    };

    const handleChange = (newValue, str) => {
      if (!newValue && !str) {
        onChange(null);
        return;
      }

      // string is passed in when user is typing in the field and undefined when
      // a date is selected from the picker
      if (str !== undefined) {
        if (str.length !== format.length) {
          return;
        }
        setShowError(true);
      }

      const dateValue = formatDate(newValue, format);
      if (dateValue) {
        onChange(dateValue);
      }
    };

    const handleBlur = (event) => {
      const str = event.target.value;
      const validatedDateString = formatDate(parseDate(str, format), format);
      const isInvalid = !!(str && !validatedDateString);

      // onError seems not to be able to handle inValid
      if (isInvalid) {
        setError('invalidDate');
        setShowError(true);
        onChange(undefined);
      } else {
        // return null when value is empty
        onChange(validatedDateString || null);
      }

      if (error) {
        setShowError(true);
      }
    };

    const handleError = (newError) => {
      if (newError === 'invalidDate') {
        setShowError(true);
      } else if (newError !== error) {
        setShowError(false);
      }
      setError(newError);
    };

    const hasError = !!((error && showError) || TextFieldProps?.error);

    return (
      <DatePicker
        showTodayButton // ?
        renderInput={(props) => (
          <DateInput
            {...props}
            classes={classes}
            className={className}
            dataTestId={dataTestId}
            error={hasError ? getErrorMessage(error) : undefined}
            onBlur={handleBlur}
            ref={ref}
            TextFieldProps={TextFieldProps}
            sx={sx}
          />
        )}
        // Passing in undefined causes the picker to display the current date, pass in "Invalid Date" to get an empty input.
        // Otherwise, attempt to parse the date into an object. If that fails, give the picker whatever was received.
        value={
          value === undefined
            ? 'Invalid Date'
            : parseDate(value, format) || value
        }
        inputFormat={format}
        onChange={handleChange}
        onError={handleError}
        maxDate={parseDate(maxDate, format)}
        minDate={parseDate(minDate, format)}
        {...datePickerProps}
      />
    );
  }
);

SovosDatePicker.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Format used to parse the date, must be a valid [unicode date
   * string](https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table)
   */
  format: string,
  /**
   * Message to show when value is invalid
   */
  invalidDateMessage: string,
  /**
   * Callback fired when input value is changed and valid, and when the
   * input is blurred. Returns null if empty, the value if valid, or
   * undefined if invalid. `function(value: string | null | undefined) => void`
   */
  onChange: func.isRequired,
  /**
   * Value of the input. If a `string`, expected to match the `format`
   */
  value: oneOfType([instanceOf(Date), string]),
  /**
   * Maximum date that can be selected. If a `string`, expected to match the `format`
   */
  maxDate: oneOfType([instanceOf(Date), string]),
  /**
   * Message to show when value is below `minDate`
   */
  maxDateMessage: string,
  /**
   * Minimum date that can be selected. If a `string`, expected to match the `format`
   */
  minDate: oneOfType([instanceOf(Date), string]),
  /**
   * Message to show when value is below `minDate`
   */
  minDateMessage: string,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: oneOfType([arrayOf(oneOfType([func, object, bool])), func, object]),
  /**
   * Props passed to the SovosTextField
   */
  TextFieldProps: object,
};

SovosDatePicker.defaultProps = {
  classes: undefined,
  className: undefined,
  'data-testid': undefined,
  format: undefined,
  invalidDateMessage: undefined,
  maxDate: undefined,
  maxDateMessage: undefined,
  minDate: undefined,
  minDateMessage: undefined,
  TextFieldProps: undefined,
  sx: undefined,
  value: undefined,
};

SovosDatePicker.baseComponent = {
  name: 'DatePicker',
  link: 'date-picker/',
};

export default SovosDatePicker;
