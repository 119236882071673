import React from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import {
  decomposeColor,
  recomposeColor,
  emphasize,
  styled,
} from '@mui/material/styles';
import { ArrowRight } from 'mosaic-react-icons';
import { ButtonBase, LinearProgress } from '@mui/material';
import SovosTypography from '../../sovos-typography';
import SovosCheckbox from '../../sovos-checkbox';
import SectionDivider from './SectionDivider';
import SovosIconButton from '../../sovos-icon-button';
import SovosFixedWidthText from '../../sovos-fixed-width-text';
import itemTypes from '../helpers/itemTypes';
import { getFocusStyles } from '../../internals/utils';

const getSelectedHoveredColor = (palette) => {
  const selected = decomposeColor(palette.action.selected);
  const opacity = selected.values[3];

  if (opacity) {
    const newOpacity = opacity + palette.action.hoverOpacity;
    selected.values[3] = newOpacity < 1 ? newOpacity : 1;
    return recomposeColor(selected);
  }

  return emphasize(palette.action.selected, palette.action.hoverOpacity);
};

const ListItem = styled('li')({
  position: 'relative',
});

const ItemButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'isExpandable',
})(({ isExpandable, theme: { palette, spacing, typography } }) => ({
  ...typography.body1,
  cursor: 'pointer',
  display: 'flex',
  margin: 0,
  minHeight: spacing(4.5),
  paddingBottom: 0,
  paddingLeft: isExpandable ? 0 : spacing(2),
  paddingRight: spacing(2),
  paddingTop: 0,
  textAlign: 'left',
  width: '100%',
  '&:hover, &:focus, &:focus-visible': {
    backgroundColor: palette.action.hover,
  },
  '&:focus-visible, &.Mui-focusVisible': {
    backgroundColor: palette.action.hover,
    ...getFocusStyles({ offset: -2 }),
  },
  '&.sovosTreeListItem__ItemButton--selected': {
    backgroundColor: palette.action.selected,
    '&.Mui-focusVisible': {
      backgroundColor: getSelectedHoveredColor(palette),
    },
    '&:hover, &:focus, &:focus-visible': {
      backgroundColor: getSelectedHoveredColor(palette),
    },
  },
  '&.sovosTreeListItem__ItemButton--noHover': {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
  '&.sovosTreeListItem__ItemButton--disabled': {
    color: palette.text.disabled,
  },
}));

const ItemText = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isExpandable',
})(({ isExpandable, theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  height: '100%',
  marginLeft: isExpandable ? spacing(5) : 0,
  minWidth: 0,
  padding: 0,
}));

const NestedItems = styled('ul')(({ theme: { spacing } }) => ({
  listStyle: 'none',
  paddingLeft: spacing(4.5),
}));

const ToggleButton = styled(SovosIconButton)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.text.primary,
    cursor: 'pointer',
    left: spacing(0.5),
    position: 'absolute',
    top: spacing(0.25),
    transition: 'transform 0.3s',
    '&[aria-expanded="true"]': {
      transform: 'rotate(90deg)',
    },
    zIndex: 4,
  })
);

const Loading = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing(4),
  textAlign: 'center',
  '& p': {
    marginTop: spacing(),
  },
}));

const TreeListItem = ({
  classes,
  expandedItems,
  hasCheckbox,
  isExpandable,
  isTopLevel,
  item,
  loadingMessage,
  onClick,
  onExpand,
  parentId,
  selectedItems,
  showLessTooltipText,
  showMoreTooltipText,
}) => {
  const isDivider =
    item.type === itemTypes.HEADER || item.type === itemTypes.DIVIDER;

  const isDisabled = item.disabled === true;
  const parent = parentId ? `${parentId}_` : '';
  const id = `${parent}${item.id}`;

  if (!isTopLevel && isDivider) {
    console.error('Headers and dividers are only allowed at the top level');
    return null;
  }

  const isExpanded = () => expandedItems[id];

  const renderToggle = (expanded) => {
    if (!isExpandable) {
      return null;
    }

    function handleKeyUp(e) {
      if (e.key === 'ArrowRight' && !expanded) {
        onExpand(e, id, item);
      }
      if (e.key === 'ArrowLeft' && expanded) {
        onExpand(e, id, item);
      }
    }

    const labelText = expanded ? showLessTooltipText : showMoreTooltipText;

    if (item.nestedItems || item.nestedItemCount) {
      return (
        <ToggleButton
          aria-expanded={expanded ? 'true' : 'false'}
          className={classes?.toggleBtn}
          data-testid="sovosTreeListItem__toggle"
          onKeyUp={(e) => handleKeyUp(e)}
          onClick={(e) => onExpand(e, id, item)}
          size="small"
          tooltipText={labelText}
        >
          <ArrowRight />
        </ToggleButton>
      );
    }

    return null;
  };

  const renderNestedItems = () => {
    const expanded = isExpanded();

    if (item.nestedItems && expanded) {
      return (
        <NestedItems className={classes?.nestedItem}>
          {item.nestedItems.map((nItem) => (
            <TreeListItem
              hasCheckbox={hasCheckbox}
              key={nItem.id}
              item={nItem}
              selectedItems={selectedItems}
              onClick={onClick}
              onExpand={onExpand}
              parentId={id}
              expandedItems={expandedItems}
              classes={classes}
              isExpandable={!!(nItem.nestedItems || nItem.nestedItemCount)}
              loadingMessage={loadingMessage}
            />
          ))}
        </NestedItems>
      );
    }

    if (item.isLoading && expanded) {
      return (
        <Loading className={classes?.loading}>
          <LinearProgress />
          <SovosTypography color="text.secondary" variant="body1">
            {loadingMessage}
          </SovosTypography>
        </Loading>
      );
    }

    return null;
  };

  const handleClick =
    isDisabled || item.isExpandOnly ? undefined : () => onClick(id, item);
  const isSelected = selectedItems.includes(id);
  const noHover = isDisabled || !onClick;

  return (
    <ListItem
      className={clsx(classes?.listItem, 'sovosTreeListItem')}
      data-testid="sovosTreeListItem"
    >
      {isDivider ? (
        <SectionDivider content={item.content} />
      ) : (
        <>
          {renderToggle(isExpanded())}
          <ItemButton
            className={clsx('sovosTreeListItem__ItemButton', {
              'sovosTreeListItem__ItemButton--selected': isSelected,
              [classes?.selectedItem]: classes?.selectedItem && isSelected,
              'sovosTreeListItem__ItemButton--noHover': noHover,
              [classes?.noHover]: classes?.noHover && noHover,
              'sovosTreeListItem__ItemButton--disabled': isDisabled,
              [classes?.disabledItem]: classes?.disabledItem && isDisabled,
            })}
            classes={{
              root: classes?.item,
              focusVisible: classes?.itemFocusVisible,
            }}
            component="button"
            disabled={isDisabled || !onClick}
            disableRipple
            disableTouchRipple
            isExpandable={isExpandable}
            onClick={handleClick}
          >
            <ItemText
              className={clsx('sovosTreeListItem__text', classes?.itemText)}
              isExpandable={isExpandable}
            >
              {hasCheckbox && (
                <SovosCheckbox checked={isSelected} size="small" />
              )}
              {typeof item.content === 'string' ? (
                <SovosFixedWidthText
                  text={item.content}
                  className="sovosTreeListText"
                />
              ) : (
                <>{item.content}</>
              )}
            </ItemText>
          </ItemButton>
          {renderNestedItems()}
        </>
      )}
    </ListItem>
  );
};

const treeListItemShape = {
  content: (props) => {
    const { content, type } = props;
    if (type !== itemTypes.DIVIDER && !content) {
      return new Error(
        'The prop `item.content` is required when type is not DIVIDER.'
      );
    }
    return null;
  },
  disabled: bool,
  id: string.isRequired,
  isLoading: bool,
  nestedItemCount: number,
  selected: bool,
  type: oneOf([...Object.values(itemTypes)]),
};
treeListItemShape.nestedItems = arrayOf(shape(treeListItemShape));
export const treeListItemPropTypes = shape(treeListItemShape);

TreeListItem.propTypes = {
  classes: object.isRequired,
  expandedItems: object.isRequired,
  hasCheckbox: bool.isRequired,
  isExpandable: bool.isRequired,
  isTopLevel: bool,
  item: treeListItemPropTypes.isRequired,
  loadingMessage: string,
  onClick: func,
  onExpand: func.isRequired,
  parentId: string,
  selectedItems: array.isRequired,
  showLessTooltipText: string,
  showMoreTooltipText: string,
};

TreeListItem.defaultProps = {
  isTopLevel: false,
  loadingMessage: 'Loading Items...',
  onClick: undefined,
  parentId: '',
  showLessTooltipText: 'Show Less',
  showMoreTooltipText: 'Show More',
};

export default TreeListItem;
