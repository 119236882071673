import React from 'react';
import { styled } from '@mui/material/styles';
import { node, object, string } from 'prop-types';

const Root = styled('div')({
  height: '100%',
  overflow: 'auto',
});

/**
 * **Note**: Must be a child of `SovosPageContentTwoColumn.
 *
 * Children must be must be `SovosPageContentGeneric` or `SovosPageContentTable`.`
 */
const SovosPageContentTwoColumnMain = ({
  children,
  className,
  'data-testid': dataTestId,
  style,
}) => (
  <Root className={className} data-testid={dataTestId} style={style}>
    {children}
  </Root>
);

SovosPageContentTwoColumnMain.propTypes = {
  /**
   * The content of the main column
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Inline styles applied to the root element
   */
  style: object,
};

SovosPageContentTwoColumnMain.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  style: undefined,
};

export default SovosPageContentTwoColumnMain;
