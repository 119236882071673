import * as types from '../constants/actionTypes';
import initialState from './accountInitialState';

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case types.ACCOUNT_ON_CHANGE:
      return { ...state, current: action.account, isDirty: true };
    case types.ACCOUNT_ON_SAVE:
      return { ...state, isDirty: false };
    case types.ACCOUNT_ON_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
