import React from 'react';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  SovosAvatar,
  SovosButton,
  SovosHeroBanner,
  SovosHeroBannerSubtitle,
  SovosHeroBannerTitle,
  SovosLink,
  SovosPageContentGeneric,
  SovosPageLayout,
  SovosSettings,
  SovosTypography,
} from 'mosaic-react';
import PersonalSection from './components/PersonalSection';
import TermsOfService from './components/TermsOfService';
import PasswordSection from './components/PasswordSection';
import {
  accountOnSave,
  accountOnChange,
  accountOnReset,
} from './actions/accountActions';

const Delimiter = () => (
  <Box component="span" sx={{ marginLeft: 1, marginRight: 1 }}>
    •
  </Box>
);

const MyAccount = () => {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const user = account.current;

  const onSave = () => {
    dispatch(accountOnSave());
  };

  const onPersonalChange = (value) => {
    dispatch(accountOnChange(value));
  };

  const handleReset = () => {
    dispatch(accountOnReset());
  };

  const onPasswordChange = (value) => {
    const newUserValue = { ...account.current };
    newUserValue.password = value;
    const today = new Date();
    const dateString = `${today.getMonth() + 1}/${today.getDate()}/${today
      .getFullYear()
      .toString()
      .slice(2)}`;
    newUserValue.passwordLastChanged = dateString;
    accountOnChange(newUserValue);
  };

  const titleBarActions = () => [
    <SovosButton
      onClick={handleReset}
      key="resetUserAccount"
      className="MyAccountPage__ResetButton"
      variant="text"
    >
      Reset
    </SovosButton>,
    <SovosButton
      onClick={onSave}
      key="saveUserAccount"
      className="MyAccountPage__AddButton"
      disabled={!account.isDirty}
    >
      Save
    </SovosButton>,
  ];

  const renderHeroBanner = () => {
    if (user) {
      return (
        <SovosHeroBanner>
          <SovosHeroBannerTitle>
            <SovosAvatar
              name={user.first}
              surname={user.last}
              size="large"
              sx={{ marginRight: 2 }}
            />
            <span>
              {user.first} {user.last}
            </span>
          </SovosHeroBannerTitle>
          <SovosHeroBannerSubtitle>
            <div>{user.accountType}</div>
            <div>{`User ID - ${user.id}`}</div>
          </SovosHeroBannerSubtitle>
        </SovosHeroBanner>
      );
    }
    return null;
  };

  return (
    <SovosPageLayout
      pageTitle="My Account"
      titleBarActions={titleBarActions()}
      className="MyAccountPage"
    >
      {renderHeroBanner()}
      <SovosPageContentGeneric>
        <SovosSettings>
          <PersonalSection user={user} onChange={onPersonalChange} />
          <PasswordSection user={user} onPasswordChange={onPasswordChange} />
        </SovosSettings>
        <SovosTypography color="text.hint" variant="body2" sx={{ margin: 1 }}>
          <SovosLink
            color="inherit"
            href="https://sovos.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </SovosLink>
          <Delimiter />
          <TermsOfService />
          <Delimiter />
          <span>&copy; {new Date().getFullYear()} Sovos Compliance, LLC</span>
        </SovosTypography>
      </SovosPageContentGeneric>
    </SovosPageLayout>
  );
};

export default MyAccount;
