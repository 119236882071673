import React, { forwardRef } from 'react';
import { string, func } from 'prop-types';
import { SovosMenuItem } from 'mosaic-react';
import FilingSelect from './FilingSelect';

const Deadline = forwardRef(({ value, onChange }, ref) => (
  <FilingSelect onChange={onChange} ref={ref} value={value}>
    <SovosMenuItem value="No Offset">No Offset</SovosMenuItem>
    <SovosMenuItem value="10d">-10 d</SovosMenuItem>
    <SovosMenuItem value="8d">-8 d</SovosMenuItem>
    <SovosMenuItem value="6d">-6 d</SovosMenuItem>
    <SovosMenuItem value="4d">-4 d</SovosMenuItem>
    <SovosMenuItem value="3d">-3 d</SovosMenuItem>
    <SovosMenuItem value="2d">-2 d</SovosMenuItem>
    <SovosMenuItem value="1d">-1 d</SovosMenuItem>
  </FilingSelect>
));

Deadline.propTypes = {
  value: string,
  onChange: func.isRequired,
};

Deadline.defaultProps = {
  value: 'No Offset',
};

export default Deadline;
