import React from 'react';
import { bool, func, instanceOf, oneOfType, shape, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { getDateRangeMessage, getIconSizes } from '../internals/utils';
import SovosToolbarGroup from '../sovos-toolbar-group';
import SovosDatePicker from '../sovos-date-picker';
import SovosSnackbar from '../sovos-snackbar';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledToolbarGroup = styled(SovosToolbarGroup)(
  ({ theme: { spacing } }) => ({
    paddingLeft: spacing(0.5),
    paddingRight: spacing(0.5),
  })
);

const StyledDatePicker = styled(SovosDatePicker)(
  ({ disabled, theme: { palette, spacing } }) => ({
    '&.MuiTextField-root': {
      width: spacing(15),
    },
    '& .MuiInputBase-root': {
      '&:before': {
        borderWidth: 0,
      },
      '&:hover:before': {
        borderWidth: 1,
        borderBottomColor: palette.text.secondary,
      },
      '& input': {
        '&::placeholder': {
          opacity: 1,
        },
      },
      '&.Mui-focused': {
        '& input': {
          '&::placeholder': {
            color: palette.text.secondary,
          },
        },
      },
      '&.Mui-error': {
        color: palette.text.primary,
        '&::before, &::after, &:hover::before, &:hover::after': {
          borderWidth: 0,
        },
        // override the adornment color for errors
        '& .MuiIconButton-root': {
          '& svg': {
            color: palette.error.main,
          },
        },
      },
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0,

      '& .MuiIconButton-root': {
        padding: 0,
        marginLeft: 0,
        ...getIconSizes(spacing).small.button,
        '& svg': {
          color: disabled ? palette.action.disabled : palette.text.primary,
          ...getIconSizes(spacing).small.icon,
        },
      },
    },
    // hide inline error messages
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  })
);

const Dash = styled('div')(({ theme: { palette, spacing } }) => ({
  height: 0,
  width: spacing(2),
  borderTop: `1px solid ${palette.text.primary}`,
  margin: `0 ${spacing(0.5)}`,
}));

/**
 * **Note**: must be a child of `SovosToolbar`
 */
const SovosToolbarDateSelector = ({
  className,
  'data-testid': dataTestId,
  disabled,
  format: formatProps,
  fromLabel,
  maxDate,
  minDate,
  onChange,
  toLabel,
  invalidEndDateMessage: invalidEndDateMessageProp,
  invalidStartDateMessage: invalidStartDateMessageProp,
  endDateAfterStartDateMessage: endDateAfterStartDateMessageProp,
  values,
  ...rest
}) => {
  const { t } = useMosaicTranslation();

  const format = formatProps || t('toolbarDateSelector.format');
  const invalidStartDateMessage =
    invalidStartDateMessageProp ||
    t('toolbarDateSelector.invalidStartDateMessage');
  const invalidEndDateMessage =
    invalidEndDateMessageProp || t('toolbarDateSelector.invalidEndDateMessage');
  const endDateAfterStartDateMessage =
    endDateAfterStartDateMessageProp ||
    t('toolbarDateSelector.endDateAfterStartDateMessage');

  const [errorMessage, setErrorMessage] = React.useState('');
  const [fromPlaceholder, setFromPlaceholder] = React.useState(fromLabel);
  const [toPlaceholder, setToPlaceholder] = React.useState(toLabel);

  React.useEffect(() => {
    setErrorMessage(
      getDateRangeMessage({
        values,
        format,
        minDate,
        maxDate,
        invalidStartDateMessage,
        invalidEndDateMessage,
        endDateAfterStartDateMessage,
      })
    );
  }, [
    values,
    format,
    maxDate,
    minDate,
    invalidEndDateMessage,
    invalidStartDateMessage,
    endDateAfterStartDateMessage,
  ]);

  const pickerProps = {
    format,
    InputAdornmentProps: {
      position: 'start',
    },
  };

  const TextFieldProps = {
    variant: 'standard',
    placeholder: 'place',
  };

  return (
    <StyledToolbarGroup
      className={clsx('sovosToolbarDateSelector', className)}
      data-testid={dataTestId}
      {...rest}
    >
      <StyledDatePicker
        format={format || t('toolbarDateSelector.format')}
        disabled={disabled}
        {...pickerProps}
        maxDate={values.to || maxDate}
        minDate={minDate}
        name="from"
        onChange={(newValue) => {
          onChange({ from: newValue, to: values.to });
        }}
        TextFieldProps={{
          ...TextFieldProps,
          inputProps: {
            'aria-label': fromLabel,
            onBlur: () => setFromPlaceholder(fromLabel),
            onFocus: () => setFromPlaceholder(format.toLowerCase()),
            placeholder: fromPlaceholder || t('toolbarDateSelector.fromLabel'),
          },
        }}
        value={values.from}
      />
      <Dash />
      <StyledDatePicker
        format={format || t('toolbarDateSelector.format')}
        disabled={disabled}
        {...pickerProps}
        maxDate={maxDate}
        minDate={values.from || minDate}
        name="to"
        onChange={(newValue) => {
          onChange({ from: values.from, to: newValue });
        }}
        TextFieldProps={{
          ...TextFieldProps,
          inputProps: {
            'aria-label': toLabel,
            onBlur: () => setToPlaceholder(toLabel),
            onFocus: () => setToPlaceholder(format.toLowerCase()),
            placeholder: toPlaceholder || t('toolbarDateSelector.toLabel'),
          },
        }}
        value={values.to}
      />
      <SovosSnackbar
        message={errorMessage}
        open={errorMessage.length > 0}
        onClose={() => setErrorMessage('')}
        variant="error"
      />
    </StyledToolbarGroup>
  );
};

SovosToolbarDateSelector.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * If `true`, the button is disabled
   */
  disabled: bool,
  /**
   * Text label for the error indicating a `from` value occurs after the `to` value
   */
  endDateAfterStartDateMessage: string,
  /**
   * Format of a valid date i.e.: `DD/MM/YYYY`
   */
  format: string,
  /**
   * Label for 'from' field
   */
  fromLabel: string,
  /**
   * Text label for the error indicating an invalid `to` value
   */
  invalidEndDateMessage: string,
  /**
   * Text label for the error indicating an invalid `from` value
   */
  invalidStartDateMessage: string,
  /**
   * Date instance or string value of the maximum `to` value
   */
  maxDate: oneOfType([instanceOf(Date), string]),
  /**
   * Date instance or string value of the minimum `from` value
   */
  minDate: oneOfType([instanceOf(Date), string]),
  /**
   * Callback fired when the value is changed `function(data: object) => void`
   */
  onChange: func.isRequired,
  /**
   * Label for `to` field
   */
  toLabel: string,
  /**
   * Object with string properties for `from` and `to` values
   */
  values: shape({ from: string, to: string }).isRequired,
};

SovosToolbarDateSelector.defaultProps = {
  className: undefined,
  'data-testid': undefined,
  disabled: false,
  endDateAfterStartDateMessage: undefined,
  format: undefined,
  fromLabel: undefined,
  invalidEndDateMessage: undefined,
  invalidStartDateMessage: undefined,
  maxDate: undefined,
  minDate: undefined,
  toLabel: undefined,
};

export default SovosToolbarDateSelector;
