import React from 'react';
import {
  arrayOf,
  oneOfType,
  bool,
  func,
  node,
  number,
  object,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import SovosAutocomplete from '../sovos-autocomplete';
import SovosTextField from '../sovos-text-field';
import SovosFilterDrawerSection from '../sovos-filter-drawer-section';

const Root = styled(SovosFilterDrawerSection)(({ theme: { spacing } }) => ({
  '& .sovosFilterTextEntry__input': {
    marginBottom: spacing(),

    '&.MuiTextField-root, & .MuiTextField-root': {
      minHeight: 'auto',
    },
  },
}));

const getStyledRow = ({
  autoComplete,
  AutocompleteProps,
  entry,
  handleChange,
}) => {
  const commonProps = {
    autoFocus: !entry,
    size: 'small',
  };

  if (autoComplete || AutocompleteProps) {
    const { options, ...autoCompleteProps } = AutocompleteProps || {};

    return (
      <SovosAutocomplete
        className="sovosFilterTextEntry__input"
        data-testid="autocomplete"
        disableClearable
        onChange={handleChange}
        options={autoComplete || options}
        value={entry || null}
        {...commonProps}
        {...autoCompleteProps}
      />
    );
  }

  return (
    <SovosTextField
      className="sovosFilterTextEntry__input"
      data-testid="text"
      fullWidth
      onChange={handleChange}
      value={entry || ''}
      {...commonProps}
    />
  );
};

getStyledRow.propTypes = {
  entry: node.isRequired,
  classes: object.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  autoComplete: arrayOf(string),
  AutocompleteProps: object,
};

getStyledRow.defaultProps = {
  autoComplete: undefined,
  AutocompleteProps: undefined,
};

const getRow = (componentProps) => (props) =>
  getStyledRow({ ...componentProps, ...props });

/**
 * For use in `SovosFilterDrawer`
 */
const SovosFilterTextEntry = ({
  autoComplete,
  AutocompleteProps,
  className,
  data,
  'data-testid': dataTestId,
  label,
  multiple,
  onChange,
  ...rest
}) => (
  <Root
    className={clsx('sovosFilterTextEntry', className)}
    data={data}
    data-testid={dataTestId}
    getRow={getRow({ autoComplete, AutocompleteProps })}
    label={label}
    multiple={multiple}
    onChange={onChange}
    {...rest}
  />
);

SovosFilterTextEntry.propTypes = {
  /**
   * Array of autocomplete options. If provided, component will display a
   * SovosAutocomplete component. Do not use with AutocompleteProps
   */
  autoComplete: arrayOf(string),
  /**
   * Properties passed to the SovosAutocomplete component. Allows control
   * of async loading. Do not use with the autoComplete prop
   */
  AutocompleteProps: shape({
    clearOptions: func,
    loading: bool,
    loadOptions: func,
    minimumCharacters: number,
    options: arrayOf(oneOfType([string, object])),
    getOptionLabel: func,
    isOptionEqualToValue: func,
  }),
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * An array of strings or object
   */
  data: arrayOf(oneOfType([string, object])),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Section label
   */
  label: string.isRequired,
  /**
   * When true, the user can add an arbitrary number of values
   */
  multiple: bool,
  /**
   * Callback fired when the input value is changed `function(data:
   * string[]) => void`
   */
  onChange: func.isRequired,
};

SovosFilterTextEntry.defaultProps = {
  autoComplete: undefined,
  AutocompleteProps: undefined,
  className: undefined,
  data: undefined,
  'data-testid': undefined,
  multiple: false,
};

export default SovosFilterTextEntry;
