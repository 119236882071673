import React from 'react';
import { string } from 'prop-types';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { ViewColumn } from 'mosaic-react-icons';
import SovosIconButton from '../sovos-icon-button/SovosIconButton';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

/**
 * `SovosToolbarColumn` (opens a drawer to configure columns). **Note**:
 * must be a child of `SovosToolbarActions` component
 */
const SovosToolbarColumn = ({ className, color, tooltipText, ...props }) => {
  const { palette } = useTheme();
  const { t } = useMosaicTranslation();

  return (
    <SovosIconButton
      {...props}
      tooltipText={tooltipText || t('toolbarColumn.tooltip')}
      className={clsx('sovosToolbarColumn', className)}
      color={color || palette.text.secondary}
    >
      <ViewColumn />
    </SovosIconButton>
  );
};
SovosToolbarColumn.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Can be 'primary', 'default', or a valid css Hex color. Default is the
   * text color.
   */
  color: string,
  /**
   * Tooltip for the icon
   */
  tooltipText: string,
};

SovosToolbarColumn.defaultProps = {
  className: undefined,
  color: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  tooltipText: undefined,
};

export default SovosToolbarColumn;
