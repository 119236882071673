import React from 'react';
import { node, object, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const Root = styled('div')(({ theme: { spacing } }) => ({
  padding: `${spacing()} ${spacing(2)}`,
}));

/**
 * Correctly positions arbitrary content at the top of `SovosPageLayout`
 */
const SovosGenericHeroBanner = ({
  children,
  classes,
  className,
  style,
  ...rest
}) => (
  <Root
    className={clsx('sovosGenericHeroBanner', className, classes?.root)}
    style={style}
    {...rest}
  >
    {children}
  </Root>
);

SovosGenericHeroBanner.propTypes = {
  /**
   * The content of the hero banner
   */
  children: node.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Inline styles applied to the root element
   */
  style: object,
};

SovosGenericHeroBanner.defaultProps = {
  classes: undefined,
  className: undefined,
  style: undefined,
};

export default SovosGenericHeroBanner;
