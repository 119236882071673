import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  contentSidePadding,
  contentToolbarActions,
  contentTablePadding,
} from '../internals/page-layout/contentPadding';
import SovosTableGroup from '../sovos-table-group';
import { spacingToNumber } from '../internals/utils';

const StyledTableGroup = styled(SovosTableGroup)(
  ({ theme: { breakpoints, spacing } }) => {
    const padding = contentSidePadding(breakpoints, spacing);
    const toolbarPadding = contentToolbarActions(breakpoints, spacing);
    const tablePadding = contentTablePadding(breakpoints, spacing);
    return {
      '& .sovosTableGroup__noTable': {
        ...padding,
      },
      '& .sovosTableGroup__tableWrapper': {
        ...tablePadding,
      },
      '& .sovosPaginationFooter': {
        ...padding,
        marginTop: 0,
      },
      '& .sovosTableGroup__toolbar.MuiToolbar-root': {
        borderTop: 'none',
        ...toolbarPadding,
        height: spacingToNumber(spacing(6)) - 1,
        minHeight: spacingToNumber(spacing(6)) - 1,
      },
    };
  }
);

/**
 * `SovosPageContentTable` should only be used as a child of
 * `SovosPageLayout` or `SovosPageContentTwoColumnMain`, and is responsible
 * for responsively setting the amount of padding and handling other
 * aspects of full-page layout. It is otherwise identical to
 * `SovosTableGroup` and takes all the same props.
 */
const SovosPageContentTable = ({ className, ...rest }) => (
  <StyledTableGroup
    className={clsx('sovosPageContentTable', className)}
    {...rest}
  />
);

SovosPageContentTable.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosPageContentTable.defaultProps = {
  className: undefined,
};

SovosPageContentTable.baseComponent = {
  name: 'SovosTableGroup',
};

export default SovosPageContentTable;
