import React from 'react';
import { SovosErrorPage } from 'mosaic-react';

const NotFoundPage = () => (
  <SovosErrorPage
    actionUrl="/"
    appName="Demo App"
    errorCode="404"
    helpUrl="http://sovos.com/support"
  />
);

export default NotFoundPage;
