import React, { forwardRef } from 'react';
import { bool, node, string } from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import clsx from 'clsx';

const StyledListItem = styled(ListItem)(
  ({ theme: { overrides, palette, spacing, transitions } }) => ({
    backgroundColor: alpha(palette.primary.light, 0.0),
    borderRadius: spacing(0.5),
    color:
      overrides?.sovosNavigation?.navItemForeground ??
      palette.primary.contrastText,
    display: 'flex',
    height: spacing(4.25),
    transition: `background-color ${transitions.duration.short}ms`,
    width: 'auto',

    '&:hover': {
      backgroundColor:
        overrides?.sovosNavigation?.navItemHover ??
        alpha(palette.primary.light, 0.08),
    },

    '&.sovosNavigationLinks__link--selected': {
      backgroundColor: alpha(palette.primary.light, 0.12),
      color: palette.primary.contrastText,
    },

    '&.sovosNavigationLinks__link--disabled': {
      cursor: 'not-allowed',
    },
  })
);

const BaseLink = forwardRef(
  (
    { children, className, isDisabled, isNested, isSelected, onClick, ...rest },
    ref
  ) => (
    <StyledListItem
      component="li"
      button
      disableRipple
      className={clsx('sovosNavigationLinks__link', className, {
        'sovosNavigationLinks__link--nested': isNested,
        'sovosNavigationLinks__link--selected': isSelected,
        'sovosNavigationLinks__link--disabled': isDisabled,
      })}
      onClick={onClick}
      aria-current={isSelected ? 'page' : undefined}
      {...rest}
      ref={ref}
      role="link"
    >
      {children}
    </StyledListItem>
  )
);

BaseLink.propTypes = {
  children: node.isRequired,
  className: string,
  isDisabled: bool,
  isNested: bool,
  isSelected: bool.isRequired,
  onClick: (props) => {
    const { isDisabled, onClick } = props;
    if (!isDisabled && !onClick) {
      return new Error(
        `Required prop onClick not supplied to` +
          ` \`${props.component}\`. Validation failed.`
      );
    }

    return null;
  },
};

BaseLink.defaultProps = {
  className: undefined,
  isDisabled: false,
  isNested: false,
  onClick: undefined,
};

export default BaseLink;
