import { useEffect } from 'react';

export const useClickOutside = (ref, callBack) => {
  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callBack();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useClickOutside;
