import React, { forwardRef } from 'react';
import { node, object, oneOf, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import SovosTooltip from '../sovos-tooltip';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'color',
})(
  ({
    backgroundColor: bgColorProp,
    color: colorProp,
    size: sizeProp,
    theme: { palette, spacing, typography },
  }) => {
    const size = (() => {
      const sizeKey = sizeProp?.toLowerCase();
      if (sizeKey === 'small') return spacing(3);
      if (sizeKey === 'large') return spacing(5);
      return spacing(4);
    })();

    return {
      ...typography.body2,
      backgroundColor: bgColorProp ?? palette.grey[300],
      color: colorProp ?? palette.text.secondary,
      display: 'inline-flex',
      height: size,
      width: size,
    };
  }
);

const SovosAvatar = forwardRef(
  (
    {
      backgroundColor,
      children,
      classes,
      className,
      color,
      name,
      noNameMessage,
      size,
      style,
      surname,
      ...rest
    },
    ref
  ) => {
    const { t } = useMosaicTranslation();

    const renderToolTipTitle = () => {
      if (name && surname) {
        return `${name} ${surname}`;
      }
      if (name && !surname) {
        return `${name}`;
      }
      if (!name && surname) {
        return `${surname}`;
      }
      return noNameMessage || t('avatar.noName');
    };

    const renderInitials = () => {
      if (children) return children;
      if (name && surname) {
        return name[0].toUpperCase() + surname[0].toUpperCase();
      }
      if (name && !surname) {
        return name[0].toUpperCase();
      }
      if (!name && surname) {
        return surname[0].toUpperCase();
      }
      return '?';
    };

    return (
      <SovosTooltip title={renderToolTipTitle()}>
        <StyledAvatar
          backgroundColor={backgroundColor}
          classes={classes}
          className={clsx('sovosAvatar', className)}
          color={color}
          ref={ref}
          size={size}
          style={style}
          {...rest}
        >
          {renderInitials()}
        </StyledAvatar>
      </SovosTooltip>
    );
  }
);

SovosAvatar.propTypes = {
  /**
   * Override background color
   */
  backgroundColor: string,
  /**
   * Override children. Defaults to first and last initial
   */
  children: node,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Override the text color
   */
  color: string,
  /**
   * First name to display
   */
  name: string,
  /**
   * Tooltip text to display when `name` and `surname` are empty
   */
  noNameMessage: string,
  /**
   * Size of Avatar
   */
  size: oneOf(['small', 'medium', 'large']),
  /**
   * Inline styles applied to the root element
   */
  style: object,
  /**
   * Surname to display
   */
  surname: string,
};

SovosAvatar.defaultProps = {
  backgroundColor: undefined,
  children: undefined,
  classes: undefined,
  className: undefined,
  color: undefined,
  name: undefined,
  noNameMessage: undefined,
  size: 'medium',
  style: {},
  surname: undefined,
};

SovosAvatar.baseComponent = {
  name: 'Avatar',
  link: 'avatar/',
};

export default SovosAvatar;
