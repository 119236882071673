import * as types from '../constants/actionTypes';

export function accountOnChange(account) {
  return { type: types.ACCOUNT_ON_CHANGE, account };
}

export function accountOnSave() {
  return { type: types.ACCOUNT_ON_SAVE };
}

export function accountOnReset() {
  return { type: types.ACCOUNT_ON_RESET };
}
