import React from 'react';
import { bool, func, node, object, oneOf, string } from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import SovosDrawerHeader from '../sovos-drawer-header/SovosDrawerHeader';
import {
  findChildrenByType,
  filterOutChildrenByType,
} from '../internals/utils/reactHelpers';

const StyledDrawer = styled(Drawer)(({ size, theme: { spacing } }) => {
  const drawerWidths = {
    small: spacing(48),
    large: spacing(68),
  };

  return {
    '& .sovosDrawer__container': {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      maxWidth: '100vw',
      width: drawerWidths[size],
    },
    '& .sovosDrawer__content': {
      padding: `${spacing(2)} ${spacing(3)}`,
      overflowY: 'auto',
      flex: '1 1 auto',
    },
  };
});

const transitionsTimeout = 300;

const paperProps = {
  component: 'aside',
  elevation: 16,
};

const SovosDrawer = ({
  children,
  className,
  onClose,
  open,
  size,
  style,
  ...rest
}) => (
  <StyledDrawer
    anchor="right"
    BackdropProps={{
      'data-testid': 'sovosModalBackdrop',
    }}
    className={clsx('sovosDrawer', className)}
    open={open}
    onClose={onClose}
    PaperProps={paperProps}
    size={size}
    style={style}
    transitionDuration={transitionsTimeout}
    {...rest}
  >
    <div className="sovosDrawer__container">
      {findChildrenByType(children, SovosDrawerHeader)}
      <div className="sovosDrawer__content" data-testid="drawerContent">
        {filterOutChildrenByType(children, SovosDrawerHeader)}
      </div>
    </div>
  </StyledDrawer>
);

SovosDrawer.propTypes = {
  /**
   * The content of the drawer
   */
  children: node,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Callback fired when the drawer is closed, i.e. when the user clicks off of it
   */
  onClose: func.isRequired,
  /**
   * The drawer's open state
   */
  open: bool.isRequired,
  /**
   * The drawer's width
   *
   * - `small`: 384px
   * - `large`: 544px
   */
  size: oneOf(['small', 'large']),
  /**
   * Inline styles applied to the root element
   */
  style: object,
};

SovosDrawer.defaultProps = {
  children: undefined,
  className: undefined,
  size: 'small',
  style: undefined,
};

SovosDrawer.baseComponent = {
  name: 'Drawer',
  link: 'drawer/',
};

export default SovosDrawer;
