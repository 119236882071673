import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';
import { FilterList } from 'mosaic-react-icons';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import SovosIconButton from '../sovos-icon-button/SovosIconButton';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, theme: { palette } }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color || palette.primary.main,
    color: palette.common.white,
  },
}));

/**
 * `SovosToolbarFilter` (opens the filter drawer) **Note**: must be a child
 * of `SovosToolbarActions`
 *
 * Props from the SovosIconButton
 */
const SovosToolbarFilter = ({
  appliedFiltersCount,
  className,
  color,
  tooltipText,
  ...props
}) => {
  const { t } = useMosaicTranslation();

  return (
    <SovosIconButton
      {...props}
      className={clsx('sovosToolbarFilter__button', className)}
      color={color}
      tooltipText={tooltipText || t('toolbarFilter.tooltip')}
    >
      <StyledBadge
        badgeContent={appliedFiltersCount > 0 ? appliedFiltersCount : 0}
      >
        <FilterList />
      </StyledBadge>
    </SovosIconButton>
  );
};

SovosToolbarFilter.propTypes = {
  /**
   * Number of filters that have been applied
   */
  appliedFiltersCount: (props) => {
    const { appliedFiltersCount } = props;
    const count = Number.parseInt(appliedFiltersCount, 10);

    if (Number.isNaN(count)) {
      return new Error('`appliedFiltersCount` is not a number');
    }

    if (!/^[0-9]\d*$/.test(count)) {
      return new Error(
        `Invalid prop (no negative numbers allowed) 'appliedFiltersCount': '${count}' supplied to 'SovosToolbarFilter'`
      );
    }

    return null;
  },
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * One of the Material UI theme colors, `primary`, `default`, etc. or a
   * custom css Hex color string
   */
  color: string,
  /**
   * Tooltip for the icon
   */
  tooltipText: string,
};

SovosToolbarFilter.defaultProps = {
  appliedFiltersCount: 0,
  className: undefined,
  color: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  tooltipText: undefined,
};

export default SovosToolbarFilter;
