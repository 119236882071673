import React from 'react';
import { bool, shape, string } from 'prop-types';
import { format, parseISO } from 'date-fns';

const Never = 'Never';

const ExpirationDate = ({ content }) => (
  <span>
    {content.doesNotExpire
      ? Never
      : format(parseISO(content.expirationDate), 'yyyy/MM/dd')}
  </span>
);

ExpirationDate.propTypes = {
  content: shape({
    doesNotExpire: bool,
    expirationDate: string,
  }).isRequired,
};

export default ExpirationDate;
