import React from 'react';
import { node, string } from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

const StyledToolbar = styled(Toolbar)(({ theme: { palette, spacing } }) => {
  const border = `1px solid ${palette.divider}`;

  return {
    '&.MuiToolbar-root': {
      backgroundColor: palette.background.default,
      borderBottom: border,
      borderTop: border,
      boxSizing: 'border-box',
      display: 'flex',
      height: spacing(6),
      minHeight: spacing(6),
      padding: 0,
      width: '100%',
    },
  };
});

const SovosToolbar = ({ children, className, ...rest }) => (
  <StyledToolbar className={clsx('sovosToolbar', className)} {...rest}>
    {children}
  </StyledToolbar>
);

SovosToolbar.propTypes = {
  /**
   * Contents of the `SovosToolbar` component:
   *
   * - `SovosToolbarTitle`, `SovosToolbarActions`, `SovosToolbarBulkActions`,
   *   or `SovosToolbarDataGrouping`
   * - To add custom elements to the toolbar, wrap them in a `SovosToolbarGroup`
   */
  children: node.isRequired,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosToolbar.defaultProps = {
  className: undefined,
};

SovosToolbar.baseComponent = {
  name: 'Toolbar',
  link: 'toolbar/',
};

export default SovosToolbar;
