import React from 'react';
import { arrayOf, bool, func, string, instanceOf, oneOfType } from 'prop-types';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import SovosDatePicker from '../sovos-date-picker';
import SovosFilterDrawerSection from '../sovos-filter-drawer-section';

const Root = styled(SovosFilterDrawerSection)(({ theme: { spacing } }) => ({
  '& .sovosFilterDateInput__input': {
    marginBottom: spacing(),

    '&.MuiTextField-root': {
      minHeight: 'auto',
    },
  },
}));

/**
 * For use in `SovosFilterDrawer`
 */
const SovosFilterDateInput = ({
  className,
  data,
  'data-testid': dataTestId,
  format,
  label,
  multiple,
  onChange,
  minDate,
  maxDate,
  ...rest
}) => {
  const getRow = ({ entry, handleChange }) => (
    <SovosDatePicker
      className="sovosFilterDateInput__input"
      format={format}
      maxDate={maxDate}
      minDate={minDate}
      onChange={(value) => handleChange({ target: { value } }, value)}
      value={entry}
      TextFieldProps={{
        fullWidth: true,
        size: 'small',
      }}
    />
  );

  getRow.propTypes = {
    entry: string.isRequired,
    handleChange: func.isRequired,
  };

  return (
    <Root
      className={clsx('sovosFilterDateInput', className)}
      data={data}
      data-testid={dataTestId}
      getRow={getRow}
      label={label}
      multiple={multiple}
      onChange={onChange}
      {...rest}
    />
  );
};
SovosFilterDateInput.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * The date values to render. An array of strings in the specified format
   */
  data: arrayOf(string),
  /**
   * @ignore
   */
  'data-testid': string,
  /**
   * Date format string used as a placeholder and validator for the input
   */
  format: string,
  /**
   * Section label
   */
  label: string.isRequired,
  /**
   * Maximum allowed date
   */
  maxDate: oneOfType([instanceOf(Date), string]),
  /**
   * Minimum allowed date
   */
  minDate: oneOfType([instanceOf(Date), string]),
  /**
   * When true, the user can add an arbitrary number of dates
   */
  multiple: bool,
  /**
   * Callback fired when the input value is changed `function(data:
   * string[]) => void`
   */
  onChange: func.isRequired,
};

SovosFilterDateInput.defaultProps = {
  className: undefined,
  data: [],
  'data-testid': undefined,
  format: 'MM/dd/yyyy',
  maxDate: undefined,
  minDate: undefined,
  multiple: false,
};

export default SovosFilterDateInput;
