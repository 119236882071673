import assetKeys from './assetKeys';
import localAssets from './localAssets';

export { assetKeys };

let assetsHost;

function getImageRoot() {
  return `${assetsHost}/images`;
}

const assetResolver = {
  configure(host) {
    if (host && typeof host === 'string') {
      assetsHost = host;
    }
  },
  heroBanners(key) {
    if (!assetsHost) {
      // Only background images for hero banner colors are included in the local assets. Including all the images generates a prohibitively large package.
      if (!Object.values(assetKeys.heroBanners.colors).includes(key)) {
        return undefined;
      }

      return localAssets.heroBanners[key];
    }

    return `${getImageRoot()}/heroBanners/${key}`;
  },
  branding(key) {
    if (!assetsHost) {
      if (!Object.values(assetKeys.branding).includes(key)) {
        return undefined;
      }

      return localAssets.branding[key];
    }

    return `${getImageRoot()}/branding/${key}`;
  },
  translation(lang) {
    return localAssets.locales[lang];
  },
};

export default assetResolver;
