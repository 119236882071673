/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const url = '/api/v1/imports';

const formatDate = (val) => val && val.split('/').reverse().join('-');

export function loadImports(page, itemsPerPage, sortProp, sortOrder, filters) {
  const timeStampFrom = formatDate(filters.timeStamp?.[0]?.from);
  const timeStampTo = formatDate(filters.timeStamp?.[0]?.to);
  const reportDateFrom = formatDate(filters.reportDate?.[0]?.from);
  const reportDateTo = formatDate(filters.reportDate?.[0]?.to);

  const params = {
    _sort: sortProp,
    _order: sortOrder,
    _page: page,
    _limit: itemsPerPage,
    timeStamp_gte: timeStampFrom,
    timeStamp_lte: timeStampTo,
    reportDate_gte: reportDateFrom,
    reportDate_lte: reportDateTo,
  };

  return axios.get(url, { params }).then((response) => {
    const imports = response.data;
    const totalItems = response.headers['x-total-count'];
    return { totalItems, imports };
  });
}

export function loadImport(id) {
  return axios.get(`${url}/${id}`).then((response) => response.data);
}

export function loadImportsSummary() {
  return axios.get(`/api/v1/imports/summary`).then((res) => res.data);
}
