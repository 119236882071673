import React, { useState, useEffect, useRef } from 'react';
import {
  any,
  arrayOf,
  bool,
  element,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { Delete, InsertDriveFile, MoreVert } from 'mosaic-react-icons';
import SovosTypography from '../sovos-typography';
import SovosButton from '../sovos-button';
import SovosIconButton from '../sovos-icon-button';
import SovosIconMenu from '../sovos-icon-menu';
import SovosTooltip from '../sovos-tooltip';
import SovosConfirmationDialog from '../sovos-confirmation-dialog';
import calculateBytes from '../internals/utils/calculateBytes';
import { useResize } from '../hooks';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const getActionIconStyles = ({ palette, spacing }) => ({
  color: palette.text.primary,
  marginRight: `-${spacing(0.5)}`,
  opacity: 0,

  '&:focus-visible': {
    opacity: 1,
  },
});

const Root = styled('div')(({ theme: { palette, spacing } }) => ({
  alignItems: 'center',
  backgroundColor: palette.common.white,
  display: 'flex',
  minHeight: spacing(5),
  justifyContent: 'space-between',
  margin: 0,
  padding: `0 ${spacing()} 0 0`,

  '&.sovosFile--hovered': {
    '& .sovosFile__deleteButton, & .sovosFile__actionsMenu': {
      opacity: 1,
    },
  },
}));

const FileDownloadButton = styled(SovosButton)(
  ({ theme: { palette, spacing } }) => ({
    border: '2px solid transparent',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    flex: '1 1 auto',
    height: spacing(5),
    overflow: 'hidden',
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',

      '& .sovosFile__fileName, .sovosFile__fileIcon': {
        color: palette.primary.main,
      },
    },

    '&.Mui-focusVisible': {
      border: `2px solid ${palette.primary.main}`,
    },
  })
);

const InsertDriveFileIconFileIcon = styled(InsertDriveFile)(
  ({ theme: { palette, spacing } }) => ({
    color: palette.grey[400],
    marginLeft: spacing(),
  })
);

const FileText = styled('div')(({ theme: { spacing } }) => ({
  alignItems: 'center',
  border: '2px solid transparent',
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  marginRight: spacing(),
}));

const FileName = styled(SovosTypography)(({ theme: { spacing } }) => ({
  flex: '0 1 auto',
  margin: `0 0 0 ${spacing()}`,
  maxWidth: '80%',
  overflow: 'hidden',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const FileSize = styled(SovosTypography)(({ theme: { spacing } }) => ({
  flex: '0 0 auto',
  marginLeft: spacing(0.5),
}));

const MetaData = styled('div')(({ theme: { spacing } }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: '0 1 auto',
  justifyContent: 'space-between',
  marginLeft: spacing(),
}));

const UserName = styled(SovosTypography)(({ theme: { spacing } }) => ({
  lineHeight: 1,
  marginRight: spacing(2),
  textAlign: 'right',
}));

const TimeStamp = styled(SovosTypography)(() => ({
  flex: '1 1 auto',
  lineHeight: 1,
  textAlign: 'left',
}));

const DeleteBtn = styled(SovosIconButton)(({ theme }) =>
  getActionIconStyles(theme)
);

const FileActions = styled(SovosIconMenu)(({ theme }) =>
  getActionIconStyles(theme)
);

const SovosFile = ({
  actionMenuItems,
  classes,
  className,
  confirmDelete,
  FileDeleteConfirmationProps,
  fileInfo,
  onClick,
  onDelete,
  ...rest
}) => {
  const { t } = useMosaicTranslation();

  const [fileNameDoesOverflow, setFileNameDoesOverflow] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [buttonIsFocused, setButtonIsFocused] = useState(false);
  const [isWide, setIsWide] = useState(undefined);
  const [hovered, setHovered] = useState(false);
  const fileRoot = useRef(null);
  const btnFileName = useRef(null);

  const [{ width: fileWidth }] = useResize(fileRoot);

  useEffect(() => {
    const nameWidth = btnFileName.current.offsetWidth;
    const nameScrollWidth = btnFileName.current.scrollWidth;
    setFileNameDoesOverflow(nameScrollWidth > nameWidth);

    if (fileWidth > 480) {
      setIsWide(true);
    } else {
      setIsWide(false);
    }
  }, [fileWidth]);

  const openConfirmDelete = () => setDeleteConfirmOpen(true);

  const handleDelete = () => {
    setDeleteConfirmOpen(false);
    onDelete(fileInfo);
  };

  const handleDeleteKeyUp = (event) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      handleDelete();
    }

    return null;
  };

  const closeDeleteDialog = () => setDeleteConfirmOpen(false);

  const { documentLink, name, bytes, uploadedBy, uploadedDate } = fileInfo;
  const uploadedByIsString = typeof uploadedBy === 'string';

  const hasUserName = () => {
    if (uploadedByIsString) {
      if (uploadedBy.length) return true;
      return false;
    }

    return uploadedBy && !!(uploadedBy.name || uploadedBy.surname);
  };

  const getFileToolTipText = () => {
    let tooltipText = '';
    if (fileNameDoesOverflow && name) tooltipText += `${name} `;
    if (uploadedDate && !isWide) tooltipText += `${uploadedDate} `;
    if (uploadedBy && !isWide) {
      tooltipText += uploadedByIsString
        ? `by ${uploadedBy}`
        : `by ${uploadedBy.name} ${uploadedBy.surname}`;
    }

    return tooltipText;
  };

  const renderFileText = () => (
    <>
      <InsertDriveFileIconFileIcon
        className="sovosFile__fileIcon"
        fontSize="small"
      />
      <FileName
        className="sovosFile__fileName"
        color="text.primary"
        ref={btnFileName}
        variant="body1"
      >
        {name}
      </FileName>
      {bytes && (
        <FileSize color="text.secondary" variant="body1">
          ({calculateBytes(bytes)})
        </FileSize>
      )}
    </>
  );

  return (
    <Root
      className={clsx('sovosFile', className, classes?.root, {
        'sovosFile--hovered': hovered,
      })}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      ref={fileRoot}
      {...rest}
    >
      <SovosTooltip title={getFileToolTipText()}>
        {onClick || documentLink ? (
          <FileDownloadButton
            className={clsx(
              'sovosFile__fileButton',
              classes?.fileDownloadButton
            )}
            color="inherit"
            data-testid="fileButton"
            disableRipple
            onBlur={() => setButtonIsFocused(false)}
            onClick={
              documentLink
                ? () => window.open(documentLink, '_blank', 'noreferrer')
                : onClick
            }
            onFocus={() => setButtonIsFocused(true)}
            onKeyUp={buttonIsFocused && onDelete ? handleDeleteKeyUp : null}
            variant="text"
          >
            {renderFileText()}
          </FileDownloadButton>
        ) : (
          <FileText>{renderFileText()}</FileText>
        )}
      </SovosTooltip>
      {isWide && (
        <MetaData>
          {hasUserName() && (
            <UserName variant="body1" color="text.secondary">
              {uploadedByIsString ? uploadedBy : uploadedBy.name}{' '}
              {uploadedByIsString ? null : uploadedBy.surname}
            </UserName>
          )}
          {uploadedDate && (
            <TimeStamp component="time" variant="body1" color="text.secondary">
              {uploadedDate}
            </TimeStamp>
          )}
        </MetaData>
      )}
      {onDelete && (
        <DeleteBtn
          className={clsx('sovosFile__deleteButton', classes?.deleteBtn)}
          onClick={confirmDelete ? openConfirmDelete : handleDelete}
          size="small"
          tooltipText={
            FileDeleteConfirmationProps?.defaultActionButtonLabel || t('delete')
          }
        >
          <Delete />
        </DeleteBtn>
      )}
      {actionMenuItems && (
        <FileActions
          className="sovosFile__actionsMenu"
          iconElement={<MoreVert />}
          onClose={() => setHovered(false)}
          size="small"
        >
          {actionMenuItems}
        </FileActions>
      )}
      {confirmDelete && (
        <SovosConfirmationDialog
          title={
            FileDeleteConfirmationProps?.defaultTitleText ||
            t('file.confirmDelete')
          }
          content={
            FileDeleteConfirmationProps?.defaultContentText ||
            t('file.confirmDeleteContent')
          }
          open={deleteConfirmOpen}
          ActionButtonProps={{
            children:
              FileDeleteConfirmationProps?.defaultActionButtonLabel ||
              t('delete'),
            onClick: handleDelete,
          }}
          CancelButtonProps={{
            children:
              FileDeleteConfirmationProps?.defaultCancelButtonLabel ||
              t('cancel'),
            onClick: closeDeleteDialog,
          }}
          negative
          onClose={closeDeleteDialog}
        />
      )}
    </Root>
  );
};

SovosFile.propTypes = {
  /**
   * A list of actions to render in a drop down menu. Expected to be
   * `SovosMenuItem` elements.
   */
  actionMenuItems: arrayOf(element),
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * When true, a confirmation dialog will be displayed before onDelete is fired
   */
  confirmDelete: bool,
  /**
   * Object of strings for the delete confirmation dialog
   */
  FileDeleteConfirmationProps: shape({
    defaultTitleText: string,
    defaultContentText: string,
    defaultActionButtonLabel: string,
    defaultCancelButtonLabel: string,
  }),
  /**
   * An object of information describing the file
   */
  fileInfo: shape({
    bytes: number,
    downloadLink: string,
    id: any,
    name: string,
    uploadedDate: string,
    uploadedBy: oneOfType([
      shape({
        name: string,
        surname: string,
      }),
      string,
    ]),
  }).isRequired,
  /**
   * Callback fired when the file is clicked
   */
  onClick: func,
  /**
   * Callback fired when the delete icon is clicked. When not present, a
   * delete button will not be displayed `function(fileInfo: object) => void`
   */
  onDelete: func,
};

SovosFile.defaultProps = {
  actionMenuItems: undefined,
  classes: undefined,
  className: undefined,
  confirmDelete: false,
  FileDeleteConfirmationProps: {
    defaultTitleText: undefined,
    defaultContentText: undefined,
    defaultActionButtonLabel: undefined,
    defaultCancelButtonLabel: undefined,
  },
  onClick: undefined,
  onDelete: undefined,
};

export default SovosFile;
