import React, { useEffect, useState } from 'react';
import { array, bool, func, number, object, string, shape } from 'prop-types';
import clsx from 'clsx';
import { Box, Input } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  KeyboardArrowLeft as Left,
  KeyboardArrowRight as Right,
} from 'mosaic-react-icons';
import useMediaQuery from '@mui/material/useMediaQuery';

import SovosIconButton from '../sovos-icon-button';
import { isEnter } from '../internals/utils/eventHelpers';
import SovosMenuItem from '../sovos-menu-item';
import SovosButton from '../sovos-button';
import SovosSelect from '../sovos-select';
import { getFocusStyles } from '../internals/utils';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const transparent = 'rgba(255, 255, 255, 0)';

const StyledMenuItem = styled(SovosMenuItem)(
  ({ theme: { palette, spacing, typography } }) => ({
    '&.MuiMenuItem-root': {
      color: palette.text.primary,
      fontSize: typography.body2.fontSize,
      minHeight: spacing(3),
      padding: `0 ${spacing(2)}`,
    },
  })
);

const StyledInput = styled(Input)(
  ({ theme: { palette, shape: themeShape, spacing, typography } }) => ({
    '& .MuiInput-input': {
      fontSize: typography.body2.fontSize,
      textDecorationColor: palette.text.primary,
      border: `1px solid ${palette.text.primary}`,
      borderRadius: themeShape.borderRadius,
      padding: [[spacing(0.5), 0]],
      width: spacing(4),
      height: spacing(3),
      textAlign: 'center',
      boxSizing: 'border-box',
    },
  })
);

const PageButton = styled(SovosButton, {
  shouldForwardProp: (prop) => prop !== 'isCurrent',
})(({ isCurrent, theme: { palette, spacing, typography } }) => ({
  boxShadow: 'none',
  fontSize: typography.body2.fontSize,
  fontWeight: typography.body2.fontWeight,
  height: spacing(3),
  margin: 0,
  minHeight: spacing(3),
  minWidth: spacing(3),
  padding: 0,
  width: spacing(3),
  '&:last-child': {
    marginRight: 0,
  },
  '&.MuiButton-containedPrimary': {
    '&:hover': {
      backgroundColor: isCurrent ? palette.primary.dark : palette.action.hover,
      boxShadow: 'none',
      cursor: isCurrent ? 'default' : undefined,
    },
    '&:focus-visible': {
      '&::after': {
        ...getFocusStyles({ offset: 2 }),
      },
    },
  },
}));

const StyledSelect = styled(SovosSelect)(
  ({ theme: { palette, spacing, typography } }) => ({
    '& .MuiSelect-select': {
      color: palette.text.primary,
      fontSize: typography.body2.fontSize,
      height: spacing(2.5),
      lineHeight: spacing(2.5),
      padding: '0 !important',
      textAlign: 'center',
    },
    '&.MuiTextField-root': {
      width: spacing(3),
    },
  })
);

const Root = styled('div')(({ theme: { palette, spacing, typography } }) => ({
  alignItems: 'center',
  borderTop: `1px solid ${palette.divider}`,
  boxSizing: 'border-box',
  color: palette.text.primary,
  display: 'flex',
  height: spacing(6),
  justifyContent: 'flex-end',
  ...typography.body2,
}));

const SovosPaginationFooter = ({
  classes,
  className,
  currentPage,
  displayItemsPerPageSelector,
  itemsPerPage,
  itemsPerPageLabel,
  itemsPerPageOptions,
  labels,
  onPageChange,
  style,
  totalItems,
  ...rest
}) => {
  const { t } = useMosaicTranslation();
  const theme = useTheme();
  const numberOfPages = Math.ceil(totalItems / itemsPerPage);
  const { palette, spacing } = useTheme();
  const [pageInput, setPageInput] = useState(currentPage);

  const isBreakpointSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setPageInput(currentPage);
  }, [currentPage]);

  const renderItems = () =>
    itemsPerPageOptions.map((value) => (
      <StyledMenuItem
        className={clsx(
          `sovosPaginationFooter__itemsPerPageOption--${value}`,
          classes?.item
        )}
        key={value}
        value={value}
      >
        {value}
      </StyledMenuItem>
    ));

  const handlePrevPage = () => {
    const newPage = currentPage === 1 ? numberOfPages : currentPage - 1;
    onPageChange(newPage, itemsPerPage);
  };

  const handleNextPage = () => {
    const newPage = currentPage === numberOfPages ? 1 : currentPage + 1;
    onPageChange(newPage, itemsPerPage);
  };

  const handlePerPageChanged = (event) => {
    onPageChange(1, event.target.value);
  };

  const directPageChange = (pageNumber) => {
    let newPage = pageNumber;
    if (newPage > numberOfPages) {
      newPage = numberOfPages;
    }
    if (newPage < 1) {
      newPage = 1;
    }

    onPageChange(newPage, itemsPerPage);
  };

  const onTextFieldBlur = (event) => {
    const num = Number(event.target.value);

    if (Number.isInteger(num)) {
      let newPage = num;
      if (num >= numberOfPages) {
        newPage = numberOfPages;
      } else if (num <= 1) {
        newPage = 1;
      }

      directPageChange(newPage);

      // force the page input to get set in the case where the input is empty
      // and setting the current page doesn't trigger a state change
      setPageInput(newPage);
    }
  };

  const onTextFieldChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setPageInput(value);
    }
  };

  const handleKeyDown = (event) => {
    if (isEnter(event)) {
      onTextFieldBlur(event);
    }
  };

  const focusPageInput = (event) => {
    event.target.select();
  };

  const renderButtonArray = () => {
    const arrayLength = Math.ceil(numberOfPages);
    // fills an array with values from 1 to numberOfPages
    const buttonArray = Array.from(
      new Array(arrayLength),
      (val, index) => index + 1
    );

    return buttonArray.map((pageNum) => {
      const isCurrent = currentPage === pageNum;
      return (
        <PageButton
          className={clsx('sovosPaginationFooter__pageButton', {
            [classes?.buttonRootCurrent]: isCurrent,
          })}
          classes={{
            root: classes?.buttonRoot,
          }}
          disableRipple
          isCurrent={isCurrent}
          key={pageNum}
          color={isCurrent ? palette.common.white : palette.text.primary}
          backgroundColor={isCurrent ? palette.primary.main : transparent}
          onClick={() => directPageChange(pageNum)}
        >
          {pageNum}
        </PageButton>
      );
    });
  };

  const renderPaginationButtons = () => {
    if (!isBreakpointSmall && numberOfPages > 1 && numberOfPages <= 10) {
      return (
        <Box sx={{ display: 'flex', gap: spacing(0.5) }}>
          {renderButtonArray()}
        </Box>
      );
    }
    return (
      <Box
        className={classes?.longPageContainer}
        sx={{ marginLeft: 0.5, marginRight: 0.5 }}
      >
        <StyledInput
          classes={{
            input: classes?.input,
          }}
          className="sovosPaginationFooter__pageInput"
          disableUnderline
          onBlur={onTextFieldBlur}
          onChange={onTextFieldChange}
          onFocus={focusPageInput}
          onKeyDown={handleKeyDown}
          value={pageInput}
        />
        <Box
          component="span"
          className={classes?.longPageSeparator}
          sx={{ marginLeft: 0.5, marginRight: 0.5 }}
        >
          /
        </Box>
        {numberOfPages}
      </Box>
    );
  };

  const renderPaginationGroup = () => {
    if (numberOfPages === 1) {
      return null;
    }

    const arrowSx = {
      backgroundColor: 'transparent',
      border: 'none',
      color: palette.text.primary,
      cursor: 'pointer',
      height: spacing(3),
      minWidth: spacing(3),
      padding: 0,
      textDecoration: 'none',
      width: spacing(3),

      '&:disabled': {
        color: palette.action.disabled,
      },

      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    };

    return (
      <Box
        className={classes?.buttonContainer}
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: spacing(3),
          marginLeft: spacing(2),
        }}
      >
        <SovosIconButton
          className={clsx('sovosPaginationFooter__prevButton', classes?.arrow)}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          sx={arrowSx}
          tooltipText={
            labels?.previousTooltipText || t('paginationFooter.previousPage')
          }
        >
          <Left />
        </SovosIconButton>
        {renderPaginationButtons()}
        <SovosIconButton
          className={clsx('sovosPaginationFooter__nextButton', classes?.arrow)}
          onClick={handleNextPage}
          disabled={currentPage >= numberOfPages}
          sx={arrowSx}
          tooltipText={
            labels?.nextTooltipText || t('paginationFooter.nextPage')
          }
        >
          <Right />
        </SovosIconButton>
      </Box>
    );
  };

  // Remove when we add internationalization library
  const getResultsLabel = () => {
    const results = labels?.results || t('paginationFooter.results');

    if (totalItems === 1) {
      const lastChar = results[results.length - 1];
      return lastChar === 's' ? results.slice(0, -1) : results;
    }

    return results;
  };

  return (
    <Root
      className={clsx('sovosPaginationFooter', classes?.root, className)}
      style={style}
      {...rest}
    >
      {totalItems > 0 && (
        <Box
          className={classes?.resultsCountWrapper}
          sx={{
            color: palette.text.secondary,
            marginRight: 'auto',
            marginLeft: spacing(1),
          }}
        >
          {totalItems}
          &nbsp;
          {getResultsLabel()}
        </Box>
      )}
      {displayItemsPerPageSelector && (
        <>
          <Box
            className={classes?.showWrapper}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: spacing(3),
            }}
          >
            <Box
              component="span"
              className={classes?.show}
              sx={{
                marginRight: spacing(0.5),
              }}
            >
              {itemsPerPageLabel || t('paginationFooter.itemsPerPage')}
            </Box>
            <StyledSelect
              classes={{
                root: classes?.selectRoot,
                input: classes?.selectTextField,
                select: classes?.select,
              }}
              className="sovosPaginationFooter__itemsPerPageSelect"
              IconComponent={() => null}
              inputProps={{
                'aria-label': 'Items Per Page',
              }}
              MenuProps={{}}
              onChange={handlePerPageChanged}
              value={itemsPerPage.toString()}
              variant="standard"
            >
              {renderItems()}
            </StyledSelect>
          </Box>
        </>
      )}
      {renderPaginationGroup()}
    </Root>
  );
};

SovosPaginationFooter.propTypes = {
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * The current selected page
   */
  currentPage: number.isRequired,
  /**
   * When false, the items per page selector is not displayed
   */
  displayItemsPerPageSelector: bool,
  /**
   * The currently selected items per page amount
   */
  itemsPerPage: number.isRequired,
  /**
   * The label for the items per page selector
   */
  itemsPerPageLabel: string,
  /**
   * An array of options for the items per page selector
   */
  itemsPerPageOptions: array,
  /**
   * Object of text labels pagination footer
   */
  labels: shape({
    nextTooltipText: string,
    previousTooltipText: string,
    results: string,
  }),
  /**
   * Callback fired on any change,`function(newPage: number, itemsPerPage:
   * number) => void`
   */
  onPageChange: func.isRequired,
  /**
   * Override or extend the styles applied to the component
   */
  style: object,
  /**
   * Quantity to display in the results count. Along with `itemsPerPage`,
   * used to calculate the number of pages and the style of display
   */
  totalItems: number.isRequired,
};

SovosPaginationFooter.defaultProps = {
  classes: undefined,
  className: undefined,
  displayItemsPerPageSelector: true,
  itemsPerPageLabel: undefined,
  itemsPerPageOptions: [20, 50, 100],
  labels: undefined,
  style: undefined,
};

SovosPaginationFooter.baseComponent = {
  name: 'Pagination',
};

export default SovosPaginationFooter;
