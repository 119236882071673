import React, { useCallback, useState } from 'react';
import { array, bool, func, object, shape, string } from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Delete } from 'mosaic-react-icons';
import SovosEditableDrawer from '../../sovos-editable-drawer';
import SovosOrderableList from '../../sovos-orderable-list';
import useMosaicTranslation from '../../internals/i18n/useMosaicTranslation';

export const getInactive = (availableGroupings, activeGroupings) =>
  availableGroupings
    .filter((ag) => !activeGroupings.find((eg) => eg.title === ag.title))
    .map((group) => ({
      id: group.id,
      title: group.title,
    }));

const ToolbarDataGroupingDrawer = ({
  availableGroupings,
  enabledGroupings,
  labels,
  onClose,
  onUpdateGroupings,
  open,
  OrderableListLabelProps,
}) => {
  const { t } = useMosaicTranslation();

  const getGroupings = useCallback((groupingArray) => {
    if (groupingArray.length > 0) {
      return groupingArray.map((group) => ({
        id: group.id || uuid(),
        title: group.title,
      }));
    }

    return [];
  }, []);

  const [activeGroupings, setActiveGroupings] = useState(
    getGroupings(enabledGroupings)
  );

  const [inactiveGroupings, setInactiveGroupings] = useState(
    getInactive(getGroupings(availableGroupings), activeGroupings)
  );

  const onChange = (enabled) => {
    setActiveGroupings(enabled);
    setInactiveGroupings(
      getInactive(getGroupings(availableGroupings), enabled)
    );
  };

  const onApply = () => {
    onUpdateGroupings(activeGroupings);
  };

  const onCancel = () => {
    setActiveGroupings(getGroupings(enabledGroupings));
    setInactiveGroupings(
      getInactive(getGroupings(availableGroupings), enabledGroupings)
    );
    onClose();
  };

  return (
    <SovosEditableDrawer
      onClose={onCancel}
      open={open}
      PrimaryButtonProps={{
        children: labels?.applyButton || t('apply'),
        onClick: onApply,
        className: 'ToolbarDataGroupingDrawer__applyButton',
      }}
      SecondaryButtonProps={{
        children: labels?.cancelButton || t('cancel'),
        onClick: onCancel,
        className: 'ToolbarDataGroupingDrawer__cancelButton',
      }}
      title={labels?.title || t('toolbarDataGrouping.drawer.title')}
      helpText={labels?.helpText || t('toolbarDataGrouping.drawer.help')}
      addButton={labels?.addButton || t('toolbarDataGrouping.drawer.addButton')}
      searchPlaceHolder={
        labels?.searchPlaceHolder ||
        t('toolbarDataGrouping.drawer.searchPlaceHolder')
      }
    >
      <SovosOrderableList
        activeIcon={<Delete />}
        activeItems={activeGroupings}
        inactiveItems={inactiveGroupings}
        labels={OrderableListLabelProps}
        onChange={onChange}
      />
    </SovosEditableDrawer>
  );
};

ToolbarDataGroupingDrawer.propTypes = {
  enabledGroupings: array.isRequired,
  availableGroupings: array.isRequired,
  open: bool,
  onClose: func.isRequired,
  onUpdateGroupings: func.isRequired,
  OrderableListLabelProps: object.isRequired,
  labels: shape({
    title: string,
    applyButton: string,
    cancelButton: string,
    helpText: string,
    addButton: string,
    searchPlaceHolder: string,
  }),
};

ToolbarDataGroupingDrawer.defaultProps = {
  open: false,
  labels: undefined,
};

export default ToolbarDataGroupingDrawer;
