import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

const StyledContent = styled(DialogContent)(
  ({ theme: { palette, spacing, typography } }) => ({
    ...typography.body1,
    color: palette.text.secondary,
    margin: `0 0 ${spacing()} 0`,
    padding: 0,
  })
);

/**
 * `SovosDialogContent` is a component to be used inside `SovosDialog` to
 * set up a dialog body.
 */
const SovosDialogContent = ({ className, ...props }) => (
  <StyledContent className={clsx('SovosDialogContent', className)} {...props} />
);

SovosDialogContent.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosDialogContent.defaultProps = {
  className: undefined,
};

export default SovosDialogContent;
