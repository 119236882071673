import React, { forwardRef } from 'react';
import { string, func } from 'prop-types';
import { SovosMenuItem } from 'mosaic-react';
import FilingSelect from './FilingSelect';

const Assignee = forwardRef(({ value, onChange }, ref) => (
  <FilingSelect onChange={onChange} ref={ref} value={value}>
    <SovosMenuItem value="No Assignee">No Assignee</SovosMenuItem>
    <SovosMenuItem value="Bob Hoskins">Bob Hoskins</SovosMenuItem>
    <SovosMenuItem value="Tara Boberra">Tara BoBerra</SovosMenuItem>
    <SovosMenuItem value="Slim Shady">Slim Shady</SovosMenuItem>
    <SovosMenuItem value="Randy Savage">Randy Savage</SovosMenuItem>
  </FilingSelect>
));

Assignee.propTypes = {
  value: string,
  onChange: func.isRequired,
};

Assignee.defaultProps = {
  value: 'No Assignee',
};

export default Assignee;
