export const navigationWidthFactor = 30;
export const collapsedWidthFactor = 7;

export const iconButtonSizes = {
  extraSmall: {
    button: {
      width: 24,
      height: 24,
    },
    icon: {
      height: 16,
      width: 16,
    },
  },
  small: {
    button: {
      width: 36,
      minWidth: 36,
      height: 36,
    },
    icon: {
      height: 20,
      width: 20,
    },
  },
  medium: {
    button: {
      width: 36,
      minWidth: 36,
      height: 36,
    },
    icon: {
      height: 24,
      width: 24,
    },
  },
  large: {
    button: {
      width: 48,
      minWidth: 48,
      height: 48,
    },
    icon: {
      height: 36,
      width: 36,
    },
  },
};
