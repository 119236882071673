import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { styled } from '@mui/material/styles';
import SovosTooltip from '../../sovos-tooltip';
import theme from '../../themes/sovos-theme';

const dotSize = 1.25; // 10px
const statusDotSpace = 2.625; // 21px (!?!)

const getStatusColor = (statuses, children, palette) => {
  const color = statuses?.[children]?.split('.');
  const [colorKey, colorSubKey = 'main'] = color || [];
  return palette?.[colorKey]?.[colorSubKey];
};

const Root = styled('div')(({ theme: { spacing } }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  top: spacing(0.25),
}));

const Dot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'statuses' && prop !== 'children',
})(({ statuses, children, theme: { palette, spacing } }) => ({
  backgroundColor:
    getStatusColor(statuses, children, palette) || palette.text.disabled,
  position: 'relative',
  height: spacing(dotSize),
  width: spacing(dotSize),
  borderRadius: spacing(),
}));

const Text = styled('span')(({ theme: { spacing } }) => ({
  marginLeft: spacing(statusDotSpace - dotSize),
  maxWidth: `calc(100% - ${spacing(statusDotSpace)})`,
}));

const StatusCellContent = (props) => {
  const {
    columnTitle,
    children,
    hideStatusLabel,
    hiddenTitle,
    statuses,
    setRef,
  } = props;

  const renderDot = () => {
    const dot = <Dot statuses={statuses}>{children}</Dot>;
    if (!hideStatusLabel) {
      return dot;
    }

    const tooltipText = !hiddenTitle ? children : `${columnTitle}: ${children}`;

    return (
      <SovosTooltip placement="right" title={tooltipText}>
        {dot}
      </SovosTooltip>
    );
  };

  return (
    <Root ref={setRef}>
      {renderDot()}
      {!hideStatusLabel && <Text>{children}</Text>}
    </Root>
  );
};

StatusCellContent.propTypes = {
  children: (props) => {
    const { statuses, children } = props;
    const { palette } = theme;
    const color = statuses?.[children];
    const statusColor = getStatusColor(statuses, children, palette);

    if (color == null) {
      return new Error(
        `\`SovosTable\` received a status '${children}' not found in statuses.`
      );
    }
    if (statusColor == null) {
      return new Error(
        `\`SovosTable\` received a color '${color}' that could not be mapped to a theme.palette color.`
      );
    }

    return null;
  },
  columnTitle: string.isRequired,
  hideStatusLabel: bool,
  hiddenTitle: bool,
  setRef: func.isRequired,
  statuses: object,
};

StatusCellContent.defaultProps = {
  children: undefined,
  hideStatusLabel: false,
  hiddenTitle: false,
  statuses: undefined,
};

export default StatusCellContent;
