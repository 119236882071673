import React, { useState } from 'react';
import { arrayOf, bool, element, func, node, shape, string } from 'prop-types';
import clsx from 'clsx';
import { mergeClassNameIntoProps } from '../internals/utils/classNameHelpers';
import sovosIconButtonElement from '../internals/prop-types/sovosIconButtonElement';
import SovosButton from '../sovos-button';
import SovosSlidingPanel from '../sovos-sliding-panel/SovosSlidingPanel';
import SovosSlidingPanelHeader from '../sovos-sliding-panel-header';
import SovosSlidingPanelHeaderActionButtons from '../sovos-sliding-panel-header-action-buttons';
import SovosSlidingPanelHeaderAdditionalButtons from '../sovos-sliding-panel-header-additional-buttons';
import CloseDialog from './components/CloseDialog';
import useMosaicTranslation from '../internals/i18n/useMosaicTranslation';

const renderAdditionalButtons = (additionalButtons) => {
  if (additionalButtons) {
    return (
      <SovosSlidingPanelHeaderAdditionalButtons>
        {additionalButtons}
      </SovosSlidingPanelHeaderAdditionalButtons>
    );
  }

  return null;
};

/**
 * Sliding panels can be stacked. See `SovosSlidingPanel` for an example.
 * Displays a header with save and cancel buttons and content. Displays a
 * confirmation dialog when `isDirty` returns false.
 */
const SovosEditableSlidingPanel = ({
  additionalButtons,
  children,
  isDirty,
  onClose,
  CancelButtonProps,
  SaveButtonProps,
  titleIconButton,
  CloseDialogProps,
  title,
  className,
  ...panelProps
}) => {
  const { t } = useMosaicTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const onCancel = () => {
    setOpenDialog(false);
    onClose();
  };

  const hideDialog = () => setOpenDialog(false);

  const showDialog = () => {
    if (isDirty()) {
      setOpenDialog(true);
    } else {
      onClose();
    }
  };

  const cancelButtonProps = {
    ...CancelButtonProps,
    children: CancelButtonProps?.children || t('cancel'),
  };

  const {
    OKButtonProps = {},
    CancelButtonProps: DialogCancelButtonProps = {},
    ...closeDialogProps
  } = CloseDialogProps || {};

  closeDialogProps.title =
    closeDialogProps.title || t('editableSlidingPanel.closeDialogPropsTitle');

  closeDialogProps.content =
    closeDialogProps.content ||
    t('editableSlidingPanel.closeDialogPropsContent');

  OKButtonProps.children =
    OKButtonProps.children ||
    t('editableSlidingPanel.closeDialogPropsOKButtonProps');

  DialogCancelButtonProps.children =
    DialogCancelButtonProps.children ||
    t('editableSlidingPanel.closeDialogPropsCancelButtonProps');

  const calculatedSaveButtonProps = {
    children: t('save'),
    ...mergeClassNameIntoProps(
      SaveButtonProps,
      'sovosEditableSlidingPanel__saveButton'
    ),
  };

  return (
    <>
      <SovosSlidingPanel
        className={clsx('sovosEditableSlidingPanel', className)}
        onClose={showDialog}
        {...panelProps}
      >
        <SovosSlidingPanelHeader
          title={title}
          titleIconButton={titleIconButton}
        >
          <SovosSlidingPanelHeaderActionButtons>
            <SovosButton
              variant="text"
              onClick={showDialog}
              {...mergeClassNameIntoProps(
                cancelButtonProps,
                'sovosEditableSlidingPanel__cancelButton'
              )}
            />
            <SovosButton {...calculatedSaveButtonProps} />
          </SovosSlidingPanelHeaderActionButtons>
          {renderAdditionalButtons(additionalButtons)}
        </SovosSlidingPanelHeader>
        {children}
      </SovosSlidingPanel>
      <CloseDialog
        open={openDialog}
        {...closeDialogProps}
        OKButtonProps={{
          ...OKButtonProps,
          onClick: onCancel,
        }}
        CancelButtonProps={{
          ...DialogCancelButtonProps,
          onClick: hideDialog,
        }}
      />
    </>
  );
};

SovosEditableSlidingPanel.propTypes = {
  /**
   * Additional buttons to display in the panel header
   */
  additionalButtons: arrayOf(element),
  /**
   * The content of the sliding panel
   */
  children: node.isRequired,
  /**
   * Properties for the cancel button
   */
  CancelButtonProps: shape({
    children: node,
    className: string,
  }),
  /**
   * Properties for the save button. If `children` is missing, the default
   * of "Save" will be used
   */
  SaveButtonProps: shape({
    children: node,
    className: string,
    onClick: func.isRequired,
    disabled: bool,
  }).isRequired,
  /**
   * Properties for the close dialog, should only be used for internationalization
   */
  CloseDialogProps: shape({
    title: node,
    content: node,
    OKButtonProps: shape({
      children: node,
      className: string,
    }),
    CancelButtonProps: shape({
      children: node,
      className: string,
    }),
  }),
  /**
   * Callback fired when the cancel button is clicked. If the function
   * returns `true`, the close dialog will be shown. Otherwise, the panel is closed
   */
  isDirty: func.isRequired,
  /**
   * Callback fired when the panel is closed
   */
  onClose: func.isRequired,
  /**
   * Sliding Panel Title
   */
  title: node,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
  /**
   * Icon button to display in the sliding panel header
   */
  titleIconButton: sovosIconButtonElement,
};

SovosEditableSlidingPanel.defaultProps = {
  additionalButtons: null,
  CloseDialogProps: undefined,
  title: '',
  CancelButtonProps: undefined,
  className: undefined,
  titleIconButton: undefined,
};

SovosEditableSlidingPanel.baseComponent = {
  name: 'SovosSlidingPanel',
};

export default SovosEditableSlidingPanel;
