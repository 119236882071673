export const initialState = {
  isLoading: false,
  list: [],
  totalItems: 0,
};

export function filingsReducer(state, action) {
  switch (action.type) {
    case 'LoadFilingsBegin':
      return { ...state, isLoading: true, list: [], totalItems: 0 };
    case 'LoadFilingsSuccess':
      return {
        ...state,
        isLoading: false,
        list: action.filings || [],
        totalItems: parseInt(action.totalItems, 10),
      };
    case 'LoadFilingsFailure':
      return { ...state, isLoading: false, list: [], totalItems: 0 };
    default:
      return state;
  }
}
