import spacingToNumber from './spacingToNumber';

/**
 * Return icon sizes based on a sizing function
 *
 * @param {function} spacing - The theme method to calculate the size
 * @returns {object} - Icon button sizes
 */
export default function getIconSizes(spacing) {
  return {
    extraSmall: {
      button: {
        width: spacingToNumber(spacing(3)),
        minWidth: spacingToNumber(spacing(3)),
        height: spacingToNumber(spacing(3)),
      },
      icon: {
        fontSize: spacingToNumber(spacing(2.25)),
      },
    },
    small: {
      button: {
        width: spacingToNumber(spacing(4)),
        minWidth: spacingToNumber(spacing(4)),
        height: spacingToNumber(spacing(4)),
      },
      icon: {
        fontSize: spacingToNumber(spacing(2.5)),
      },
    },
    medium: {
      button: {
        width: spacingToNumber(spacing(5)),
        minWidth: spacingToNumber(spacing(5)),
        height: spacingToNumber(spacing(5)),
      },
      icon: {
        fontSize: spacingToNumber(spacing(3)),
      },
    },
    large: {
      button: {
        width: spacingToNumber(spacing(6)),
        minWidth: spacingToNumber(spacing(6)),
        height: spacingToNumber(spacing(6)),
      },
      icon: {
        fontSize: spacingToNumber(spacing(4.5)),
      },
    },
    inherit: {
      icon: {
        fontSIze: 'inherit',
      },
    },
  };
}
