export const initialState = {
  isLoading: false,
  isDeleting: false,
  current: {
    first: undefined,
    last: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    username: undefined,
    email: undefined,
  },
};

export function userReducer(state, action) {
  switch (action.type) {
    case 'LoadUserBegin':
      return { ...state, isLoading: true, current: undefined };
    case 'LoadUserSuccess':
      return { ...state, isLoading: false, current: action.user };
    case 'LoadUserFailure':
      return { ...state, isLoading: false, current: undefined };
    case 'AddUserBegin':
      return { ...state, isLoading: true };
    case 'AddUserSuccess':
      return { ...state, isLoading: false };
    case 'AddUserFailure':
      return { ...state, isLoading: false };
    case 'EditUserBegin':
      return { ...state, isLoading: true };
    case 'EditUserSuccess':
      return { ...state, isLoading: false };
    case 'EditUserFailure':
      return { ...state, isLoading: false };
    case 'DeleteUserBegin':
      return { ...state, isDeleting: true };
    case 'DeleteUserSuccess':
      return { ...state, isDeleting: false };
    case 'DeleteUserFailure':
      return { ...state, isDeleting: false };
    case 'ClearUser':
      return { ...state, current: {} };
    default:
      return state;
  }
}
