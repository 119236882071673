import React from 'react';
import { arrayOf, shape, func, object, string } from 'prop-types';
import clsx from 'clsx';
import { List, ListItem } from '@mui/material/';
import { styled } from '@mui/material/styles';

const StyledList = styled(List)(({ theme: { palette, spacing } }) => ({
  background: palette.background.default,
  padding: `${spacing()} ${spacing(2)}`,
}));

const StyledListItem = styled(ListItem)(
  ({ theme: { palette, spacing, typography } }) => ({
    borderBottomColor: palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    fontSize: typography.body1.fontSize,
    height: spacing(6),
    padding: 0,

    '&:last-of-type': {
      borderBottomWidth: 0,
    },
  })
);

const renderRows = (rowRenderer, data, classes) =>
  data.map((entry, index) => (
    <StyledListItem
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      className={clsx('sovosList__item', classes?.row)}
    >
      {rowRenderer(entry)}
    </StyledListItem>
  ));

/**
 * Unlike the Material UI `List`, this component does not take children.
 * Instead, child list items are generated based on `data` and `rowRender` props.
 */
const SovosList = ({
  data,
  sortFunction,
  classes,
  className,
  rowRenderer,
  ...props
}) => {
  let sortedData = data;
  if (typeof sortFunction === 'function') {
    sortedData = sortedData.slice().sort(sortFunction);
  }

  const rows = renderRows(rowRenderer, sortedData, classes);

  return (
    <StyledList
      className={clsx('sovosList', classes?.root, className)}
      {...props}
    >
      {rows}
    </StyledList>
  );
};

SovosList.propTypes = {
  /**
   * Data provided to the getRows function.
   */
  data: arrayOf(shape).isRequired,
  /**
   * Function used to get the row items.
   */
  rowRenderer: func.isRequired,
  /**
   * Function used to sort provided data
   */
  sortFunction: func,
  /**
   * Override or extend the styles applied to the component
   */
  classes: object,
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosList.defaultProps = {
  classes: undefined,
  className: undefined,
  sortFunction: undefined,
};

SovosList.baseComponent = {
  name: 'List',
  link: 'list/',
};

export default SovosList;
