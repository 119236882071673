import React from 'react';

const ToS = (
  <section>
    <h1>Sovos Compliance, LLC </h1>
    <h1>Terms of Service</h1>
    <h3>Last modified on 01/01/2019</h3>
    <hr />
    <p>
      This Services Agreement (“<strong>Agreement</strong>”) is made between
      Sovos Compliance, LLC (“Sovos”) and you, or, if you represent an entity or
      other organization, that entity or organization (in either case,
      “Client”). Sovos provides certain online tools, software and other
      services that offer Client the ability to manage specified aspects of
      Client’s business (the “<strong>Services</strong>”) through the web site
      located at www.shipcompliant.com and through various other websites
      operated by and on behalf of Sovos (the “Site”). Sovos is willing to
      provide Client with access to and use of the Services and each Site solely
      under the terms of this Agreement. Unless otherwise expressly specified in
      an agreement agreed to by an authorized representative of Sovos, all
      access to and use of any Services by Client is subject to the terms of
      this Agreement.
    </p>
    <p>
      PLEASE CAREFULLY READ THIS AGREEMENT. BY ACCESSING OR USING THE SITE OR
      SERVICES (OR BY SUBMITTING AN ORDER TO ACCESS OR USE ANY SERVICES), CLIENT
      AGREES THAT IT HAS READ AND AGREES TO BE BOUND BY THE TERMS AND CONDITIONS
      OF THIS AGREEMENT.{' '}
    </p>
    <p>
      <strong>1. Definitions. </strong>Terms used in this Agreement will have
      the definitions given in this Agreement or, if not defined in this
      Agreement, will have their plain English meaning as commonly interpreted
      in the United States.
    </p>
    <p>
      <strong>2. Orders and Confirmation.</strong> Client may complete and
      submit multiple order forms to Sovos under this Agreement (whether in
      paper or physical form, online through the Site or Services or in other
      electronic form) seeking to access or use the Services (each, an “Order
      Form”). Unless expressly stated on an Order Form, all Order Forms placed
      by Client will be governed by the terms of this Agreement. Sovos will
      accept Order Forms placed by Client by providing notice to Client or by
      providing Client with access to the Services specified in the Order Form
      (in each case, a “Confirmation” of the Order Form). Following
      Confirmation, each Order Form will be subject to the terms of this
      Agreement and Client may access and use the Services subject to
      Confirmation by Sovos under the terms of this Agreement. If any conflict
      arises between the terms of any Order Form and the terms of this
      Agreement, the terms of this Agreement will control over the terms of that
      Order Form, except where the Order Form expressly states the intent to
      supersede a specific portion of the Agreement. If the terms of any Order
      Form conflict with the terms of any Confirmation of that Order Form, the
      terms of the Confirmation will control over the terms of the Order Form.
    </p>
    <p>
      <strong>3. Term. </strong>This Agreement will begin as of the earlier of
      the date (the “Effective Date”) that Sovos first provides Client with a
      Confirmation of an Order Form placed by Client or first provides Client
      with access to or use of any portion of the Site or Services and shall
      continue in full force and effect until the expiration or termination of
      all Order Forms, unless otherwise terminated earlier as provided
      hereunder. The initial term of the applicable Order Form(s) shall be set
      forth in the Order Form. Unless otherwise specified in the Order Form, the
      term of the Order Form shall automatically renew for the same length of
      time as the initial term unless terminated as set forth in Section 4.{' '}
    </p>
    <p>
      <strong>4. Termination.</strong> This Agreement may be terminated by (i)
      either party providing the other party with notice of its intent not to
      renew the term of this Agreement at least 90 day prior to the end of the
      initial term or then-current renewal term, or (ii) either party
      immediately upon written notice if the other party commits a
      non-remediable material breach of this Agreement or if the other party
      fails to cure any remediable breach of the Agreement or provide a written
      plan of cure acceptable to the non-breaching party within 30 days of being
      notified in writing of such breach.{' '}
    </p>
    &nbsp;
    <p>
      <strong>5. Effect of Termination.</strong> Upon termination or expiration
      of this Agreement for any reason, (1) all rights granted under this
      Agreement will terminate and Client must immediately cease all access to
      and use of the Services; (2) Sovos will have no further obligation to
      provide any Services to Client, in whole or in part; (3) all Fees incurred
      under this Agreement will be immediately due and payable by Client; (4)
      Client will return to Sovos or destroy (at Sovos’ direction) all
      Confidential Information of Sovos in Client’s possession. Within 30 days
      following termination, Client may request that Sovos provide a copy of
      Client Data in Sovos’ possession to Client in a mutually agreeable
      electronic format. If Client has not paid all invoiced fees (except with
      respect to charges then under reasonable and good faith dispute), Sovos
      reserves the right to hold the Client Data until such time that all fees
      have been paid. After such 30-day period, Sovos shall have no obligation
      to maintain or provide any Client Data.{' '}
    </p>
    &nbsp;
    <p>
      <strong> 6. Client Responsibilities.</strong>
    </p>
    <strong />
    <p>
      <strong>6.1. Direction and Control. </strong>The Services provide Client
      with access to certain online tools, software and other services for use
      by Client in the management of Client’s business. Client acknowledges that
      the Services are designed to aide Client in its business and that Client
      maintains direction and control over the use of the Services and the
      management of its business, including all taxes, registration fees,
      license fees, and other charges and payments (“Payments”) and all reports,
      registrations, licenses, postings, filings or other documents
      (“Documents”) prepared, processed, or submitted using the Services. Client
      agrees that Client is solely responsible for determining the
      appropriateness and suitability of the Services for use in Client’s
      business and for using the Services to meet Client’s requirements,
      including all&nbsp;equirements under applicable international, federal,
      state, and local treaties, laws, rules, regulations, andordinances
      (“Laws”) based on Client’s particular circumstances.{' '}
    </p>
    <p>
      {' '}
      <strong>6.2. No Tax or Professional Advice.</strong> The Services do not
      include, and Sovos does not provide, tax, accounting, financial, legal,
      compliance, or other professional advice or opinions regarding any
      requirements to which Client may be subject under applicable Law,
      including any Documents or Payments prepared or submitted using the
      Services. Client understands that Laws change frequently and their
      application varies widely based upon the specific facts and circumstances
      involved. Client agrees that Client will obtain the assistance of
      qualified tax, accounting, financial, legal, compliance, or other
      professional advisors as required in connection with Client’s use of the
      Services and compliance with applicable Laws.{' '}
    </p>
    <p>
      {' '}
      <strong>6.3. No Fiduciary Relationship</strong>. When Sovos provides the
      Services to Client, Sovos acts at the direction of Client to offer Client
      the ability to manage specific aspects of Client’s business. Under no
      circumstances will Client’s use of the Services create a fiduciary
      relationship between Client and Sovos or create any fiduciary obligation
      by Sovos to Client. Client agrees that Client and Sovos are independent
      contractors and this Agreement does not create a partnership, franchise,
      joint venture, agency, or employment relationship. Both Client and Sovos
      will remain responsible for paying each of its own employees, including
      employment related taxes and insurance.{' '}
    </p>
    <p>
      <strong> 6.4. Compliance</strong>. While the Services may be used by
      Client in managing Client’s business, Client remains solely and entirely
      responsible for Client’s compliance with all Laws regarding Client’s
      business, operations, products, and services, including, as applicable,
      with respect to the shipment of wine or other alcoholic beverages and the
      payment of all sales, use, value added, licensing and other taxes, fees,
      and charges that may be applicable to Client’s business or operations,
      products, or services. Without limiting the foregoing, Client will review:
      (a) its business and financial records and practices for errors or
      omissions; (b) all payments and Documents prepared, processed, or
      submitted using the Services prior to using such information for any
      purpose; and (c) all shipping decisions, compliance status and reports for
      errors prior to using such information for any purpose.{' '}
    </p>
    <p>
      <strong> 7. Services.</strong> Subject to and conditioned on compliance
      with the terms and conditions of this Agreement, during the term of this
      Agreement Sovos agrees to use commercially reasonable efforts to provide
      Client with the right to access and use the Services subject to
      Confirmations under this Agreement, solely for Client’s own benefit in
      connection with the management of Client’s business. Client’s rights to
      access and use the Services are personal, non-exclusive, non-transferable
      and non-sublicensable.{' '}
    </p>
    <p>
      {' '}
      <strong>8. Modifications to the Services.</strong> Sovos may from time to
      time update, change or revise the Site or Services. All such updates,
      changes or revisions will be effective when made available to Client on
      the Site or through the Services and will be deemed part of the “Site” or
      “Services,” respectively, for all purposes under this Agreement.{' '}
    </p>
    <p>
      <strong> 9. Access to the Services.</strong>
    </p>
    <p>
      {' '}
      <strong>9.1. Set-up.</strong> Client is solely responsible for the set-up
      and configuration of all Services, and all equipment and facilities
      required to access or use the Services, to meet Client’s business and
      legal requirements based on Client’s particular circumstances.
    </p>
    <p>
      {' '}
      <strong>9.2. Accounts and Users.</strong> Client will access all Services
      through an account (“Account”) established for Client. Client may
      designate an unlimited number of its employees, agents, and contractors
      (collectively, “Users”) to have access to Client’s Account. Client will be
      provided with a user identification and will select a password for
      Client’s Account (each such user identification and password, a “User
      ID”). Each User ID is personal in nature and may be used only by the
      applicable Users of Client. Client will ensure the security and
      confidentiality of each User ID and will notify Sovos immediately if any
      User ID is lost, stolen or otherwise compromised. Client acknowledges that
      Client is fully responsible for all Services provided, and all costs,
      fees, liabilities or damages incurred, through use of each User ID
      (whether lawful or unlawful).
    </p>
    <p>
      {' '}
      <strong>9.3. Responsibility.</strong> Client is solely responsible for all
      use of the Services through Client’s Account, for the actions or omissions
      of each User of Client’s Account, and for compliance by each User with the
      applicable terms of this Agreement. Client will ensure that all Users
      agree to and abide by the terms and conditions of this Agreement when
      accessing Client’s Account. Client acknowledges and agrees that Sovos may
      deem anyone accessing Client’s Account using a Client User ID as having
      legal authority to act on behalf of Client and that any Services or
      Documents ordered, provided or completed through Client’s Account will be
      deemed to have been lawfully authorized by Client.
    </p>
    <p>
      <strong>10. Access to Third-Party Services.</strong> The Services may
      include services or functionality developed, provided, or maintained by
      third-party service providers (“Third Party Services”). In addition to the
      terms of this Agreement, Client’s access to and use of any Third Party
      Services is also subject to any other terms separate from this Agreement
      that Client may enter into (or may have entered into) relating to those
      Third Party Services (“Third Party Service Terms”). Except as set forth in
      this Agreement, the terms of any Third Party Service Terms will control in
      the event of a conflict between the terms of this Agreement and those
      Third Party Service Terms. Third Party Services may be subject to
      additional Fees as described in each Order Form or through the Services.
    </p>
    &nbsp;
    <p>
      {' '}
      <strong>11. Access to Data.</strong>
    </p>
    <p>
      {' '}
      <strong>11.1. Client Data</strong>. All data, information and other
      content Client provides to Sovos through or in connection with the
      Services (“Client Data”), including as contained in any Document prepared
      or submitted through the Services, will remain owned by Client. Client
      will provide Sovos with all Client Data necessary for Sovos’ performance
      of the Services under this Agreement, including the accurate, complete and
      timely preparation and submission of all Documents through the Services.
      Client will provide all Client Data prior to each applicable time deadline
      provided through the Services or by Sovos (“Deadline”). Client
      acknowledges and agrees that Sovos does not audit, validate, or verify any
      Client Data and that Client is solely responsible for the accuracy,
      completeness, and timeliness of all Client Data. Sovos will be permitted
      to rely on any Client Data as accurate and complete in the performance of
      all Services, including the preparation and submission of all Documents
      through the Services.{' '}
    </p>
    <p>
      {' '}
      <strong>11.2. Service Data.</strong> Other than the Client Data provided
      by Client, all data, information and other content made available to
      Client through the Services (“Service Data”) is owned by Sovos and its
      third party service providers. Subject to and conditioned on compliance
      with the terms and conditions of this Agreement, Client may access and use
      the Service Data solely for Client’s own business purposes in connection
      with Client’s use of the Services. Sovos uses commercially reasonable
      efforts to maintain and verify that the Services and Service Data are
      accurate and current with respect to the information provided, but Sovos
      cannot verify the accuracy of, and will not be responsible for any errors
      or omissions in, any Service Data. All Services and Service Data are
      provided for informational purposes only and Client is solely responsible
      for verifying the accuracy, completeness and applicability of all Service
      Data and for Client’s use of and reliance on the Service Data.{' '}
    </p>
    <p>
      {' '}
      <strong>12. Restrictions.</strong> Client acknowledges that the Services,
      Service Data, and the databases, software, hardware and other technology
      used by or on behalf of Sovos to provide the Services (the “Technology”)
      and their structure, organization, and underlying data, information and
      source code constitute valuable trade secrets of Sovos. Client will not,
      and will not permit any third party to: (1) access or use the Services or
      Service Data, in whole or in part, except as expressly provided in this
      Agreement; (2) violate any policy of Sovos posted or provided through or
      in connection with the Services; (3) use the Services or Service Data in
      any unlawful manner or in any other manner that could damage, disable,
      overburden or impair the Services; (4) use automated scripts to collect
      information from or otherwise interact with the Services or Service Data;
      (5) alter, modify, reproduce, reverse engineer, create derivative works of
      the Services, Service Data or Technology; (6) distribute, sell, resell,
      loan, lease, license, sublicense or transfer any of Client’s rights to
      access or use the Services or Service Data, including, without limitation,
      by providing outsourced, hosted, or other services to third parties using
      the Services or Service Data or otherwise making the Services or Service
      Data, or access thereto, available to any third party; (7) attempt to
      circumvent or overcome any technological protection measures intended to
      restrict access to any portion of the Services, Service Data or
      Technology; or (8) interfere in any manner with the operation or hosting
      of the Services, Service Data or any Technology, or attempt to gain
      unauthorized access to the Services, Service Data or any Technology.
      Client will not allow any access to or use of the Services or Service Data
      by anyone other than Client’s authorized Users, and any such use will be
      consistent with the terms, conditions and restrictions set forth in this
      Agreement.{' '}
    </p>
    <p>
      <strong> 13. AutoFile Services.</strong>
    </p>
    <p>
      {' '}
      <strong>13.1. Access and Use. </strong>If Client elects to receive
      AutoFile Services, as defined below from Sovos, then the terms of this
      Section 13 will apply to Client’s access to and use of the AutoFile
      Services. All AutoFile Services will be considered part of the “Services”
      under this Agreement and be subject to the terms and conditions of this
      Agreement in addition to the terms of this Section 13.
    </p>
    <p>
      <strong>13.2. AutoFile Services. </strong>The “AutoFile Services” include
      Sovos’ assistance in the preparation and submission of the Payments and
      Documentation (“Filings”) Client provides to applicable federal, state or
      local government agencies or divisions (“Recipients”). By purchasing an
      edition of the Services that includes the AutoFile Services, Client agrees
      to use the AutoFile Services in accordance with the terms of this
      Agreement and agrees to pay to Sovos all applicable Fees associated with
      each such Filing.{' '}
    </p>
    <p>
      {' '}
      <strong>13.3. Review and Validation.</strong> Client is solely responsible
      for confirming the applicability of each Filing and will review all
      Filings prior to submission by Sovos to the applicable Recipient and
      validate that each Filing and all Client Data and other data, information
      or content contained therein is accurate, complete, timely, and being made
      in compliance with all applicable Laws. Sovos assumes no responsibility
      for any failure by Client to do any of the foregoing or for any errors,
      omissions, or inaccuracies in any Client Data or in any Filing based on or
      relating to any Client Data. Client agrees to provide Sovos with any
      corrections or other changes to each Filing or any Client Data prior to
      all applicable Deadlines established by Sovos for the Payment or Filing.
    </p>
    <p>
      <strong> 13.4. Hold Requests.</strong> Client may submit a request to
      Sovos as indicated through the AutoFile Services to place a hold on the
      preparation or submission of a Payment or Filing prior to the Deadline for
      that Payment or Filing established by Sovos (each, a “Hold Request”).
      Sovos will not be required to accept or honor any Hold Request unless
      submitted as indicated by Sovos through the AutoFile Services prior to the
      applicable Deadline for the applicable Payment or Filing. Following
      receipt of a Hold Request, Sovos will have no further responsibility for
      preparing or submitting any Payment or Filing subject to that Hold Request
      and Client will be solely and entirely responsible for preparation and
      submission of each such Payment or Filing.
    </p>
    <p>
      {' '}
      <strong>13.5. Payment Account</strong>. Client will designate one of
      Client’s bank accounts to be used for making Payments made through the
      AutoFile Services (the Client “Payment Account”). Client will ensure that
      sufficient funds are maintained in the Payment Account on or before all
      applicable Deadlines established by Sovos. Sovos will not be responsible
      for providing any funds to make Payments on Client’s behalf and will not
      be responsible for any failure to maintain funds in Client’s Payment
      Account sufficient to make any Payment. If Client’s Payment Account
      contains insufficient funds to make any Payment, Client will immediately
      deposit all required funds into the Payment Account. Sovos is not required
      to confirm that Client’s Payment Account contains sufficient funds to make
      any Payment. Without limiting the foregoing, if for any reason Client’s
      Payment Account does not contain sufficient funds to make any Payment,
      Sovos may refuse to submit that Payment until sufficient funds are
      available in the Payment Account, may submit any Filing to an applicable
      Recipient without proper or sufficient Payment, or may cancel submission
      of that Payment and refuse to proceed further with the Payment or any
      related Filing. In each case it will be Client’s sole responsibility to
      ensure that all required Filings and Payments are made directly to the
      applicable Recipient and Sovos will not be responsible for any penalties,
      fines, interest, or other assessments due as a result of failure to submit
      any such Filing or Payment.
    </p>
    <p>
      {' '}
      <strong>13.6. Credentials.</strong> Client will submit all credentials and
      other data or information necessary for Sovos to submit each Payment or
      Filing with each applicable Recipient (“Credentials”) prior to the
      applicable Deadline established by Sovos. Client will submit all
      Credentials to Sovos through the AutoFile Services as indicated by Sovos.
      Sovos will assume all Credentials are valid and is not required to confirm
      the accuracy or validity of any Credentials. Sovos will not be responsible
      for any failure by Client to submit any valid Credentials to Sovos prior
      to any applicable Deadline. Without limiting the foregoing, if for any
      reason Client fails to submit valid Credentials to Sovos prior to an
      applicable Deadline, Sovos may refuse to submit any Payment or Filing for
      which such Credentials are required.
    </p>
    <p>
      {' '}
      <strong>13.7. Preparation and Submission.</strong> Unless Client submits a
      valid Hold Request for a Payment or Filing to Sovos in accordance with
      this Agreement, Client acknowledges and agrees that Sovos may prepare and
      submit that Payment or Filing to the applicable Recipient, provided that
      Sovos will not be responsible for any failure or delay in submitting any
      Payment or Filing if Client fails to meet any applicable Deadline for that
      Payment or Filing or is otherwise not in compliance with the terms of this
      Agreement.
    </p>
    <p>
      <strong> 13.8. AutoFile Payments.</strong> Payments may be made by Sovos
      by check or electronic funds transfer, as indicated through the AutoFile
      Services. In the case of Payment by check, Sovos may print a check payable
      from Client’s Payment Account and submit that check to the applicable
      Recipient receiving such Payment. Alternatively, Sovos may withdraw and
      transfer the applicable Payment amount from Client’s Payment Account to a
      bank account controlled by Sovos and make Payment to the applicable
      Recipient from that account. If Client has elected to have any Payments
      made by electronic funds transfer, Client authorizes Sovos to withdraw all
      applicable Payment amounts from Client’s Payment Account and arrange for
      such amounts to be remitted to the applicable Recipient. Client
      acknowledges that Sovos may aggregate any of Client’s Payment amounts with
      those of other clients of Sovos and make a combined payment to any
      Recipient. Client agrees to and hereby does grant Sovos all rights and
      powers necessary to enable Sovos to make all Payments from Client’s
      Payment Account in whatever form Client has indicated or agreed through
      the AutoFile Services. Client will provide Sovos with all data,
      information and credentials regarding Client’s Payment Account necessary
      for Sovos to make all such Payments.{' '}
    </p>
    <p>
      <strong>14. Data Privacy. </strong>Use of the Services is subject to the
      terms and conditions of this Agreement and Sovos’ then-current privacy
      policy http://sovos.com/privacy (the “Privacy Policy”). Sovos agrees to
      treat all personally identifiable Client Data (“Client Personal Data”)
      consistent with the Privacy Policy. Client represents and warrants to
      Sovos that Client has all necessary right, title, interest and consent
      necessary to allow Sovos to use the Client Personal Data and other Client
      Data for the purposes for which Client provides such Client Personal Data
      and other Client Data to Sovos. Notwithstanding anything in the Privacy
      Policy (or the provisions of Section 20 below), Sovos may generate and
      collect non-personally identifiable information (information that does not
      identify an entity or person as the source thereof, including, without
      limitation, as such information relates to total volumes of shipments
      using the Services originating in Client’s state, total volumes of
      shipments using the Services from Client’s state into other states on a
      state-by-state basis and similar information) resulting from Client’s
      access to and use of the Services. To the extent any such information is
      generated or collected by Sovos, the information will be solely owned by
      Sovos and may be used by Sovos for any lawful business purpose without any
      obligation to Client under this Agreement, provided that such purpose does
      not directly identify Client or any of Client’s customers.{' '}
    </p>
    <p>
      <strong>15. Fees.</strong> Client agrees to pay Sovos all fees relating to
      Client’s access to and use of Services (“Fees”). Client understands that
      its right to use the Services is limited by the number of License Metrics
      purchased. “License Metrics” means the limitation on the usage of the
      Services as designated and/or defined in the applicable Order Form by a
      term such as the number of cases shipped or distributed. All fees are
      based on the License Metrics purchased and the quantity(ies) of License
      Metrics provided in the Order Form represent maximum amounts that Client
      has committed to for the Term. There shall be no fee adjustments or
      refunds for any decreases in usage or License Metrics during the Term. The
      Fees will include all fees associated with any applicable Filings and
      Payments prepared and submitted through the AutoFile Services, any
      applicable late fees of which Client is notified through the AutoFile
      Services to which Client is subject due to any failure by Client to meet
      any Deadline or otherwise comply with the terms of this Agreement. All
      Fees are as set forth on each applicable Order Form or as provided through
      the Services. Sovos may adjust any Fees at any time during the term of
      this Agreement, to be effective upon the next billing period following
      notice to Client. Sovos reviews all accounts on a trailing 12-month basis.
      If Client exceeds the License Metrics designated in the Order Form within
      the 12-month period, Sovos will adjust the monthly fee accordingly. All
      fees are shown in U.S. Dollars and all payments will be made in U.S.
      Dollars.{' '}
    </p>
    <p>
      <strong>16. Payment. </strong>Billing for all Fees will begin on the
      Effective Date. Client must pay for all Fees incurred when invoiced by
      Sovos. All Fees must be paid using ACH withdrawal from Client’s Payment
      Account or another bank account designated by Client (in either case,
      Client’s “Bank Account”) or by credit card designated by Client. Client
      agrees to have the Fees charged to Client’s Bank Account or Credit Card on
      file with Sovos when due and authorizes Sovos to automatically submit such
      charges to Client’s Bank Account or Credit Card. If payment of any Fees is
      declined for any reason, Sovos may make additional attempts to process
      payment. If a declined payment is not resolved through such attempts,
      access to and use of Client’s Account and all Services may be suspended or
      terminated by Sovos. All Fees will be non- refundable once paid to Sovos
      (including upon any termination or suspension of this Agreement). Until
      paid in full, all past due amounts will bear an additional charge of the
      lesser of 1 1/2% per month or the maximum amount permitted under
      applicable law. Client will be responsible for all expenses incurred by
      Sovos due to the use of collection agencies, attorneys, or courts of law
      for collection of any undisputed Fees from Client.{' '}
    </p>
    <p>
      <strong>17. Taxes. </strong>Client is solely responsible for all use,
      sales, excise, licensing, value added, and other applicable taxes and
      charges levied or imposed on either Client or Sovos that may result from
      Client’s use of or access to the Services, including relating to all
      Filings and Payments prepared and submitted through the Services (except
      any such taxes based on Sovos’ net income).{' '}
    </p>
    <p>
      <strong>18. Suspension.</strong> Sovos may suspend Client’s access to the
      Services upon any actual, threatened, or suspected breach of this
      Agreement or applicable Law or upon any other conduct deemed by Sovos to
      be inappropriate or detrimental to the Services, Sovos, or any other Sovos
      customer or user.{' '}
    </p>
    <p>
      <strong>19. Survival. </strong>The rights and obligations of each party
      under the following Sections will survive the expiration or termination of
      this Agreement for any reason: 1, 5, 6.2, 12, 16, 20, 21, 22, 23, 25, and
      28.{' '}
    </p>
    <p>
      2<strong>0. Confidentiality. </strong>The Services and Technology and the
      delivery and use thereof, this Agreement, and any other information
      related thereto, including, without limitation, any User IDs or other
      Account information, constitute confidential information and valuable
      trade secrets of Sovos. All information and data regarding Client’s
      customers, including without limitation, identities, addresses, purchasing
      patterns, and volume of purchases constitute confidential information and
      valuable trade secrets of Client. Such information is hereinafter referred
      to as the “Confidential Information” of each party. Each party agrees to
      protect the other party’s Confidential Information that is in its
      possession with the same or comparable security measures as it uses to
      protect its own Confidential Information, provided that such measures will
      be at least reasonable for such purpose. Each party agrees that, except as
      expressly directed by the other or as necessary for the purposes of this
      Agreement, it will not at any time during or after the term of this
      Agreement: (1) disclose any Confidential Information to any third party;
      (2) permit any third party to examine and/or make copies of any reports,
      documents or electronic data containing Confidential Information; or (3)
      use any of the Confidential Information. Each party may disclose
      Confidential Information to personnel having a need to know the
      Confidential Information in the performance of their duties under this
      Agreement, and will instruct them to keep such information confidential
      and each party will remain liable for such personnel’s compliance with
      this Section. Sovos may disclose Client’s Confidential Information on a
      need to know basis to its subcontractors who are providing all or part of
      the Services, provided those subcontractors have executed confidentiality
      agreements and further provided that Sovos shall remain liable for any
      unauthorized disclosure of Client’s Confidential Information by those
      subcontractors. If either party is required to disclose the other party’s
      Confidential Information pursuant to any statute, regulation, order,
      subpoena or document discovery request, it will furnish written notice of
      such disclosure to the other party as soon as practicable to afford such
      party the opportunity to seek a protective order and the party required to
      make such disclosure will reasonably cooperate in such efforts (at the
      other party’s reasonable expense). In the event of any conflict between
      the Privacy Policy and the provisions of this Section 20, the provisions
      of this Section 20 shall control.{' '}
    </p>
    <p>
      <strong>21. Ownership.</strong> Sovos retains all right, title and
      interest, including, without limitation, all IPR, in and to the Site,
      Services, and Technology, and any additions, improvements, updates, and
      modifications thereto. Client acknowledges that Client is not receiving
      any ownership interest in or to any of the foregoing, and no right or
      license is granted to Client to use them apart from Client’s limited right
      to access the Services under this Agreement. The Sovos name, logo and the
      product and service names associated with the Services are trademarks of
      Sovos (or its third party providers), and no right or license is granted
      to Client to use them. Client will not alter, obscure or remove any
      copyright, trademark or any other notices that are provided on or in
      connection with the Services, Service Data or Technology. For purposes of
      this Agreement, “IPR” means any and all intellectual property rights,
      proprietary rights, rights of publicity, rights of privacy, and any and
      all other legal rights protecting data, information or intangible property
      throughout the world, including, without limitation, any and all
      copyrights, trademarks, service marks, trade secrets, patent rights, moral
      rights, sui generis rights in databases, and contract rights.{' '}
    </p>
    <p>
      <strong>22. Warranties.</strong>
    </p>
    <p>
      <strong>22.1. Mutual. </strong>Each party hereby represents, warrants and
      covenants to the other party that: (1) this Agreement is a legal and valid
      obligation binding upon such party and enforceable in accordance with its
      terms; and (2) such party has full power and legal authority to enter into
      this Agreement and to carry out the provisions hereof without any
      additional consent or authorization.{' '}
    </p>
    <p>
      <strong>22.2. Client Warranties.</strong> In addition, Client represents,
      warrants, and covenants to Sovos that: (a) Client will comply with all
      applicable Laws, including all Laws applicable to Client’s access to and
      use of the Services and all reports, registrations, postings, or other
      filings or payments Client is required to prepare or submit under
      applicable Law; (b) Client’s use of the Services will not cause Sovos to
      violate any applicable Law; (c) all Client Data is and will remain
      current, accurate and complete; (d) Client has and will maintain all
      right, title, interest, and consents necessary to allow Sovos to use,
      process, and share all Client Data for purposes of providing all Services
      under this Agreement; and (e) Client will not make any statements
      concerning the Services that Client is not authorized by this Agreement.{' '}
    </p>
    <p>
      <strong>23. Disclaimer. </strong>ALTHOUGH SOVOS USES COMMERCIALLY
      REASONABLE EFFORTS TO PROVIDE CLIENT WITH ACCESS TO AND USE OF THE
      SERVICES, SOVOS PROVIDES THE SERVICES ONLY AS EXPRESSLY STATED HEREIN AND
      DOES NOT OFFER A GUARANTEE OF ANY KIND AS TO THE SERVICES OR ANY SERVICE
      DATA. CLIENT’S USE AND RELIANCE UPON THE SERVICES AND DATA IS AT CLIENT’S
      OWN RISK. EXCEPT AS EXPRESSLY STATED HEREIN, THE SERVICES AND SERVICE DATA
      ARE PROVIDED TO CLIENT “AS IS” AND SOVOS AND ITS SUPPLIERS AND LICENSORS
      EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND
      WITH REGARD TO THE SERVICES, SERVICE DATA, AND ANY SUBJECT MATTER OF THIS
      AGREEMENT, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF TITLE, NON-
      INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, FUNCTIONALITY OR
      MERCHANTABILITY, ACCURACY OF RESULTS OR INFORMATION, OR UNINTERRUPTED USE,
      WHETHER EXPRESS, IMPLIED OR STATUTORY. NO ORAL OR WRITTEN INFORMATION OR
      ADVICE GIVEN BY SOVOS, ITS EMPLOYEES, DISTRIBUTORS, DEALERS, OR AGENTS
      WILL INCREASE THE SCOPE OF THE ABOVE WARRANTIES OR CREATE ANY NEW
      WARRANTIES. Some jurisdictions do not allow the exclusion of certain
      warranties or the limitation or exclusion of liability for certain
      damages. Accordingly, some of the disclaimers, limitations and exclusions
      contained herein may not apply to Client. To the extent that Sovos may
      not, as a matter of applicable Law, disclaim any warranty or limit or
      exclude any liability, the scope and duration of such warranty and the
      extent of Sovos’ liability shall be the minimum permitted under such
      applicable Law.{' '}
    </p>
    <p>
      <strong>24. Indemnification.</strong> Subject to the remainder of this
      Section, Sovos will, at its expense, defend Client in any suit or cause of
      action alleging that the Services or any part thereof infringes any United
      States copyright, trade secret or trademark right, and Sovos will
      indemnify Client against all damages that a court finally awards as a
      result of such claim or agreed to in a settlement by the parties
      (including attorneys’ reasonable fees and court costs to the extent that
      Sovos fails to promptly assume such defense). The foregoing obligation of
      indemnification is conditioned up Client providing Sovos (i) with prompt
      notice of any such claim; (ii) sole control over the defense and
      settlement of such claim; and (iii) reasonable assistance (at Sovos’
      expense) in the defense and settlement of such claim. If the Services are
      held to infringe or believed to infringe on any United States copyright,
      trade secret or trademark right, Sovos, at Sovos’ option and expense, may
      procure the right for Client to continue using the Services or replace or
      to modify the Services so that they becomes non-infringing, or if neither
      of these options is practical in Sovos’ sole judgment, Sovos may terminate
      this Agreement with respect to such Services and provide Client a refund
      of the unused portion of any pre-paid amounts applicable to such Services.{' '}
    </p>
    <p>
      <strong>25. Limitation on Liability</strong>.{' '}
    </p>
    <p>
      25.1 REGARDLESS OF WHETHER ANY REMEDY SET FORTH HEREIN FAILS OF ITS
      ESSENTIAL PURPOSE OR OTHERWISE, TO THE GREATEST EXTENT PERMISSIBLE UNDER
      APPLICABLE LAW, EVEN IF SOVOS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      LOSS OR DAMAGES AND WHETHER OR NOT SUCH LOSS OR DAMAGES ARE FORESEEABLE,
      IN NO EVENT WILL SOVOS, ITS EMPLOYEES, AGENTS, SUPPLIERS, LICENSORS OR
      AFFILIATES BE LIABLE TO CLIENT OR TO ANY THIRD PARTY FOR ANY SPECIAL,
      INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF
      THIS AGREEMENT OR THE USE OR INABILITY TO USE THE SERVICES OR ANY DATA
      SUPPLIED THEREWITH, INCLUDING, WITHOUT LIMITATION, ANY LOST REVENUE,
      PROFITS OR BUSINESS OPPORTUNITY, LOST OR CORRUPTED DATA, OR INTERRUPTION
      OF BUSINESS.{' '}
    </p>
    <p>
      25.2 NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS AGREEMENT,
      SOVOS’ TOTAL LIABILITY FOR ANY AND ALL DAMAGES SHALL NOT EXCEED THE TOTAL
      FEES RECEIVED BY SOVOS FROM CLIENT FOR USE OF THE SERVICES UNDER THIS
      AGREEMENT IN FOR THE FIRST 12-MONTH PERIOD OF THE AGREEMENT.{' '}
    </p>
    <p>
      25.3 EACH PARTY EXPRESSLY ACKNOWLEDGES AND AGREES THAT THE FOREGOING
      LIMITATIONS OF LIABILITY AND ALLOCATION OF RISK REFLECT PART OF THE
      BARGAINED-FOR EXCHANGE OF THE PARTIES WITH RESPECT TO THIS AGREEMENT AND
      THE SERVICES. THIS AGREEMENT IS NOT INTENDED TO LIMIT CLIENT’S
      RESPONSIBILITY OR LIABILITY WITH RESPECT TO ANY FILING OR PAYMENT REQUIRED
      TO BE MADE BY CLIENT UNDER APPLICABLE LAW, WHETHER OR NOT SUCH FILING OR
      PAYMENT IS MADE BY SOVOS ON BEHALF OF CLIENT UNDER THIS AGREEMENT. CLIENT
      WILL REMAIN SOLELY RESPONSIBLE FOR ALL SUCH FILINGS AND PAYMENTS CLIENT IS
      REQUIRED TO MAKE UNDER APPLICABLE LAW.{' '}
    </p>
    <p>
      <strong>26. Notices. </strong>All notices to Client provided for under
      this Agreement or relating to the operation of the Services may be sent by
      Sovos electronically via electronic mail or through posting on Site or
      Services. All other notices, consents, and communications provided for
      under this Agreement (including all notices Client provides to Sovos) will
      be in writing and will be delivered by hand or sent by express courier or
      delivery service that guarantees delivery within 48 hours (such as Federal
      Express or DHL) to the address of the party receiving such notice or to
      such other address as the party may designate in writing, or by electronic
      mail. Such notices, consents and communications will be deemed to have
      been received by the addressee upon confirmed receipt, but in no event
      later than 48 hours after the notice or communication is delivered to a
      courier or delivery service that guarantees delivery within 48 hours.{' '}
    </p>
    <p>
      <strong>27. Force Majeure.</strong> If Sovos is unable to perform any of
      its obligations under this Agreement because of any event beyond its
      reasonable control and foreseeability, including, without limitation, any
      natural disaster, labor disputes, actions or decrees of governmental
      entities, riots, war, terrorism, or epidemics (a “Force Majeure Event”),
      the obligations of Sovos under this Agreement prevented by such Force
      Majeure Event will be suspended for the duration of the Force Majeure
      Event, provided that Sovos exercises reasonable efforts to resume
      performance of such obligations as soon as possible despite such Force
      Majeure Event. The Services are intended to assist Client in managing
      aspects of its business and as such are dependent upon Client’s
      performance of its obligations under this Agreement and as stated through
      the Services. Client is solely responsible for any failure or delay in the
      performance of the Services or other obligation of Sovos under this
      Agreement due to Client’s failure or delay in performance, including any
      applicable late Fees under this Agreement, any applicable penalties,
      fines, interest or other assessments imposed on Client, Sovos or any third
      party, or any other liabilities or damages.{' '}
    </p>
    <p>
      <strong>28. General. </strong>This Agreement will be governed by the laws
      of the Commonwealth of Massachusetts, without regard to or application of
      conflicts of law rules or principles. The parties explicitly disclaim the
      application of the United Nations Convention on the Sale of Goods. Any
      action or lawsuit related to this Agreement must be brought exclusively in
      either the Federal or State Courts for the Commonwealth of Massachusetts,
      and each party hereby irrevocably submits and waives any objection to the
      exclusive jurisdiction and forum of such courts. If any provision of this
      Agreement is held to be unenforceable, that provision will be removed to
      the extent necessary to comply with the law, replaced by a provision that
      most closely approximates the original intent and economic effect of the
      original to the extent consistent with the law, and the remaining
      provisions will remain in full force. Unless withdrawn upon written
      request, Client allows Sovos to publicize its relationship with Sovos. The
      failure of either party to require performance by the other party of any
      provision hereof will not affect the full right to require such
      performance at any time thereafter, nor will the waiver by either party of
      a breach of any provision hereof be taken or held to be a waiver of the
      provision itself. Neither this Agreement nor any rights or obligations of
      Client hereunder may be assigned by Client (in whole or in part and
      including by sale, merger, operation of law, or otherwise) without the
      prior written approval of Sovos. Any assignment in derogation of the
      foregoing will be null and void. Sovos may assign this Agreement to any
      party that assumes Sovos’ obligations hereunder. This Agreement includes
      all Order Forms and Confirmations under this Agreement and is the complete
      and exclusive statement of the agreement between the parties. This
      Agreement supersedes any proposal or prior agreement, oral or written, and
      any other communications between the parties in relation to the subject
      matter of this Agreement. The provisions of this Agreement are for the
      sole benefit of the parties and their successors and permitted assigns,
      and they will not be construed as conferring any rights to any third party
      (including any third party beneficiary rights). Any reference herein to
      “including” will mean “including, without limitation.” Upon request from
      Sovos, Client agrees to provide Sovos with such documentation or records
      with respect to Client’s activities under this Agreement, including access
      and use by Client of the Services, as may be reasonably requested for
      Sovos to verify Client’s compliance with the terms of this Agreement. This
      Agreement and any Order Form may be executed or agreed to by the parties
      in multiple counterparts (including via facsimile or online or electronic
      method approved by Sovos), each of which will be deemed an original and
      all of which will constitute one and the same instrument. Photocopies,
      facsimiles and other electronic copies of original signatures in a form
      approved by Sovos will have the same force and effect as original
      signatures. Sovos reserves the right to amend or modify this Agreement at
      any time and from time to time by providing notice to Client (including
      notice through the Services). Any amendment or modification will be
      effective as to Client’s continued use of the Services 30 days following
      such notice. Client’s continued use of the Services, or submission of a
      subsequent Order Form, shall serve as Client’s agreement to any such
      amendment or modification.{' '}
    </p>
    <p>&nbsp;</p>
  </section>
);

export default ToS;
