import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';

const StyledContent = styled(CardContent)(({ theme: { spacing } }) => ({
  padding: spacing(2),
  '&:last-child': {
    paddingBottom: spacing(2),
  },
}));

/**
 * **Note** Must be a child of `SovosCard`
 */
const SovosCardContent = ({ className, ...rest }) => (
  <StyledContent className={clsx('sovosCardContent', className)} {...rest} />
);

SovosCardContent.propTypes = {
  /**
   * Extend the class name applied to the root element
   */
  className: string,
};

SovosCardContent.defaultProps = {
  className: undefined,
};

SovosCardContent.baseComponent = {
  name: 'Card Content',
  link: 'card-content/',
};

export default SovosCardContent;
