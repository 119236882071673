import clsx from 'clsx';

export function mergeClassNameIntoProps(props, className) {
  if (!props) {
    return props;
  }

  return {
    ...props,
    className: clsx(props.className, className),
  };
}

export function normalizeClassnames(className) {
  // Check for null or undefined but allow other falsy values to be serialized
  if (className != null) {
    // eslint-disable-line
    return className.toString().replace(/[\W]/gi, '');
  }

  return className;
}
