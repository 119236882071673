export default {
  current: {
    first: 'Adam',
    last: 'Vandelay',
    email: 'adam.vandelay@hmail.com',
    alternateEmail: 'avandelay@imail.com',
    sms: '303-555-1212',
    id: '7',
    accountType: 'Super Duper User',
    password: 'password123',
    passwordLastChanged: '05/15/19',
  },
  isDirty: false,
};
